const toggleClass = function () {
  return {
    restrict: 'A',
    link(scope, el) {
      el.click(() => {
        $(el.attr('data-target')).toggleClass(el.attr('toggle-class'));
      });
    },
  };
};

export default toggleClass;
