const RedirectRouter = {
  route: (module) => {
    module.config(['$urlRouterProvider', ($urlRouterProvider) => {
      $urlRouterProvider.when('/courses/contact-us', '/about-us');
      $urlRouterProvider.when('/courses/participation', '/courses/participation-test-cycle-primer');
      $urlRouterProvider.when('/articles/personalizing-your-utest', '/articles/getting-started-with-the-new-utest');
      $urlRouterProvider.when('/category/paid-project-essentials', '/courses');
      $urlRouterProvider.when('/courses/paid-project-essentials', '/faq');
      $urlRouterProvider.when('/invitations/#sandboxprogram', '/courses/invitations#anchor1');
      $urlRouterProvider.when('/automation', '/automation-mobile-and-web-apps');
      $urlRouterProvider.when('/category/paid-project-essentials/participation', '/courses/getting-started');
      $urlRouterProvider.when('/discussion', '/forums');
      $urlRouterProvider.when('/discussion/category/:categoryId', '/forums/category/:categoryId');
      $urlRouterProvider.when('/discussion/:discussionId/page/:page', '/forums/:discussionId/page/:page');
      $urlRouterProvider.when('/discussion/:discussionId', '/forums/:discussionId');
      $urlRouterProvider.when('/forums/:forumId', '/forums/:forumId/page/1');
      $urlRouterProvider.when('/new/discussion', '/new/forums');
      $urlRouterProvider.when('/my-discussions', '/my-forums');
      $urlRouterProvider.when('/gamification', '/upoints');
      $urlRouterProvider.when('/new-utester-frequently-asked-questions', '/articles/getting-started-with-the-new-utest');
      $urlRouterProvider.when('/resources-tools', '/search-result/tag/Tools');
      $urlRouterProvider.when('/resources-best-practices/#coc', '/courses/participation-communication-guidelines');
      $urlRouterProvider.when('/terms-use', '/terms-and-conditions');
      $urlRouterProvider.when('/index.html', '/');
      $urlRouterProvider.when('/careers', '/projects');
    }]);
  },
};

export default RedirectRouter;
