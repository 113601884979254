import _ from 'lodash';

class NavSidebarItemCtrl {
  constructor($state, $transitions) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.state = $state;
      ctrl.isSelected = false;

      if (_.intersection(ctrl.state.current.name.split('.'), ctrl.highlight.split(',')).length > 0) {
        ctrl.isSelected = true;
      }

      $transitions.onSuccess({}, (transition) => {
        // The state is split on . for the state name. We split on comma in the highlight since we pass it in that way
        if (_.intersection(transition.to().name.split('.'), ctrl.highlight.split(',')).length > 0) {
          ctrl.isSelected = true;
        } else {
          ctrl.isSelected = false;
        }
      });
    };
  }
}

NavSidebarItemCtrl.$inject = ['$state', '$transitions'];

export default NavSidebarItemCtrl;
