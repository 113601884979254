export default `
<div id="mainContent">
  <div class="block container">
    <div class="section-home-cover">
      <div class="tool-category">
        <div class="tool-btn">
          <a id="coverBtn" ui-sref="tool" class="btn btn-rounded small btn-filled">Back to Overview</a>
        </div>
        <h2>{{category.name}}</h2>
        <p>{{category.description}}</p>
      </div>
    </div>
  </div>
  <div class="block container clearfix tool">
    <uib-tabset>
      <uib-tab heading="Tools">
        <tool-feed-item tool="tool" ng-repeat="tool in groups.highestRated.feedItems" class="section-home-feed"></tool-feed-item>
        <load-more load-callback="groups.highestRated.loadMore()" is-loading="groups.highestRated.isLoading" is-last-page="groups.highestRated.isLastPage" fade-target="footer"></load-more>
      </uib-tab>
      <uib-tab heading="Recently Added" select="groups.recentlyAdded.loadOnce()">
        <tool-feed-item tool="tool" ng-repeat="tool in groups.recentlyAdded.feedItems" class="section-home-feed"></tool-feed-item>
        <load-more load-callback="groups.recentlyAdded.loadMore()" is-loading="groups.recentlyAdded.isLoading" is-last-page="groups.recentlyAdded.isLastPage" fade-target="footer"></load-more>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
