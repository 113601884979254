/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import embed from './embed';
import upload from './upload';
import template from './markdown-guide.html';

const customBtns = ({
  $scope, $element, $compile, easymde, AlertService, $q, $cookies, $uibModal, $state, csrfCookieName,
}) => {
  const out = {
    emoji: {
      name: 'emoji',
      action() {
        const url = $state.href('emoji.category');
        const wnd = window.open(url, '_blank');
        wnd.opener = null;
      },
      title: 'Emoji',
      className: 'fa fa-smile-o',
    },
    guide: {
      name: 'guide',
      action() {
        $uibModal.open({
          animation: true,
          template,
          controller: 'ModalCtrl',
          resolve: {
            data: null,
          },
        });
      },
      title: 'Markdown Guide',
      className: 'fa fa-question',
    },
  };

  const embedInstance = embed({
    $scope, $element, $compile, easymde,
  });
  const uploadInstance = upload({
    $element, $scope, $compile, AlertService, easymde, $q, $cookies, csrfCookieName,
  });
  return _.merge(out, embedInstance, uploadInstance);
};

export default customBtns;
