import controller from './upload-btn.controller';
import template from './upload-btn.component.html';

const uploadBtn = {
  bindings: {
    onChange: '&',
  },
  template,
  controller,
};

export default uploadBtn;
