const countWord = function (number) {
  switch (number) {
    case 0:
      return 'No';
    default:
      return number;
  }
};

const pluralize = function () {
  return function (number, singular, plural) {
    number = number || 0;
    plural = plural || (`${singular}s`);

    const word = (number === 1) ? singular : plural;

    return `${countWord(number)} ${word}`;
  };
};

export default pluralize;
