export default `
<div class="community-feed-item-comment">
  <div ntux-avatar-badges="$ctrl.comment.user" class="community-feed-item-comment__avatar"></div>
  <div class="community-feed-item-comment__content">
    <div class="community-feed-item-comment__header">
      <div class="community-feed-item-comment__user">{{$ctrl.comment.user.name}}</div>
      <div class="community-feed-item-comment__date">{{$ctrl.comment.published_at | relativeDate}}</div>
    </div>
    <div class="community-feed-item-comment__body" ng-bind-html="$ctrl.comment.content_built"></div>
  </div>
</div>
`;
