const playerSugar = function ($transitions) {
  return {
    restrict: 'A',
    link() {
      $transitions.onStart({}, () => {
        if (!(<any>window).embedly) return;

        embedly('player', (player) => {
          player.pause();
          player.on('play', () => {
            embedly('player', (player2) => {
              if (player2.url !== player.url) {
                player2.pause();
              }
            });
          });
        });
      });
    },
  };
};

playerSugar.$inject = ['$transitions'];

export default playerSugar;
