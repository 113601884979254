import template from './community-featured.component.html';
import controller from './community-featured.controller';

const communityFeatured = {
  bindings: {
    feed: '<',
  },
  template,
  controller,
};

export default communityFeatured;
