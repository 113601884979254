// the import order here matters. If mainModule comes too late, the uTestV2 AngularJS module
// isn't loaded before downgraded-components attempts to access it, and the app doesn't run.
import 'zone.js';
import 'core-js/proposals/reflect-metadata';
import 'core-js/features/reflect';
import angular from 'angular';
import { mainModule } from './app'; // eslint-disable-line
import { enableProdMode, NgZone } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { setAngularJSGlobal } from '@angular/upgrade/static';
import { UIRouter, UrlService } from '@uirouter/core';
import { environment } from './environments/environment';

import { AppModule } from './app/ng-app/app.module';

if (environment.production) {
  enableProdMode();
}

setAngularJSGlobal(angular);

mainModule.config(['$urlServiceProvider', ($urlService: UrlService) => $urlService.deferIntercept()]);

platformBrowserDynamic().bootstrapModule(AppModule).then(platformRef => {
  const { urlService }: { urlService: UrlService } = platformRef.injector.get(UIRouter);

  function startUIRouter() {
    urlService.listen();
    urlService.sync();
  }

  platformRef.injector.get<NgZone>(NgZone).run(startUIRouter);
});
