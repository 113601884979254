import { Component, Input } from '@angular/core';

@Component({
  selector: 'four-oh-one',
  templateUrl: './four-oh-one.component.html',
})
export class FourOhOneComponent {
  @Input() returnToUrl: string;
  @Input() user: any;
}
