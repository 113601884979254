class CourseTrackService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  all() {
    const parameters: any = {};
    // currently we have no way to turn off pagination for a specific index endpoint,
    // so pass a high value to make sure all tracks come back
    parameters.page = 1;
    parameters.per_page = 50;

    parameters.sort = 'position';

    return this.$http.get('/api/v1/course_tracks', { params: parameters });
  }

  track(trackId) {
    return this.$http.get(`/api/v1/course_tracks/${trackId}`, { params: { include_course: true } });
  }

  trackWithQuiz(trackId) {
    return this.$http.get(`/api/v1/course_tracks/${trackId}`, { params: { include_quiz: true } });
  }

  trackWithCourse(trackId, courseId) {
    return this.$http.get(`/api/v1/course_tracks/${trackId}`, { params: { course_id: courseId, include_course: true } });
  }

  courses(trackId) {
    return this.$http.get(`/api/v1/course_tracks/${trackId}/courses`);
  }

  submitQuiz(trackId, formData) {
    return this.$http.post(`/api/v1/course_tracks/${trackId}/submit_answers`, { user_choices: formData });
  }

  updateCourseTrackProgress(trackId, options) {
    return this.$http.put(`/api/v1/course_tracks/${trackId}/update_user_course_track_progress`, options);
  }

  getPendingAcademyTestCycleByLevel(level) {
    return this.$http.get(`/api/v1/test_cycles/academy/${level}`);
  }

  addTesterToTestCycle(data) {
    return this.$http.post('/api/v1/test_cycles/academy/add_tester', data);
  }
}

CourseTrackService.$inject = ['$http'];
export default CourseTrackService;
