/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { asapScheduler, BehaviorSubject, Observable } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';
import { Option } from '../../option/option';
import { OptionsRootHolder } from '../../options-root-holder';
import { OptionSelectionService } from '../option-selection.service';
import { SelectionEvent } from '../selection-event';
import { MultiSelection } from './multi-selection';

@Injectable()
export class OptionMultiSelectionService extends OptionSelectionService {
  constructor(root: OptionsRootHolder) {
    super(root);
  }

  private readonly selection = new BehaviorSubject<MultiSelection>({values: [], userInput: false});

  readonly selection$: Observable<MultiSelection> = this.selection.pipe(
    // we need to observe on asapScheduler
    // to avoid ExpressionChangedAfterItHasBeenCheckedError in some situations
    observeOn(asapScheduler)
  );

  getSelection(): MultiSelection {
    return this.selection.value;
  }

  selectAll(event: MultiSelection): void {
    this.selection.next(event);
  }

  select(event: SelectionEvent): void {
    const selected = this.selection.value.values || [];

    const newOptions = selected.some(value => super.compareValues(event.value, value))
      ? selected.filter(value => !super.compareValues(event.value, value))
      : [...selected, event.value];

    this.selection.next({values: newOptions, userInput: event.userInput});
  }

  isSelected$(option: Option): Observable<boolean> {
    return this.selection$.pipe(
      map(event => event.values?.some(value => super.compareValues(option.value, value))),
    );
  }

  isAnySelected$(options: Option[]): Observable<boolean> {
    return this.selection$.pipe(
      map(event => options.some(option => event.values?.some(value => super.compareValues(option.value, value)))));
  }

  clear(event: { userInput: boolean }): void {
    this.selection.next({values: [], userInput: event.userInput});
  }
}
