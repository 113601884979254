export default `
<span>
  <span ng-if="!reverse" >
    <a ng-click="openOverlay()" aria-label="Likes list" class="func-link" ng-show="likeable.likes.count" href="#" ng-bind="likeable.likes.count|pluralize:'Like'" ></a>
  </span>
  <span ng-show="currentUser" tabindex=0 ng-if="!removeLikeIcon" aria-label="{{likeable.likes.liked_by_current_user ? 'Liked' : 'Like'}}" role="button" aria-pressed="false" ng-keydown="$event.keyCode === 13 && likeIt()" class="icon-heart" data-value="{{likeable.likes.liked_by_current_user ? 'Liked' : 'Like'}}" ng-class="{active: likeable.likes.liked_by_current_user, 'feed-like': number, 'clickable': likeable.current_user_permissions.like}" ng-click="likeIt()"></span>
  <span ng-if="reverse" >
    <a ng-click="openOverlay()" aria-label="Likes list" class="func-link" ng-show="likeable.likes.count" href="#" ng-bind="likeable.likes.count|pluralize:'Like'" ></a>
  </span>
</span>
`;
