import _ from 'lodash';

class FollowersController {
  constructor($scope, UserService, FeedStore, user) {
    $scope.followers = new FeedStore(_.bind(UserService.followers, UserService, user.data.id));
    $scope.followers.load();
  }
}

FollowersController.$inject = ['$scope', 'UserService', 'FeedStore', 'user'];

export default FollowersController;
