class SingleDiscussionCtrl {
  constructor($scope, $state, $sce, $filter, discussion, DiscussionService, AlertService, ProgressBarService, $timeout) {
    if ($state.is('singlediscussion')) {
      $state.go('singlediscussionpage', { page: 1, discussionId: discussion }, { location: 'replace' });
      return;
    }

    $scope.discussion = discussion.data;
    $scope.discussion.content_built = $sce.trustAsHtml($filter('uMarkdown')($scope.discussion.content));
    $scope.service = DiscussionService;

    $scope.toggleLock = function (discussion) {
      DiscussionService.toggleLock(discussion).then((res) => {
        $scope.discussion.locked = res.data.locked;
        if (res.data.locked) {
          AlertService.add('Topic locked');
        } else {
          AlertService.add('Topic unlocked');
        }
      });
    };

    $scope.focusBtn = (triggerBtn) => {
      switch (triggerBtn) {
        case 'delete':
          $('#deleteBtnNo').attr('aria-hidden', 'false');
          $timeout(() => {
            $('#deleteBtn').attr('aria-hidden', 'true');
            $('#deleteBtnNo').focus();
          }, 150);
          break;
        case 'no':
          $('#deleteBtn').attr('aria-hidden', 'false');
          $timeout(() => {
            $('#deleteBtnNo').attr('aria-hidden', 'true');
            $('#deleteBtn').focus();
          }, 150);
          break;
        default:
      }
    };

    $scope.handleKeydownDeleteConformation = (event) => {
      const id = event.target.attributes.id.value;
      if (!event.shiftKey && event.keyCode === 9 && id === 'deleteBtnYes') {
        event.preventDefault();
        $('#deleteBtnNo').focus();
      } else if (event.shiftKey && event.keyCode === 9 && id === 'deleteBtnNo') {
        event.preventDefault();
        $('#deleteBtnYes').focus();
      }
    };

    $scope.service = DiscussionService;

    $scope.fullUrl = $state.href('singlediscussion', { discussionId: discussion.data.id }, { absolute: true });

    $scope.deleteDiscussion = (id) => {
      DiscussionService.remove(id).then(() => {
        $state.go('discussion');
        AlertService.add('Topic successfully deleted');
      }, () => {
        AlertService.add('Couldn\'t delete the Forum Topic', 'danger');
        $scope.state = 'normal';
      });
    };

    $scope.update = function () {
      ProgressBarService.start();
      DiscussionService.update($scope.discussion).then(() => {
        AlertService.add('Topic title successfully updated!', 'success');
        ProgressBarService.done();
      }, () => {
        AlertService.add('Topic title couldn\'t be updated', 'danger');
        ProgressBarService.done();
      });
    };
  }
}

SingleDiscussionCtrl.$inject = ['$scope', '$state', '$sce', '$filter', 'discussion', 'DiscussionService', 'AlertService', 'ProgressBarService', '$timeout'];

export default SingleDiscussionCtrl;
