<main>
  <div *ngIf="user" class="fourHundred">
    <h1>Hold up. <img alt="" src="assets/images/smiling-face-with-sunglasses.png" /></h1>
    <h2>You need to sign in to see this page.</h2>
    <a href="/login?return_to={{returnToUrl}}" target="_self" class="btn btn-blue">Sign in</a>
  </div>
  <div *ngIf="!user" class="fourHundredNotAuthorized">
    <img alt="" src="assets/images/smiling-face-with-sunglasses.png" /><br />
    <h1>Hold up. Authorization Required.</h1>
    <p>You need to log in or register to access this page.</p>
    <a href="/login?return_to={{returnToUrl}}" target="_self" class="btn btn-unauth">Sign in</a>
    <a uiSref="signup" target="_self" class="btn btn-unauth">Register</a>
  </div>
</main>
