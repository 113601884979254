export default `
<div class="quiz-result-item">
  <div class="question" ng-bind="$ctrl.question.questionText"></div>
  <div class="answer">
    <div class="answer-status" aria-label="{{$ctrl.resultItem.correct_answer ? 'Correct Answer' : 'Incorrect Answer' }}">
      <i class="material-icons" ng-class="$ctrl.resultItem.correct_answer ? 'right-answer' : 'wrong-answer' "
      ng-bind="$ctrl.resultItem.correct_answer ? 'check_circle_outline' : 'highlight_off' " aria-hidden="true">
      </i>
    </div>
    <div class="answer-details">
      <div class="answer-label">Your answer:</div>
      <div class="user-answer" ng-bind="$ctrl.answerPosition + '. '+ $ctrl.resultItem.answer"></div>
    </div>
    <div class="answer-solution" ng-if="!$ctrl.resultItem.correct_answer">
      <div class="highlight">
        The correct answer can be found in
        <a ui-sref="track-course({ trackId: $ctrl.courseTrack.id, courseId: $ctrl.question.course.slug })">{{$ctrl.question.course.title}}</a>
      </div>
      <button ui-sref="track-course({ trackId: $ctrl.courseTrack.id, courseId: $ctrl.question.course.slug })">Review Lesson</button>
    </div>
  </div>
</div>
`;
