/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import { AbstractControl, FormControlStatus, ValidationErrors } from '@angular/forms';
import { observeProperty } from '@ng-falcon/+utils/observe-property';
import { concat, defer, Observable, of, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, map, share } from 'rxjs/operators';

export interface NgfControlEnhancement<V> {
  readonly value$: Observable<V>;
  readonly errors$: Observable<ValidationErrors | null>;
  readonly touched$: Observable<boolean>;
  readonly untouched$: Observable<boolean>;
  readonly pristine$: Observable<boolean>;
  readonly dirty$: Observable<boolean>;
  readonly status$: Observable<FormControlStatus>;
  readonly valid$: Observable<boolean>;
  readonly invalid$: Observable<boolean>;
  readonly pending$: Observable<boolean>;
  readonly enabled$: Observable<boolean>;
  readonly disabled$: Observable<boolean>;
}

export function createNgfControlEnhancement<T extends AbstractControl>(control: T): NgfControlEnhancement<T['value']> {
  const value$: Observable<T['value']> = ofProperty(control, 'value');
  const errors$: Observable<ValidationErrors | null> = ofProperty(control, 'errors');

  const touched$: Observable<boolean> = ofProperty(control, 'touched');
  const untouched$: Observable<boolean> = touched$.pipe(map(touched => !touched));

  const pristine$: Observable<boolean> = ofProperty(control, 'pristine');
  const dirty$: Observable<boolean> = pristine$.pipe(map(pristine => !pristine));

  const status$: Observable<FormControlStatus> = concat(
    defer(() => of(control.status)),
    control.statusChanges
  ).pipe(
    distinctUntilChanged(),
    share({ connector: () => new ReplaySubject() })
  );
  const valid$: Observable<boolean> = status$.pipe(map(status => status === 'VALID'));
  const invalid$: Observable<boolean> = status$.pipe(map(status => status === 'INVALID'));
  const pending$: Observable<boolean> = status$.pipe(map(status => status === 'PENDING'));
  const enabled$: Observable<boolean> = status$.pipe(map(status => status !== 'DISABLED'));
  const disabled$: Observable<boolean> = status$.pipe(map(status => status === 'DISABLED'));

  return {
    value$,
    errors$,
    touched$,
    untouched$,
    pristine$,
    dirty$,
    status$,
    valid$,
    invalid$,
    pending$,
    enabled$,
    disabled$
  };
}

function ofProperty<T extends AbstractControl, P extends keyof T>(control: T, name: P): Observable<T[P]> {
  return concat(
    defer(() => of(control[name])),
    observeProperty(control, name)
  ).pipe(
    distinctUntilChanged(),
    share({ connector: () => new ReplaySubject() })
  );
}
