import angular from 'angular';

class UploadBtnCtrl {
  constructor($scope, $element) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.filename = 'No File Chosen';
      const uploadFileBtn: any = angular.element($element.find('a'));
      const uploadFile: any = angular.element($element.find('input'));

      uploadFileBtn.click(() => {
        uploadFile.focus().click();
      });

      uploadFile.on('change', () => {
        if (ctrl.isFileSelected(uploadFile[0].files)) {
          $scope.$apply(() => {
            ctrl.filename = uploadFile[0].files[0].name;
            ctrl.onChange({ file: uploadFile[0].files[0] });
          });
          uploadFileBtn.addClass('uploaded');
        }
      });
    };

    ctrl.isFileSelected = file => file !== undefined && file.length > 0;
  }
}

UploadBtnCtrl.$inject = ['$scope', '$element'];

export default UploadBtnCtrl;
