import template from './unauthenticated-header-nav-item.component.html';
import controller from './unauthenticated-header-nav-item.controller';

const unauthenticatedHeaderNavItem = {
  bindings: {
    name: '@',
    route: '@',
    highlight: '@',
  },
  template,
  controller,
};

export default unauthenticatedHeaderNavItem;
