class DashboardActivityProjectsTabCtrl {
  constructor(ConfigService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ConfigService.config().then((res) => {
        ctrl.profile_url = res.data.profile_url;
      });
    };
  }
}

DashboardActivityProjectsTabCtrl.$inject = ['ConfigService'];

export default DashboardActivityProjectsTabCtrl;
