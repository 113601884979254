import template from './follow-btn.html';

const followBtn = function (UserService, LightTesterErrorService, $analytics, $document) {
  let uniqueID = 1;

  return {
    restrict: 'E',
    replace: true,
    scope: {
      user: '=',
    },
    template,
    link(scope) {
      scope.uniqueID = `follow${uniqueID++}`;

      const updateCurrentUserPermissions = function (followResponse) {
        if (followResponse) {
          scope.user.current_user_permissions = followResponse.data.current_user_permissions;
        }

        return followResponse;
      };

      const trackFollowing = (options) => {
        $analytics.eventTrack(options.action, { category: 'Follows', label: options.label });
      };

      scope.toggleFollow = function () {
        if (LightTesterErrorService.show()) {
          scope.user.follow_stats.followed_by_current_user = !scope.user.follow_stats.followed_by_current_user;
          return;
        }

        if (scope.user.follow_stats.followed_by_current_user) {
          UserService.follow(scope.user.id).then(updateCurrentUserPermissions);
          scope.user.follow_stats.follower_count++;
          trackFollowing({ action: 'follow', label: $document[0].title });
        } else {
          UserService.unfollow(scope.user.id).then(updateCurrentUserPermissions);
          scope.user.follow_stats.follower_count--;
          trackFollowing({ action: 'unfollow', label: $document[0].title });
        }
      };
      scope.followKeydown = function (event) {
        if ([13, 32].includes(event.keyCode)) {
          event.preventDefault();
          scope.user.follow_stats.followed_by_current_user = !scope.user.follow_stats.followed_by_current_user;
          scope.toggleFollow();
        }
      };
    },
  };
};

followBtn.$inject = ['UserService', 'LightTesterErrorService', '$analytics', '$document'];
export default followBtn;
