import template from './feed-item.component.html';
import controller from './feed-item.controller';

const feedItem = {
  bindings: {
    item: '<',
    singleView: '<',
    relevantTime: '@',
    store: '=?',
    analyticsTrackingLabel: '@',
    pinnable: '=?',
    rightInfo: '<',
    removeTodo: '<',
    removeLikeIcon: '<',
    showFlagReason: '<',
    onHideFlaggedItem: '&',
    showPin: '<',
  },
  template,
  controller,
};

export default feedItem;
