import _ from 'lodash';
import template from './archive-btn.html';

const archiveBtn = function (ArchiveService, AlertService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '=',
    },
    template,
    link(scope) {
      scope.processing = false;

      scope.getTitle = function () {
        if (scope.item.state === 'archived') {
          return 'Unarchive';
        }
        return 'Archive';
      };

      function successCallback(res) {
        scope.item.state = res.data.state;
        if (scope.item.state === 'archived') {
          AlertService.add(`You successfully archived this ${scope.item.type}`);
        } else {
          AlertService.add(`You successfully unarchived this ${scope.item.type}`);
        }
        scope.processing = false;
      }

      function errorCallback() {
        scope.processing = false;
        AlertService.add('Request failed', 'danger');
      }

      scope.toggleArchiveStatus = function () {
        if (!scope.processing && !_.isUndefined(scope.item.state)) {
          scope.processing = true;
          if (scope.item.state === 'published') {
            ArchiveService.archive(scope.item).then(successCallback, errorCallback);
          } else {
            ArchiveService.unarchive(scope.item).then(successCallback, errorCallback);
          }
        }
      };
    },
  };
};

archiveBtn.$inject = ['ArchiveService', 'AlertService'];

export default archiveBtn;
