import _ from 'lodash';
import DiscussionService from '../components/discussion/discussion.service';
import DiscussionCategoryService from '../components/discussion/discussion-category.service';
import DiscussionCtrl from '../discussion/home/discussion.controller';
import discussionTemplate from '../discussion/home/discussion.html';
import DiscussionCategoryCtrl from '../discussion/category/category.controller';
import discussionCategoryTemplate from '../discussion/category/category.html';
import SingleDiscussionCtrl from '../discussion/single/single-discussion.controller';
import singleDiscussionTemplate from '../discussion/single/single-discussion.html';
import NewDiscussionCtrl from '../discussion/new-discussion.controller';
import newDiscussionTemplate from '../discussion/new-discussion.html';
import EditDiscussionCtrl from '../discussion/edit-discussion.controller';
import editDiscussionTemplate from '../discussion/edit-discussion.html';
import UserAuthenticator from '../authentication/user-authenticator';
import MyDiscussionsCtrl from '../discussion/my-discussions.controller';
import myDiscussionsTemplate from '../discussion/my-discussions.html';
import TransferDiscussionCtrl from '../discussion/transfer/transfer-discussion.controller';
import transferDiscussionTemplate from '../discussion/transfer/transfer-discussion.html';

const DiscussionRoutes = {
  route: (module) => {
    module
      .factory('DiscussionService', DiscussionService)
      .service('DiscussionCategoryService', DiscussionCategoryService)
      .controller('DiscussionCtrl', DiscussionCtrl)
      .controller('DiscussionCategoryCtrl', DiscussionCategoryCtrl)
      .controller('SingleDiscussionCtrl', SingleDiscussionCtrl)
      .controller('NewDiscussionCtrl', NewDiscussionCtrl)
      .controller('EditDiscussionCtrl', EditDiscussionCtrl)
      .controller('MyDiscussionsCtrl', MyDiscussionsCtrl)
      .controller('TransferDiscussionCtrl', TransferDiscussionCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('discussions', {
          url: '/forums?:tab&:topic_page&:category_id',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          template: '<div ui-view="discussions-index" ng-show="$state.includes(\'discussion\')"></div><div ui-view="discussions-show" ng-show="$state.includes(\'singlediscussionpage\')" ></div>',
          parent: 'root',
          authenticated: UserAuthenticator,
        })
        .state('discussion', {
          url: '',
          parent: 'discussions',
          sticky: false,
          views: {
            'discussions-index': {
              template: discussionTemplate,
              controller: 'DiscussionCtrl',
            },
          },
          resolve: {
            randomDiscussions: ['DiscussionService', DiscussionService => DiscussionService.random()],
            headerData: ['StaticValuesService', (StaticValuesService) => {
              const defaultData = {
                title: 'Software Testing Forums',
                description: 'Network with your peers, debate hot topics and exchange knowledge with top minds in the industry. Start a discussion or join one that’s already going on.',
                coverBg: {
                  small: 'assets/images/hero/discussions-bg-xs.jpg',
                  large: 'assets/images/hero/discussions-bg.jpg',
                },
              };
              return StaticValuesService.pageData('forums').then((response) => {
                response.data = _.defaultsDeep(response.data, defaultData);
                return response;
              }, _error => ({
                data: defaultData,
              }));
            }],
            coverBg: ['ImageDataService', 'headerData', (ImageDataService, headerData) => (headerData.data.coverBg.small === 'assets/images/hero/discussions-bg-xs.jpg'
              ? ImageDataService.getData(headerData.data.coverBg.small) : headerData.data.coverBg.small)],
            title: [() => 'uTest Community Forum'],
            authenticated: UserAuthenticator,
          },
        })
        .state('singlediscussion', {
          url: '/:discussionId',
          parent: 'discussions',
          reloadOnSearch: false,
          resolve: {
            discussion: ['$transition$', function ($transition$) {
              return $transition$.params().discussionId;
            }],
            title: ['discussion', discussion => `uTest Community Forum - ${discussion.data.title}`],
            authenticated: UserAuthenticator,
          },
          views: {
            'discussions-show': {
              controller: 'SingleDiscussionCtrl',
            },
          },
        })
        .state('singlediscussionpage', {
          url: '/:discussionId/page/:page?sort',
          parent: 'discussions',
          reloadOnSearch: false,
          sticky: false,
          resolve: {
            discussion: ['$transition$', 'DiscussionService', function ($transition$, DiscussionService) {
              return DiscussionService.find($transition$.params().discussionId);
            }],
            title: ['discussion', discussion => `uTest Community Forum - ${discussion.data.title}`],
            authenticated: UserAuthenticator,
          },
          views: {
            'discussions-show': {
              template: singleDiscussionTemplate,
              controller: 'SingleDiscussionCtrl',
            },
          },
        })
        .state('discussioncategory', {
          url: '/forums/category/:categoryId',
          template: discussionCategoryTemplate,
          controller: 'DiscussionCategoryCtrl',
          parent: 'root',
          resolve: {
            discussionCategory: ['DiscussionCategoryService', '$transition$', function (DiscussionCategoryService, $transition$) {
              return DiscussionCategoryService.find($transition$.params().categoryId);
            }],
            title: ['discussionCategory', discussionCategory => `uTest Community Forum - ${discussionCategory.data.name}`],
            authenticated: UserAuthenticator,
          },
        })
        .state('mydiscussions', {
          url: '/my-forums',
          template: myDiscussionsTemplate,
          controller: 'MyDiscussionsCtrl',
          parent: 'root',
          resolve: {
            user: UserAuthenticator,
            title: [() => 'uTest Community - My Discussions'],
            authenticated: UserAuthenticator,
          },
        })
        .state('newDiscussion', {
          url: '/new/forums',
          template: newDiscussionTemplate,
          controller: 'NewDiscussionCtrl',
          parent: 'root',
          resolve: {
            user: UserAuthenticator,
            title: [() => 'uTest Community Forum - Create Discussion Topic'],
            authenticated: UserAuthenticator,
          },
        })
        .state('editDiscussion', {
          url: '/forums/:discussionId/edit',
          template: editDiscussionTemplate,
          controller: 'EditDiscussionCtrl',
          parent: 'root',
          resolve: {
            discussion: ['$transition$', 'DiscussionService', function ($transition$, DiscussionService) {
              return DiscussionService.find($transition$.params().discussionId);
            }],
            user: UserAuthenticator,
            title: [() => 'uTest Community Forum - Edit Discussion Topic'],
            authenticated: UserAuthenticator,
          },
        })
        .state('transferDiscussions', {
          url: '/transfer/forums',
          template: transferDiscussionTemplate,
          controller: 'TransferDiscussionCtrl',
          parent: 'root',
          resolve: {
            user: UserAuthenticator,
            title: [() => 'uTest Community - Transfer Discussion Topic'],
            authenticated: UserAuthenticator,
          },
        });
    }]);
  },
};

export default DiscussionRoutes;
