export default `
<div class="write-status-fs open">
  <div ng-click="isOpen = true" class="write-status-fs-placeholder">Write your Status Update here</div>
  <div class="write-status-fs-inner write-status_show_on_open" ng-style="innerStyle">
    <a href="" class="close" ng-click="close()">&times;</a>
    <ut-editor [(value)]="status.content" [config]="'clean'" [placeholder]="'Write your Status Update here'" text-required="true" text-area-label="Write your Status Update here"></ut-editor>
  </div>
  <div class="write-status-fs-attachments write-status_show_on_open">
    <p class="label label-default label-outline label-block label-m" ng-show="status.attachment.value">
      <span ng-click="setAttachment({})" class="close">&times;</span>
      <i ng-class="status.attachment.icon" class="mr1"></i><span ng-bind="status.attachment.label"></span>
    </p>
  </div>
  <div class="write-status-fs-footer write-status_show_on_open text-right">
    <write-status-attach status="status" set-attachment="setAttachment" popover-placement="top" ></write-status-attach>
    <button ng-click="submit()" type="submit" class="btn btn-blue btn-auto" ng-bind="status.id ? 'Save' : 'Post'"></button>
  </div>
</div>
`;
