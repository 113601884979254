/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { CdkConnectedOverlay, CdkOverlayOrigin, ConnectedPosition, Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fromEvent, merge, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { OptionHoverService } from '../option-hover.service';
import { Option } from '../option/option';
import { OptionSelectionService } from '../selection/option-selection.service';
import { OptionOpenService } from "@ng-falcon/controls/option/option-open.service";

@Component({
  selector: 'ngf-options-overlay',
  templateUrl: './options-overlay.component.html',
  styleUrls: ['./options-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionsOverlayComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  set triggerRef(triggerRef: ElementRef<HTMLElement> | HTMLElement) {
    if (triggerRef instanceof ElementRef) {
      this._triggerRef = triggerRef;
    } else {
      this._triggerRef = new ElementRef(triggerRef);
    }
  }

  @Input() public disabled = false;
  @Input() public readonly = false;

  @ViewChild(CdkConnectedOverlay)
  connectedOverlay: CdkConnectedOverlay;

  constructor(private cdr: ChangeDetectorRef,
              private overlay: Overlay,
              private service: OptionSelectionService,
              private hoverService: OptionHoverService,
              private openService: OptionOpenService,
              @Inject(DOCUMENT) private document: Document) { }

  private sub = Subscription.EMPTY;
  private _triggerRef: ElementRef<HTMLElement>;
  isOpen = false;
  scrollStrategy: ScrollStrategy;
  positions: ConnectedPosition[];
  triggerRect: DOMRect;
  origin: CdkOverlayOrigin;

  ngOnInit(): void {
    this.triggerRect = this._triggerRef.nativeElement.getBoundingClientRect();
    this.origin = new CdkOverlayOrigin(this._triggerRef);
    this.scrollStrategy = this.overlay.scrollStrategies.reposition();
    this.positions = [
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'start',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'bottom'
      },
      {
        originX: 'end',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'top'
      },
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'bottom'
      }
    ];
  }

  ngAfterViewInit(): void {
    this.sub = merge(
      this.handleKeydown$(),
      this.handleOutsideClick$()
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  toggle(): void {
    this.isOpen ? this.close() : this.open();
  }

  open() {
    if (!(this.disabled || this.readonly) && !this.isOpen) {
      this.changeIsOpen(true);
      this.triggerRect = this._triggerRef.nativeElement.getBoundingClientRect();

      this.cdr.markForCheck();
    }
  }

  close() {
    if (this.isOpen) {
      this.changeIsOpen(false);
      this.hoverService.hover(null);

      this.cdr.markForCheck();
    }
  }

  private changeIsOpen(value: boolean) {
    this.isOpen = value;
    this.openService.setOpened(value);
  }

  private handleOutsideClick$() {
    return fromEvent<MouseEvent>(this.document, 'click').pipe(
      filter(() => this.isOpen),
      tap((event: MouseEvent) => this.handleOutsideClick(event))
    );
  }

  private handleOutsideClick(event: MouseEvent) {
    const target = event.target as Element;
    const originEl = this.origin.elementRef.nativeElement;
    const overlayEl = this.connectedOverlay.overlayRef.overlayElement;

    if (this.notIn(originEl, target) && this.notIn(overlayEl, target)) {
      this.close();
    }
  }

  private handleKeydown$() {
    return fromEvent(this._triggerRef.nativeElement, 'keydown').pipe(
      tap((event: KeyboardEvent) => this.handleKeydown(event))
    );
  }

  private handleKeydown(event: KeyboardEvent) {
    switch (event.code) {
      case 'Down': // IE/Edge specific value
      case 'ArrowDown':
        this.openOrHoverNext();
        break;
      case 'Up': // IE/Edge specific value
      case 'ArrowUp':
        this.hoverService.prev();
        break;
      case 'Left': // IE/Edge specific value
      case 'ArrowLeft':
        this.hoverService.left();
        break;
      case 'Right': // IE/Edge specific value
      case 'ArrowRight':
        this.hoverService.right();
        break;
      case 'Enter':
        if (this.isOpen) {
          this.selectHovered();
        } else {
          this.open();
        }
        break;
      case 'Space':
        if (!this.isOpen) {
          this.open();
        } else {
          return; // if options are open we do not want to prevent default behavior of Space
        }
        break;
      case 'Esc': // IE/Edge specific value
      case 'Escape':
        this.close();
        break;
      case 'Tab':
        this.close();
        return; // for Tab we do not want to prevent default behavior
      default:
        return; // do nothing
    }

    event.preventDefault();
  }

  private openOrHoverNext() {
    if (!this.isOpen) {
      this.open();
    }

    this.hoverService.next();
  }

  private selectHovered() {
    const hovered = this.hoverService.getValue();

    if (hovered && hovered instanceof Option) {
      this.service.select({value: hovered.value, userInput: true});
    }
  }

  private notIn(el: HTMLElement, target: Element) {
    return el !== target && !el.contains(target);
  }

}
