export default `
<div>
  <div ng-if="!hideAvatar" avatar-badges="'me'" class="profile-thumb ut-navbar-avatar"></div>
  <div class="write-status">
    <form>
      <input suggestion-typeahead typeahead-template-url="app/components/status/suggestion-typeahead.html" typeahead-on-select="replaceSuggestion($item)" typeahead-loading="typeaheadLoading" typeahead="user for user in loadSuggestions($viewValue)" ng-class="inputHoverClass" ng-maxlength="250" maxlength="250" type="text" ng-model="status.content" placeholder="Write your Status Update here" ng-style="inputStyle">
      <button ng-disabled="submitButton.disabled" ng-mouseover="setInputHoverClass('hover')" ng-mouseout="setInputHoverClass('')" type="button" class="btn btn-filled blue-tab"  ng-click="!submitButton.disabled && postStatus()">&gt;</span><span class="btn-text">Post</span></button>
      <write-status-attach status="status" popover-placement="{{popoverPlacement}}"></write-status-attach>
    </form>
  </div>
</div>
`;
