import template from './academy-course-section.component.html';

const academyCourseSection = {
  bindings: {
    section: '<',
    isFirstSection: '<',
  },
  template,
};

export default academyCourseSection;
