export default `
<div class="nav-sidebar-item" ng-class="{'nav-sidebar-item--selected' : $ctrl.isSelected}">
  <a ui-sref="{{$ctrl.route}}" class="nav-sidebar-item__link" ng-class="{'nav-sidebar-item__link--selected' : $ctrl.isSelected}">
    <div aria-hidden="true" class="nav-sidebar-item__icon">
      <i class="material-icons">{{$ctrl.icon}}</i>
    </div>
    <div class="nav-sidebar-item__text" ng-class="$ctrl.isSelected && 'nav-sidebar-item__text--selected'">
      {{$ctrl.name}}
    </div>
  </a>
</div>
`;
