export default `
<div id="mainContent">
  <div class="grid w890">
    <h2>Following</h2>
    <div class="user-list">
      <user-list-item user="user"  ng-repeat="user in following.feedItems"  ></user-list-item>
    </div>
    <load-more load-callback="following.loadMore()" is-loading="following.isLoading" is-last-page="following.isLastPage" fade-target="footer"></load-more>
  </div>
</div>
`;
