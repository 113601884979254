class NavSidebarFooter {
  constructor($location: ng.ILocationService, UserService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.$location = $location;
      ctrl.year = new Date().getFullYear();

      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
      });
    };
  }
}

NavSidebarFooter.$inject = ['$location', 'UserService'];

export default NavSidebarFooter;
