/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import emojione from 'emojione';

const keyToName = name => name.replace(/[:_]/g, ' ').replace(/[0-9]+/, '');
const emojiNames = _.keys(emojione.emojioneList);

const EmojiConfig = {
  match: /:([a-z_\-]+)$/,
  feed: (match) => {
    const query = match[1];

    const matchingEmoji = _.filter(emojiNames, name => name.indexOf(query) !== -1);

    const emojiDescription = name => `${emojione.toImage(name)} ${keyToName(name)}`;

    const emojiWithDescription = _.map(matchingEmoji, name => [emojiDescription(name), name]);

    return emojiWithDescription;
  },
};

export default EmojiConfig;
