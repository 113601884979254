import {
  Component, ContentChildren, QueryList, AfterContentInit, OnDestroy,
} from '@angular/core';
import {
  AccordionGroupComponent,
} from './accordion-group.component';

@Component({
  selector: 'ut-accordion',
  template: '<ng-content></ng-content>',
})

class AccordionComponent implements AfterContentInit, OnDestroy {
  @ContentChildren(AccordionGroupComponent)
    groups: QueryList<AccordionGroupComponent>;

  ngAfterContentInit() {
    this.groups.toArray().forEach((t) => {
      t.toggleGroup.subscribe(() => {
        this.openGroup(t);
      });
    });
  }

  openGroup(group: AccordionGroupComponent) {
    const wasAlreadyOpened = group.opened;
    this.groups.toArray().forEach(t => {
      t.opened = false;
    });
    group.opened = !wasAlreadyOpened;
  }

  ngOnDestroy(): void {
    this.groups.toArray().forEach((t) => {
      t.toggleGroup.unsubscribe();
    });
  }
}

export { AccordionComponent };
