/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {merge, Observable, Subject, Subscription} from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { OptionSingleSelectionService } from '../../option/selection/signle-selection/option-single-selection.service';
import { AutocompleteComponent } from '../autocomplete.component';

@Component({
  selector: 'ngf-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent implements OnInit, OnDestroy {

  constructor(public autocomplete: AutocompleteComponent,
              private optionService: OptionSingleSelectionService) {}


  // Allows a parent component to be able to clear the component selection, externally
  @Input()
  clearDropdownEvent: Subject<void>;

  private sub: Subscription;

  value$: Observable<any>;

  ngOnInit(): void {
    this.value$ = this.optionService.selection$
      .pipe(map(selection => selection.value));

    this.sub = merge(
      this.handleSelectionChange$(),
      this.handleWrittenValueChanged$(),
      this.handleExternalClear$()
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleSearch(search: string): void {
    this.autocomplete.handleSearch(search);
  }

  handleClearValue() {
    this.optionService.clear({userInput: true});
  }

  handleBlur() {
    this.autocomplete.onTouched();
  }

  handleToggle() {
    this.autocomplete.optionsOverlay.toggle();
  }

  private handleSelectionChange$() {
    return this.optionService.selection$.pipe(
      filter(selection => selection.userInput),
      tap(selection => {
        this.autocomplete.handleSelection(selection.value);
        this.autocomplete.optionsOverlay.close();
        this.autocomplete.onTouched();
      })
    );
  }

  private handleExternalClear$() {
    if (this.clearDropdownEvent) {
      return this.clearDropdownEvent.asObservable().pipe(
        tap(() => {
          this.handleClearValue();
        })
      )
    } else {
     return EMPTY;
    }
  }

  private handleWrittenValueChanged$() {
    return this.autocomplete.writtenValue$.pipe(
      tap(value => this.optionService.select({value, userInput: false}))
    );
  }
}
