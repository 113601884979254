import _ from 'lodash';

class CommunityTrendingListItemCtrl {
  item: any;
  constructor($scope) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.title = ctrl.item.title || ctrl.item.content;
      ctrl.author = _.get(ctrl.item, 'user');
      ctrl.date = ctrl.item.published_at;
      ctrl.type = ctrl.item.type === 'topic' ? 'Discussion' : _.capitalize(ctrl.item.type);
      ctrl.numLikes = _.get(ctrl.item, 'likes.count');
      ctrl.route = ctrl.urlForItem(ctrl.item);
    };
    $scope.$on('feedItemLikedUpdateTrending', (event, likeable) => {
      if (ctrl.item.id === likeable.id) {
        ctrl.numLikes = likeable.likes.count;
      }
    });
    $scope.$on('feedItemCommentedUpdateTrending', (event, commentable) => {
      if (ctrl.item.id === commentable.id) {
        if (ctrl.item.comments) {
          ctrl.item.comments.count = commentable.comments.count;
        } else {
          ctrl.item.post_count = commentable.post_count;
        }
      }
    });
  }

  get numComments() {
    let numComments = this.item.comments ? this.item.comments.count : this.item.post_count;
    if (this.item.type === 'topic') {
      numComments -= 1;
    }
    return numComments > 0 ? numComments : 0;
  }

  urlForItem(item) {
    switch (item.type) {
      case 'article':
        return `singlearticle({articleId: '${item.slug}'})`;
      case 'course':
        return `singlecourse({courseId: '${item.slug}'})`;
      case 'project':
        return `singleproject({projectId: '${item.slug}'})`;
      case 'topic':
        return `singlediscussionpage({page: 1, discussionId: '${item.id}'})`;
      case 'status':
        return `singlestatus({statusId: '${item.id}'})`;
      default:
    }
  }
}

CommunityTrendingListItemCtrl.$inject = ['$scope'];

export default CommunityTrendingListItemCtrl;
