export default `
<div id="mainContent">
  <div class="block container">
    <div class="row">
      <div class="col-sm-12">
        <div class="search-result">
          <h2>Search Results
            <small ng-if="$ctrl.searchByTag" ><br>Tag: {{$ctrl.searchByTag}}</small>
          </h2>
          <div class="pull-right">
            <custom-dropdown ng-if="$ctrl.sortable()" on-change="$ctrl.sortingChanged" model="$ctrl.sorting" options="$ctrl.sortingOptions" ></custom-dropdown>
          </div>
          <div class="search-result-section">
            <uib-tabset>
              <uib-tab ng-repeat="type in $ctrl.types" heading="{{type}}" active="$ctrl.tabStates[type]" select="$ctrl.selectTab(type)">
                <feed-item item="item" ng-repeat="item in $ctrl.resultsFeed.feedItems"></feed-item>
                <p class="feed-loading-message" ng-if="!$ctrl.resultsFeed.isLoading && $ctrl.resultsFeed.feedItems.length == 0" >No Results</p>
                <load-more load-callback="$ctrl.resultsFeed.loadMore()" is-loading="$ctrl.resultsFeed.isLoading" is-last-page="$ctrl.resultsFeed.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
              </uib-tab>
            </uib-tabset>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
