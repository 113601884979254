import { Component, Input } from '@angular/core';

@Component({
  selector: 'four-oh-four',
  templateUrl: './four-oh-four.component.html',
})
export class FourOhFourComponent {
  @Input('title') title: string;
  @Input('user') user: any;
}
