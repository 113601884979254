import SignUpCtrl from '../sign-up/sign-up.controller';
import registrationTemplate from '../layouts/registration.html';
import signUpTemplate from '../sign-up/sign-up.html';
import signUpAddressTemplate from '../sign-up/signup-address.html';
import signUpBasicInfoTemplate from '../sign-up/signup-basic-info.html';
import signUpCompleteTemplate from '../sign-up/signup-complete.html';
import signUpDevicesTemplate from '../sign-up/signup-devices.html';
import signUpOutroTemplate from '../sign-up/signup-outro.html';
import signUpLiteTemplate from '../sign-up/signup-lite.html';
import CustomRegistrationService from '../components/sign-up/custom-registration.service';
import FeatureToggleResolver from '../feature-toggles/feature-toggle-resolver';
import GeoService from '../sign-up/geo.service';

const signupState = {
  url: '/signup',
  redirectTo: 'signup.personal',
  parent: 'registration',
  sticky: true,
  template: signUpTemplate,
  controller: 'SignUpCtrl',
  resolve: {
    leftPanelBg: ['ImageDataService', ImageDataService => ImageDataService.getData('/assets/images/home/utest_welcome-xs.jpg')],
    globalConfig: ['ConfigService', ConfigService => ConfigService.config().then(response => response.data)],
  },
};

const customSignupState = {
  url: '/c-signup',
  redirectTo: 'signup.personal',
  parent: 'registration',
  sticky: true,
  template: signUpTemplate,
  controller: 'SignUpCtrl',
  resolve: {
    leftPanelBg: ['ImageDataService', ImageDataService => ImageDataService.getData('/assets/images/home/utest_welcome-xs.jpg')],
    globalConfig: ['ConfigService', ConfigService => ConfigService.config().then(response => response.data)],
  },
};

const liteState = () => ({
  url: '/lite',
  template: signUpLiteTemplate,
  redirectOnDirectNav: 'signup.personal',
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: -1,
        title: 'Tell us about yourself',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
});

const personalState = () => ({
  url: '/personal',
  template: signUpBasicInfoTemplate,
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: 1,
        title: 'Tell us about yourself',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
});

const addressState = () => ({
  url: '/address',
  redirectOnDirectNav: 'signup.personal',
  template: signUpAddressTemplate,
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: 2,
        title: 'Add your address',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
});

const devicesState = () => ({
  url: '/devices',
  redirectOnDirectNav: 'signup.personal',
  template: signUpDevicesTemplate,
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: 3,
        title: 'Tell us about your devices',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
});

const completeState = () => ({
  url: '/complete',
  redirectOnDirectNav: 'signup.personal',
  template: signUpCompleteTemplate,
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: 4,
        title: 'The last step',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
});

const outroState = {
  url: '/outro?from',
  redirectOnDirectNav: 'signup.personal',
  template: signUpOutroTemplate,
  resolve: {
    currentStep: ['$rootScope', function ($rootScope) {
      $rootScope.currentStep = {
        number: 5,
        title: 'Almost there!',
      };
    }],
    title: [() => 'uTest - User SignUp'],
  },
};

const introState = {
  url: '/:id?utm_source&utm_medium&utm_campaign',
  resolve: {
    customRegistration: ['$sce', '$filter', '$rootScope', '$transition$', 'CustomRegistrationService',
      function ($sce, $filter, $rootScope, $transition$, CustomRegistrationService) {
        if ($transition$.params().id) {
          return CustomRegistrationService.find($transition$.params().id).then((customRegistration) => {
            $rootScope.customRegistration = customRegistration.data;
            $rootScope.currentStep = { number: 0 };
            $rootScope.currentStep.title = $rootScope.customRegistration.title;
          });
        }
      }],
  },
};

const SignUpRouter = {
  route: (module) => {
    module
      .controller('SignUpCtrl', SignUpCtrl)
      .service('GeoService', GeoService)
      .service('CustomRegistrationService', CustomRegistrationService);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('referral', {
          controller: ['$location', '$transition$', '$timeout', ($location, $transition$, $timeout) => {
            const redirectTo = `/signup/personal?utm_source=profile-referral&utm_medium=referral&utm_campaign=leads&utm_content=id-${$transition$.params().testerId}`;
            $timeout(() => $location.url(redirectTo));
          }],
          url: '/ref{testerId:[0-9]+}',
        })
        .state('registration', {
          controller: ['$state', '$scope', 'featureFlags', ($state, $scope, featureFlags) => {
            $scope.$state = $state;
            $scope.featureFlags = featureFlags;
          }],
          url: '',
          abstract: true,
          template: registrationTemplate,
          resolve: {
            features: FeatureToggleResolver,
          },
        })
        // signup and customsignup re-use most of the state definition (e.g. personalState)
        // that's why those are functions - so that we create two different objects
        .state('signup', signupState)
        .state('signup.personal', personalState())
        .state('signup.address', addressState())
        .state('signup.devices', devicesState())
        .state('signup.complete', completeState())
        .state('signup.intro', { url: '/:id?utm_source&utm_medium&utm_campaign', redirectTo: 'customsignup.intro' })
        .state('customsignup', customSignupState)
        .state('customsignup.lite', liteState())
        .state('customsignup.personal', personalState())
        .state('customsignup.address', addressState())
        .state('customsignup.devices', devicesState())
        .state('customsignup.complete', completeState())
        .state('customsignup.outro', outroState)
        .state('customsignup.intro', introState); // intro state must be last, because it has a catch-all url
    }]);
  },
};

export default SignUpRouter;
