class GeoService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  search(q: string) {
    return this.$http.get(`/api/v1/geolocations/autocomplete?query=${q}`);
  }

  details(placeId: string) {
    return this.$http.get(`/api/v1/geolocations/details/${placeId}`);
  }

  geocode(address: string) {
    return this.$http.get(`/api/v1/geolocations/geocode?address=${address}`);
  }
}

GeoService.$inject = ['$http'];

export default GeoService;
