/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Option } from '../option/option';
import { OptionsRootHolder } from '../options-root-holder';
import { OptionMultiSelectionService } from './multi-selection/option-multi-selection.service';
import { OptionSelectionService } from './option-selection.service';
import { SelectionEvent } from './selection-event';
import { OptionSingleSelectionService } from './signle-selection/option-single-selection.service';

@Injectable()
export class DelegateOptionSelectionService extends OptionSelectionService {

  constructor(root: OptionsRootHolder,
              private singleSelectionService: OptionSingleSelectionService,
              private multiSelectionService: OptionMultiSelectionService) {
    super(root);
  }

  clear(param: { userInput: boolean }): void {
    this.optionSelectionService.clear(param);
  }

  isAnySelected$(options: Option[]): Observable<boolean> {
    return this.optionSelectionService.isAnySelected$(options);
  }

  isSelected$(option: Option): Observable<boolean> {
    return this.optionSelectionService.isSelected$(option);
  }

  select(event: SelectionEvent) {
    this.optionSelectionService.select(event);
  }

  private get optionSelectionService(): OptionSelectionService {
    return this.root.get().multiselect
      ? this.multiSelectionService
      : this.singleSelectionService;
  }
}
