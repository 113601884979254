export default `
<div feature-flag="UTEST_ONETRUST_COOKIE_CONSENT" feature-flag-hide>
  <div class="alert cookie-alert" role="alert" type="info" ng-if="$ctrl.cookieAlertOpen">
    <div>
      <span>uTest uses cookies to provide you with the best digital experience. If you continue to use our site, we will assume that you agree to the use of such cookies. Find out more about cookies by reading our <a href="/privacy-policy">Privacy Policy</a>.</span>
    </div>
    <div style="margin-top: 1rem">
      <span>
        <btn href="#" ng-click="$ctrl.agree()" class="btn btn-blue">Accept</btn>
        <btn href="#" ng-click="$ctrl.decline()" class="btn" style="border: none; font-size: 1.1rem">Decline</btn>
      </span>
    </div>
  </div>
  <div class="cookie-reset alert alert-warning" ng-if="$ctrl.resetCookieBanner">
    <div>
      <span>Your current cookie preference is: {{$ctrl.statusText}}</span>
      <span>If you wish to reset your cookie preferences:
        <a href="#" ng-click="$ctrl.reset()">Reset</a>
      </span>
    </div>
  </div>
</div>
`;
