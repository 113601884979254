const bgImg = function () {
  return {
    restrict: 'A',
    scope: {
      bgImg: '@',
      swap: '@',
    },
    link(scope, element) {
      element.addClass('blurable');

      if (scope.swap) {
        const img = new Image();
        img.addEventListener('load', () => {
          element.css('background-image', `url(${scope.swap})`).removeClass('blur');
        });
        img.src = scope.swap;
        if (!img.complete) {
          element.addClass('blur');
        }
      }

      scope.$watch('bgImg', (link) => {
        element.css({
          'background-image': `url(${link})`,
          'background-size': 'cover',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
        });
        if (scope.swap) {
          element.addClass('blur');
        }
      });
    },
  };
};

export default bgImg;
