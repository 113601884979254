class NavHeaderNotificationsCtrl {
  constructor(ActivityService, $interval: ng.IIntervalService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;
    ctrl.reloadUserNotifications = () => {
      if (document.hidden) {
        return;
      }

      if (ctrl.currentUser.id) {
        ActivityService.unreadCount().then((res) => {
          ctrl.currentUser.unread_activities_count = res.data.unread_activities_count;
          ctrl.currentUser.unread_community_activities_count = res.data.unread_community_activities_count; // eslint-disable-line max-len
          ctrl.currentUser.unread_project_activities_count = res.data.unread_project_activities_count; // eslint-disable-line max-len
        });
      }
    };

    ctrl.$onInit = function () {
      $interval(ctrl.reloadUserNotifications, 30000);

      $scope.$root.$on('clearCommunityNotificationCounter', (_event) => {
        ctrl.currentUser.unread_activities_count -= ctrl.currentUser.unread_community_activities_count; // eslint-disable-line max-len
        ctrl.currentUser.unread_community_activities_count = 0;
      });

      $scope.$root.$on('clearProjectNotificationCounter', (_event) => {
        ctrl.currentUser.unread_activities_count -= ctrl.currentUser.unread_project_activities_count; // eslint-disable-line max-len
        ctrl.currentUser.unread_project_activities_count = 0;
      });
    };

    ctrl.clearUnreadNotifications = function () {
      ctrl.currentUser.unread_activities_count = 0;
    };

    ctrl.unread_count = () => {
      const count = ctrl.currentUser.unread_activities_count;

      if (count < 0) return 0;
      if (count > 99) return 99;

      return count;
    };
  }
}

NavHeaderNotificationsCtrl.$inject = ['ActivityService', '$interval', '$scope'];

export default NavHeaderNotificationsCtrl;
