<div id="mainContent">
  <div class="section-home-unauth-cover">
    <div class="section-home-unauth-cover-bg">
      <img src="{{ unauthImages.left }}" class="unauth-hero-img-left hidden-xs" alt="">
      <img src="{{ unauthImages.right }}" class="unauth-hero-img-right-overlay hidden-xs" alt="">
      <div [ngStyle]="{'background-image': 'url(' + bgUrl + ')'}" class="unauthenticated-project-bg"></div>
    </div>
    <div class="container center-text">
      <div class="col-sm-6 col-sm-offset-3 col-xs-12 horizontal-center" [ngClass]="{'padding-sides-40': isSmallMode}">
        <h1 class="section-big-title-unauth">The Largest Community of Testers in the World</h1>
        <p class="section-desc-unauth">Earn money & learn new skills while helping world-class companies deliver amazing digital experiences.</p>
        <a href="/signup" class="ng-binding btn btn-unauth margin-bottom-100">Become a uTester</a>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12 visible-xs">
        <img src="{{ unauthImages.left }}" class="unauth-hero-img-bottom visible-xs" alt="">
      </div>
    </div>
  </div>

  <div class="block container unauth-section-margin">
    <div class="col-sm-6 col-sm-push-6 col-xs-12">
      <img src="{{ unauthImages.macbook }}" class="image-right-max-300" alt="">
    </div>
    <div class="col-sm-6 col-sm-pull-6 col-xs-12">
      <div>
        <h2>Learn New Skills, Grow, and Connect</h2>
        <div>uTest is the home of the largest community of digital freelance software testers in the world - helping some of the most recognized and iconic brands provide quality digital experiences to their customers.</div>
        <div>Whether it's improving their testing skills in our Academy, finding a testing project to earn money, or connecting with testing experts within our global community, uTest has become the go-to destination for people who value digital quality, are passionate about user experience, and are curious about cutting-edge technology.</div>
      </div>
    </div>
  </div>

  <div class="block container unauth-home-table-bg unauth-section-margin" [ngStyle]="{'background-image': isSmallMode ? 'none' : 'url(' + unauthImages.howItWorks.table_bg + ')'}">
    <div class="col-sm-12 row how-utest-works">
      <div class="col-sm-6 col-sm-offset-3 col-xs-12 horizontal-center" [ngClass]="{'padding-sides-40': isSmallMode}">
        <h2 class="">How uTest Works</h2>
        <div>When you join uTest, you are unlocking a new world of freelancing opportunities. Learn how we match you with paid testing projects from our clients.</div>
      </div>
    </div>
    <div class="col-sm-12 how-utest-works-video">
      <ut-wistia-popover-player [hashed_id]="videoId"></ut-wistia-popover-player>
    </div>
  </div>

  <div class="block container unauth-section-margin">
    <div class="row visible-xs">
      <div class="row">
        <div class="col-xs-2"></div>
        <div class="col-xs-4 center-text" *ngFor="let brandRow of brands | slice:0:2;">
          <img src="{{ brandRow.asset.url }}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
      <div class="row">
        <div class="col-xs-4 center-text" *ngFor="let brandRow of brands | slice:2:5;">
          <img src="{{ brandRow.asset.url }}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
    </div>

    <div class="equal-height">
      <div class="col-sm-3 hidden-xs">
        <div class="col-xs-12 padding-0" *ngFor="let brandRow of brands | slice:0:5; let idx = index;">
          <img src="{{ brandRow.asset.url }}" [ngStyle]="{'float':  idx % 2 === 0 ? 'right' : 'left'}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
      <div class="col-sm-6 col-xs-12 padding-top-10 padding-bottom-10">
        <div class="center-text">
          <h2>Earn Money Testing for the Brands You Love</h2>
          <div>When companies like Uber need to ensure a new feature is ready before rolling it out globally, they utilize uTesters to validate that it works, looks and performs as expected. Our clients count on the feedback of uTesters to ensure that their products live up to the high expectations of their customers.</div>
        </div>
      </div>
      <div class="col-sm-3 hidden-xs">
        <div class="col-xs-12 padding-0" *ngFor="let brandRow of brands | slice:5:10; let idx = index;">
          <img src="{{ brandRow.asset.url }}" [ngStyle]="{'float':  idx % 2 === 0 ? 'left' : 'right'}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
    </div>

    <div class="row visible-xs">
      <div class="row">
        <div class="col-xs-4 center-text" *ngFor="let brandRow of brands | slice:5:8;">
          <img src="{{ brandRow.asset.url }}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
      <div class="row">
        <div class="col-xs-2"></div>
        <div class="col-xs-4 center-text" *ngFor="let brandRow of brands | slice:8:10;">
          <img src="{{ brandRow.asset.url }}" alt="{{ brandRow.asset.file_name.split('.')[0] }} Logo">
        </div>
      </div>
    </div>
  </div>

  <div class="section-home-unauth-cover unauth-section-margin">
    <div class="section-home-cover-bg">
      <img src="{{ unauthImages.footerLeft }}" class="unauth-hero-img-left-full hidden-xs" alt="">
      <img src="{{ unauthImages.footerRight }}" class="unauth-hero-img-right hidden-xs" alt="">
      <div class="section-bottom-bg unauthenticated-project-bg"></div>
    </div>
    <div class="block container center-text padding-0">
      <div class="col-sm-3 hidden-xs"></div>
      <div class="col-sm-6 col-xs-12 center-text">
        <h1 class="section-big-title-unauth">Ready to Start Testing?</h1>
        <p class="section-desc-unauth">Learn new skills, earn money for sharing your feedback, and connect with a global community of individuals passionate about digital quality.</p>
        <a href="/signup" class="ng-binding btn btn-unauth margin-bottom-100">Become a uTester</a>
      </div>
      <div class="col-sm-3 hidden-xs"></div>
    </div>
  </div>
</div>
