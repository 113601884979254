import AdminService from '../components/services/admin.service';
import UserAuthenticator from '../authentication/user-authenticator';
import FlaggedContentCtrl from '../flagged-content/flagged-content.controller';
import flaggedContentTemplate from '../flagged-content/flagged-content.html';
import UrlShortenerCtrl from '../url-shortener/url-shortener.controller';
import urlShortenerTemplate from '../url-shortener/url-shortener.html';

const AdminRouter = {
  route: (module) => {
    module
      .controller('FlaggedContentCtrl', FlaggedContentCtrl)
      .controller('UrlShortenerCtrl', UrlShortenerCtrl)
      .service('AdminService', AdminService);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('flaggedcontent', {
          url: '/flagged-content',
          parent: 'root',
          template: flaggedContentTemplate,
          controller: 'FlaggedContentCtrl',
          resolve: {
            user: UserAuthenticator,
            title: [() => 'uTest Flagged Content'],
          },
        })
        .state('urlshortener', {
          url: '/url-shortener',
          parent: 'root',
          template: urlShortenerTemplate,
          controller: 'UrlShortenerCtrl',
          resolve: {
            title: [() => 'uTest URL Shortener'],
          },
        });
    }]);
  },
};

export default AdminRouter;
