import _ from 'lodash';

class LeaderboardCtrl {
  constructor($scope, LeaderboardService, FeedStore) {
    $scope.recent = new FeedStore(_.bind(LeaderboardService.feed, LeaderboardService), { days: 30, per_page: 20 });
    $scope.allTime = new FeedStore(_.bind(LeaderboardService.feed, LeaderboardService), { per_page: 20 });
    $scope.recent.load();
  }
}

LeaderboardCtrl.$inject = ['$scope', 'LeaderboardService', 'FeedStore'];

export default LeaderboardCtrl;
