import template from './master-checkbox.html';

const masterCheckbox = function () {
  let i = 0;

  return {
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: {
      ngModel: '=',
    },
    template,
    link: (scope) => {
      scope.id = `masterCheckbox${i}`;
      ++i;
    },
  };
};

export default masterCheckbox;
