import template from './flash-message.html';

const flashMessage = function ($timeout) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      message: '=',
      type: '=',
    },
    template,
    link(scope, el) {
      scope.typeClass = `alert-${scope.type}`;

      const autoclose = 10000;

      scope.close = function () {
        el.addClass('alert-close').delay(200).slideUp();
      };

      $timeout(scope.close, autoclose, false);
    },
  };
};

flashMessage.$inject = ['$timeout'];

export default flashMessage;
