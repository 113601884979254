import template from './community-featured-list-item.component.html';
import controller from './community-featured-list-item.controller';

const communityFeaturedListItem = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default communityFeaturedListItem;
