import _ from 'lodash';

class UnauthenticatedHeaderNavItemCtrl {
  private state: any; // @ts-ignore
  private isSelected: any;

  constructor($state, $transitions) {
    // @ts-ignore
    this.$onInit = () => {
      this.state = $state;
      this.isSelected = false;

      // @ts-ignore
      if (_.intersection(this.state.current.name.split('.'), this.highlight.split(',')).length > 0) {
        this.isSelected = true;
      }

      const ctrl = this as any;
      $transitions.onSuccess({}, (transition) => {
        // The state is split on . for the state name. We split on comma in the highlight since we pass it in that way
        if (_.intersection(transition.to().name.split('.'), ctrl.highlight.split(',')).length > 0) {
          ctrl.isSelected = true;
        } else {
          ctrl.isSelected = false;
        }
      });
    };
  }
}

UnauthenticatedHeaderNavItemCtrl.$inject = ['$state', '$transitions'];

export default UnauthenticatedHeaderNavItemCtrl;
