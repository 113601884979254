import _ from 'lodash';

const LeaderboardService = ($http, UserService) => {
  const generatedData = function (res) {
    res.data = _.map(res.data, user => UserService.generatedData({ data: user }).data);
    return res;
  };

  const all = () => $http.get('/api/v1/users/leaderboard').then(generatedData);

  const feed = (parameters) => {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 20 });
    return $http.get('/api/v1/users/leaderboard', { params: parameters }).then(generatedData);
  };

  return {
    all,
    feed,
  };
};

LeaderboardService.$inject = ['$http', 'UserService'];

export default LeaderboardService;
