import _ from 'lodash';
import template from './subscribe-btn.html';

const subscribeBtn = function (SubscribeService, AlertService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      item: '=',
    },
    template,
    link(scope) {
      scope.processing = false;

      scope.getTitle = function (isSubscribed) {
        if (isSubscribed) {
          return 'Unsubscribe';
        }
        return 'Subscribe';
      };

      function successCallback(res) {
        scope.item.subscriptions = res.data.subscriptions;
        if (scope.item.subscriptions.subscribed_by_current_user) {
          AlertService.add(`You successfully subscribed to this ${scope.item.type}`);
        } else {
          AlertService.add(`You successfully unsubscribed from this ${scope.item.type}`);
        }
        scope.processing = false;
      }

      function errorCallback() {
        scope.processing = false;
        AlertService.add('Request failed', 'danger');
      }

      scope.toggleSubscription = function () {
        if (!scope.processing) {
          scope.processing = true;
          if (_.isUndefined(scope.item.subscriptions) || !scope.item.subscriptions.subscribed_by_current_user) {
            SubscribeService.subscribe(scope.item).then(successCallback, errorCallback);
          } else {
            SubscribeService.unsubscribe(scope.item).then(successCallback, errorCallback);
          }
        }
      };
    },
  };
};

subscribeBtn.$inject = ['SubscribeService', 'AlertService'];

export default subscribeBtn;
