import _ from 'lodash';

const randomcolor = function () {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      let color1;

      const range = [150, 300];

      if (!_.isUndefined(attrs.randomcolordebug)) {
        color1 = parseInt(attrs.randomcolordebug);
        element.html(`${color1}`);
      } else {
        color1 = _.random(range[0], range[1]);
      }

      const color2 = parseInt(`${color1 * 1.3}`);

      element.css({
        background: `linear-gradient(135deg, hsla(${color2}, 45%, 45%, 1), hsla(${color1}, 45%, 45%, 1))`,
      });
    },
  };
};

export default randomcolor;
