import _ from 'lodash';

class ActivityListItemCtrl {
  activity: any;
  badge: any;
  testerAppUrl: string;
  constructor(GamificationService, ConfigService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ConfigService.config().then((res) => {
        this.testerAppUrl = res.data.profile_url;
      });

      if (this.activity.type === 'badge_activity') {
        this.badge = GamificationService.badgesById[this.activity.item.badge_type];
      }

      this.bugChangeReasons = {
        1: 'Out of scope',
        2: 'Works as designed',
        3: 'Duplicate',
        4: 'Need more info',
        5: 'Other',
        6: 'Did Not Follow Instructions',
      };
    };

    ctrl.textContent = (htmlString) => {
      const tempElement = document.createElement('div');
      const stringWithSpaces = htmlString.replace(/<\/\p><p>/gi, '</p> <p>');
      tempElement.innerHTML = stringWithSpaces;

      return tempElement.textContent || tempElement.innerText || '';
    };
  }

  get testerAppLink() {
    if (_.get(this.activity, 'item.type') === 'utest30/requirement_survey_response') {
      return `${this.testerAppUrl}/requirement-survey/${this.activity.item.requirement_survey.id}`;
    }

    if (_.get(this.activity, 'item.type') === 'utest30/test_cycle') {
      return `${this.testerAppUrl}/testcycles/${this.activity.item.id}/`;
    }

    if (_.get(this.activity, 'item.type') === 'utest30/slot_waitlist_event_history') {
      return `${this.testerAppUrl}/testcycles/${this.activity.item.test_cycle_id}/slots`;
    }

    if (_.get(this.activity, 'item.type') === 'utest30/bug_message') {
      if (this.activity.item.bug) {
        return `${this.testerAppUrl}/testcycles/${this.activity.item.bug.test_cycle.id}/issues/${this.activity.item.bug.id}/discussion`;
      }
      return `${this.testerAppUrl}/testcycles/${this.activity.item.test_run_result.test_cycle.id}/testcases/${this.activity.item.entity_id}/discussion`;
    }

    if (_.get(this.activity, 'item.type') === 'utest30/bug_change_progression') {
      return `${this.testerAppUrl}/testcycles/${this.activity.item.bug.test_cycle.id}/issues/${this.activity.item.bug.id}`;
    }

    if (_.get(this.activity, 'item.type') === 'utest30/test_run_result_approval_change_progression') {
      return `${this.testerAppUrl}/testcycles/${this.activity.item.test_run_result.test_cycle.id}/testcases/${this.activity.item.test_run_result.id}`;
    }

    return '#';
  }

  get iconType() {
    const typeMap = {
      badge_activity: 'badge',
      bug_activity: 'glyph',
      bug_message_activity: 'glyph',
      reject_activity: 'glyph',
      requirement_survey_invite_activity: 'glyph',
      test_case_activity: 'glyph',
      test_cycle_status_activity: 'glyph',
      test_cycle_invite_activity: 'glyph',
      removed_from_waitlist_activity: 'glyph',
      available_waitlist_slot_activity: 'glyph',
    };

    return typeMap[this.activity.type] || 'avatar';
  }

  get iconContent() {
    const glyphMap = {
      bug_activity: 'bug_report',
      bug_message_activity: 'mail',
      reject_activity: 'highlight_off',
      requirement_survey_invite_activity: 'mail',
      test_case_activity: 'assignment',
      test_cycle_status_activity: 'assignment',
      test_cycle_invite_activity: 'mail',
      removed_from_waitlist_activity: 'mail',
      available_waitlist_slot_activity: 'mail',
    };

    switch (this.iconType) {
      case 'badge':
        return `assets/images/badges/${this.badge.icon}`;
      case 'avatar':
        return this.activity.user.avatar_url;
      case 'glyph':
        return glyphMap[this.activity.type];
      default:
        return '';
    }
  }

  get iconModifier() {
    if (this.activity.type === 'test_cycle_status_activity') {
      if (this.activity.item.test_cycle_status_id === 2) return 'activity-list-item__icon--green';
      if (this.activity.item.test_cycle_status_id === 3) return 'activity-list-item__icon--red';
      if (this.activity.item.test_cycle_status_id === 6) return 'activity-list-item__icon--yellow';
    }

    if (this.activity.type === 'bug_activity') {
      if (this.activity.item.bug_status_id === 2) return 'activity-list-item__icon--green';
      if (this.activity.item.bug_status_id === 3) return 'activity-list-item__icon--red';
      if ([4, 8, 9, 10, 11, 12].includes(this.activity.item.bug_status_id)) return 'activity-list-item__icon--yellow';
    }

    if (this.activity.type === 'test_case_activity') {
      if (this.activity.item.approval_status_id === 2) return 'activity-list-item__icon--green';
      if (this.activity.item.approval_status_id === 3) return 'activity-list-item__icon--red';
    }

    return '';
  }
}

ActivityListItemCtrl.$inject = ['GamificationService', 'ConfigService'];

export default ActivityListItemCtrl;
