import _ from 'lodash';
import { StateService } from '@uirouter/core';

class ProjectService {
  $http: ng.IHttpService;
  $state: StateService;
  UrlService: any;
  constructor($state, UrlService, $http) {
    this.$state = $state;
    this.UrlService = UrlService;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/projects/${id}`).then(this.UrlService.addResponseUrl);
  }

  random() {
    return this.$http.get('/api/v1/projects/random').then(this.UrlService.addResponseUrls);
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10, filter: { state: 'published' } });

    return this.$http.get('/api/v1/projects', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  create(project) {
    return this.$http.post('/api/v1/projects', project);
  }

  apply(id) {
    return this.$http.post(`/api/v1/projects/${id}/apply`, '');
  }

  info() {
    return this.$http.get('/api/v1/projects/info');
  }
}

ProjectService.$inject = ['$state', 'UrlService', '$http'];

export default ProjectService;
