import template from './community-feed.component.html';
import controller from './community-feed.controller';

const communityFeed = {
  bindings: {
    user: '<',
    likes: '=',
  },
  template,
  controller,
};

export default communityFeed;
