import template from './comment-section-short.component.html';
import controller from './comment-section-short.controller';

const commentSectionShort = {
  bindings: {
    commentable: '<',
  },
  template,
  controller,
};

export default commentSectionShort;
