import _ from 'lodash';

class CommunityFeedCtrl {
  constructor(HomeFeedService, FeedStore, $scope: ng.IScope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.communityFeedItems = new FeedStore(_.bind(HomeFeedService.feed, HomeFeedService), { sort: '-published_at' });
      ctrl.communityFeedItems.loadOnce();

      ctrl.feedType = 'entireCommunity';
      if (ctrl.user) {
        ctrl.filterOptions = [
          { display: `Followed Only <div class="community-feed-filter-followed-only" aria-label="${ctrl.user.follow_stats.following_count} people"><i class="material-icons">people</i> ${ctrl.user.follow_stats.following_count}</div>`, value: 'followedOnly', selectedDisplay: 'Followed Only' },
          { display: 'Entire Community', value: 'entireCommunity' },
        ];
        ctrl.feedType = ctrl.filterOptions[0].value;
        ctrl.personalFeedItems = new FeedStore(_.bind(HomeFeedService.personalFeed, HomeFeedService));
        ctrl.personalFeedItems.loadOnce();
        ctrl.feedHasChanged = false;
      }

      $scope.$on('statusPosted', (event, status) => {
        ctrl.communityFeedItems.feedItems.unshift(status);
        ctrl.personalFeedItems.feedItems.unshift(status);
      });

      $scope.$on('statusDeleted', (event, status) => {
        ctrl.updateFeedOnRemoval(status);
      });

      $scope.$on('statusUpdated', (event, status) => {
        const personalFeedIndex = ctrl.personalFeedItems.feedItems.findIndex(item => item.id === status.id);
        const communityFeedIndex = ctrl.communityFeedItems.feedItems.findIndex(item => item.id === status.id);
        if (ctrl.personalFeedItems.feedItems[personalFeedIndex]) {
          ctrl.personalFeedItems.feedItems[personalFeedIndex] = status;
        }
        if (ctrl.communityFeedItems.feedItems[communityFeedIndex]) {
          ctrl.communityFeedItems.feedItems[communityFeedIndex] = status;
        }
      });

      $scope.$on('feedItemLiked', (event, likeable) => {
        const personalFeedIndex = ctrl.personalFeedItems.feedItems.findIndex(item => item.id === likeable.id);
        const communityFeedIndex = ctrl.communityFeedItems.feedItems.findIndex(item => item.id === likeable.id);
        $scope.$broadcast('feedItemLikedUpdateTrending', likeable);
        if (ctrl.feedType === 'entireCommunity' && ctrl.personalFeedItems.feedItems[personalFeedIndex]) {
          ctrl.personalFeedItems.feedItems[personalFeedIndex] = likeable;
        }
        if (ctrl.feedType === 'followedOnly' && ctrl.communityFeedItems.feedItems[communityFeedIndex]) {
          ctrl.communityFeedItems.feedItems[communityFeedIndex] = likeable;
        }
      });
    };

    $scope.$on('feedItemCommented', (event, commentable) => {
      const personalFeedIndex = ctrl.personalFeedItems.feedItems.findIndex(item => item.id === commentable.id);
      const communityFeedIndex = ctrl.communityFeedItems.feedItems.findIndex(item => item.id === commentable.id);
      $scope.$broadcast('feedItemCommentedUpdateTrending', commentable);
      if (ctrl.feedType === 'entireCommunity' && ctrl.personalFeedItems.feedItems[personalFeedIndex]) {
        ctrl.personalFeedItems.feedItems[personalFeedIndex] = commentable;
      }
      if (ctrl.feedType === 'followedOnly' && ctrl.communityFeedItems.feedItems[communityFeedIndex]) {
        ctrl.communityFeedItems.feedItems[communityFeedIndex] = commentable;
      }
    });

    ctrl.updateFeed = function (feedType) {
      ctrl.feedType = feedType;
      if (!ctrl.feedHasChanged) {
        $scope.$broadcast('applyFreezeFrame');
        ctrl.feedHasChanged = true;
      }
    };

    ctrl.updateFeedOnRemoval = (feedItem) => {
      _.remove(ctrl.personalFeedItems.feedItems, item => (item as any).id === feedItem.id);
      _.remove(ctrl.communityFeedItems.feedItems, item => (item as any).id === feedItem.id);
    };

    ctrl.loadMore = function () {
      ctrl.loadingMore = true;
      switch (ctrl.feedType) {
        case 'followedOnly':
          ctrl.personalFeedItems.loadMore().then(() => {
            ctrl.loadingMore = false;
          });
          break;
        case 'entireCommunity':
          ctrl.communityFeedItems.loadMore().then(() => {
            ctrl.loadingMore = false;
          });
          break;
        default:
      }
    };
  }
}

CommunityFeedCtrl.$inject = ['HomeFeedService', 'FeedStore', '$scope'];

export default CommunityFeedCtrl;
