/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';

const MentionConfigBuilder = function (SearchService, $sanitize) {
  const userDescription = user => `<div class="profile-thumb" style="background-image:url(${user.avatar_url})" ></div> ${$sanitize(user.name)}`;
  const addDescription = ({ data }) => _.map(data, user => [userDescription(user), $sanitize(user.slug)]);
  const searchUser = SearchService.searchOneFactory('User');

  return {
    match: /(?:[\W]|^)@([^@\s]+[\s|@]?[^@\s]+)$/,
    replaceMatch: /[^@]+$/,
    feed: ([_, match]) => searchUser({ query: match }).then(addDescription),
  };
};

export default MentionConfigBuilder;
