export default `
<div class="col-md-4 col-sm-6">
  <div class="dashboard-card">
    <h2 class="dashboard-card__heading">
      Profile Completion
    </h2>
    <div class="dashboard-card-profile-completion-graph">
      <svg class="dashboard-card-profile-completion-graph__progress" ng-attr-viewBox="0 0 {{$ctrl.svgSize}} {{$ctrl.svgSize}}">
        <circle class="dashboard-card-profile-completion-graph__progress-meter" ng-attr-cx="{{$ctrl.svgCenter}}" ng-attr-cy="{{$ctrl.svgCenter}}" ng-attr-r="{{$ctrl.meterRadius}}" ng-attr-stroke-width="{{$ctrl.meterWidth}}" ng-attr-stroke-dasharray="{{$ctrl.meterCircumference}}" stroke-linecap="round" ng-attr-transform="rotate(90 {{$ctrl.svgCenter}} {{$ctrl.svgCenter}})"/>
        <circle class="dashboard-card-profile-completion-graph__progress-value" ng-attr-cx="{{$ctrl.svgCenter}}" ng-attr-cy="{{$ctrl.svgCenter}}" ng-attr-r="{{$ctrl.meterRadius}}" ng-attr-stroke-width="{{$ctrl.meterWidth}}" ng-attr-stroke-dasharray="{{$ctrl.meterCircumference}}" ng-attr-stroke-dashoffset="{{$ctrl.dashOffset}}" stroke="#027CC2" stroke-linecap="round" ng-attr-transform="rotate(90 {{$ctrl.svgCenter}} {{$ctrl.svgCenter}})" />
      </svg>
      <div class="dashboard-card-profile-completion-graph__user_avatar" style="background-image: url({{$ctrl.currentUser.avatar_url}});"></div>
      <div class="dashboard-card-profile-completion-graph__percentage-badge">{{$ctrl.currentUser.tester_profile_completion_percentage}}%</div>
    </div>
    <div class="dashboard-card-profile-completion-button">
      <a class="dashboard-link-button" href="{{$ctrl.completeMyProfileUrl}}">
        Complete my profile
      </a>
    </div>
  </div>
</div>
`;
