import template from './dashboard-activity-community-tab.component.html';
import controller from './dashboard-activity-community-tab.controller';

const dashboardActivityCommunityTab = {
  bindings: {
    communityActivities: '<',
  },
  template,
  controller,
};

export default dashboardActivityCommunityTab;
