import _ from 'lodash';

const embedlyService = function ($http, $compile, $rootScope, CookieAgreementService, $timeout) {
  function getEmbed(url) {
    return $http.get(`/api/v1/embeds/embed?url=${url}`);
  }

  function extract(url, imageWrapUrl, key) {
    if (!CookieAgreementService.hasAcceptedTargetingCookies()) {
      /* TODO: Some callers of EmbedlyService.extract implicitely rely on the asynchronicity
       *       of the HTTP request. That's probably not a good way to do things - better would
       *       be to make the asynchronicity explicit by returning a promise here or something
       *       like that. For now in order to make this work with Cookie opt-in, run the code
       *       wrapped in a timeout.
       */
      $timeout(() => {
        insertLinkOnly(url, key);
      });
      return;
    }

    getEmbed(url).then(({ data }) => {
      const $container = $(`[data-embedly-${key}="${url}"]`);
      if (!_.isUndefined(data.html)) {
        $container.html(data.html);
        setEmbedlyTitle($container, data);
        return;
      }
      const link = imageWrapUrl ? [`<a class="img-crop" href="${imageWrapUrl}">`, '</a>'] : ['', ''];
      $container.html(`${link[0]}<img src="${data.url}" alt="">${link[1]}`);
    }, () => {
      insertLinkOnly(url);
    });
  }

  function insertLinkOnly(url, key?) {
    const $container = $(`[data-embedly-${key}="${url}"]`);
    const linkScope = $rootScope.$new(true);
    linkScope.url = url;
    const html = $compile('<a class="status-attachment_link" target="_blank" rel="noopener noreferrer" ng-href="{{::url}}" >{{::url}}</a>')(linkScope);
    $container.html(html);
  }

  function setEmbedlyTitle(container, data) {
    // check to see if container has been loaded into DOM, then set iframe title, prevents typeError
    if (container.length > 0) {
      container[0].firstChild.title = data.title;
    }
  }

  return {
    extract,
    getEmbed,
  };
};

embedlyService.$inject = ['$http', '$compile', '$rootScope', 'CookieAgreementService', '$timeout'];

export default embedlyService;
