/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */
import { Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EntireWindowPendingComponent } from './entire-window-pending.component';

@Injectable()
export class EntireWindowPendingService {

  private overlayRef: OverlayRef = null;

  constructor(private readonly overlay: Overlay) {
  }

  enable() {
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
      const spinnerOverlayPortal = new ComponentPortal(EntireWindowPendingComponent);
      this.overlayRef.attach(spinnerOverlayPortal);
    }
  }

  disable() {
    if (!!this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef = null;
    }
  }

}
