/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import * as Ladda from 'ladda';
import {
  EMAIL_REGEX, NAME_REGEX, CAPTCHA_NONE, CAPTCHA_UTEST,
} from '../constants';
import termsModalTemplate from '../components/modal/terms-modal.html';

class CommunityRegistrationFormCtrl {
  constructor(StaticValuesService, $uibModal, StaticPageService, UserService, AlertService, ServerMessages, ConfigService, vcRecaptchaService, $location, $window, $scope, AccessibleFormService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));
      ctrl.name_pattern = new RegExp(NAME_REGEX);
      ctrl.email_pattern = new RegExp(EMAIL_REGEX);

      ctrl.days = StaticValuesService.days();
      ctrl.months = StaticValuesService.months();
      ctrl.years = StaticValuesService.years();

      ctrl.loadLocationData();

      StaticValuesService.languages().then((languages) => {
        ctrl.languages = languages;
      });

      StaticValuesService.webDevices().then((devices) => {
        ctrl.webDevices = devices;
      });
      ctrl.webOSVersions = [];
      ctrl.languages = [];

      StaticValuesService.mobileDevices().then((devices) => {
        ctrl.mobileDevices = devices;
      });
      ctrl.mobileDeviceModels = [];
      ctrl.mobileDeviceOSVersions = [];

      ctrl.registrationData = {
        firstName: '',
        lastName: '',
        email: '',
        birthDate: {},
        city: '',
        stateId: '',
        countryId: undefined,
        zip: '',
        webDevice: { osId: undefined, osVersionId: undefined, osLanguageId: undefined },
        mobileDevice: { handsetMakerId: undefined, handsetModelId: undefined, handsetOSId: undefined },
        password: '',
        privateCommunityId: ctrl.registration.community_id,
        httpReferrer: document.referrer,
      };

      ConfigService.config().then((res) => {
        ctrl.globalConfig = res.data;

        ctrl.recaptchaKey = ctrl.globalConfig.recaptcha_client_key;
        ctrl.captcha_method = ctrl.globalConfig.captcha_method;
        if (ctrl.captcha_method === CAPTCHA_UTEST) {
          ctrl.loadUtestCaptcha();
        }
      });

      ctrl.submissionInProgress = false;
    };

    ctrl.loadLocationData = () => {
      StaticValuesService.countriesAndStates().then((countriesAndStates) => {
        ctrl.statesByCountryId = _.mapValues(countriesAndStates.states);
        ctrl.countries = countriesAndStates.countries.data;
      });
    };

    ctrl.selectDay = function () {
      const { day, month, year } = ctrl.registrationData.birthDate;
      ctrl.days = StaticValuesService.days(month, year);
      // if selected date does not exist in selected year or month then reset the date
      if (ctrl.days.indexOf(day) === -1) {
        ctrl.registrationData.birthDate.day = undefined;
      }
    };

    ctrl.selectMonth = function () {
      ctrl.selectDay();

      const { month, year } = ctrl.registrationData.birthDate;
      ctrl.months = StaticValuesService.months(year);

      // if selected month is not valid in selected year then reset the month
      if (month && Object.keys(ctrl.months).indexOf((month - 1).toString()) === -1) {
        ctrl.registrationData.birthDate.month = '';
      }
    };

    ctrl.selectYear = function () {
      ctrl.selectDay();
      ctrl.selectMonth();
    };

    ctrl.selectCountry = function () {
      delete ctrl.registrationData.stateId;
      ctrl.states = ctrl.statesByCountryId[ctrl.registrationData.countryId];
    };

    ctrl.getWebOSVersions = function () {
      if (ctrl.registrationData.webDevice.osId) {
        StaticValuesService.osVersions(ctrl.registrationData.webDevice.osId).then((versions) => {
          ctrl.webOSVersions = versions;
        });
      }

      ctrl.registrationData.webDevice.osVersionId = undefined;
      ctrl.registrationData.webDevice.osLanguageId = undefined;
    };

    ctrl.getMobileDeviceModels = function () {
      if (ctrl.registrationData.mobileDevice.handsetMakerId) {
        StaticValuesService.handsetModels(ctrl.registrationData.mobileDevice.handsetMakerId).then((models) => {
          ctrl.mobileDeviceModels = models;
        });
      }

      ctrl.registrationData.mobileDevice.handsetModelId = undefined;
      ctrl.registrationData.mobileDevice.handsetOSId = undefined;
    };

    ctrl.getMobileDeviceOSVersions = function () {
      StaticValuesService.handsetOs(ctrl.registrationData.mobileDevice.handsetModelId).then((os) => {
        ctrl.mobileDeviceOSVersions = os;
      });

      ctrl.registrationData.mobileDevice.handsetOSId = undefined;
    };

    // FORM SUBMISSION
    ctrl.submitForm = function (form) {
      if (ctrl.isValidForm(form) && !ctrl.submissionInProgress) {
        ctrl.startSpinner();
        if (ctrl.captcha_method === CAPTCHA_NONE) {
          ctrl.createUser();
        } else if (ctrl.captcha_method === CAPTCHA_UTEST) {
          ctrl.registrationData.captcha_response = {
            challenge: ctrl.utestCaptcha.challenge,
            response: ctrl.utestCaptcha.response,
          };
          ctrl.createUser();
        } else {
          vcRecaptchaService.execute(ctrl.captchaWidgetID);
        }
      } else if (!ctrl.isValidForm(form)) {
        AccessibleFormService.focusFirstError(form, $scope);
      }
    };

    ctrl.isValidForm = function (form) {
      form.$$submitted = true;
      return !Object.keys(form.$error).length;
    };

    ctrl.createUser = function () {
      ctrl.setProgressBar(0.3, 0.9);
      return UserService.createUser(ctrl.registrationData).then((response) => {
        ctrl.setProgressBar(1.0, 1.0);
        ctrl.stopSpinner();
        ctrl.user = response.data;
        ctrl.trackCompleteRegistration();
        $window.location = '/login';
      }, (error) => {
        ctrl.stopSpinner();
        AlertService.addDanger(ServerMessages.prettyMessage(error));
      });
    };

    ctrl.showTerms = function (slug) {
      $uibModal.open({
        animation: true,
        template: termsModalTemplate,
        controller: 'ModalCtrl',
        windowClass: 'light-modal',
        resolve: {
          data: () => StaticPageService.get(slug),
        },
      });
    };

    ctrl.trackCompleteRegistration = function () {
      // this is to track completed registrations only on production
      const hostsToExclude = '(integration|stage|localhost)';
      if (!$location.$$host.match(hostsToExclude)) {
        $window.fbq('track', 'CompleteRegistration', { value: 0, currency: 'USD' });
      }
    };

    ctrl.isEmptyKeyInObject = function (obj) {
      if (Object.keys(obj).length > 0) {
        return !Object.keys(obj).every(key => obj[key] !== '' && obj[key] !== null && obj[key] !== undefined);
      }
      return true;
    };

    ctrl.startSpinner = function () {
      ctrl.submissionInProgress = true;
      ctrl.spinnerBtn.start();
      ctrl.setProgressBar(0.1, 0.3);
    };

    ctrl.setProgressBar = function (start, end) {
      let interval = start;
      setInterval(() => {
        if (interval < end) {
          ctrl.spinnerBtn.setProgress(interval);
          interval += 0.1;
        }
      }, 300);
    };

    ctrl.stopSpinner = function () {
      ctrl.spinnerBtn.stop();
      ctrl.spinnerBtn.remove();
      ctrl.submissionInProgress = false;
    };

    ctrl.setWidgetId = function (widgetId) {
      ctrl.captchaWidgetID = widgetId;
    };

    ctrl.onCaptchaResponse = function (response) {
      ctrl.registrationData.captcha_response = response;
      ctrl.createUser().catch(() => {
        // reset captcha on save failure
        ctrl.cbExpiration();
      });
    };

    ctrl.loadUtestCaptcha = function () {
      StaticValuesService.captcha().then((response) => {
        ctrl.utestCaptcha = response.data;
      });
    };

    ctrl.cbExpiration = function () {
      vcRecaptchaService.reload(ctrl.captchaWidgetID);
      ctrl.registrationData.captcha_response = null;
    };
  }
}

CommunityRegistrationFormCtrl.$inject = ['StaticValuesService', '$uibModal', 'StaticPageService', 'UserService', 'AlertService', 'ServerMessages', 'ConfigService', 'vcRecaptchaService', '$location', '$window', '$scope', 'AccessibleFormService'];

export default CommunityRegistrationFormCtrl;
