import angular from 'angular';

class NavHeaderSearchFieldCtrl {
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onChanges = function (changes) {
      if (changes.query) {
        ctrl.query = angular.copy(changes.query.currentValue);
      }
    };

    ctrl.submit = function () {
      ctrl.onSubmit({
        $event: {
          query: ctrl.query,
        },
      });
    };
  }
}

export default NavHeaderSearchFieldCtrl;
