export default `
<div class="community-featured-list-item">
  <div class="community-featured-list-item__title">
    <a ui-sref="{{$ctrl.route}}" aria-label="{{$ctrl.title}}, {{$ctrl.type}} by {{$ctrl.author.name}}">{{$ctrl.title}}</a>
  </div>
  <div class="community-featured-list-item__author">
    <a ng-if="$ctrl.item.type !== 'project'" ui-sref="profile.about({profileId: $ctrl.author.slug})" aria-hidden="true" tabindex="-1">
      {{$ctrl.item.type === 'referral_campaign' ? 'uTest Community Management' : $ctrl.author.name}}
    </a>
    <a ng-if="$ctrl.item.type === 'project'" ui-sref="project" aria-hidden="true" tabindex="-1">
      <span ng-if="!$ctrl.item.urgent">Ongoing Project</span>
      <span ng-if="$ctrl.item.urgent">Urgent Project</span>
    </a>
  </div>
  <div class="community-featured-list-item__footer">
    <small aria-hidden="true">{{$ctrl.type}}
      <span ng-if="$ctrl.item.type !== 'referral_campaign'"> &#x2022; {{$ctrl.date | relativeDate}} </span>

      <span class="community-featured-list-item-stats" ng-if="$ctrl.showStats">
        <span class="community-trending-list-item__likes">
          <i class="material-icons community-trending-list-item__icon">favorite</i> {{$ctrl.numLikes | numToK}}
        </span>
        <span class="community-trending-list-item__comments">
          <i class="material-icons community-trending-list-item__icon">comment</i> {{$ctrl.numComments | numToK}}
        </span>
      </span>
    </small>
  </div>
</div>
`;
