export default `
<div class="nav-sidebar-dropdown" ng-class="{'nav-sidebar-item--selected' : $ctrl.isSelected}">
  <div class="nav-sidebar-item">
    <a href="{{$ctrl.url}}" class="nav-sidebar-item__link">
      <div aria-hidden="true" class="nav-sidebar-item__icon" ng-class="{'nav-sidebar-item__icon--selected' : $ctrl.isSelected}">
        <i class="material-icons">{{$ctrl.icon}}</i>
      </div>
      <div class="nav-sidebar-item__text" ng-class="{'nav-sidebar-item__text--selected' : $ctrl.isSelected}">
        {{$ctrl.name}}
      </div>
    </a>
    <nav-sidebar-dropdown-toggle
      class="nav-sidebar-dropdown__arrow"
      on-click="$ctrl.toggleDropdown()"
      expanded="$ctrl.isExpanded"
      name="{{$ctrl.name}}"
    >
      <i class="material-icons" aria-hidden="true">{{$ctrl.isExpanded ? 'expand_less' : 'expand_more'}}</i>
    </nav-sidebar-dropdown-toggle>
  </div>
</div>
<div ng-show="$ctrl.isExpanded" ng-class="$ctrl.isExpanded && 'nav-sidebar-dropdown--expanded'" ng-transclude></div>
`;
