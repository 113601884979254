class PageViewService {
  ServiceByType: any;
  constructor(ServiceByType) {
    this.ServiceByType = ServiceByType;
  }

  addPageView(type, id) {
    return this.ServiceByType.get(type).addPageView(id);
  }
}

PageViewService.$inject = ['ServiceByType'];

export default PageViewService;
