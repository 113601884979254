import angular from 'angular';
import {
  Component, HostListener, OnInit,
} from '@angular/core';
import {
  APPLICATION_SIZE_MODES, UNAUTH_WHY_UTEST_BG,
  UNAUTH_WHY_UTEST_IMG, UNAUTH_WHY_UTEST_VIDEO_ID,
} from '../../../constants';

@Component({
  selector: 'ut-why-utest',
  templateUrl: './why-utest.component.html',
  styleUrls: ['./why-utest.component.scss'],
})
class WhyUtestComponent implements OnInit {
  images: any = UNAUTH_WHY_UTEST_IMG;

  bgUrl: string;

  videoId: string = UNAUTH_WHY_UTEST_VIDEO_ID;

  textContent = {
    headerHeading: 'Why uTest?',
    headerDesc: 'uTesters get to experience and test new technology before the general public. Be the first to test the latest.',
    communityHeading: 'Our Community',
    communityDesc: 'Curious, creative, and inclusive - our global community of freelance testers is the engine that powers uTest. Whether you\'re looking for testing tips, career advice, or simply want to talk about your favorite testing tools or the latest tech news – there\'s something for everyone within our community feed, forums and articles.'
      + '<br/><br/>Over the years, our community has grown to become the largest and most passionate group of testers in the world.',
    projectsHeading: 'Paid Projects',
    projectsDesc1: 'Testers are paid twice a month through your choice of either PayPal or Payoneer – whichever is most convenient for you. Freelance testers on uTest earn money for:',
    projectsList: [
      'Submitting approved bugs and test cases',
      'Completing usability surveys',
      'Referral bonuses to signup new testers',
    ],
    projectsDesc2: 'To further reward excellence, higher payouts are available depending on bug value and your current tester rating tier. Visit our <a href=\'https://www.utest.com/projects?tab=suggested\'>Projects Board</a> to browse current testing project opportunities.',
    academyHeading: 'The uTest Academy',
    academyDesc: 'Knowledge is power. uTest prides itself on helping our members learn, continue to grow, and succeed as a tester.\n'
      + '<br/><br/>Members have exclusive access to the uTest Academy – our educational platform dedicated to training and skill advancement. Whether you simply need to learn the basics of bug reporting, improve your Charles Proxy skills, or learn about API testing, the uTest Academy has something for you.',
    articlesForumsHeading: 'Articles & Forums',
    articlesForumsDesc: 'Read tips and best practices from some of our community\'s most knowledgeable testers, stay up to date with technology trends, and share your own expertise within uTest\'s community articles and forums.\n'
      + '<br/><br/>Our active forums give members the opportunity to share testing experiences, ask questions, and receive fast answers from our responsive global community. Do you have something important to share? Write an article to share your knowledge with the whole community!',
    trustedSecureHeading: 'Trusted & Secure',
    trustedSecureDesc: 'Personal information provided by testers and clients is handled with utmost confidentiality and is protected with industry-standard encryption and security measures. uTest’s privacy policies comply with global data protection regulations, ensuring that your information is not misused or disclosed without consent.'
      + '<br/><br/> The uTest platform is designed with security in mind. Regular security audits and updates are conducted to safeguard against potential threats. We thoroughly vet the projects and clients that are brought into the platform. This vetting process ensures that the projects are legitimate and safe for testers to participate in.',
  };

  previousSizeMode: string;
  isSizeModeChanged: boolean;

  @HostListener('window:orientationchange', [])
  onOrientationChange() {
    this.closeWistiaPopover();
  }

  calculateBgStyle() {
    this.isSizeModeChanged = false;
    if (window.innerWidth <= APPLICATION_SIZE_MODES.MEDIUM) {
      this.bgUrl = UNAUTH_WHY_UTEST_BG.small;
      this.isSizeModeChanged = this.previousSizeMode !== 'small';
      this.previousSizeMode = 'small';
    } else if (window.innerWidth > APPLICATION_SIZE_MODES.MEDIUM && window.innerWidth < APPLICATION_SIZE_MODES.WIDE) {
      this.bgUrl = UNAUTH_WHY_UTEST_BG.medium;
      this.isSizeModeChanged = this.previousSizeMode !== 'medium';
      this.previousSizeMode = 'medium';
    } else {
      this.bgUrl = UNAUTH_WHY_UTEST_BG.wide;
      this.isSizeModeChanged = this.previousSizeMode !== 'wide';
      this.previousSizeMode = 'wide';
    }
    if (this.isSizeModeChanged) {
      this.closeWistiaPopover();
    }
  }

  ngOnInit() {
    this.calculateBgStyle();
    angular.element(window).on('resize', () => {
      this.calculateBgStyle();
    });
    window.scroll(0, 0);
  }

  closeWistiaPopover() {
    const button = angular.element(document.querySelector('#wistia-o98jkwxo72-1_popover_popover_close_button'));
    if (button) {
      button.click();
    }
  }
}

export { WhyUtestComponent };
