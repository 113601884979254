/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputContainerComponent } from './input-container.component';
import { PrefixDirective } from './prefix.directive';
import { SuffixDirective } from './suffix.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    InputContainerComponent,
    PrefixDirective,
    SuffixDirective,
  ],
  exports: [
    InputContainerComponent,
    PrefixDirective,
    SuffixDirective,
  ]
})
export class InputContainerModule {}
