import template from './project.html';
import controller from './project.controller';

const project = {
  bindings: {
    $transition$: '<',
    user: '<',
    coverBg: '<',
    headerData: '<',
    projectInfo: '<',
  },
  template,
  controller,
};

export default project;
