class SingleStatusCtrl {
  constructor($scope, $location, StatusService, status) {
    $scope.status = status.data;

    function setInstruction(state) {
      if (state === 'hidden') {
        $scope.instructions = [{
          type: 'danger',
          message: 'This status is hidden',
        }];
      } else {
        $scope.instructions = [];
      }
    }

    setInstruction($scope.status.state);

    $scope.$watch('status.state', setInstruction);

    $scope.service = StatusService;
  }
}

SingleStatusCtrl.$inject = ['$scope', '$location', 'StatusService', 'status'];

export default SingleStatusCtrl;
