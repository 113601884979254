import { map, isString } from 'lodash';

const UrlService = ['$state', '$q', '$http', function ($state, $q, $http) {
  const self = this;

  this.addItemUrl = function (item) {
    switch (item.type) {
      case 'article':
        item.url = $state.href('singlearticle', { articleId: item.slug }, { absolute: true });
        break;
      case 'course':
        item.url = $state.href('singlecourse', { courseId: item.slug }, { absolute: true });
        break;
      case 'tool':
        item.url = $state.href('singletool', { toolId: item.slug }, { absolute: true });
        break;
      case 'project':
        item.url = $state.href('singleproject', { projectId: item.slug }, { absolute: true });
        break;
      case 'discussion':
        item.url = $state.href('singlediscussionpage', { page: 1, discussionId: item.slug }, { absolute: true });
        break;
      case 'referral_campaign':
        item.url = $state.href('referralCampaignsShow', { referralCampaignId: item.slug }, { absolute: true });
        break;
      default:
    }

    return item;
  };

  this.addResponseUrl = function (response) {
    response.data = self.addItemUrl(response.data);
    return response;
  };

  this.addResponseUrls = function (response) {
    response.data = map(response.data, self.addItemUrl);
    return response;
  };

  this.shorten = function (url, slashtag) {
    const def = $q.defer();
    const data: any = { url };

    if (isString(slashtag) && slashtag.trim().length > 0) {
      data.slashtag = slashtag.trim();
    }

    $http.post('/api/v1/services/url_shorten', data).then(({ data }) => {
      def.resolve(data);
    }, ({ data }) => { def.reject(data); });
    return def.promise;
  };
}];

export default UrlService;
