<div class="feed article-two_column">
  <div class="feed-header clearfix">
    <a class="feed-item-info" uiSref="profile.about" [uiParams]="{ profileId: article.user.slug }" [attr.aria-label]="'Article Author, {{article.user.name}}'">
        <div class="feed-header-info-icon">
          <div class="profile-thumb" [bgImg]="article.user.avatar_url"></div>
        </div>
        <div class="feed-header-info-name">
              {{article.user.name | slice: 0 : 20}}
              <span *ngIf="article.user.country?.name">
                ({{article.user.country.name}})
              </span>
          <div class="feed-item-header-title" *ngIf="article.user.user_title">{{article.user.user_title | slice: 0 : 40 }}</div>
        </div>
    </a>
  </div>

  <div class="feed-content">
    <div class="feed-content-heading clearfix">
      <div><a uiSref="singlearticle" [uiParams]="{ articleId : article.slug }">{{article.title}}</a></div>
    </div>
    <div class="feed-content-footer">
      <div class="status-btns">
        <ut-community-feed-item-like-count [likeable]="article"></ut-community-feed-item-like-count>
        <ut-community-feed-item-like-btn [likeable]="article"></ut-community-feed-item-like-btn>
        <ut-comment-btn [commentable]="article"></ut-comment-btn>
      </div>
      <div class="timestamp">{{article.published_at | relativeDate}}</div>
    </div>
  </div>
</div>
