<div class="why-utest__community-slides">
  <img class="utesters__image" [src]="communityUtestersSelected.image" draggable="false" (mouseup)="onMouseUp()"
       (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)" alt="{{ communityUtestersSelected.person }}"/>
  <div class="why-utest__community-slides-box">
    <span class="why-utest__community-slides-box-name" [innerHTML]="communityUtestersSelected.person"></span>
  </div>
  <div class="why-utest__community-slides-nav">
    <div [class]="communityUtestersSelected.id === 1 ? 'oval-top-selected' : 'oval-top'" (click)="uTesterNavigateClick(1)"></div>
    <div [class]="communityUtestersSelected.id === 2 ? 'oval-top-selected' : 'oval-top'" (click)="uTesterNavigateClick(2)"></div>
    <div [class]="communityUtestersSelected.id === 3 ? 'oval-top-selected' : 'oval-top'" (click)="uTesterNavigateClick(3)"></div>
  </div>
</div>
