import NProgress from 'nprogress';

class ProgressBarService {
  constructor() {
    NProgress.configure({ showSpinner: false });
  }

  start() {
    NProgress.start();
  }

  done() {
    NProgress.done();
  }
}

export default ProgressBarService;
