export default `
<div>
  <div class="dashboard-card-list" ng-if="$ctrl.activities.length > 0">
    <activity-list-item current-user="$ctrl.currentUser" activity="activity" ng-repeat="activity in $ctrl.activities" ng-if="activity.type !== 'hidden'"></activity-list-item>
  </div>

  <div class="dashboard-activity-no-list-items" ng-if="$ctrl.activities.length === 0" ng-show="$ctrl.activities">
    <div class="dashboard-activity-no-list-items__primary">
      You don't have any recent activity.
    </div>

    <div class="dashboard-activity-no-list-items__secondary">
      Check out the community page to share content and meet other uTesters.
    </div>

    <a ui-sref="community" class="dashboard-link-button">
      Community Page
    </a>
  </div>

  <a class="dashboard-card-footer" ui-sref="activity" ng-if="$ctrl.activities.length > 0">
    View all activity
  </a>
</div>
`;
