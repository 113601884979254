import _ from 'lodash';

class HomeFeedService {
  $http: ng.IHttpService;
  UrlService: any;
  constructor(UrlService, $http: ng.IHttpService) {
    this.UrlService = UrlService;
    this.$http = $http;
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/home', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  personalFeed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/home/personal_feed', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  pinned() {
    return this.$http.get('/api/v1/home/pinned');
  }

  trending() {
    return this.$http.get('api/v1/home/trending');
  }
}

HomeFeedService.$inject = ['UrlService', '$http'];

export default HomeFeedService;
