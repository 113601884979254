import _ from 'lodash';

class DiscussionCategoryService {
  $http: ng.IHttpService;
  categoryTypeFilter: ng.IFilterFunction;
  constructor($http, $filter) {
    this.$http = $http;
    this.categoryTypeFilter = $filter('categoryType');
  }

  all() {
    return this.$http.get('/api/v1/categories', { params: { per_page: 50 } });
  }

  allForSelect() {
    // @ts-ignore
    const withParents = _.partial(_.filter, _, category => _.has(category, 'parent'));
    // @ts-ignore
    const setFullNames = _.partial(_.each, _, (category: any) => {
      category.fullName = `${this.categoryTypeFilter(category.parent.name)}: ${category.name}`;
    });

    return this.all().then(_.property('data'))
      .then(withParents)
      .then(setFullNames) // @ts-ignore
      .then(_.partial(_.sortBy, _, 'fullName'));
  }

  find(id) {
    return this.$http.get(`/api/v1/categories/${id}`);
  }
}

DiscussionCategoryService.$inject = ['$http', '$filter'];

export default DiscussionCategoryService;
