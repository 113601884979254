import template from './view-more.component.html';
import controller from './view-more.controller';

const viewMore = {
  bindings: {
    isLoading: '=',
    isLastPage: '=',
    loadCallback: '&',
    label: '@?',
  },
  template,
  controller,
};

export default viewMore;
