import template from './delete-btn.component.html';

const controller = function () {
  this.activated = false;
};

const deleteBtn = {
  bindings: {
    onDelete: '&',
  },
  template,
  controller,
};

export default deleteBtn;
