/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { InputContainerModule } from '../input-container/input-container.module';
import { OptionModule } from '../option/option.module';
import { AutocompleteInputComponent } from './autocomplete-input/autocomplete-input.component';
import { AutocompleteComponent } from './autocomplete.component';
import { MultiSelectComponent } from './multi-select/multi-select.component';
import { SingleSelectComponent } from './single-select/single-select.component';

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    OptionModule,
    MatIconModule,
    InputContainerModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AutocompleteComponent,
    SingleSelectComponent,
    MultiSelectComponent,
    AutocompleteInputComponent,
  ],
  exports: [
    OptionModule,
    InputContainerModule, // TODO-MKU: this should be removed
    AutocompleteComponent,
  ]
})
export class AutocompleteModule {}
