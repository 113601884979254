import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utNumToK',
})

export class NumToKPipe implements PipeTransform {
  transform(num: number): string {
    const numString = num.toString();
    const vitalNum = numString.split('').splice(0, 3);
    if (num >= 1000) {
      if (num >= 100000) {
        return `${vitalNum.join('')}k`;
      }
      if (num >= 10000) {
        if (vitalNum[2] === '0') {
          return `${vitalNum[0]}${vitalNum[1]}k`;
        }
        return `${vitalNum[0]}${vitalNum[1]}.${vitalNum[2]}k`;
      }
      if (vitalNum[1] === '0') {
        return `${vitalNum[0]}k`;
      }
      return `${vitalNum[0]}.${vitalNum[1]}k`;
    }
    return numString;
  }
}
