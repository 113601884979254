export default `
<div class="community-trending-list-item">
  <div class="community-trending-list-item__title" ng-if="$ctrl.title">
    <a ui-sref="{{$ctrl.route}}" aria-label="{{$ctrl.type}}, {{$ctrl.title}}" ng-bind-html="$ctrl.title | cleanUp | ulinky"></a>
  </div>
  <div class="community-trending-list-item-details">
    <div aria-hidden="true">
      <div ng-if="$ctrl.item.type !== 'project'" ntux-avatar-badges="$ctrl.author" class="community-trending-list-item__avatar"></div>
    </div>
    <div class="trending-container">
      <a ui-sref="singlestatus({statusId: $ctrl.item.id})" class="status-link-cover" id="thumbnailLink" aria-label="thumbnail for {{$ctrl.type}}, {{$ctrl.title}}" >
        <status-attachment class="community-trending-list-item__thumbnail" ng-if="$ctrl.type == 'Status' && $ctrl.item.attached_url" status="$ctrl.item" location="trending"></status-attachment>
      </a>
      <div class="community-trending-list-item-details__author">
        <a ui-sref="profile.about({profileId: $ctrl.author.slug})" aria-label="{{$ctrl.type}} Author, {{$ctrl.author.name}}">
          {{$ctrl.author.name}}
        </a>
      </div>
      <div class="community-trending-list-item__footer">
        <small>
          <span class="community-trending-list-item__details">
          {{$ctrl.type}} <span><span class="icon-toggle"> &#x2022; </span>{{$ctrl.date | relativeDate}}</span>
          </span>
          <span class="community-trending-list-item-stats" ng-if="$ctrl.item.type !== 'project'">
            <span class="community-trending-list-item__likes">
              <i class="material-icons community-trending-list-item__icon">favorite</i> {{$ctrl.numLikes | numToK}}
            </span>
            <span class="community-trending-list-item__comments">
              <i class="material-icons community-trending-list-item__icon">comment</i> {{$ctrl.numComments | numToK}}
            </span>
          </span>
        </small>
      </div>
    </div>
  </div>
</div>
`;
