import _ from 'lodash';

class FollowingController {
  constructor($scope, UserService, FeedStore, user) {
    $scope.following = new FeedStore(_.bind(UserService.following, UserService, user.data.id));
    $scope.following.load();
  }
}

FollowingController.$inject = ['$scope', 'UserService', 'FeedStore', 'user'];

export default FollowingController;
