import _ from 'lodash';
import { EMPTY_CONTENT_MARKDOWNS } from '../constants';

class EditDiscussionCtrl {
  constructor($scope, $state, DiscussionService, AlertService, ProgressBarService, ServerMessages, featureFlags, discussion) {
    const goToTopic = function (item) {
      $state.go('singlediscussionpage', { page: 1, discussionId: item.id });
    };
    $scope.saveInProgress = false;
    $scope.discussion = discussion.data;

    $scope.save = function () {
      const trimmedComment = _.trim($scope.discussion.content);
      if (_.isEmpty(trimmedComment) || EMPTY_CONTENT_MARKDOWNS.includes(trimmedComment)) {
        AlertService.addDanger('Can\'t post empty content!');
        return;
      }

      if ($scope.discussion && $scope.discussion.title && ($scope.discussion.content ? $scope.discussion.content.replace(/\s/g, '').length > 0 : false) && !$scope.saveInProgress) {
        $scope.saveInProgress = true;

        ProgressBarService.start();

        DiscussionService.update({
          id: $scope.discussion.id,
          title: $scope.discussion.title,
          content: $scope.discussion.content,
        }).then((res) => {
          const newItem = res.data;
          goToTopic(newItem);
          AlertService.add('Forum topic successfully saved');
          ProgressBarService.done();
          $scope.saveInProgress = false;
        }, (response) => {
          AlertService.addDanger(ServerMessages.prettyMessage(response));
          $scope.saveInProgress = false;
          ProgressBarService.done();
        });
      } else {
        AlertService.add('Please fill out all the fields', 'danger');
      }
    };
  }
}

EditDiscussionCtrl.$inject = ['$scope', '$state', 'DiscussionService', 'AlertService', 'ProgressBarService', 'ServerMessages', 'featureFlags', 'discussion'];

export default EditDiscussionCtrl;
