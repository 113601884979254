import { StateService } from '@uirouter/core';

class CourseTrackQuizQuestionCtrl {
  constructor($state: StateService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.userChoice.push({ question: ctrl.question.id });
      if (ctrl.nextQuestion === undefined) {
        ctrl.isLastQuestion = true;
      } else {
        ctrl.isLastQuestion = false;
      }
    };

    ctrl.getResults = (trackId) => {
      $state.go('report-card', { trackId });
    };

    ctrl.goToNextQuestion = () => {
      if (ctrl.isLastQuestion) {
        $state.go('report-card', { trackId: ctrl.courseTrack.id });
      } else {
        $scope.$emit('showNextQuestion', ctrl.questionNumber + 1);
      }
    };
  }
}

CourseTrackQuizQuestionCtrl.$inject = ['$state', '$scope'];

export default CourseTrackQuizQuestionCtrl;
