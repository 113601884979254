import template from './nav-sidebar-dropdown.component.html';
import controller from './nav-sidebar-dropdown.controller';

const navSidebarDropdown = {
  bindings: {
    icon: '@',
    name: '@',
    url: '@',
    highlight: '@',
  },
  template,
  controller,
  transclude: true,
};

export default navSidebarDropdown;
