import controller from './status-attachment.controller';
import template from './status-attachment.component.html';

const statusAttachement = {
  bindings: {
    status: '<',
    location: '@',
  },
  template,
  controller,
};

export default statusAttachement;
