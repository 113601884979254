class HallOfFameService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  periods() {
    return this.$http.get('/api/v1/users/hall_of_fame/periods');
  }

  winners(year, period) {
    return this.$http.get(`/api/v1/users/hall_of_fame/${year}/${period}`);
  }
}

HallOfFameService.$inject = ['$http'];

export default HallOfFameService;
