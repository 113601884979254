export default `
<style type="text/css">
.section-home-cover-random iframe {
  margin: 0 auto;
  display: block;
}
</style>
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w960">
      <div class="section-home-cover-random">
        <div class="c6">
          <h1>VON ZU HAUSE AUS GELD VERDIENEN</h1>
          <p>uTest ist eine Software-Testing Firma, die es einem erlaubt, von zu Hause aus Geld zu verdienen, indem man Firmen dabei hilft ihre Produkte zu verbessern, wenn Sie in der realen Welt Anwendung finden - dort, wo Apps und Websites letztlich auch genutzt, gelebt und gespielt werden. Unsere Tester Community besteht aus weltweit über 175 000 Testern. Tritt bei und werde Teil dieser einzigartigen Gemeinschaft und tausche dich mit Gleichgesinnten aus.</p>
          <a class="aButton bgYellow txtBlack trackThis" href="https://platform.utest.com/" target="_blank">JETZT ANMELDEN</a> </div>
        <div class="c6">
          <div class="wistia_embed wistia_async_yu42n31axu" style="width:680px; height:383px;"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="grid w960">
  <div class="c4">
    <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/textbook72x73.png" />
      <h3>Was ist uTest?</h3>
      <p>Klicke auf diesen Link, um mehr über uTest zu erfahren.</p>
      <a class="aButton aButtonSmall bgYellow" href="http://www.utest.com/was-ist-utest" target="_blank;">Zur Erklärung</a> </div>
  </div>
  <div class="c4">
    <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/QA72x73.png" />
      <h3>Das deutsche uTest Forum</h3>
      <p>Wir haben auch ein deutsches Forum in dem wir alles rund ums Thema Testen auf uTest besprechen. Hier findest du auch alle Neuerungen! Einfach anklicken und im Formular eintragen (du benötigst vorher einen aktiven uTest account).</p>
      <a class="aButton aButtonSmall bgYellow" href="https://docs.google.com/forms/d/1yMfBLecVMx1w5sHp6tSBEpeOg6lzMzpbziScJWbVX20/viewform?usp=send_form" target="_blank;" rel="noopener noreferrer">Jetzt beitreten</a> </div>
  </div>
  <div class="c4">
    <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/play72x73.png" />
      <h3>uTest university</h3>
      <p>Verbessere deine Fähigkeiten rund ums Testen, indem du auf hunderte kostenlose Kurse zugreifst, die von Experten verfasst wurden. Dies beinhaltet How tos, aufgezeichnete Webinare und schriftliche Kurse.</p>
      <a class="aButton aButtonSmall bgYellow" href="http://university.utest.com/" target="_blank;">Jetzt besuchen</a> </div>
  </div>
</div>
<div class="grid w960">
  <div class="c2"> <img alt="Contact Us" src="/assets/images/legacy/icons/map_contactus.png"> </div>
  <div class="c10">
    <div class="cf">
      <h3 class="c12">Hast du noch weitere Fragen?</h3>
      <p class="c7">Schaue in unsere deutschen FaQs wenn du noch weitere Fragen zum Thema uTest hast, beispielsweise hinsichtlich Bezahlung, dein erster Test Cycle, und vieles mehr.</p>
      <div class="c5 ctaBandButton"> <a class="aButton bgBlue" target="_blank" href="http://www.utest.com/german-faqs">Häufig gestellte Fragen</a> </div>
    </div>
  </div>
</div>
`;
