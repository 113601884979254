export default `
<div id="mainContent">
  <community-nav></community-nav>
  <div class="container-fluid">
    <h1>Edit Topic</h1>
    <form ng-submit="save()" class="form-clean">
      <div class="input-row-invisible">
        <label for="discussionTitle">Discussion Title</label><span aria-hidden="true"> *Required</span>
        <input ng-model="discussion.title" placeholder="Discussion Title" type="text" aria-required="true" id="discussionTitle">
      </div>
      <div class="input-row-invisible">
        <label>Topic Text</label><span aria-hidden="true"> *Required</span>
        <ut-editor [(value)]="discussion.content" text-required="true" text-area-label="Write your Forums Post here"></ut-editor>
      </div>
    </form>
  </div>
</div>
<div class="editor-footer">
  <div class="container-fluid editor-footer-content">
    <button type="button" ng-disabled="saveInProgress" class="btn editor-footer-action-btn topic" ng-click="save()">Save</button>
  </div>
</div>
`;
