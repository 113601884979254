import controller from './articles.controller';
import template from './articles.component.html';

const articles = {
  bindings: {
    headerData: '<',
    coverBg: '<',
  },
  template,
  controller,
};

export default articles;
