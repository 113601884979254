/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';

const angularjsDeps = [
  '$analytics',
  '$compile',
  '$cookies',
  '$filter',
  '$q',
  '$sanitize',
  '$scope',
  '$state',
  '$timeout',
  '$uibModal',
  'AlertService',
  'ConfigService',
  'FeedStore',
  'LightTesterErrorService',
  'LikeService',
  'SearchService',
  'UserService',
];

const angularjsDependencyProviders = _.map(angularjsDeps, (dependency) => ({
  provide: dependency,
  useFactory: ($injector: any) => $injector.get(dependency),
  deps: ['$injector'],
}));

export default angularjsDependencyProviders;
