import angular from 'angular';

class UnauthenticatedHeaderCtrl {
  constructor($window) {
    // @ts-ignore
    this.$onInit = () => {
      const ctrl = this as any;
      ctrl.isOpen = false;
      ctrl.toggleMenu = () => {
        ctrl.isOpen = !ctrl.isOpen;
      };

      ctrl.closeMenu = () => {
        ctrl.isOpen = false;
      };

      if ($window.innerWidth >= 992) {
        $('#unauthenticated-dropdown-nav').addClass('unauthenticated-nav-bar__dropdown-menu--hide');
      } else {
        $('#unauthenticated-dropdown-nav').removeClass('unauthenticated-nav-bar__dropdown-menu--hide');
      }

      // @ts-ignore
      this.onResize = function () {
        if ($window.innerWidth >= 992) {
          $('#unauthenticated-dropdown-nav').addClass('unauthenticated-nav-bar__dropdown-menu--hide');
        } else {
          $('#unauthenticated-dropdown-nav').removeClass('unauthenticated-nav-bar__dropdown-menu--hide');
        }
      };

      // @ts-ignore
      this.removeEventListeners = function () {
        angular.element($window).off('resize', this.onResize);
      };

      // @ts-ignore
      angular.element($window).on('resize', this.onResize);
    };

    // @ts-ignore
    this.$onDestroy = function () {
      this.removeEventListeners();
    };
  }
}

UnauthenticatedHeaderCtrl.$inject = ['$window'];

export default UnauthenticatedHeaderCtrl;
