import _ from 'lodash';

const DiscussionService = ($state, $http) => {
  const typeFromName = function (name) {
    return name.split(/\s/)[0].toLowerCase();
  };

  const addTypeToOneDiscussion = function (discussion) {
    discussion.category_type = typeFromName(_.get(discussion, 'category.parent.name', 'default'));
    return discussion;
  };

  const addTypeToDiscussionPromise = function (res) {
    if (_.isArray(res.data)) {
      res.data = _.map(res.data, addTypeToOneDiscussion);
    } else {
      res.data = addTypeToOneDiscussion(res.data);
    }
    return res;
  };

  const random = () => $http.get('/api/v1/topics/random').then(addTypeToDiscussionPromise);

  const feed = (parameters) => {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return $http.get('/api/v1/topics', { params: parameters }).then(addTypeToDiscussionPromise);
  };

  const find = id => $http.get(`/api/v1/topics/${id}`).then(addTypeToDiscussionPromise);

  const create = data => $http.post('/api/v1/topics', data);

  const postComment = (id, comment) => $http.post(`/api/v1/topics/${id}/comments`, comment);

  const postCommentAndReturnCommentable = function (id, comment) {
    return $http.post(`/api/v1/topics/${id}/comments`, Object.assign(comment, { return_commentable: true }));
  };

  const filter = params => $http.get('/api/v1/topics', { params: { filter: params } });

  const like = function (id) {
    return $http.post(`/api/v1/topics/${id}/like`);
  };

  const unlike = function (id) {
    return $http.delete(`/api/v1/topics/${id}/like`);
  };

  const flag = function (id, reason) {
    return $http.post(`/api/v1/topics/${id}/flag`, { reason });
  };

  const getPage = function (id, page, sort, keyword) {
    const params: any = { page };

    switch (sort) {
      case 'oldest':
        params.sort = 'published_at';
        break;
      case 'newest':
        params.sort = '-published_at';
        break;
      default:
    }

    keyword = keyword.replace(/;/g, '');

    if (keyword) {
      params.filter = { keyword };
    }

    return $http.get(`/api/v1/topics/${id}/comments/flat`, { params });
  };

  const remove = id => $http.delete(`/api/v1/topics/${id}`);

  const likedUsers = function (id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return $http.get(`/api/v1/topics/${id}/liked_users`, { params: parameters });
  };

  const update = function (discussion) {
    return $http.put(`/api/v1/topics/${discussion.id}`, discussion);
  };

  const transfer = data => $http.post('/api/v1/topics/transfer', data);

  const toggleLock = function (discussion) {
    const un = discussion.locked ? 'un' : '';
    return $http.post(`/api/v1/topics/${discussion.id}/${un}lock`);
  };

  const toggleHide = function (discussion) {
    const which = discussion.state === 'hidden' ? 'publish' : 'hide';
    return $http.post(`/api/v1/topics/${discussion.id}/${which}`);
  };

  const removeTodo = function (id) {
    return $http.delete(`/api/v1/topics/${id}/todo`);
  };

  const subscribe = function (id) {
    return $http.post(`/api/v1/topics/${id}/subscribe`);
  };

  const unsubscribe = function (id) {
    return $http.delete(`/api/v1/topics/${id}/subscribe`);
  };

  return {
    feed,
    random,
    find,
    create,
    postComment,
    postCommentAndReturnCommentable,
    filter,
    like,
    unlike,
    flag,
    getPage,
    remove,
    likedUsers,
    update,
    toggleLock,
    toggleHide,
    removeTodo,
    subscribe,
    unsubscribe,
    transfer,
  };
};

DiscussionService.$inject = ['$state', '$http'];

export default DiscussionService;
