export default `
<div class="activity-feed-wrapper">
  <div class="profile-activity-feed-item activity-comment_activity" ng-repeat="comment in commentsStore.feedItems" >
    <div class="activity-feed-content" >
      <div class="profile-thumb" style="background: url({{comment.user.avatar_url}});background-size:cover;"></div>
      <div class="activity-header">
      <a ui-sref="profile.about({profileId: comment.user.slug})">{{comment.user.name}}</a>
      <span tabindex="0"
            class="fa fa-info-circle"
            role="button"
            aria-label="{{ 'Open ' + comment.user.name + ' tooltip' }}"
            dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: comment.user})"
            ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: comment.user})"
            ng-click="showTooltip('user-tooltip', {user: comment.user});$event.preventDefault(); $event.stopImmediatePropagation()"
            ng-mouseleave="hideTooltip()"></span>
        commented on <a ng-href="{{stateStore(comment.commentable)}}">{{cs.getCommentableLabel(comment.commentable)}}</a>.
      </div>
    </div>
    <div class="time-stamp">{{comment.published_at | relativeDate}}</div>

    <article class="content-extra" ng-bind-html='comment.content | uMarkdown | trustAsHtml'></article>

  </div>
</div>
<load-more load-callback="commentsStore.loadMore()" is-loading="commentsStore.isLoading" is-last-page="commentsStore.isLastPage" fade-target="footer"></load-more>
`;
