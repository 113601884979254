import _ from 'lodash';
import { StateService } from '@uirouter/core';

class ToolService {
  $http: ng.IHttpService;
  $state: StateService;
  UrlService: any;
  constructor($state: StateService, UrlService, $http: ng.IHttpService) {
    this.$state = $state;
    this.UrlService = UrlService;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/tools/${id}`).then(this.UrlService.addResponseUrl);
  }

  random() {
    return this.$http.get('/api/v1/tools/random').then(this.UrlService.addResponseUrls);
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/tools', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  like(id) {
    return this.$http.post(`/api/v1/tools/${id}/like`, '');
  }

  unlike(id) {
    return this.$http.delete(`/api/v1/tools/${id}/like`);
  }

  commentsPage(id, page, sort) {
    const params = {
      page: !page ? 1 : page,
      sort: sort || 'threaded',
    };

    return this.$http.get(`/api/v1/tools/${id}/comments/flat`, { params });
  }

  postComment(id, comment) {
    return this.$http.post(`/api/v1/tools/${id}/comments`, comment);
  }

  postTool(tool) {
    return this.$http.post('/api/v1/tools', tool);
  }

  addTodo(id) {
    return this.$http.post(`/api/v1/tools/${id}/todo`, '');
  }

  removeTodo(id) {
    return this.$http.delete(`/api/v1/tools/${id}/todo`);
  }

  likedUsers(id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/tools/${id}/liked_users`, { params: parameters });
  }

  subscribe(id) {
    return this.$http.post(`/api/v1/tools/${id}/subscribe`, '');
  }

  unsubscribe(id) {
    return this.$http.delete(`/api/v1/tools/${id}/subscribe`);
  }
}

ToolService.$inject = ['$state', 'UrlService', '$http'];

export default ToolService;
