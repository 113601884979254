/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Component, OnDestroy, OnInit } from '@angular/core';
import { asyncScheduler, merge, Subscription } from 'rxjs';
import { filter, observeOn, tap } from 'rxjs/operators';
import { OptionMultiSelectionService } from '../../option/selection/multi-selection/option-multi-selection.service';
import { AutocompleteComponent } from '../autocomplete.component';

@Component({
  selector: 'ngf-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, OnDestroy {

  constructor(public autocomplete: AutocompleteComponent,
              private optionService: OptionMultiSelectionService) {}

  private sub: Subscription;

  ngOnInit(): void {
    this.sub = merge(
      this.handleSelectionChange$(),
      this.handleWrittenValueChanged$()
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleSearch(search: string): void {
    this.autocomplete.handleSearch(search);
  }

  handleToggle() {
    this.autocomplete.optionsOverlay.toggle();
  }

  private handleSelectionChange$() {
    return this.optionService.selection$.pipe(
      observeOn(asyncScheduler),
      filter(selection => selection.userInput),
      tap(selection => {
        this.autocomplete.handleSelection(selection.values);
        this.autocomplete.onTouched();
      })
    );
  }

  private handleWrittenValueChanged$() {
    return this.autocomplete.writtenValue$.pipe(
      tap(values => this.optionService.selectAll({values, userInput: false}))
    );
  }
}
