import _ from 'lodash';

class AcademyCourseCtrl {
  constructor(CourseTrackService, CourseService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      if (ctrl.courseTrack) {
        ctrl.course = ctrl.courseTrack.current_course;
        ctrl.courses = ctrl.courseTrack.courses;

        if (ctrl.course !== undefined) {
          ctrl.findPreviousAndNext();
          ctrl.allCoursesViewed = _.every(ctrl.courses, course => (course.viewed_by_current_user || course.slug === ctrl.course.slug));
          if (ctrl.allCoursesViewed) {
            CourseTrackService.updateCourseTrackProgress(ctrl.courseTrack.id, {
              viewed_all_courses: true,
            }).then((res) => {
              ctrl.courseTrack.user_viewed_all_courses = res.data.user_has_viewed_all_courses;
              ctrl.courseTrack.user_can_take_quiz = res.data.user_can_take_quiz;
              ctrl.courseTrack.user_can_apply_to_test_cycle = res.data.user_can_apply_to_test_cycle;
              ctrl.courseTrack.user_has_passed_practice_test_cycle_except_lv_5 = res.data.user_has_passed_practice_test_cycle_except_lv_5;
              ctrl.courseTrack.test_cycle_link = res.data.test_cycle_link;
              ctrl.courseTrack.user_has_completed_course_track = res.data.user_has_completed_course_track;
            });
          }
        }
      }

      if (ctrl.course !== undefined) {
        CourseService.addPageView(ctrl.course.id);
      }
    };

    ctrl.findPreviousAndNext = () => {
      const currentIndex = _.findIndex(ctrl.courses, c => (c as any).slug === ctrl.course.slug);

      ctrl.next = ctrl.courses[currentIndex + 1];
      ctrl.previous = ctrl.courses[currentIndex - 1];
    };

    ctrl.testCycleSignUp = () => {
      $scope.$broadcast('testCycleSignUp');
    };
  }
}

AcademyCourseCtrl.$inject = ['CourseTrackService', 'CourseService', '$scope'];

export default AcademyCourseCtrl;
