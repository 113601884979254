export default `
<div id="mainContent" class="footer-wrapper">
  <div class="container block">
    <h2>uPoints Leaderboard</h2>
    <p>Here are the testers who have earned the most uPoints <a ui-sref="gamification">(click here to see how)</a>. These are great people to follow and connect with.</p>
    <uib-tabset>
      <uib-tab heading="Last 30 Days">
        <feed-leaderboard user="leader" ng-repeat="leader in recent.feedItems"></feed-leaderboard>
        <load-more load-callback="recent.loadMore()" is-loading="recent.isLoading" is-last-page="recent.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
      </uib-tab>
      <uib-tab heading="All Time" select="allTime.loadOnce()" >
        <feed-leaderboard user="leader" ng-repeat="leader in allTime.feedItems"></feed-leaderboard>
        <load-more load-callback="allTime.loadMore()" is-loading="allTime.isLoading" is-last-page="allTime.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
