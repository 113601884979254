export default `
<div class="nav-sidebar-footer" role="contentinfo">
  <a class="nav-sidebar-footer__link nav-sidebar-footer__link--inline" href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self" ng-if="!$ctrl.currentUser">Sign in</a>
  <span ng-if="!$ctrl.currentUser"> / </span>
  <a class="nav-sidebar-footer__link nav-sidebar-footer__link--inline" href="/signup/personal" ng-if="!$ctrl.currentUser">Sign up</a>
  <a class="nav-sidebar-footer__link" href="/utest-guidelines">uTest Guidelines</a>
  <a class="nav-sidebar-footer__link" href="/terms-and-conditions">Terms & Conditions</a>
  <a class="nav-sidebar-footer__link" href="/privacy-policy">Privacy Policy</a>
  <!-- OneTrust Cookies Settings button start -->
  <button id="ot-sdk-btn" class="ot-sdk-show-settings nav-sidebar-footer__button--onetrust">Cookie Settings</button>
  <!-- OneTrust Cookies Settings button end -->
  <a class="nav-sidebar-footer__link" href="https://support.utest.com/csp" target="_blank" ng-if="!$ctrl.currentUser">Help</a>
  <div class="nav-sidebar-footer__copyright">
    © {{$ctrl.year}} Applause App Quality, Inc.
  </div>
</div>
`;
