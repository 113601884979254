import template from './search-result.component.html';
import controller from './search-result.controller';

const searchResult = {
  bindings: {
    $transition$: '<',
    user: '<',
  },
  template,
  controller,
};

export default searchResult;
