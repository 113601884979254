const onFinishRender = function ($timeout) {
  return {
    restrict: 'A',
    link(scope) {
      if (scope.$last === true) {
        $timeout(() => {
          scope.$emit('content.changed');
        });
      }
    },
  };
};

onFinishRender.$inject = ['$timeout'];

export default onFinishRender;
