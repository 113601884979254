import angular from 'angular';

const cleanUp = function () {
  return function (string) {
    if (!angular.isString(string)) {
      return string;
    }
    return $('<div/>').html(string.replace('<br>', ' ').replace(/<\/?([a-z][a-z0-9]*)\b[^>]*>/gi, '')).text();
  };
};

export default cleanUp;
