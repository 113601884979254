import template from './community-feed-item-delete-overlay.component.html';

const communityFeedItemDeleteOverlay = {
  bindings: {
    item: '=',
  },
  template,
};

export default communityFeedItemDeleteOverlay;
