class AlertMessageCtrl {
  constructor(AlertMessageService, $rootScope: ng.IRootScopeService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      AlertMessageService.getAll().then((res) => {
        this.alertMessages = res.data;
        $rootScope.$broadcast('adjust-nav-padding');
      });
    };

    ctrl.closeAlert = function (index, id) {
      AlertMessageService.dismiss(id);
      this.alertMessages.splice(index, 1);
      $rootScope.$broadcast('adjust-nav-padding');
    };
  }
}

AlertMessageCtrl.$inject = ['AlertMessageService', '$rootScope'];

export default AlertMessageCtrl;
