import angular from 'angular';
import 'wicg-inert/dist/inert.min';

const accessibleInertService = function accessibleInertService($rootScope) {
  let elems;
  let temp;
  //  find all supported elements and add ut-inert attribute
  function initializeInertService(triggerElement, optionalTags) {
    setTimeout(() => {
      temp = triggerElement;
      const supportedTags = optionalTags || ['a[href]:not([disabled])', 'a[href^="#"]', 'a[href="#"]', 'a[href]', 'a[href="/"]', 'a',
        'textarea:not([disabled])', 'select:not([disabled])', 'button:not([disabled])', '[tabindex]:not([tabindex="-1"])', '[tabindex="0"]',
        'h1', 'h2', 'h3', 'div', 'p', 'small', 'label', 'input', '.clickable', 'span', 'article', 'img', 'ul', 'li'];
      // @ts-ignore
      elems = $('body').querySelectorAll(supportedTags);
      if (triggerElement) {
        for (const element of elems) { // eslint-disable-line
          const elem = angular.element(element);
          const isParentDiv = (elem[0].tagName === 'DIV' && elem[0].children.length > 0);
          if (!isParentDiv) {
            angular.element(element).attr('ut-inert', '');
          }
        }
      } else {
        for (const element of elems) {  // eslint-disable-line
          const elem = angular.element(element);
          const isParentDiv = (elem[0].tagName === 'DIV' && elem[0].children.length > 0);
          if (!isParentDiv && elem.is(':visible')) {
            angular.element(element).attr('ut-inert', '');
          }
        }
      }
    }, 100);
  }

  // set aria-hidden to true/false on elements with ut-inert attribute
  function setAriaHidden(bool, target) {
    // if target is parent of modal element, remove the ut-inert so its not hidden
    if (target) {
      const element = angular.element(target);
      element.removeAttr('ut-inert');
    }
    const inertVal = bool ? 'true' : null;
    $('[ut-inert]').attr('inert', inertVal);
  }

  $rootScope.$on('content.changed', () => {
    initializeInertService(temp, undefined);
  });
  return {
    initializeInertService, setAriaHidden,
  };
};
accessibleInertService.$inject = ['$rootScope'];

export default accessibleInertService;
