import Dropzone from 'dropzone';

const dropzone = function ($cookies, $timeout, $rootScope) {
  return {
    restrict: 'A',
    scope: {
      dropzone: '=',
    },
    link(scope, el) {
      $timeout(() => {
        new Dropzone(el[0], {
          dictDefaultMessage: 'Choose a file',
          url: '/api/v1/uploads',
          uploadMultiple: false,
          maxFiles: 1,
          headers: {
            'X-Csrf-Token': $cookies.get($rootScope.csrfCookieName),
          },
          init() {
            this.on('success', function (file, res) {
              scope.dropzone.onSuccess(file, res, this);
            });
            this.on('error', function (err) {
              scope.dropzone.onError(err, this);
            });
          },
        });
      });
    },
  };
};

dropzone.$inject = ['$cookies', '$timeout', '$rootScope'];

export default dropzone;
