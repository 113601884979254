import template from './course-track-quiz-question.component.html';
import controller from './course-track-quiz-question.controller';

const courseTrackQuizQuestion = {
  bindings: {
    courseTrack: '<',
    question: '<',
    questionNumber: '<',
    userChoice: '<',
    previousQuestion: '<',
    nextQuestion: '<',
    step: '<',
  },
  template,
  controller,
};

export default courseTrackQuizQuestion;
