class CustomRegistrationService {
  $http: ng.IHttpService;
  constructor($http) {
    this.$http = $http;
  }

  find(id: number) {
    return this.$http.get(`/api/v1/custom_registrations/${id}`);
  }
}

CustomRegistrationService.$inject = ['$http'];

export default CustomRegistrationService;
