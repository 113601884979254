const strLimit = function ($filter) {
  return function (input, limit) {
    if (input && input.length <= limit) {
      return input;
    }
    return `${$filter('limitTo')(input, limit)}...`;
  };
};

strLimit.$inject = ['$filter'];

export default strLimit;
