import SingleStatusCtrl from '../status/single/single-status.controller';
import singleStatusTemplate from '../status/single/single-status.html';
import StatusService from '../components/status/status.service';
import WriteStatus from '../components/status/write-status.directive';

const StatusRouter = {
  route: (module) => {
    module
      .controller('SingleStatusCtrl', SingleStatusCtrl)
      .service('StatusService', StatusService)
      .directive('writeStatus', WriteStatus);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('singlestatus', {
          url: '/status/:statusId?comments?sort',
          parent: 'root',
          reloadOnSearch: false,
          template: singleStatusTemplate,
          controller: 'SingleStatusCtrl',
          resolve: {
            status: ['$transition$', 'StatusService', function ($transition$, StatusService) {
              return StatusService.find($transition$.params().statusId);
            }],
            title: ['status', function (status) {
              return `uTest Community Status - ${status.data.content}`;
            }],
          },
        });
    }]);
  },
};

export default StatusRouter;
