import template from './img-crop.component.html';
import controller from './img-crop.controller';

const imgCrop = {
  bindings: {
    src: '@',
    alt: '@',
  },
  template,
  controller,
};

export default imgCrop;
