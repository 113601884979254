/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { asyncScheduler, BehaviorSubject, Observable } from 'rxjs';
import { map, observeOn } from 'rxjs/operators';
import { Option } from '../../option/option';
import { OptionsRootHolder } from '../../options-root-holder';
import { OptionSelectionService } from '../option-selection.service';
import { SelectionEvent } from '../selection-event';

@Injectable()
export class OptionSingleSelectionService extends OptionSelectionService {
  constructor(root: OptionsRootHolder) {
    super(root);
  }

  private readonly selection = new BehaviorSubject<SelectionEvent>({value: null, userInput: false});

  readonly selection$: Observable<SelectionEvent> = this.selection.pipe(
    // we need to observe on asyncScheduler
    // to avoid ExpressionChangedAfterItHasBeenCheckedError in some situations
    observeOn(asyncScheduler),
  );

  getSelection(): SelectionEvent {
    return this.selection.value;
  }

  select(event: SelectionEvent): void {
    const alreadySelected = super.compareValues(event.value, this.selection.value.value);
    if (alreadySelected) {
      return;
    }

    this.selection.next({value: event.value, userInput: event.userInput});
  }

  isSelected$(option: Option): Observable<boolean> {
    return this.selection$.pipe(
      map(event => super.compareValues(event.value, option.value)));
  }

  isAnySelected$(options: Option[]): Observable<boolean> {
    return this.selection.pipe(
      map(event => options.some(option => super.compareValues(event.value, option.value))));
  }

  clear(event: { userInput: boolean }): void {
    this.selection.next({value: null, userInput: event.userInput});
  }

}
