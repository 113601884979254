import _ from 'lodash';
import { StateService } from '@uirouter/core';

class FeedItemCtrl {
  constructor($state: StateService, $filter: ng.IFilterFunction, AlertService, StatusService, $analytics, UserService, ProgressBarService, GamificationService, $element: JQuery) {
    const ctrl: ng.IController = this;
    const setup: any = {};

    ctrl.$onInit = () => {
      setup[ctrl.item.type]();
      const defaults = {
        title: ctrl.item.title,
        description: ctrl.item.content,
        type: ctrl.item.type,
        date: ctrl.item.published_at,
        sub_title: null,
        image: null,
        header_info_image: _.get(ctrl.item, 'user.avatar_url'),
        header_info_text: _.get(ctrl.item, 'user.name'),
        header_info_title: _.get(ctrl.item, 'user.user_title'),
        country: _.get(ctrl.item, 'user.country.name'),
        header_info_link: $state.href('profile.about', { profileId: _.get(ctrl.item, 'user.slug') }),
      };

      ctrl.showTags = ctrl.item.tags && !_.includes(['project', 'course'], ctrl.item.type);

      const userPinnedContent = _.get(ctrl.item.user, 'pinned_content', {});
      ctrl.isContentPinned = _.get(userPinnedContent, `${ctrl.item.type}_id`, null) === ctrl.item.id;

      ctrl.display = _.defaults(ctrl.display, defaults);
    };

    ctrl.pinContent = () => {
      if (ctrl.store) {
        const pinnedContent = {};
        pinnedContent[`${ctrl.item.type}_id`] = ctrl.isContentPinned ? null : ctrl.item.id;
        ProgressBarService.start();
        UserService.updateMe({ pinned_content: pinnedContent }).then(() => {
          ctrl.store.load();
          let successMessage: string;
          if (ctrl.isContentPinned) {
            successMessage = `Your ${ctrl.item.type} has been unpinned from your profile feed`;
          } else {
            successMessage = `New ${ctrl.item.type} is pinned on your profile feed`;
          }
          AlertService.addSuccess(successMessage);
          ProgressBarService.done();
        }, () => {
          ProgressBarService.done();
          AlertService.addDanger('There was an error');
        });
      }
    };

    ctrl.deleteItem = () => {
      if (ctrl.item.type === 'status') {
        StatusService.delete(ctrl.item.id).then(() => {
          AlertService.add('Status successfully deleted');
          if (ctrl.singleView) {
            $state.go('home');
          } else if (ctrl.store) {
            ctrl.store.load();
          } else {
            $element.remove();
          }
        }, () => {
          AlertService.add('There was an error', 'danger');
        });
      }
    };

    ctrl.statusChanged = (res) => {
      if (ctrl.singleView) {
        $state.reload();
      } else {
        ctrl.display.description = res.data.content;
        $(`#editStatus${ctrl.item.id}`).removeClass('open');
      }
    };

    setup.article = () => {
      ctrl.display = {
        url: $state.href('singlearticle', { articleId: ctrl.item.slug }),
        description: $filter('uMarkdown')(ctrl.item.content),
      };
    };

    setup.topic = () => {
      ctrl.display = {
        description: null,
        type: $element.hasClass('compressed') ? null : 'Discussion',
        url: $state.href('singlediscussionpage', { discussionId: ctrl.item.id, page: 1 }),
        sub_title: true,
        date: ctrl.relevantTime ? ctrl.item[ctrl.relevantTime] : ctrl.item.published_at,
      };
    };

    setup.course = () => {
      ctrl.display = {
        url: $state.href('singlecourse', { courseId: ctrl.item.slug }),
      };
    };

    setup.project = () => {
      ctrl.display = {
        url: $state.href('singleproject', { projectId: ctrl.item.slug }),
        type: null,
        header_info_text: ctrl.item.urgent ? 'Urgent Project' : 'Ongoing Project',
        header_info_link: $state.href('singleproject', { projectId: ctrl.item.slug }),
        user_has_applied: ctrl.item.user_has_applied,
      };
    };

    setup.tool = () => {
      ctrl.display = {
        url: $state.href('singletool', { toolId: ctrl.item.slug }),
        title: ctrl.item.name,
        description: ctrl.item.description,
        image: ctrl.item.icon ? ctrl.item.icon.url : null,
      };
    };

    setup.user = () => {
      ctrl.item.user = ctrl.item;
      ctrl.display = {
        title: null,
        type: 'User',
        url: $state.href('profile.about', { userId: ctrl.item.slug }),
        header_info_image: ctrl.item.avatar_url,
        header_info_text: ctrl.item.name,
      };
    };

    setup.status = () => {
      ctrl.display = {
        title: null,
        description: ctrl.item.content,
        url: $state.href('singlestatus', { statusId: ctrl.item.id }),
        fullUrl: $state.href('singlestatus', { statusId: ctrl.item.id }, { absolute: true }),
      };
    };

    setup.comment = () => {
      ctrl.display = {
        title: null,
        description: ctrl.item.content,
      };
    };

    setup.badge_activity = () => {
      const badge = GamificationService.badgesById[ctrl.item.item.badge_type];
      ctrl.display = {
        type: 'new badge',
        date: ctrl.item.date,
        title: `${badge.name}`,
        description: `${badge.description}`,
        image: `assets/images/badges/${badge.icon}`,
      };
    };

    ctrl.goToStatus = ($event) => {
      if ($event.target.nodeName !== 'A') {
        $state.go('singlestatus', { statusId: ctrl.item.id });
      }
    };

    ctrl.getWrapperClasses = () => {
      const classes = [];
      classes.push(`feed-item-type-${ctrl.item.type}`);
      if (ctrl.singleView) {
        classes.push('feed-item-single-view');
      }

      if ($element.hasClass('compressed')) {
        classes.push('compressed');
      }
      return classes.join(' ');
    };

    ctrl.tracking = () => {
      if (ctrl.analyticsTrackingLabel) {
        $analytics.eventTrack(ctrl.analyticsTrackingLabel, {
          category: 'click',
          label: `${ctrl.item.type}:${ctrl.item.id}`,
        });
      }
    };

    ctrl.removeItem = (item) => {
      ctrl.onHideFlaggedItem(item);
    };
  }
}

FeedItemCtrl.$inject = ['$state', '$filter', 'AlertService', 'StatusService', '$analytics', 'UserService', 'ProgressBarService', 'GamificationService', '$element'];

export default FeedItemCtrl;
