import { Component, Input } from '@angular/core';

@Component({
  selector: 'ut-article-feed-item',
  templateUrl: './article-feed-item.component.html',
})

export class ArticleFeedItemComponent {
  @Input() article: any;
  @Input() featured: any;
}
