import template from './referral-campaign-feed.component.html';

const referralCampaignFeed = {
  bindings: {
    feed: '<',
  },
  template,
};

export default referralCampaignFeed;
