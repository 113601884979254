import _ from 'lodash';
import angular from 'angular';
import $ from 'jquery';

angular.module('uTestV2.meta', [])
  .factory('MetaService', () => {
    const defaults = {
      title: 'uTest',
      canonical: false,
    };

    const meta = {};
    _.forEach(defaults, (val, key) => {
      meta[key] = defaults[key];
    });

    function set(name, value) {
      meta[name] = value;
    }

    function getAll() {
      return meta;
    }

    function clearPrevious() {
      _.forEach(meta, (val, key) => {
        meta[key] = defaults[key];
      });
    }

    return {
      set,
      getAll,
      clearPrevious,
    };
  })
  .directive('metaValue', ['MetaService', function (MetaService) {
    return {
      restrict: 'A',
      link(scope) {
        // @ts-ignore
        scope.meta = MetaService.getAll();
      },
    };
  }])
  .run(['$transitions', '$rootScope', '$compile', ($transitions, $rootScope, $compile) => {
    $compile(`<link ng-if='meta.canonical' rel="canonical" href="{{meta.canonical | trustAsUrl}}" />
              <title meta-value ng-bind="meta.title ? (meta.title | emojiUnicode) : meta.title" >uTest</title>`)($rootScope, (html) => {
      $('head').append(html);
    });

    $transitions.onFinish({}, (transition) => {
      const toState = transition.$to();
      if (toState.resolvables.length && _.find(toState.resolvables, ['token', 'title'])) {
        $rootScope.meta.title = transition.injector().get('title');
      }
    });
  }]);
