const avatarBadges = function (UserService) {
  return {
    restrict: 'A',
    scope: {
      avatarBadges: '=',
    },
    link(scope, el) {
      function setup(user) {
        let badges = '<span class="avatar-badges-wrapper" >';
        el.css({
          'background-image': `url(${user.avatar_url})`,
        });
        const badge = [];
        const title = [];

        if (user.is_ambassador) {
          badge.push('avatar-badge-a');
          title.push('uTest Ambassador');
        }
        if (user.is_employee) {
          badge.push('avatar-badge-u');
          title.push('uTest Employee');
        }
        if (user.user_type === 'moderator' || user.user_type === 'admin') {
          badge.push('avatar-badge-m');
          title.push('Moderator');
        }
        if (user.platform_roles) {
          if (user.platform_roles.pm) {
            badge.push('avatar-badge-pm');
            title.push('Project Manager');
          }
          if (user.platform_roles.ttl) {
            badge.push('avatar-badge-ttl');
            title.push('Test Team Lead');
          }
        }
        if (badge.length) {
          badges += `<span title="${title.join(', ')}" class="avatar-badge ${badge[0]}"></span>`;
        }
        badges += '';
        badges += '</span>';
        el.append(badges);
      }

      if (scope.avatarBadges === 'me') {
        UserService.me().then((res) => {
          if (res !== null) {
            setup(res.data);
          } else {
            setup(scope.avatarBadges);
          }
        });
      } else {
        setup(scope.avatarBadges);
      }
    },
  };
};

avatarBadges.$inject = ['UserService'];

export default avatarBadges;
