import _ from 'lodash';

class SearchService {
  $http: ng.IHttpService;

  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  search(query) {
    return this.$http.get('/api/v1/search', { params: { query } });
  }

  searchMixed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/search/mixed', { params: _.defaults(parameters || {}, { page: 1, per_page: 10 }) });
  }

  searchOneFactory(type, resultsByTag) {
    const { $http } = this;
    const byTag = resultsByTag ? '/by-tag' : '';
    return parameters => $http.get(`/api/v1/search/${type}${byTag}`, { params: parameters });
  }
}

SearchService.$inject = ['$http'];
export default SearchService;
