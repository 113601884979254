export default `
<div id="mainContent" class="footer-wrapper">
  <uib-alert class="visible-xs" ng-repeat="alert in $ctrl.mobileAlerts" type="{{$ctrl.alert.type}}" close="$ctrl.closeAlert($index)" ><span ng-bind-html="alert.msg"></span></uib-alert>
  <div class="container-fluid">
    <h2>Pending Articles</h2>
    <uib-tabset>
      <uib-tab heading="pending">
        <div class="feed article pending-article" ng-repeat="article in $ctrl.pendingArticles.feedItems" on-finish-render="ngRepeatFinished">
          <div class="feed-header-info-name">
            <div class="feed-item-info">
              <a avatar-badges="article.user" ui-sref="profile.about({profileId: article.user.slug})" class="profile-thumb" ></a>
              <a ui-sref="profile.about({profileId: article.user.slug})">{{article.user.name | strLimit: 20}}</a>
              <div tabindex="0"
                   class="fa fa-info-circle"
                   role="button"
                   aria-label="{{ 'Open ' + article.user.name + ' tooltip' }}"
                   dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: article.user})"
                   ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: article.user})"
                   ng-click="showTooltip('user-tooltip', {user: article.user});$event.preventDefault(); $event.stopImmediatePropagation()"
                   ng-mouseleave="hideTooltip()"></div>
              <div class="feed-item-header-title" ng-if="article.user.user_title">  {{article.user.user_title | strLimit: 50}}
              </div>
              <span class="time-stamp">{{article.updated_at | relativeDate}}</span>
            </div>
          </div>
          <div class="pending-article-title">
            <h4><a ui-sref="singlearticle({articleId : article.slug})">{{article.title}}</a></h4>
          </div>
          <div class="pending-article-content">
           <p ng-if="article.content"  class="feed-item-description" ng-bind-html="article.content | cleanUp | ulinky"></p>
          </div>
          <div class="func-btns">
            <div class="main-btns">
              <a ng-show="article.current_user_permissions.publish" class="btn btn-rounded small publish clickable" href="" ng-mouseup="toggleSlideUp('publishItem' + article.id)"><span>Publish</a>
              <a ng-show="article.current_user_permissions.reject" href="" class="btn btn-rounded small delete" ng-mouseup="toggleSlideUp('rejectItem' + article.id)">Reject</a>
            </div>
          </div>
          <section class="slide-up slide-up-move" id="publishItem{{article.id}}">
            <p>Are you sure you want to publish this Article?</p>
            <p>
              <a ng-click="$ctrl.publishArticle(article.id, 'publish')" class="btn small btn-yellow">Publish</a>
              <a ng-click="toggleSlideUp('publishItem' + article.id)" class="btn small" >Cancel</a>
            </p>
          </section>
          <section class="slide-up slide-up-move" id="rejectItem{{article.id}}">
            <p>Are you sure you want to reject this Article?</p>
            <div>
              <input ng-model="$ctrl.reject.reason" placeholder="Reject Reason" type="text">
            </div>
            <br>
            <p>
              <a ng-click="$ctrl.rejectArticle(article.id, $ctrl.reject.reason)" class="btn small btn-yellow">Reject</a>
              <a ng-click="toggleSlideUp('rejectItem' + article.id)" class="btn small" >Cancel</a>
            </p>
          </section>
        </div>
        <load-more load-callback="$ctrl.pendingArticles.loadMore()" is-loading="$ctrl.pendingArticles.isLoading" is-last-page="$ctrl.pendingArticles.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
      </uib-tab>
      <uib-tab heading="rejected">
        <div class="feed article pending-article" ng-repeat="article in $ctrl.rejectedArticles.feedItems" on-finish-render="ngRepeatFinished">
            <div class="feed-header-info-name">
            <div class="feed-item-info">
              <a avatar-badges="article.user" ui-sref="profile.about({profileId: article.user.slug})" class="profile-thumb" ></a>
              <a ui-sref="profile.about({profileId: article.user.slug})">{{article.user.name | strLimit: 20}}</a>
              <div tabindex="0"
                    class="fa fa-info-circle"
                    role="button"
                    aria-label="{{ 'Open ' + article.user.name + ' tooltip' }}"
                    dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: article.user})"
                    ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: article.user})"
                    ng-click="showTooltip('user-tooltip', {user: article.user});$event.preventDefault(); $event.stopImmediatePropagation()"
                    ng-mouseleave="hideTooltip()"></div>
              <div class="feed-item-header-title" ng-if="article.user.user_title">  {{article.user.user_title | strLimit: 50}}
              </div>
              <span class="time-stamp">{{article.updated_at | relativeDate}}</span>
            </div>
          </div>

          <div class="pending-article-content">
            <h4><a ui-sref="singlearticle({articleId : article.slug})">{{article.title}}</a></h4>
          </div>
          <div class="pending-article-content">
            <p ng-if="article.content"  class="feed-item-description" ng-bind-html="article.content | cleanUp | ulinky"></p>
            <strong>Reason:</strong> {{article.reject_reason}}
            <br>
            <strong>Reviewer:</strong> {{article.reviewer.name}}
          </div>

          <div class="func-btns">
            <div class="main-btns">
              <a ng-show="currentUser.user_type == 'admin'" class="btn btn-rounded small publish clickable" href="" ng-mouseup="toggleSlideUp('publishItem' + article.id)"><span>Publish</a>
            </div>
          </div>
          <section class="slide-up slide-up-move" id="publishItem{{article.id}}">
            <p>Are you sure you want to publish this Article?</p>
            <p>
              <a ng-click="$ctrl.publishArticle(article.id, 'reject')" class="btn small btn-yellow">Publish</a>
              <a ng-click="toggleSlideUp('publishItem' + article.id)" class="btn small" >Cancel</a>
            </p>
          </section>
        </div>
        <load-more load-callback="$ctrl.rejectedArticles.loadMore()" is-loading="$ctrl.rejectedArticles.isLoading" is-last-page="$ctrl.rejectedArticles.isLastPage" on-finish-render="ngRepeatFinished" fade-target="footer"></load-more>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
