class UserDeviceService {
  private $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getDevices() {
    return this.$http.get('/api/v1/users/me/devices');
  }

  getRequestedDevices() {
    return this.$http.get('/api/v1/home/requested_devices');
  }
}

UserDeviceService.$inject = ['$http'];

export default UserDeviceService;
