import _ from 'lodash';
import angular from 'angular';

class SubmitAToolCtrl {
  constructor($scope, $state, $location, ToolService, ToolCategoryService, user, Slug, ProgressBarService, $element, $timeout) {
    $scope.target = $location.absUrl();
    $scope.text = 'Submit a Tool';

    $scope.tool_categories = [];
    $scope.currentUser = user;
    $scope.tool = {
      vendor_name: '',
    };

    if ($scope.currentUser) {
      ToolCategoryService.all().then((categories) => { $scope.tool_categories = categories.data; });
    }

    $scope.submitMessages = [];
    $scope.submitTool = function () {
      if ($scope.tool.name && $scope.tool.category_id) {
        $scope.tool.slug = Slug.slugify($scope.tool.name);
        ProgressBarService.start();
        ToolService.postTool($scope.tool).then((res) => {
          ProgressBarService.done();
          $state.go('singletool', { toolId: res.data.slug });
        }, (res) => {
          if (res.data.errors[0].title === 'already exists') {
            $scope.submitMessages.push({ type: 'danger', message: 'A tool with this title was already submitted' });
          } else {
            $scope.submitMessages.push({ type: 'danger', message: 'Please fill out all the fields correctly' });
          }
          ProgressBarService.done();
        });
      } else {
        $scope.submitMessages.push({ type: 'danger', message: 'Please fill out all the fields' });
      }
    };

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 250);
  }
}

SubmitAToolCtrl.$inject = ['$scope', '$state', '$location', 'ToolService', 'ToolCategoryService', 'user', 'Slug', 'ProgressBarService', '$element', '$timeout'];

export default SubmitAToolCtrl;
