import UserResolver from '../authentication/user-resolver';
import searchResult from '../search/search-result.component';
import SearchService from '../search/search.service';

const SearchRouter = {
  route: (module) => {
    module
      .service('SearchService', SearchService)
      .component('searchResult', searchResult)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('searchresultbytag', {
            parent: 'root',
            url: '/search-result/:contentType/tagged/:query',
            component: 'searchResult',
            resolve: {
              user: UserResolver,
              title: [() => 'uTest - Search Results'],
            },
          })
          .state('searchresultbyhashtag', {
            parent: 'root',
            url: '/search-result/:contentType/hashtag/:query',
            component: 'searchResult',
            resolve: {
              user: UserResolver,
              title: [() => 'uTest - Search Results'],
            },
          })
          .state('searchresult', {
            parent: 'root',
            url: '/search-result/:contentType/:query',
            component: 'searchResult',
            resolve: {
              user: UserResolver,
              title: [() => 'uTest - Search Results'],
            },
          });
      }]);
  },
};

export default SearchRouter;
