import _ from 'lodash';

const LinkExtractor = function () {
  return function (text) {
    const self = this;
    if (text == null) {
      text = '';
    }

    this.re = /(i?)<[a-z]+([^>]+)>(.+?)<\/[a-z]+>|<\w?[a-z]+[^>]*\/?>/ig;
    this.links = {};

    this.makeId = function () {
      let text = 'a';
      const possible = '`~!$%^*()_+=-[]{}|;:,./?';

      for (let i = 0; i < 5; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

    this.id = this.makeId();

    this.replaceLinks = function (text) {
      let counter = 0;
      return text.replace(self.re, (match) => {
        const key = self.id + counter;
        self.links[key] = match;
        counter += 1;
        return key;
      });
    };

    this.text = this.replaceLinks(text);

    this.returnLinks = function (text) {
      _.forEach(self.links, (val, key) => {
        text = text.replace(key, val);
      });
      return text;
    };
  };
};

export default LinkExtractor;
