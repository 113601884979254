import Clipboard from 'clipboard';

const clipboardDirective = function (AlertService) {
  return {
    restrict: 'A',
    link(scope, el) {
      el.attr('title', 'Copy');
      el.addClass('clickable');
      const clipboard = new Clipboard(el[0]);
      clipboard.on('success', () => {
        AlertService.add('Copied!');
        scope.$apply();
      });
      clipboard.on('error', () => {
        AlertService.add('Copy to clipboard is not supported in your browser', 'warning');
        scope.$apply();
      });
    },
  };
};

clipboardDirective.$inject = ['AlertService'];

export default clipboardDirective;
