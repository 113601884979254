import template from './academy-home.component.html';
import controller from './academy-home.controller';

const academyHome = {
  bindings: {
    tracks: '<',
  },
  template,
  controller,
};

export default academyHome;
