import _ from 'lodash';

class ArticlesCtrl {
  constructor(ArticleService, UserService, FeedStore, LightTesterErrorService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.loadUser();
      ctrl.headerData = ctrl.headerData.data;
      const fetchArticles = _.bind(ArticleService.feed, ArticleService);
      ctrl.officialArticles = new FeedStore(fetchArticles, { per_page: 6, filter: { article_type: 'official' } });
      ctrl.contributedArticles = new FeedStore(fetchArticles, { per_page: 6, filter: { article_type: 'community' } });
      ctrl.popularArticles = new FeedStore(fetchArticles, { per_page: 6, sort: '-popularity', filter: { published_after: new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString() } });
      ctrl.officialArticles.load();
      ctrl.contributedArticles.load();
      ctrl.popularArticles.load();
    };

    ctrl.loadUser = () => {
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
          ctrl.canCreateArticle = ctrl.currentUser.current_user_permissions.create_article;
        } else {
          ctrl.canCreateArticle = false;
        }
      });
    };

    ctrl.checkLightTester = ($event) => {
      if (LightTesterErrorService.show()) {
        $event.preventDefault();
      }
    };
  }
}

ArticlesCtrl.$inject = ['ArticleService', 'UserService', 'FeedStore', 'LightTesterErrorService'];

export default ArticlesCtrl;
