class CourseTrackCardCtrl {
  constructor(CourseTrackService, UserService, $element: JQuery) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.$element = $element;
      ctrl.popover = ctrl.$element.find('.course-track-list-popover');
      ctrl.popover.css('visibility', 'hidden');
      ctrl.courseTrackCompleted = ctrl.courseTrack.user_has_completed_course_track;
      ctrl.hasPopoverBeenClicked = false;
      ctrl.isPopoverOpen = false;
      ctrl.showDisabledTooltip = false;
      UserService.me().then((res) => {
        ctrl.isLightTester = res?.data?.is_light_tester;
      });
    };

    ctrl.loadCourses = () => {
      if (ctrl.courses === undefined) {
        CourseTrackService.courses(ctrl.courseTrack.id).then((response) => {
          ctrl.courses = response.data;
        });
      }
    };

    ctrl.togglePopover = (event) => {
      const eventKeyArray = ['Escape', 'Tab', 'mouseout'];
      if (eventKeyArray.includes(event.key)) {
        ctrl.closePopover();
      } else {
        ctrl.loadCourses();
        event.preventDefault();
        const $target = $(event.target);
        ctrl.popover.css('display', 'block');
        ctrl.popover.css('left', $target.position().left + ($target.outerWidth(true) / 2));
        ctrl.popover.css('top', $target.position().top + ($target.outerHeight(true) + 15));
        ctrl.popover.css('visibility', 'visible');
        ctrl.isPopoverOpen = true;
      }
    };

    ctrl.closePopover = () => {
      ctrl.popover.css('visibility', 'hidden');
      ctrl.hasPopoverBeenClicked = false;
      ctrl.isPopoverOpen = false;
    };

    ctrl.viewLessonsMouseout = () => {
      if (!ctrl.hasPopoverBeenClicked) {
        ctrl.closePopover();
      }
    };

    ctrl.viewLessonsClick = (event) => {
      if (ctrl.hasPopoverBeenClicked) {
        ctrl.closePopover();
      } else {
        const $target = $(event.target);
        ctrl.hasPopoverBeenClicked = true;
        $target.focus();
      }
    };

    ctrl.toggleAccordion = () => {
      ctrl.loadCourses();
      ctrl.showAccordion = !ctrl.showAccordion;
      ctrl.isPopoverOpen = !ctrl.isPopoverOpen;
    };

    ctrl.showTooltip = () => {
      if (ctrl.isLightTester && ctrl.trackPosition > 2) {
        ctrl.showDisabledTooltip = true;
      }
    };

    ctrl.hideTooltip = () => {
      ctrl.showDisabledTooltip = false;
    };
  }
}

CourseTrackCardCtrl.$inject = ['CourseTrackService', 'UserService', '$element'];

export default CourseTrackCardCtrl;
