class DashboardCardMyDevicesCtrl {
  constructor(ConfigService, UserDeviceService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ConfigService.config().then((res) => {
        ctrl.addDevicesUrl = `${res.data.profile_url}/account/my-profile/devices`;
      });

      ctrl.devices = [];
      UserDeviceService.getDevices().then((res) => {
        ctrl.devices = res.data;
      });
    };
  }
}

DashboardCardMyDevicesCtrl.$inject = ['ConfigService', 'UserDeviceService'];

export default DashboardCardMyDevicesCtrl;
