import template from './community-feed-item-comment.component.html';
import controller from './community-feed-item-comment.controller';

const communityFeedItemComment = {
  bindings: {
    comment: '<',
  },
  template,
  controller,
};

export default communityFeedItemComment;
