import angular from 'angular';
import emojione from 'emojione';

const ulinky = function ($state, LinkExtractor) {
  const LINKY_URL_REGEXP = /(?:^|[^@A-Za-z0-9._%+-])((ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-/*]+@)\S*[^\s.;,(){}<>"\u201d\u2019]|\B[#]+[A-Za-z0-9-_]*[a-zA-Z]+[A-Za-z0-9-_]*|(?:[\W]|^)(@([a-zA-Z0-9]*[\s]?][a-zA-Z0-9]+|[a-zA-Z0-9_\-@\.]*[a-zA-Z0-9_\-@]+))/i,
    MAILTO_REGEXP = /(^|\s)mailto:/i;

  // @ts-ignore
  const linkyMinErr = angular.$$minErr('linky');
  const { isString } = angular;

  const hashtagUrl = $state.href('searchresultbyhashtag', { contentType: 'all', query: '[hashtag]' });
  const mentionUrl = $state.href('profile.about', { profileId: '[mention]' });

  return function (text, target, attributes) {
    const le = new LinkExtractor(text);
    /* eslint-disable */
    text = le.text;
    /* eslint-enable */

    if (text === null || text === '') return text;
    if (!isString(text)) throw linkyMinErr('notstring', 'Expected string but received: {0}', text);

    let match;
    let raw = text;
    let whitespace;
    const html = [];
    let url;
    let i;
    let newtab;

    while ((match = raw.match(LINKY_URL_REGEXP))) {
      // We can not end in these as they are sometimes found at the end of the sentence
      url = match[0].trim();
      newtab = false;
      if (url.substr(0, 1) === '#') {
        // const FULL_BLOCK_REGEX = new RegExp(`\`\`\`[\S\s]*?${url}[\S\s]*?\`\`\``);
        // const END_BLOCK_REGEX = new RegExp(`${url}[\S\s]*?\`\`\``);
        // eslint-disable-next-line no-template-curly-in-string
        const START_BLOCK_REGEX = /\`\`\`[\S\s]*?${url}[\S\s]*?/.toString().replace('${url}', url).replace('/', '').replace('/', '');
        // eslint-disable-next-line no-template-curly-in-string
        const END_BLOCK_REGEX = /${url}[\S\s]*?\`\`\`/.toString().replace('${url}', url).replace('/', '').replace('/', '');
        if (raw.match(new RegExp(`\`.*?${url}.*?\``)) || raw.match(START_BLOCK_REGEX)) {
          url = '';
        } else if (raw.match(new RegExp(`${url}.*?\``)) && (html.reduce(((acc, el) => acc + el.split('`').length - 1), 0) % 2) === 1) {
          url = '';
        } else if (raw.match(END_BLOCK_REGEX) && (html.reduce(((acc, el) => acc + el.split('```').length - 1), 0) % 2) === 1) {
          url = '';
        } else {
          url = hashtagUrl.replace(encodeURIComponent('[hashtag]'), encodeURIComponent(url.replace('#', '')));
        }
      } else if (url.substr(0, 1) === '@') {
        url = mentionUrl.replace(encodeURIComponent('[mention]'), url.substr(1));
      } else if (url.substr(0, 1) === '(') {
        url = false;
      } else {
        newtab = true;
        // if we did not match ftp/http/www/mailto then assume mailto
        if (!match[2] && !match[4]) {
          url = url.replace(/\*/g, ''); // filter out asterisks for links from markdown
          url = (match[3] ? 'http://' : 'mailto:') + url;
        }
      }
      i = match.index;
      addText(raw.substr(0, i));
      whitespace = match[0][0] === ' ' ? ' ' : '';
      addLink(url, match[0].replace(MAILTO_REGEXP, '').trim(), newtab);
      raw = raw.substring(i + match[0].length);
    }
    addText(raw);

    return le.returnLinks(html.join('')).replace(/:[a-z_0-9-]+:/g, emoji => emojione.toImage(emoji));

    function addText(text) {
      if (!text) {
        return;
      }
      html.push(text);
    }

    function addLink(url, text, newtab) {
      if (!url) {
        addText(whitespace + text);
        return;
      }
      html.push(`${whitespace}<a `);
      if (!newtab) {
        html.push('class="internal" ');
      }
      if (angular.isFunction(attributes)) {
        attributes = attributes(url);
      }
      if (angular.isObject(attributes)) {
        Object.keys(attributes).forEach((key) => {
          html.push(`${key}="${attributes[key]}" `);
        });
      } else {
        attributes = {};
      }
      html.push('href="', url.replace(/"/g, '&quot;'), '">');
      addText(text);
      html.push('</a>');
    }
  };
};

ulinky.$inject = ['$state', 'LinkExtractor'];

export default ulinky;
