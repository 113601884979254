import $ from 'jquery';
import template from './back-to-top.html';

const backToTop = function () {
  return {
    restrict: 'E',
    replace: true,
    template,
    scope: {
      isLoading: '=',
      isLastPage: '=',
      feedItems: '=',
    },
    link(scope, el) {
      scope.scrollToTop = function () {
        $(el).closest('body, html > [ui-view]').animate({ scrollTop: 0 }, 'medium');
        const firstElemId = this.feedItems[0].id;
        $(`.project_title_${firstElemId}`).focus();
      };
    },
  };
};

export default backToTop;
