export default `
<div class="markdown-guide-container" aria-labelledby="markdown-guide-heading">
<div class="modal-header">
  <button type="button" autofocus class="close" ng-click="cancel()" aria-label="Close">
    <i class="icon-cancel"></i>
  </button>
  <h3 class="modal-title" id="markdown-guide-heading">Markdown Guide</h3>
</div>
<div class="modal-body">
  <div class="uedit-markdown-guide" >

    <h4>Emphasis</h4>
<pre>**<strong>bold</strong>**
*<em>italics</em>*
~~<strike>strikethrough</strike>~~</pre>

    <h4>Headers</h4>
<pre># Big header
## Medium header
### Small header
#### Tiny header</pre>

    <h4>Lists</h4>
<pre>* Generic list item
* Generic list item
* Generic list item

1. Numbered list item
2. Numbered list item
3. Numbered list item</pre>

    <h4>Links</h4>
<pre>[Text to display](http://www.example.com)</pre>

    <h4>Mentions</h4>
    <p>You can mention a user on uTest by typing <code>@</code> plus their username.</p>
    <p>Typing an <code>@</code> symbol will bring up a list of people. The list filters as you type, so once you find the name of the person you are looking for, you can use the arrow keys to select it and hit enter or click on it to complete the name. </p>

    <h4>Quotes</h4>
<pre>&gt; This is a quote.
&gt; It can span multiple lines!</pre>

    <h4>Images</h4>
<pre>![](http://www.example.com/image.jpg)</pre>

    <h4>Emoji</h4>
    <p>You can add emoji to your writing by typing <code>:EMOJICODE:</code>.</p>
    <p>Typing <code>:</code> will bring up a list of suggested emoji. The list will filter as you type, so once you find the emoji you're looking for, click on it or press Enter.</p>
    <p>
      For a full list of available emoji and codes, check out <a class="emojione-link" href="https://www.emojione.com/emoji/v3" target="_blank" rel="noopener noreferrer">emojione.com</a>.
    </p>

    <h4>Displaying code</h4>
<pre>\`var example = "hello!";\`

<em>Or spanning multiple lines...</em>

\`\`\`
var example = "hello!";
alert(example);
\`\`\`</pre>

  </div>
</div>
</div>
`;
