import template from './single-project.component.html';
import controller from './single-project.controller';

const singleProject = {
  bindings: {
    project: '<',
    user: '<',
  },
  template,
  controller,
};

export default singleProject;
