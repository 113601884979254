import template from './feed-leaderboard.html';

const feedLeaderboard = function (GamificationService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      user: '=',
    },
    template,
    link: (scope) => {
      scope.badgePresentation = GamificationService.badgesById;
    },
  };
};

feedLeaderboard.$inject = ['GamificationService'];

export default feedLeaderboard;
