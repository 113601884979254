class CommunityManagersService {
  $http: ng.IHttpService;

  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getCommunityManagers() {
    return this.$http.get('/api/v1/community_managers');
  }
}

CommunityManagersService.$inject = ['$http'];

export default CommunityManagersService;
