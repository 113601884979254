export default `
<div id="mainContent" class="footer-wrapper" ui-view="status-show">
  <community-nav></community-nav>
  <uib-alert type="{{instruction.type}}" ng-repeat="instruction in instructions">{{instruction.message}}</uib-alert>
  <h1 class="sr-only">{{status.content}}, status by {{status.user.name}}</h1>
  <div class="section-single-body discussion">
    <div class="container-fluid block">
      <feed-item item="status" single-view="true"></feed-item>
    </div>
    <comment-section commentable="status" service="service"></comment-section>
  </div>
</div>
`;
