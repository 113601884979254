import communityFeedItemFlagOverlay from './community-feed-item-flag-overlay.component.html';

class CommunityFeedItemFlagCtrl {
  constructor($uibModal, FlagService, AlertService, LightTesterErrorService, $analytics) {
    const ctrl: ng.IController = this;

    ctrl.flagItem = () => {
      if (LightTesterErrorService.show()) return;

      ctrl.flagModal = $uibModal.open({
        animation: true,
        template: communityFeedItemFlagOverlay,
        controller: 'ModalCtrl',
        windowClass: 'community-feed-status-flag-modal',
        resolve: {
          data() {
            return {
              item: ctrl.item,
              submitFlag: ctrl.submitFlag,
              closeOverlay() {
                ctrl.flagModal.close();
              },
            };
          },
        },
      }).result.catch((resp) => {
        if (['cancel', 'backdrop click', 'escape key press'].indexOf(resp) === -1) throw resp;
      });
    };

    ctrl.submitFlag = (reason) => {
      if (!reason) {
        AlertService.addDanger('Please fill out the reason');
      } else {
        FlagService.flag(ctrl.item.type, ctrl.item.id, reason)
          .then(() => {
            ctrl.item.flagged_by_current_user = true;
            ctrl.trackFlagging({ type: ctrl.item.type });
            AlertService.add('Your report has been submitted');
          });
      }
    };

    ctrl.trackFlagging = (options) => {
      $analytics.eventTrack('Flagged', { category: 'Flag', label: options.type });
    };
  }
}

CommunityFeedItemFlagCtrl.$inject = ['$uibModal', 'FlagService', 'AlertService', 'LightTesterErrorService', '$analytics'];

export default CommunityFeedItemFlagCtrl;
