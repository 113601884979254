import template from './tool-category-item.component.html';

const toolCategoryItem = {
  bindings: {
    toolCategory: '=',
  },
  template,
};

export default toolCategoryItem;
