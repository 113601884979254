export default `
<div ng-show="$ctrl.feed == undefined || $ctrl.feed.length > 0" class="community-featured">
  <a href ng-keydown="$ctrl.skipFeatured($event)" class="skip-featured" aria-label="Skip Featured Posts">Skip Featured Posts</a>
  <div>
    <h2 class="community-featured-header">
      Featured
    </h2>
    <ul class="community-featured-list" id="communityFeaturedList" aria-label="Featured Posts">
      <li ng-if="!$ctrl.feed.length" class="community-featured-list-item-skeleton"></li>
      <li ng-if="!$ctrl.feed.length" class="community-featured-list-item-skeleton"></li>
      <li ng-if="!$ctrl.feed.length" class="community-featured-list-item-skeleton"></li>
      <li ng-if="!$ctrl.feed.length" class="community-featured-fade community-featured-fade--right"></li>
      <community-featured-list-item item="item" ng-repeat="item in $ctrl.feed" role="listitem"/>
    </ul>
  </div>
  <div id="communityFeaturedFadeLeft" class="community-featured-fade community-featured-fade--left"></div>
  <div id="communityFeaturedFadeRight" class="community-featured-fade community-featured-fade--right"></div>
  <div class="community-featured-list-scroll community-featured-list-scroll--left">
    <button id="communityFeaturedLeftScrollButton" class="community-featured-list-scroll__button community-featured-list-scroll__button--disabled" ng-click="$ctrl.scrollLeft()" tabindex="-1" aria-hidden="true">
      <i class="material-icons community-featured-list-scroll__arrow community-featured-list-scroll__arrow--left" aria-hidden="true">keyboard_arrow_left</i>
    </button>
  </div>
  <div class="community-featured-list-scroll community-featured-list-scroll--right">
    <button id="communityFeaturedRightScrollButton" class="community-featured-list-scroll__button community-featured-list-scroll__button--disabled" ng-click="$ctrl.scrollRight()" tabindex="-1" aria-hidden="true">
      <i class="material-icons community-featured-list-scroll__arrow community-featured-list-scroll__arrow--right" aria-hidden="true">keyboard_arrow_right</i>
    </button>
  </div>
</div>
`;
