import _ from 'lodash';
import template from './write-status.html';

const writeStatus = function (StatusService, $timeout, AlertService, $state, ProgressBarService, ServerMessages) {
  return {
    restrict: 'E',
    template,
    replace: true,
    scope: {
      mention: '=',
      status: '=?',
      successCallback: '=',
      hideAvatar: '=',
      popoverPlacement: '@',
    },
    link(scope, el) {
      scope.submitButton = {};

      function setDefaultStatus() {
        scope.status = {
          content: scope.mention ? `@${scope.mention.slug} ` : '',
          attachment: null,
          attached_url: null,
        };
      }

      scope.$watch('status.attachment', () => {
        $timeout(() => {
          scope.inputStyle = {
            'padding-right': el.find('.write-status-attach').width() + 20,
          };
        });
      });

      if (!scope.status) {
        setDefaultStatus();
      }

      scope.popoverPlacement = scope.popoverPlacement || 'bottom';

      scope.setInputHoverClass = function (cl) {
        scope.inputHoverClass = cl;
      };

      const callSuccess = function (res) {
        scope.submitButton.disabled = false;
        el.addClass('write-status-success');
        if (scope.status.id) {
          AlertService.add('Status successfully edited');
          if (scope.successCallback) {
            scope.successCallback(res);
          }
        } else {
          AlertService.add('Status successfully posted');
          setDefaultStatus();
          $state.go('community', {}, { reload: true });
        }
        $timeout(() => {
          el.removeClass('write-status-success');
        }, 2000);
        ProgressBarService.done();
      };

      scope.postStatus = function () {
        scope.status.attached_url = _.get(scope.status, 'attachment.value', null);
        if (_.isEmpty(_.trim(scope.status.content)) && !scope.status.attached_url) {
          AlertService.addDanger('Can\'t post empty status!');
        } else {
          scope.submitButton.disabled = true;
          ProgressBarService.start();
          if (scope.status.id) {
            StatusService.update(scope.status).then(callSuccess);
          } else {
            StatusService.create(scope.status.content, scope.status.attached_url).then(callSuccess, (response) => {
              AlertService.addDanger(ServerMessages.prettyMessage(response));
              ProgressBarService.done();
            });
          }
        }
      };
    },
  };
};

writeStatus.$inject = ['StatusService', '$timeout', 'AlertService', '$state', 'ProgressBarService', 'ServerMessages'];

export default writeStatus;
