export default `
<main class="academy-home" id="mainContent">
    <div class="container-fluid">
      <div class="row">
        <div class="academy-home-intro col-lg-12">
          <h1 class="academy-home-title">
            Welcome to the uTest Academy
          </h1>
          <div class="academy-home-description">
            <div class="academy-home-description-content">
                The Academy offers a range of courses to improve your skills as a tester.
                Learn new topics, get certified, and increase your tester rank to land the best-paying testing opportunities.
            </div>
            <div class="academy-light-tester-upgrade" ng-if="$ctrl.isLightTester">
              <span class="fa fa-info-circle"></span>
              <div class="academy-light-tester-upgrade__title">
                Most courses are blocked until you complete the required fields in your tester profile and add at least one testing device.
              </div>
            <div class="academy-light-tester-upgrade__cta">
              <a class="academy-light-tester-upgrade__button " href="{{$ctrl.testerAppUrl}}">Update Profile</a>
            </div>
            </div>
            <div class="academy-home-description-video">
            </div>
          </div>
        </div>
      </div>
      <div class="row course-track-list">
        <div class="col-xs-12">
          <div class="col-lg-4 col-md-4 col-sm-6 col-xs-12" ng-repeat="track in $ctrl.tracks">
            <course-track-card track-position="$index" course-track="track"></course-track-card>
          </div>
        </div>
    </div>
  </div>
</main>
`;
