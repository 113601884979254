export const
  COOKIE_OPT_IN_ACCEPTED = 'accepted',
  COOKIE_OPT_IN_DECLINED = 'declined',
  COOKIE_OPT_IN_PENDING = 'pending',
  EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  NAME_REGEX = /^((?!([0-9!¡?÷%&¿\+=@#<^>\\\/$]|(\.\w))).)*$/,
  GOOGLE_API_KEY = 'AIzaSyD0P6ggCh5k0EDL852L8Nor4j1Z9xmjznQ',
  STATIC_VALS_COUNTRIES = 'Country',
  STATIC_VALS_LANGUAGES = 'Language',
  STATIC_VALS_OS = 'OperatingSystem',
  STATIC_VALS_OS_VERSIONS = 'OperatingSystemVersion',
  STATIC_VALS_HANDSET_MAKER = 'MobileMaker',
  STATIC_VALS_HANDSET_MODEL = 'MobileModel',
  STATIC_VALS_HANDSET_OS_VERSION = 'MobileOperatingSystemVersion',
  STATIC_VALS_STATES = 'State',
  CAPTCHA_NONE = 'none',
  CAPTCHA_UTEST = 'utest',
  CAPTCHA_RECAPTCHA = 'recaptcha',
  EMPTY_CONTENT_MARKDOWNS = ['****', '__', '_****_', '**__**', '******', '**', '>', '``', '[](https://)', '![]()'],
  TESTCYCLE_STATUS = {
    DRAFT: 1,
    ACTIVE: 2,
    LOCKED: 3,
    CLOSED: 4,
    DISCARDED: 5,
    PENDING: 6,
  },
  TEST_CYCLE_STATUS_STRINGS = {
    active: 'Active',
  },
  STATUS_POST_MAX_CHARS = 250,
  KEY_CODES = {
    TAB: 9,
    RETURN: 13,
    ESC: 27,
    SPACE: 32,
    PAGEUP: 33,
    PAGEDOWN: 34,
    END: 35,
    HOME: 36,
    LEFT: 37,
    UP: 38,
    RIGHT: 39,
    DOWN: 40,
  },
  REQUESTED_DEVICES_FEATURE_NAME = 'requested_devices',
  APPLICATION_SIZE_MODES = {
    WIDE: 992,
    MEDIUM: 768,
    SMALL: 0,
  },
  UNAUTH_HOME_BG = {
    wide: 'assets/images/hero/unauth-home-bg.svg',
    medium: 'assets/images/hero/unauth-home-bg-s.svg',
  },
  UNAUTH_ABOUT_US_BG = {
    wide: 'assets/images/hero/unauth-about-us-bg.svg',
    small: 'assets/images/hero/unauth-about-us-bg-s.svg',
  },
  UNAUTH_HOME_IMG = {
    left: 'assets/images/hero/guy-with-phone.png',
    right: 'assets/images/hero/Testers.png',
    macbook: 'assets/images/home/macbook-utest-home.png',
    footerLeft: 'assets/images/home/macbook-hand-edit.png',
    footerRight: 'assets/images/home/phone-hand-edit.png',
    howItWorks: {
      img1: 'assets/images/home/howitworks/img-1.svg',
      img2: 'assets/images/home/howitworks/img-2.svg',
      img3: 'assets/images/home/howitworks/img-3.svg',
      img4: 'assets/images/home/howitworks/img-4.svg',
      table_bg: 'assets/images/home/howitworks/table-bg.svg',
    },
  },
  UNAUTH_WHY_UTEST_BG = {
    wide: 'assets/images/hero/unauth-background.svg',
    medium: 'assets/images/hero/unauth-background-s.svg',
    small: 'assets/images/hero/unauth-background-xs.svg',
  },
  UNAUTH_WHY_UTEST_VIDEO_ID = 'o98jkwxo72',
  UNAUTH_HOW_UTEST_WORKS_VIDEO_ID = '4yxg8i3o19',
  UNAUTH_WHY_UTEST_IMG = {
    videoThumbnail: 'assets/images/hero/why-utest/why-utest-video-thumbnail.jpg',
    projectBoard: 'assets/images/hero/why-utest/why-utest-projects-board.png',
    approvedBugs: 'assets/images/hero/why-utest/why-utest-approved-bugs.png',
    streamlineIcons: [
      'assets/images/hero/why-utest/streamline-icon-programming-bug-alternate@140x140.svg',
      'assets/images/hero/why-utest/streamline-icon-computer-hand-held@140x140.svg',
      'assets/images/hero/why-utest/streamline-icon-interface-user-multiple@140x140.svg',
    ],
    academy: 'assets/images/hero/why-utest/why-utest-academy.png',
    academyArticleLesson: 'assets/images/hero/why-utest/why-utest-article-lesson.png',
    articles: 'assets/images/hero/why-utest/why-utest-articles.png',
    trustedSecure: 'assets/images/hero/why-utest/why-utest-trusted-secure.jpg',
    articleCard: 'assets/images/hero/why-utest/why-utest-article-card.png',
    leftArrow: 'assets/images/hero/why-utest/left-arrow.svg',
    rightArrow: 'assets/images/hero/why-utest/right-arrow.svg',
    quotesIcon: 'assets/images/hero/why-utest/quote-icon.svg',
  },
  UNAUTH_WHY_UTEST_COMMUNITY_UTESTERS = [
    {
      id: 1,
      image: 'assets/images/hero/why-utest/utester-david.jpg',
      person: 'David S. | Spain',
    },
    {
      id: 2,
      image: 'assets/images/hero/why-utest/utester-miriam.jpg',
      person: 'Miriam D. | Mexico',
    },
    {
      id: 3,
      image: 'assets/images/hero/why-utest/utester-aleksandra.jpg',
      person: 'Aleksandra T. | Poland',
    },
  ],
  UNAUTH_WHY_UTEST_TESTER_QUOTES = [
    {
      id: 1,
      quote: 'I love very much what I am doing... I do believe I contribute to providing a better environment for users without bugs and dysfunctions.”',
      image: 'assets/images/hero/why-utest/tester-loukia.png',
      name: 'Loukia Varthi',
      address: 'United Kingdom',
    },
    {
      id: 2,
      quote: 'With software testing I get an opportunity to perfect the product, experience the product or new features before it hits the market, and of course earning while at it.”',
      image: 'assets/images/hero/why-utest/tester-laurent.png',
      name: 'Laurent Ninga',
      address: 'Tanzania',
    },
    {
      id: 3,
      quote: 'uTest opened up a new world for me, and I realized I found a profession that I really would like to educate myself more in.”',
      image: 'assets/images/hero/why-utest/tester-riho.png',
      name: 'Riho Rannamäe',
      address: 'Sweden',
    },
    {
      id: 4,
      quote: 'I\'m a mother so the idea of staying at home with my son and doing something I love AND making money from it? A dream come true.”',
      image: 'assets/images/hero/why-utest/tester-amina.png',
      name: 'Amina Touati',
      address: 'France',
    },
    {
      id: 5,
      quote: 'uTest Community has the best forum, many educational articles, wonderful practical Academy cycles, a lot of interesting paid projects and cool contests.”',
      image: 'assets/images/hero/why-utest/tester-victoria.png',
      name: 'Victoria Astasheva',
      address: 'Ukraine',
    },
  ],
  ABOUT_US_CONTENT = {
    images: {
      mission: 'assets/images/about-us/mission.png',
    },
    employees: [{
      name: 'Carlos Canto',
      title: 'Senior Manager, Community Management',
      avatar_image_url: 'assets/images/about-us/employees/carlos-canto.jpg',
      static_page_slug: 'meet-cm-carlos',
    }, {
      name: 'Jeremiah Dargis',
      title: 'Senior Manager, Community Management',
      avatar_image_url: 'assets/images/about-us/employees/jeremiah-dargis.jpg',
      static_page_slug: 'meet-cm-jeremiah',
    }, {
      name: 'Bailey Apple',
      title: 'Senior Manager, Community Management',
      avatar_image_url: 'assets/images/about-us/employees/bailey-apple.jpg',
      static_page_slug: 'meet-cm-bailey',
    }, {
      name: 'Nico Grieger',
      title: 'Senior Manager, Community Management',
      avatar_image_url: 'assets/images/about-us/employees/nico-grieger.jpg',
      static_page_slug: 'meet-cm-nico',
    }, {
      name: 'Rebecca Carroll',
      title: 'Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/rebecca-carroll.jpg',
      static_page_slug: 'meet-cm-rebecca',
    }, {
      name: 'Shafiya Banu',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/shafiya-banu.jpg',
      static_page_slug: 'meet-cm-shafiya',
    }, {
      name: 'Hang Tran',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/hang-tran.jpg',
      static_page_slug: 'meet-cm-hang',
    }, {
      name: 'Enkhzul Sukhbayar',
      title: 'Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/enkhzul-sukhbayar.jpg',
      static_page_slug: 'meet-cm-enkhzul',
    }, {
      name: 'Marialena Evrenoglou',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/marialena-evrenoglou.jpg',
      static_page_slug: 'meet-cm-marialena',
    }, {
      name: 'Bryan Williams',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/bryan-williams.jpg',
      static_page_slug: 'meet-cm-bryan',
    }, {
      name: 'Mohammed ShaNawaz',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/mohammed-shanawaz.jpg',
      static_page_slug: 'meet-cm-mohammed',
    }, {
      name: 'Alyssa Malcolm',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/alyssa-malcom.jpg',
      static_page_slug: 'meet-cm-alyssa',
    }, {
      name: 'John Keeter',
      title: 'Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/john-keeter.jpg',
      static_page_slug: 'meet-cm-john',
    }, {
      name: 'Connor Meiselman',
      title: 'Associate Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/connor-meiselman.jpg',
      static_page_slug: 'meet-cm-connor',
    }, {
      name: 'Elinor Tilles Levi',
      title: 'Community Manager',
      avatar_image_url: 'assets/images/about-us/employees/elinor-tilles.jpg',
      static_page_slug: 'meet-cm-elinor',
    }, {
      name: 'Suyu Meng',
      title: 'Community Marketing Manager',
      avatar_image_url: 'assets/images/about-us/employees/suyu-meng.jpg',
      static_page_slug: 'meet-cm-suyu',
    }],
    learnEarnConnectContent: [{
      type: 'LEARN',
      header: 'Free Training for All Testers',
      txt: 'Helping testers learn how to test and then improve their testing skills.',
    }, {
      type: 'EARN',
      header: 'Paid Projects for All Skill Levels',
      txt: 'Matching testers with paid project opportunities of all shapes and sizes.',
    }, {
      type: 'CONNECT',
      header: 'A Global Community of Crowdtesters',
      txt: 'Providing testers around the world a place to exchange their insights, experiences, and tips for software and QA testing.',
    }],
    stats: [{
      stat: '1M+',
      desc: 'testers worldwide',
    }, {
      stat: '10K+',
      desc: 'new testers every month',
    }, {
      stat: '4M+',
      desc: 'devices worldwide',
    }, {
      stat: 'Millions',
      desc: 'paid to testers monthly',
    }],
    faq: [{
      question: 'How do testers earn money on uTest?',
      answer: 'Your payout is project-based. uTest will invite you to testing projects that match your profile. You then review the tasks we ask you to complete and decide whether or not you want to participate. Each project will feature different tasks to complete. In the end, we will review your work and pay you for approved work. Payout details for each project are available for your review prior to accepting the project invitation.',
    }, {
      question: 'How are testers selected for projects?',
      answer: 'Our clients set the requirements for each testing project. Usually those are only tied to operating system specifications such as iOS or Android, which means that as long as you have a device they are looking for and speak the language they request, you’ll also be eligible to participate in the project. Other projects, such as Usability projects, require specific target groups of testers. In order to find out if you meet these requirements, we will first check your Tester Profile. If we cannot identify the information we need for your profile alone, we would personally contact you through email or survey to find out if you are a match for a project.',
    }, {
      question: 'What types of projects do testers work on?',
      answer: 'Each and every project is unique. There are no boundaries and no limits, we can test everything! The majority of projects involve functional testing, where testers use an app or website and look out for bugs and issues. Once you find a bug that’s within the scope of the testing project, report it to us. For other projects, like Usability testing, we may ask for your opinion on a given test object. Some projects ask testers to go out into the “real world” to test in a restaurant or store, or to test GPS signals, loyalty programs, or new banking systems.',
    }, {
      question: 'Do I need to have prior testing experience to join?',
      answer: 'Absolutely not. Every individual is welcome at uTest. Even if you do not seek to become a software testing professional, you can still participate in dozens of other projects where you absolutely do not need to have any software testing skills. You will identify quickly through our detailed project instructions if you got what it takes for a specific project.',
    }, {
      question: 'Do I have to pay to register on uTest?',
      answer: 'Not at all! uTest is free, has always been free and will always be free. You are here to earn money, not to lose it.',
    }, {
      question: 'Is uTest safe and secure to sign up for?',
      answer: "We prioritize the protection of our testers' personal information and adhere to stringent data security standards. It is crucial for all our testers, new and existing, to be vigilant against <a href='/phishing-notice'>phishing scams</a>. If you ever encounter anything suspicious or have any security concerns, please contact our support team immediately.",
    }, {
      question: 'Do I need to be located in a certain area for projects?',
      answer: 'While many of uTest’s projects are globally accessible and do not require testers to be in a specific location, there are exceptions where location is key. We encourage you to keep your profile updated with your current location and to adhere to our policy against using VPNs to misrepresent your location. In addition to remote testing projects, uTest occasionally offers on-site testing opportunities.',
    }],
    history: [{
      title: 'uTest was born',
      paragraph: 'uTest was founded by Doron Reuveni and Roy Solomon with the vision of building a global community of crowdtesters, testing on real devices in real time.',
      year: '07',
    }, {
      title: 'uTest named Gartner Cool Vendor',
      paragraph: 'Global research and advisory firm Gartner highlights uTest as an emerging innovative tech.',
      year: '09',
    }, {
      title: 'Expanded test services',
      paragraph: 'uTest proudly begins to offer clients solutions in Localization, Usability, Security, and Load testing, in addition to manual Functional testing.',
      year: '11',
    }, {
      title: 'Launched Applause',
      paragraph: 'Applause is officially launched as the client-facing entity, with uTest dedicated solely to supporting and managing the growing community of testers around the world.',
      year: '13',
    }, {
      title: 'Named one of Forbes\' Most Promising Companies',
      paragraph: 'Applause/uTest is included in Forbes\' list of Most Promising Companies for the first time.',
      year: '13',
    }, {
      title: '1,000,000 bugs reported',
      paragraph: 'uTesters submit their one millionth bug report on uTest!',
      year: '14',
    }, {
      title: '250,000 testers',
      paragraph: 'The uTest Community reaches a milestone of 250,000 software testers worldwide.',
      year: '16',
    }, {
      title: 'Introducing Voice Testing',
      paragraph: 'uTest introduces voice testing solutions to help solve the challenges presented by the accelerated growth of voice-powered technology.',
      year: '18',
    }, {
      title: '500,000+ testers',
      paragraph: 'The uTest community continues to grow, with an unmatched diversity of skills, languages and devices.',
      year: '19',
    }, {
      title: '1M testers',
      paragraph: 'uTesters are one million strong, located in 200+ countries and territories.',
      year: '20',
    }, {
      title: '5,000,000 bugs reported',
      paragraph: 'No bug goes undiscovered by the uTest community!',
      year: '21',
    }],
  },
  SIGN_UP_DROPDOWN_LABELS_HASH = {
    'signup.address': [
      'State, Province or Region',
      'Country',
    ],
    'signup.devices': [
      'Your Computer, Select OS',
      'Version, Select a Version',
      'Language, Select OS Language',
      'Your Mobile Device, Select Brand',
      'Model, Select a Model',
      'Operating System, Select OS',
    ],
  },
  ICONS = {
    arrowLeft: 'assets/images/icons/arrow-button-circle-left-enabled.svg',
    arrowLeftDisabled: 'assets/images/icons/arrow-button-circle-left-disabled.svg',
    arrowRight: 'assets/images/icons/arrow-button-circle-right-enabled.svg',
    arrowRightDisabled: 'assets/images/icons/arrow-button-circle-right-disabled.svg',
    dropDownArrow: 'assets/images/icons/drop-down-arrow.svg',
  },
  FEATURES = {},
  ONETRUST_CATEGORIES = {
    StrictlyNecessaryCookies: 'C0001',
    PerformanceCookies: 'C0002',
    FunctionalCookies: 'C0003',
    TargetingCookies: 'C0004',
    SocialMediaCookies: 'C0005',
  };
