/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import emojiConfig from '../../ng-app/common/ut-editor/ut-editor.custom/emoji-config';

/*
* Usage width typeahead:
* suggestion-typeahead typeahead-template-url="app/components/status/suggestion-typeahead.html" typeahead-on-select="replaceSuggestion($item)" typeahead="user for user in loadSuggestions($viewValue)"
*/
const suggestionTypeahead = function (SearchService, $timeout, $parse) {
  return {
    restrict: 'A',
    scope: false,
    require: 'ngModel',
    link(scope, el, attrs) {
      let statusBackup;
      const $textInput = $(el);
      let lastCaret = 0;
      let doSearch = true;

      function getMentionMatch(status, lastCaret) {
        if (status[lastCaret - 1] === '@') {
          doSearch = true;
        }
        if (!doSearch) {
          return false;
        }
        const search = status.substring(0, lastCaret);
        const mentionRegex = /(?:[\W]|^)@([^@\s]+[\s|@]?[^@\s]*)$/;
        const match = search.match(mentionRegex);
        if (match) {
          return match[1];
        }
        return false;
      }

      function getSuggestionMatch(status, lastCaret) {
        if (status[lastCaret - 1] === ':') {
          doSearch = true;
        }
        if (!doSearch) {
          return false;
        }
        const search = status.substring(0, lastCaret);
        const match = search.match(emojiConfig.match);
        if (match) {
          return match[1];
        }
        return false;
      }

      scope.loadSuggestions = function (viewValue) {
        statusBackup = viewValue;
        // @ts-ignore
        lastCaret = $textInput.caret();
        let match = getMentionMatch(viewValue, lastCaret);
        if (match) {
          return SearchService.searchOneFactory('User')({ query: match }).then(res => _.map(res.data, (o) => {
            o.match = match;
            o.sign = '@';
            o.replace = `@${o.slug}`;
            return o;
          }));
        }
        match = getSuggestionMatch(viewValue, lastCaret);
        if (match) {
          return _.map(_.filter(emojiConfig.feed(match), o => o[1].indexOf(match) >= 0).splice(0, 10), emoji => ({
            avatar_url: $(emoji[0]).attr('src'),
            name: emoji[1].replace(/:/g, '').replace('_', ' '),
            slug: emoji[1],
            type: 'emoji',
            match,
            sign: ':',
            replace: emoji[1],
          }));
        }
      };

      scope.replaceSuggestion = function (item) {
        const emojiIdentifier = ':';

        // Finds last index of ':' from beggining of string to lastCaret, then selects from beggining to the indexed ':'
        const emojiIdentifierBeforeCursorIndex = statusBackup.substring(0, lastCaret).lastIndexOf(emojiIdentifier);
        const beginningOfStatus = statusBackup.substring(0, emojiIdentifierBeforeCursorIndex);

        // Replaces the :text with the emoji the user picks
        const suggestedEmojiText = statusBackup.substring(emojiIdentifierBeforeCursorIndex, lastCaret).replace(item.sign + item.match, item.replace);

        // Selects string from lastCaret to end of string
        const endOfStatus = statusBackup.substring(lastCaret, statusBackup.length);

        // Final result by adding the before, emoji, and after emoji strings
        const result = beginningOfStatus + suggestedEmojiText + endOfStatus;

        const model = $parse(attrs.ngModel);
        model.assign(scope, result);
        doSearch = false;
        $timeout(() => {
          // @ts-ignore
          $textInput.caret((beginningOfStatus + suggestedEmojiText).length);
        });
      };
    },
  };
};

suggestionTypeahead.$inject = ['SearchService', '$timeout', '$parse'];

export default suggestionTypeahead;
