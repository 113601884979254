import _ from 'lodash';
import template from './overlay.html';

const overlay = function () {
  return {
    restrict: 'C',
    transclude: true,
    template,
    link(scope) {
      // trigger overlay effect
      scope.toggleOverlay = function (target) {
        target = (typeof target === 'undefined') ? '.overlay' : `#${target}`;
        if ($(target).hasClass('open')) {
          $(target).removeClass('open');
        } else if (!$(target).hasClass('open')) {
          $(target).addClass('open');
        }
        // $('.overlay input').val('');
      };

      scope.closeOverlay = function (target) {
        target = _.isUndefined(target) ? '.overlay' : `#${target}`;
        $(target).removeClass('open');
      };
    },
  };
};

export default overlay;
