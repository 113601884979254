class CommunityFollowBtnCtrl {
  constructor(UserService, LightTesterErrorService, $analytics, $document) {
    const ctrl: ng.IController = this;
    ctrl.updateCurrentUserPermissions = (followResponse) => {
      if (followResponse) {
        ctrl.user.current_user_permissions = followResponse.data.current_user_permissions;
      }
      return followResponse;
    };

    ctrl.trackFollowing = (options) => {
      $analytics.eventTrack(options.action, { category: 'Follows', label: options.label });
    };

    ctrl.toggleFollow = () => {
      if (ctrl.user.follow_stats.followed_by_current_user) {
        UserService.follow(ctrl.user.id).then(ctrl.updateCurrentUserPermissions);
        ctrl.user.follow_stats.follower_count++;
        ctrl.trackFollowing({ action: 'follow', label: $document[0].title });
      } else {
        UserService.unfollow(ctrl.user.id).then(ctrl.updateCurrentUserPermissions);
        ctrl.user.follow_stats.follower_count--;
        ctrl.trackFollowing({ action: 'unfollow', label: $document[0].title });
      }
    };
    ctrl.followKeydown = function (event) {
      if ([13, 32].includes(event.keyCode)) {
        event.preventDefault();
        if (LightTesterErrorService.show()) return;

        ctrl.user.follow_stats.followed_by_current_user = !ctrl.user.follow_stats.followed_by_current_user;
        ctrl.toggleFollow();
      }
    };
    ctrl.followClick = function (event) {
      event.preventDefault();
      event.stopPropagation();
      if (LightTesterErrorService.show()) return;

      ctrl.user.follow_stats.followed_by_current_user = !ctrl.user.follow_stats.followed_by_current_user;
      ctrl.toggleFollow();
    };
  }
}

CommunityFollowBtnCtrl.$inject = ['UserService', 'LightTesterErrorService', '$analytics', '$document'];

export default CommunityFollowBtnCtrl;
