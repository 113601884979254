const ntuxAvatarBadges = function (UserService) {
  return {
    restrict: 'A',
    scope: {
      ntuxAvatarBadges: '=',
    },
    link(scope, el) {
      function setup(user) {
        let badges = '<span class="avatar-badges-wrapper" >';
        el.css({
          'background-image': `url(${user.avatar_url})`,
        });
        let badge = '';
        const titles = [];

        if (user.is_ambassador) {
          badge = 'avatar-badge-a';
          titles.push('uTest Ambassador');
        } else if (user.is_employee) {
          badge = 'avatar-badge-u';
          titles.push('uTest Employee');
        } else if (user.user_type === 'moderator' || user.user_type === 'admin') {
          badge = 'avatar-badge-m';
          titles.push('Moderator');
        } else if (user.platform_roles) {
          if (user.platform_roles.pm) {
            badge = 'avatar-badge-pm';
            titles.push('Project Manager');
          } else if (user.platform_roles.ttl) {
            badge = 'avatar-badge-ttl';
            titles.push('Test Team Lead');
          }
        }
        if (badge.length) {
          badges += `<span title="${titles.join(', ')}" class="avatar-badge ${badge}"></span>`;
        }
        badges += '';
        badges += '</span>';
        el.append(badges);
      }

      if (scope.ntuxAvatarBadges === 'me') {
        UserService.me().then((res) => {
          if (res !== null) {
            setup(res.data);
          } else {
            setup(scope.ntuxAvatarBadges);
          }
        });
      } else {
        setup(scope.ntuxAvatarBadges);
      }
    },
  };
};

ntuxAvatarBadges.$inject = ['UserService'];

export default ntuxAvatarBadges;
