class CommunityFeedItemCommentCtrl {
  constructor($sce, $filter) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.comment.content_built = $sce.trustAsHtml($filter('uMarkdown')(ctrl.comment.content));
    };
  }
}

CommunityFeedItemCommentCtrl.$inject = ['$sce', '$filter'];

export default CommunityFeedItemCommentCtrl;
