export default `
<div id="mainContent">
  <div ng-if="tool.state === 'draft'" class="alert alert-warning">
    <div class="container">
      This tool will be publicly visible after an admin confirms it.
    </div>
  </div>
  <div class="section-single-cover">
    <div class="block container">
      <div class="feed tool">
        <div class="feed-img" bg-img="{{tool.icon.url}}"></div>
        <div class="feed-content">
          <div class="feed-content-heading clearfix">
            <a href="{{tool.website | trustAsUrl}}" class="web-link" target="_blank" rel="noopener noreferrer">{{tool.website}}</a>
            <div class="rating"><uib-rating ng-model="tool.comments.avg_rating" max="5" state-on="'icon-star-rated'" state-off="'icon-star'" readonly="true"></uib-rating>({{tool.comments.count}})</div>
            <a class="category" ui-sref="toolcategory({categoryId : tool.category.id})"><h4>{{tool.category.name}}</h4></a>
            <h2>{{tool.name}}</h2>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="section-single-toolbar">
        <ut-tag-btn [tag]="tag" ng-repeat="tag in tool.tags"></ut-tag-btn>
        <hr>
        <div class="clearfix">
          <div class="section-single-toolbar__cell_right">
            <social-sharing target="tool.url" text="tool.name"></social-sharing>
            <subscribe-btn item="tool"></subscribe-btn>
          </div>
          <div class="section-single-toolbar__cell_left">
            <like-btn likeable="tool" number="true" reverse="true"></like-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="section-single-body tool">
    <div class="block container">
      <div class="section-single-body-content">
        <p ng-bind-html="tool.description"></p>
        <uib-carousel interval="carouselInterval" ng-if="tool.gallery.length > 0">
          <uib-slide ng-repeat="slide in tool.gallery" active="slide.active">
            <img ng-src="{{slide.url}}" style="margin:auto;">
            <div class="carousel-caption">
              <h4>Slide {{$index}}</h4>
              <p>{{slide.caption}}</p>
            </div>
          </uib-slide>
        </uib-carousel>
        <div class="source"><span>Submitted by: </span><a ui-sref="profile.about({profileId: tool.user.slug})">{{tool.user.name}}</a></div>
      </div>
    </div>
  </div>
  <comment-section service="service" commentable="tool" comment-btn-label="Rate and Comment" ></comment-section>
</div>
`;
