export default `
<header class="navbar navbar-default navbar-fixed-top unauthenticated-nav-bar" role="banner">
  <div class="unauthenticated-nav-bar__logo navbar-brand hidden-xs">
    <a ui-sref="home"><img src="assets/images/uTestLogoNavDark.svg" alt="uTest home page"></a>
  </div>
  <div class="unauthenticated-nav-bar__logo-small navbar-brand hidden-sm hidden-md hidden-lg">
    <a ui-sref="home"><img src="assets/images/utestLogoIconBlue.svg" alt="uTest home page"></a>
  </div>
  <div class="unauthenticated-nav-bar__links navbar-right hidden-sm hidden-xs">
    <ul class="nav navbar-nav">
      <li>
        <unauthenticated-header-nav-item name="Why uTest" route="why-utest" highlight="why-utest"></unauthenticated-header-nav-item>
      </li>
      <li>
        <unauthenticated-header-nav-item name="About Us" route="about-us" highlight="about-us"></unauthenticated-header-nav-item>
      </li>
      <li>
        <unauthenticated-header-nav-item name="Projects" route="project" highlight="project,singleproject,projectlist"></unauthenticated-header-nav-item>
      </li>
      <li class="unauthenticated-nav-bar__divider"></li>
    </ul>
    <ul class="nav navbar-nav">
      <li>
        <a class="unauthenticated-nav-bar__link" href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">Log In</a>
      </li>
      <li>
        <a class="unauthenticated-nav-bar__sign-up" ui-sref="signup.personal">Join Today</a>
      </li>
    </ul>
  </div>
  <div class="unauthenticated-nav-bar__dropdown-toggle hidden-md hidden-lg">
    <div>
      <button id="menu-button" aria-expanded="{{$ctrl.isOpen}}" aria-controls="unauthenticated-dropdown-nav" ng-click="$ctrl.toggleMenu()">
        <i class="material-icons">menu</i>
      </button>
    </div>
  </div>
  <ul ng-show="$ctrl.isOpen" aria-labelledby="menu-button" id="unauthenticated-dropdown-nav"
      class="unauthenticated-nav-bar__dropdown-menu">
    <li><a class="unauthenticated-nav-bar__dropdown-menu-link" ui-sref="why-utest" ng-click="$ctrl.closeMenu()">Why uTest</a></li>
    <li><a class="unauthenticated-nav-bar__dropdown-menu-link" ui-sref="about-us" ng-click="$ctrl.closeMenu()">About Us</a></li>
    <li><a ui-sref="project" class="unauthenticated-nav-bar__dropdown-menu-link" ui-sref="projects" ng-click="$ctrl.closeMenu()">Projects</a></li>
    <li class="unauthenticated-nav-bar__dropdown-menu-divider"></li>
    <li><a class="unauthenticated-nav-bar__dropdown-menu-link" href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">Log In</a></li>
    <li><a class="unauthenticated-nav-bar__dropdown-menu-sign-up" ui-sref="signup.personal">Join Today</a></li>
  </ul>
</header>
`;
