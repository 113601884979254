import template from './course-track-quiz.component.html';
import controller from './course-track-quiz.controller';

const courseTrackQuiz = {
  bindings: {
    courseTrack: '<',
  },
  template,
  controller,
};

export default courseTrackQuiz;
