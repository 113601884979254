const StaticPageService = ($http) => {
  const get = id => $http.get(`/api/v1/static_pages/${id}`);

  return {
    get,
  };
};

StaticPageService.$inject = ['$http'];

export default StaticPageService;
