import {
  AfterViewInit,
  Component, Input, ViewChild,
} from '@angular/core';
import { ICONS } from '../../../constants';

@Component({
  selector: 'ut-content-slider',
  templateUrl: './content-slider.component.html',
  styleUrls: ['./content-slider.scss'],
})
class ContentSliderComponent implements AfterViewInit {
  @Input() header:string;
  @Input() text:string;
  @ViewChild('scrollableContent') scrollableContainer;

  scrollOffset = 250;
  disabledLeft: boolean = true;
  disabledRight: boolean = false;
  icons = ICONS;

  scrollRight() {
    this.scrollableContainer.nativeElement.scrollLeft += this.scrollOffset;
    this.updateDisabled();
  }

  scrollLeft() {
    this.scrollableContainer.nativeElement.scrollLeft -= this.scrollOffset;
    this.updateDisabled();
  }

  updateDisabled() {
    const nativeContainer = this.scrollableContainer.nativeElement;
    const widthScroll = nativeContainer.scrollWidth - nativeContainer.clientWidth;
    this.disabledRight = nativeContainer.scrollLeft >= widthScroll;
    this.disabledLeft = nativeContainer.scrollLeft === 0;
  }

  ngAfterViewInit() {
    // otherwise if there are many components, values from first one are taken
    setTimeout(() => this.updateDisabled(), 300);
  }
}

export { ContentSliderComponent };
