/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Observable } from 'rxjs';
import { CompareByRef, CompareWithFn } from '../../../+utils/compare-with-fn';
import { Option } from '../option/option';
import { OptionsRootHolder } from '../options-root-holder';
import { SelectionEvent } from './selection-event';

export abstract class OptionSelectionService {
  constructor(protected root: OptionsRootHolder) {}

  abstract select(event: SelectionEvent);

  abstract isSelected$(option: Option): Observable<boolean>;

  abstract isAnySelected$(options: Option[]): Observable<boolean>;

  abstract clear(param: { userInput: boolean }): void;

  findOptionByValue(value: any): Option | null {
    const options: Option[] = this.getAllOptions();
    return options.find(option => this.compareValues(option.value, value)) || null;
  }

  findOptionsByValues(values: any[]): Option[] {
    const options: Option[] = this.getAllOptions();
    return options.filter(option => values.some(value => this.compareValues(option.value, value)));
  }

  compareValues(value1: any | null, value2: any | null): boolean {
    const compareWith: CompareWithFn<any> = this.root.get().compareWith || CompareByRef;

    return value1 && value2 ? compareWith(value1, value2) : (value1 === value2);
  }

  private getAllOptions(): Option[] {
    const options: Option[] = [];
    const nodes = [...this.root.get().children];

    for (let i = 0; i < nodes.length; i++) {
      const node = nodes[i];
      if (node instanceof Option) {
        options.push(node);
      } else if (node.children) {
        nodes.push(...node.children.toArray());
      }
    }

    return options;
  }

}
