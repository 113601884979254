import _ from 'lodash';

class CourseTrackNavItemCtrl {
  constructor() {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.courseFailed = _.includes(ctrl.failedCourses, ctrl.navCourse.slug);
    };

    ctrl.$onChanges = (changes) => {
      ctrl.courseTrack = changes.courseTrack.currentValue;
    };
  }
}

export default CourseTrackNavItemCtrl;
