<main>
  <div *ngIf="user" class="fourHundred">
    <h1>Oops! The page you were looking for doesn't exist. <img alt="" src="assets/images/thinking-face.png" /></h1>
    <h2>You may have mistyped the address or the page may have moved.</h2>
    <a uiSref="home" class="ng-binding btn btn-blue">Go to Home page</a>
  </div>
  <div *ngIf="!user"  class="fourHundredNotAuthorized">
    <img alt="" src="assets/images/thinking-face.png" /><br />
    <h1>The page you were looking for doesn't exist.</h1>
    <h2>Oops! This page has moved or cannot be found</h2>
    <a uiSref="home" target="_self" class="btn btn-unauth">Return to Home Page</a>
  </div>
</main>
