<!--
  ~ Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
  -->

<ngf-autocomplete-input [placeholder]="autocomplete.placeholder"
                        [hasError]="autocomplete.hasError"
                        [hasShadow]="autocomplete.hasShadow"
                        [displayValue]="null"
                        [disabled]="autocomplete.disabled || autocomplete.isReadonly"
                        [disableClear]="autocomplete.disableClear"
                        [isOpen]="autocomplete.optionsOverlay.isOpen"
                        (search)="handleSearch($event)"
                        (toggleOptions)="handleToggle()"></ngf-autocomplete-input>
