import template from './nav-sidebar-user-info.component.html';
import controller from './nav-sidebar-user-info.controller';

const navSidebarUserInfo = {
  bindings: {
    user: '<',
  },
  template,
  controller,
};

export default navSidebarUserInfo;
