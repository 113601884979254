const mailSuggest = function ($compile) {
  return {
    restrict: 'A',
    require: '?ngModel',
    link(scope, el, attrs, ngModel) {
      const mailSuggestText = attrs.mailSuggestText || 'Did you mean ';

      const template = $compile(`${''
        + '<div class="suggested-email" ng-if="mailSuggestion" aria-hidden="true">'}${
        mailSuggestText
      }<a ng-bind="mailSuggestion" ng-click="useSuggestedMail()"></a>?`
        + '</div>')(scope);
      el.after(template);

      el.bind('blur', () => {
        el.mailcheck({
          suggested(element, suggestion) {
            scope.mailSuggestion = suggestion.full;
            scope.mailSuggestionDescription = mailSuggestText + suggestion.full;
            scope.$apply();
          },
          empty(_element) {
            scope.mailSuggestion = false;
            scope.mailSuggestionDescription = '';
            scope.$apply();
          },
        });
      });

      scope.useSuggestedMail = function () {
        ngModel.$setViewValue(scope.mailSuggestion);
        ngModel.$render();
        scope.mailSuggestion = false;
      };
    },
  };
};

mailSuggest.$inject = ['$compile'];

export default mailSuggest;
