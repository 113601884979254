class SingleToolCtrl {
  constructor($scope, tool, ToolService) {
    $scope.tool = tool.data;
    $scope.service = ToolService;

    $scope.carouselInterval = 5000;
  }
}

SingleToolCtrl.$inject = ['$scope', 'tool', 'ToolService'];

export default SingleToolCtrl;
