class StatusAttachmentCtrl {
  constructor(EmbedlyService, $state, UrlExtensionService, FreezeFrameService, $timeout, $scope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.url = ctrl.status.attached_url;
      ctrl.localImage = ctrl.url.indexOf('/') === 0;
      ctrl.isGif = UrlExtensionService.isGif(ctrl.url);
      ctrl.isPlaying = true;
      ctrl.id = $scope.$id;
      ctrl.showGifControl = false;
    };

    $scope.$on('applyFreezeFrame', () => {
      if (ctrl.isGif) {
        ctrl.insertGif();
      }
    });

    ctrl.$postLink = () => {
      if (ctrl.isGif) {
        if (ctrl.location === 'trending') {
          $timeout(() => {
            const $container = $(`#status-url-${ctrl.id}`);
            const link = ['<a class="img-crop">', '</a>'];
            $container.html(`${link[0]}<img src="${ctrl.url}" alt="">${link[1]}`);
          });
        } else {
          ctrl.insertGif();
        }
      } else if (!ctrl.localImage) {
        EmbedlyService.extract(ctrl.url, $state.href('singlestatus', { statusId: ctrl.status.id }), 'status-url');
      }
    };

    ctrl.insertGif = () => {
      $timeout(() => {
        FreezeFrameService.insertGif(ctrl.id, ctrl.url, 'status-url');
      });
      ctrl.showGifControl = true;
    };

    ctrl.togglePlayGif = () => {
      const freezeFrameTarget = $(`#status-id-${ctrl.id}`);
      freezeFrameTarget.click();
      ctrl.isPlaying = !ctrl.isPlaying;
    };
  }
}

StatusAttachmentCtrl.$inject = ['EmbedlyService', '$state', 'UrlExtensionService', 'FreezeFrameService', '$timeout', '$scope'];

export default StatusAttachmentCtrl;
