import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommunityNavComponent } from './nav/community-nav.component';
import { CommunityNavItemComponent } from './nav/community-nav-item.component';
import { CommunityFeedItemLikeBtnComponent } from './likes/community-feed-item-like-btn/community-feed-item-like-btn.component';
import { CommunityFeedItemLikeCountComponent } from './likes/community-feed-item-like-count/community-feed-item-like-count.component';
import { NumToKPipe } from '../../pipes/num-to-k';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    CommunityNavComponent,
    CommunityNavItemComponent,
    CommunityFeedItemLikeBtnComponent,
    CommunityFeedItemLikeCountComponent,
    NumToKPipe,
  ],
  exports: [
    CommunityNavComponent,
    CommunityNavItemComponent,
    CommunityFeedItemLikeBtnComponent,
    CommunityFeedItemLikeCountComponent,
  ],
})
class CommunityModule {}

export { CommunityModule };
