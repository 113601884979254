import _ from 'lodash';

class ToolCategoryCtrl {
  constructor($scope, $transition$, ToolService, tools, toolCategory, FeedStore) {
    const fetchFeed = _.bind(ToolService.feed, ToolService);
    $scope.groups = {
      highestRated: tools,
      recentlyAdded: new FeedStore(fetchFeed, { sort: '-published_at', filter: { category_id: $transition$.params().categoryId } }),
    };

    $scope.category = toolCategory.data;
  }
}

ToolCategoryCtrl.$inject = ['$scope', '$transition$', 'ToolService', 'tools', 'toolCategory', 'FeedStore'];

export default ToolCategoryCtrl;
