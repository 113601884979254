import controller from './course-track-card.controller';
import template from './course-track-card.component.html';

const courseTrackCard = {
  bindings: {
    trackPosition: '<',
    courseTrack: '<',
  },
  template,
  controller,
};

export default courseTrackCard;
