export default `
<div class="dashboard-card-list" ng-if="$ctrl.communityActivities.length > 0">
  <activity-list-item current-user="$ctrl.currentUser" activity="activity" ng-repeat="activity in $ctrl.communityActivities" ng-if="activity.type !== 'hidden'"></activity-list-item>
</div>

<div class="dashboard-activity-no-list-items" ng-if="$ctrl.communityActivities.length === 0" ng-show="$ctrl.communityActivities">
  <div class="dashboard-activity-no-list-items__primary">
    You don't have any community activity.
  </div>

  <div class="dashboard-activity-no-list-items__secondary">
    Check out the community page to share content and meet other uTesters.
  </div>

  <a ui-sref="community" class="dashboard-link-button">
    Community Page
  </a>
</div>

<a ui-sref="activity" ng-if="$ctrl.communityActivities.length > 0">
  <div class="dashboard-card-footer">
    View all activity
  </div>
</a>
`;
