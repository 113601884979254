export default `
<main class="dashboard">
  <div id="dashboardContainer" class="block container-fluid">
    <div class="row">
      <div class="col-md-8 col-sm-12">
        <dashboard-new-joiner-checklist ng-if="$ctrl.showNewJoinerChecklist && $ctrl.currentUser.is_tester"></dashboard-new-joiner-checklist>
        <dashboard-card-activity></dashboard-card-activity>
      </div>

      <dashboard-card-profile-completion ng-if="$ctrl.showCompletionCard && $ctrl.currentUser.is_tester"></dashboard-card-profile-completion>
      <dashboard-card-my-devices></dashboard-card-my-devices>
      <dashboard-card-requested-devices feature-flag="UTEST_REQUESTED_DEVICES"></dashboard-card-requested-devices>
    </div>
  </div>
</main>
`;
