/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ngfPrefix]'
})
export class PrefixDirective implements OnInit {

  // NOTE: used mainly for buttons which opens dialogs or other overlay elements
  //       example: ngf-date-picker which opens calendar dropdown
  @Input('ngfPrefixActive')
  @HostBinding('class.ngf-prefix--active')
  active = false;

  constructor(private elementRef: ElementRef<HTMLElement>,
              private renderer: Renderer2) {}

  ngOnInit(): void {
    if (this.elementRef.nativeElement instanceof HTMLButtonElement) {
      this.renderer.addClass(this.elementRef.nativeElement, `ngf-prefix--button`);
    } else {
      this.renderer.addClass(this.elementRef.nativeElement, `ngf-prefix--default`);
    }
  }

}
