export default `
<div class="recommendations-wrapper">
  <h2 class="section-title">Articles You May Like</h2>
  <div class="col-sm-4" ng-repeat="item in $ctrl.items" >
    <div class="recommendation-item">
      <div class="feed-item-info">
        <span avatar-badges="item.user" class="profile-thumb" ui-sref="profile.about({profileId: item.user.slug})"></span>
        <a class="feed-item-info_title" ui-sref="profile.about({profileId: item.user.slug})" aria-label="Article Author, {{item.user.name}}">{{item.user.name}}</a>
        <span tabindex="0"
              class="fa fa-info-circle"
              role="button"
              aria-label="{{ 'Open ' + item.user.name + ' tooltip' }}"
              dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: item.user})"
              ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: item.user})"
              ng-click="showTooltip('user-tooltip', {user: item.user});$event.preventDefault(); $event.stopImmediatePropagation()"
              ng-mouseleave="hideTooltip()"></span>
        <small>
          Article • <span >{{item.published_at | relativeDate}}</span>
        </small>
      </div>
      <div class="recommendation-item-title">
        <a ng-click="$ctrl.track(item)" ui-sref="singlearticle({articleId: item.slug})" ui-sref-opts="{inherit: false}" ng-bind="item.title" ></a>
      </div>
      <div class="recommendation-item-footer">
        <a ng-click="$ctrl.track(item)" ui-sref="singlearticle({articleId: item.slug})" ui-sref-opts="{inherit: false}">Read More</a>
      </div>
    </div>
  </div>
</div>
`;
