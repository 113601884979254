import _ from 'lodash';

class AdminService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  flaggedContent(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return this.$http.get('/api/v1/admin/flagged_content', { params: parameters });
  }
}

AdminService.$inject = ['$http'];

export default AdminService;
