import _ from 'lodash';
import angular from 'angular';

class DiscussionCategoryCtrl {
  constructor($scope, $state, $filter, DiscussionService, DiscussionCategoryService, LightTesterErrorService, UserService, FeedStore, discussionCategory, $timeout, $element) {
    $scope.category = discussionCategory.data;

    $scope.filterCategory = {
      id: $scope.category.id,
    };

    if ($scope.category.parent) {
      $scope.category.color = $filter('categoryColor')($scope.category.parent.name);
    }

    $scope.$watch('filterCategory.id', (newId, oldId) => {
      if (newId === oldId) { return; }
      $state.go('discussioncategory', { categoryId: newId });
    });

    DiscussionCategoryService.allForSelect().then((categories) => { $scope.categories = categories; });

    const fetchDiscussions = _.bind(DiscussionService.feed, DiscussionService);
    $scope.discussions = new FeedStore(fetchDiscussions, { filter: { category_id: $scope.category.id }, sort: '-sticky,-published_at' });
    $scope.discussions.load();

    UserService.me().then((res) => {
      if (res !== null) {
        $scope.currentUser = res.data;
      } else {
        $scope.currentUser = null;
      }
    });

    $scope.checkLightTester = (e) => {
      if (LightTesterErrorService.show()) {
        e.preventDefault();
      }
    };

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = 'Select category';
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 250);
  }
}

DiscussionCategoryCtrl.$inject = ['$scope', '$state', '$filter', 'DiscussionService', 'DiscussionCategoryService', 'LightTesterErrorService', 'UserService', 'FeedStore', 'discussionCategory', '$timeout', '$element'];

export default DiscussionCategoryCtrl;
