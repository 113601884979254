class ProjectListCtrl {
  constructor($scope, $state, projects) {
    $scope.projects = projects;
    if (!$scope.projects.feedItems.length) {
      $state.go('fourohfour', null, { location: false });
    }
  }
}

ProjectListCtrl.$inject = ['$scope', '$state', 'projects'];

export default ProjectListCtrl;
