export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w960">
      <div class="section-home-cover-random">
        <div class="c6">
          <h1>Paid Testing in France!</h1>
          <p>We are looking for professionals who are employed, or self employed, and live in France for Usability testing on Windows PCs and Macs. We need people in certain roles who would be willing to participate in two usability surveys.</p>
          <p>We’re looking for people who meet any of the following…</p>
          <ul>
            <li>…individuals who own their own company and are the sole employee. Independent consultants, contractors, etc.</li>
            <li>…employees who have signatory power for binding contracts, but primary duties do not involve contracts/contract signing. Example titles include Quality Assurance Agent, Senior/Lead Finance Officer, Lead Sales Officer, etc</li>
            <li>…employees who manage binding documents for a group of individuals, where contracts are one of their main duties. Sales Assistant, Office Administrator, Customer Service Representative, etc.</li>
            <li>…high-level employees who have signatory and delegation power for corporate contracts and agreements. Corporate Controller, “C” level (CFO, CEO, CTO) positions, Division Chief, etc.</li>
          </ul>
          <p>Native speakers of French who have spent the majority of their lives, and are currently living, in France are preferred.</p>
          <p>The project pays $100 USD and takes about 90 minutes, broken into two sessions. It requires a phone call with a survey expert who will guide you through the software and ask a few questions around your impression of the software.</p>
          <p> We are also offering $100 USD for successful referrals. </p>
          <p><a class="aButton bgYellow txtBlack trackThis" href="mailto:bforster@applause.com?subject=Usability Testing in France and France" target="_blank">Contact Us</a> or <a class="aButton bgYellow txtBlack trackThis"href="https://docs.google.com/forms/d/1sl0zb6u6dEs3T5P_reHYjCSt-aFDhJECwSg-QbP2Vq4/viewform" target="_blank" rel="noopener noreferrer">SIGN UP NOW</a></p>
        </div>
        <div class="c6">
          <div class="wistia_embed wistia_async_yu42n31axu" style="width:680px; height:383px;"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="grid w960">
    <div class="c4">
      <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/textbook72x73.png" />
        <h3>uTest University</h3>
        <p>uTest University leverages uTest’s access to cutting-edge testing environments and products to create a place for up-to-date educational materials about all aspects of testing.</p>
        <a class="aButton aButtonSmall bgYellow" href="http://WWW.utest.com/courses/" target="_blank;">Visit Now</a> </div>
    </div>
    <div class="c4">
      <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/QA72x73.png" />
        <h3>uTest Tester Forums</h3>
        <p>The uTest Forums host discussions on software testing trends, best QA practices, advice and webinars by some of the best QA professionals from around the world.</p>
        <a class="aButton aButtonSmall bgYellow" href="http://www.utest.com/discussion/" target="_blank;">Visit Now</a> </div>
    </div>
    <div class="c4">
      <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/play72x73.png" />
        <h3>uTest Community Channel</h3>
        <p>Watch the latest uTest Community Panel webinars, tester spotlight videos, weekly roundups (including tips and projects) and other videos on the uTest Community Youtube channel.</p>
        <a class="aButton aButtonSmall bgYellow" href="http://www.youtube.com/user/uTestCommunity" target="_blank" rel="noopener noreferrer">Visit Now</a> </div>
    </div>
  </div>
  <div class="grid w960">
    <div class="c2"> <img alt="Contact Us" src="/assets/images/legacy/icons/map_contactus.png"> </div>
    <div class="c10">
      <div class="cf">
        <h3 class="c12">STILL HAVE QUESTIONS?</h3>
        <p class="c7">Whether you interested in learning more about joining the uTest Community or you are a veteran uTester looking for career advice, click on the button for more information.</p>
        <div class="c5 ctaBandButton"> <a class="aButton bgBlue" target="_blank" href="http://www.utest.com/contact-us">More Info</a> </div>
      </div>
    </div>
  </div>
</div>
`;
