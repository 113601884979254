class HomeCtrl {
  constructor(UnauthenticatedAssetsService, $rootScope) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      if (ctrl.user) {
        $rootScope.showNtuxNavigation = true;
      } else {
        UnauthenticatedAssetsService.getBrands().then((res) => {
          ctrl.brands = res.data;
        });
      }
    };
  }
}

HomeCtrl.$inject = ['UnauthenticatedAssetsService', '$rootScope'];

export default HomeCtrl;
