import _ from 'lodash';

class ArchiveService {
  ServiceByType: any;
  constructor(ServiceByType) {
    this.ServiceByType = ServiceByType;
  }

  archive(item) {
    const service = this.ServiceByType.get(item.type);
    if (_.isFunction(service.archive)) {
      return service.archive(item.id);
    }
  }

  unarchive(item) {
    const service = this.ServiceByType.get(item.type);
    if (_.isFunction(service.publish)) {
      return service.publish(item.id);
    }
  }
}

ArchiveService.$inject = ['ServiceByType'];
export default ArchiveService;
