export default `
<div>
  <form name="userForm" class="user-form" ng-class="{'invalid': (!userForm.$valid && userForm.$$submitted)}">
    <div class="form-group-box">
      <div class="step-intro">
        Let's start setting up your account. We ask your age to ensure we meet privacy laws and it also helps brands reach out to the exact people they need to help them.
        Similarly, telling us any languages you speak aside from English can make you eligible for more opportunities.<em> All fields except "Languages" are required.</em>
      </div>
      <div class="form-control-static"></div>
      <div class="row">
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label for="firstName">First name:</label>
          </div>
          <input required type="text" id="firstName" name="firstName" aria-describedby="firstNameError" placeholder="John" ng-model="registrationData.firstName" ng-pattern="name_pattern"
                 ng-minlength="2" ng-maxlength="50" ng-auto-focus="{onlyFocusWhenEmpty: true}" aria-invalid="{{(userForm.$submitted || (userForm.firstName.$touched && userForm.firstName.$dirty)) && !!userForm.firstName.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{userForm.firstName.$valid ? 'done': 'warning'}}</i>
          <span id="firstNameError" class="error-msg" ng-show="!userForm.firstName.$valid">
            {{userForm.firstName.$error.required ? 'First name is required' : (userForm.firstName.$error.pattern ? 'No special characters': 'Between 2 and 50 characters required')}}
          </span>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label for="lastName">Last name:</label>
          </div>
          <input required type="text" id="lastName" name="lastName" aria-describedby="lastNameError" placeholder="Smith" ng-model="registrationData.lastName" ng-pattern="name_pattern"
                ng-minlength="2" ng-maxlength="50" aria-invalid="{{(userForm.$submitted || (userForm.lastName.$touched && userForm.lastName.$dirty)) && !!userForm.lastName.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{userForm.lastName.$valid ? 'done': 'warning'}}</i>
          <span id="lastNameError" class="error-msg" ng-show="!userForm.lastName.$valid">
            {{userForm.lastName.$error.required ? 'Last name is required' : (userForm.lastName.$error.pattern ? 'No special characters': 'Between 2 and 50 characters required')}}
          </span>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div>
            <label for="email">Email address:</label>
          </div>
          <input mail-suggest required id="email" name="email" aria-describedby="emailError" type="email" placeholder="jsmith@example.com"
                 ng-model="registrationData.email" ng-model-options="{ debounce: 500 }" ng-pattern="email_pattern" autocomplete="email" aria-invalid="{{(userForm.$submitted || (userForm.email.$touched && userForm.email.$dirty)) && !!userForm.email.$invalid}}">
          <i aria-hidden="true" class="validation-icon material-icons">{{(userForm.email.$valid || userForm.email.$pending)? 'done': 'warning'}}</i>
          <span id="emailError" class="error-msg" ng-show="!userForm.email.$valid && !userForm.email.$pending ">
            Enter valid email
          </span>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 select2-theme-form">
          <div>
            <label id="birthLabel" for="birthDate">Date of birth:</label>
          </div>
          <div>
            <div id="birthDate" name="birthDate" class="ui-select-box">
              <div class="col-xs-5 col-md-5 col-lg-5 months">
                <select
                  class="signup-form-input-select"
                  ng-model="registrationData.birthDate.month"
                  aria-label="Birth month"
                  ng-required="true"
                  name="birthMonth"
                  id="birthMonth"
                  ng-change="selectMonth()"
                  aria-describedby="birthDateError"
                  aria-invalid="{{(userForm.$submitted || (userForm.birthMonth.$touched && userForm.birthMonth.$dirty)) && !!userForm.birthMonth.$invalid}}"
                >
                  <option disabled selected value>Month</option>
                  <option
                    ng-repeat="month in months"
                    aria-selected="{{registrationData.birthDate.month === month.id.toString()}}"
                    value="{{month.id}}"
                  >
                    {{month.name}}
                  </option>
                </select>
              </div>
              <div class="col-xs-3 col-md-3 col-lg-3 days">
                <select
                  class="signup-form-input-select"
                  ng-model="registrationData.birthDate.day"
                  aria-label="Birth day"
                  ng-required="true"
                  name="birthDay"
                  id="birthDay"
                  ng-change="selectDay()"
                  aria-describedby="birthDateError"
                  aria-invalid="{{(userForm.$submitted || (userForm.birthDay.$touched && userForm.birthDay.$dirty)) && !!userForm.birthDay.$invalid}}"
                >
                  <option disabled selected value>Day</option>
                  <option
                    ng-repeat="day in days"
                    aria-selected="{{registrationData.birthDate.day === day.toString()}}"
                    value="{{day}}"
                  >
                    {{day}}
                  </option>
                </select>
              </div>
              <div class="col-xs-4 col-md-4 col-lg-4 years">
                <select
                  class="signup-form-input-select"
                  ng-model="registrationData.birthDate.year"
                  aria-label="Birth year"
                  ng-required="true"
                  name="birthYear"
                  id="birthYear"
                  ng-change="selectYear()"
                  aria-describedby="birthDateError"
                  aria-invalid="{{(userForm.$submitted || (userForm.birthYear.$touched && userForm.birthYear.$dirty)) && !!userForm.birthYear.$invalid}}"
                >
                  <option disabled selected value>Year</option>
                  <option
                    ng-repeat="year in years"
                    aria-selected="{{registrationData.birthDate.year === year.toString()}}"
                    value="{{year}}"
                  >
                    {{year}}
                  </option>
                </select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                <span id="birthDateError" class="error-msg" ng-show="userForm.birthDay.$invalid || userForm.birthMonth.$invalid || userForm.birthYear.$invalid">
                  Date of birth is required
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 select2-theme-form">
          <div>
            <label for="languages">Languages (spoken):</label> <span class="grey-text hidden-xs">(English is default)</span>
          </div>
          <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                     multiple limit="5" ng-model="registrationData.languages" id="languages" name="languages" on-remove="removeLanguage($item, $model)">
            <ui-select-match placeholder="Add languages (spoken): English is default">{{$item.name}}</ui-select-match>
            <ui-select-choices repeat="language.id as language in languages | filter: $select.search">
              <div ng-bind-html="language.name | highlight: $select.search"></div>
            </ui-select-choices>
          </ui-select>
        </div>
      </div>
    </div>
    <div class="form-group col-xs-12 text-right">
      <button class="btn btn-blue" ng-click="validateBasicInfoStep(userForm);">
        <span>Next: Location</span>
        <i class="material-icons" aria-hidden="true">chevron_right</i>
      </button>
    </div>
  </form>
</div>
`;
