import template from './community-follow-btn.component.html';
import controller from './community-follow-btn.controller';

const communityFollowBtn = {
  bindings: {
    user: '=',
  },
  template,
  controller,
};

export default communityFollowBtn;
