const stateStore = function ($state) {
  return function (item, dontHref) {
    if (!item.slug) {
      item.slug = item.id;
    }

    const typeStateMap = {
      article: {
        state: 'singlearticle',
        params: { articleId: item.slug },
      },
      course: {
        state: 'singlecourse',
        params: { courseId: item.slug },
      },
      tool: {
        state: 'singletool',
        params: { toolId: item.slug },
      },
      project: {
        state: 'singleproject',
        params: { projectId: item.slug },
      },
      topic: {
        state: 'singlediscussionpage',
        params: { page: 1, discussionId: item.slug },
      },
      user: {
        state: 'profile.about',
        params: { profileId: item.slug },
      },
      status: {
        state: 'singlestatus',
        params: { statusId: item.slug },
      },
    };

    if (dontHref) {
      return typeStateMap[item.type];
    }
    return $state.href(typeStateMap[item.type].state, typeStateMap[item.type].params);
  };
};

stateStore.$inject = ['$state'];
export default stateStore;
