import template from './community-user-list-item.component.html';

const communityUserListItem = {
  bindings: {
    user: '=',
    close: '=',
  },
  template,
};

export default communityUserListItem;
