export default `
<div id="mainContent">
  <div class="hall-of-fame" >

    <div class="inner">

      <h1><img src="assets/images/utestLogo-small-white.png" alt="">Hall of Fame</h1>

      <select ng-change="searchYearChange()" ng-model="search.year" ng-options="year for year in searchOptions.years" ></select>

      <select ng-change="searchPeriodChange()" ng-model="search.period" ng-options="period for period in searchOptions.periods" >
      </select>

    </div>

  </div>

  <div class="hall-of-fame-content">

    <div class="grid w890">

      <div ng-repeat="pageData in data">

        <h2>{{pageData.title}}</h2>

        <ul class="animated {{animationClass}}" >
          <li ng-repeat="user in pageData.winners track by $index" >
            <user-card user="user" ></user-card>
          </li>
        </ul>

      </div>

      <loading-dots ng-if="isLoading" ></loading-dots>

    </div>

    <nav>
      <a ng-class="{invisible: next === undefined}" ui-sref="halloffamedate(next)">Next Quarter</a>
      <a ng-class="{invisible: next === undefined, up: true}" ui-sref="halloffamedate(next)"></a>
      <a ng-class="{invisible: previous === undefined, down: true}" ui-sref="halloffamedate(previous)"></a>
      <a ng-class="{invisible: previous === undefined}" ui-sref="halloffamedate(next)">Previous Quarter</a>
    </nav>

  </div>

</div>
`;
