export default `
<div class="load-more-btn">
  <button class="btn loading" ng-click="loadMore()" ng-hide="isLastPage" ng-class="{started: isLoading}" fade-target="footer">
    <span class="static" ng-bind="label"></span>
    <span class="scale l">L</span>
    <span class="scale o">o</span>
    <span class="scale a">a</span>
    <span class="scale d">d</span>
    <span class="scale i">i</span>
    <span class="scale n">n</span>
    <span class="scale g">g</span>
  </button>
  <loading-dots ng-if="isLoading"></loading-dots>
</div>
`;
