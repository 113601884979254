import { Observable, Subject } from 'rxjs';

export function observeProperty<T extends object, K extends keyof T>(obj: T, prop: K): Observable<T[K]> {
  const propertyObserver = getPropertyObserver(obj);

  if (!propertyObserver[prop]) {
    const subject = new Subject<T[K]>();
    onChange(obj, prop, newValue => subject.next(newValue));
    propertyObserver[prop] = subject.asObservable();
  }

  return propertyObserver[prop];
}

/**
 * creates or returns existing property observer for object
 */
function getPropertyObserver<T extends object>(obj: T): { [K in keyof T]?: Observable<T[K]> } {
  const PROPERTY_OBSERVER_KEY = 'applause_observe_property';

  if (!obj[PROPERTY_OBSERVER_KEY]) {
    Object.defineProperty(obj, PROPERTY_OBSERVER_KEY, {enumerable: false, value: {}});
  }

  return obj[PROPERTY_OBSERVER_KEY];
}

function onChange<T extends object, K extends keyof T>(obj: T, prop: K, callback: (value: T[K]) => void) {
  const descriptor = createDescriptor(obj, prop);
  const setter = descriptor.set;

  /**
   * override descriptor setter, by calling original set method and emitting event setter argument
   */
  descriptor.set = function() {
    setter.apply(setter, arguments);
    callback(arguments[0]);
  };

  Object.defineProperty(obj, prop, descriptor);
}

/**
 * create new PropertyDescriptor based on existing one
 */
function createDescriptor<T extends object, K extends keyof T>(obj: T, prop: K): PropertyDescriptor {
  const descriptor = {...Object.getOwnPropertyDescriptor(obj, prop)};

  /**
   * if descriptor has value and value is writable, then replace it with getter and setter, so we can track changes
   */
  if (descriptor.hasOwnProperty('value') && descriptor.writable) {
    let value = descriptor.value;

    descriptor.set = v => value = v;
    descriptor.get = () => value;

    delete descriptor.value;
    delete descriptor.writable;
  }

  return descriptor;
}
