import template from './quiz-result-item.component.html';
import controller from './quiz-result-item.controller';

const quizResultItem = {
  bindings: {
    resultItem: '<',
    courseTrack: '<',
    questions: '<',
  },
  template,
  controller,
};

export default quizResultItem;
