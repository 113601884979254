import _ from 'lodash';

class ProjectFeedItemCtrl {
  constructor($rootScope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
    };

    ctrl.setFeed = () => {
      if (ctrl.feed) {
        if (_.isUndefined($rootScope.navigatePrevNext)) {
          $rootScope.navigatePrevNext = {};
        }
        $rootScope.navigatePrevNext.project = {
          feed: ctrl.feed,
        };
      }
    };
  }
}

ProjectFeedItemCtrl.$inject = ['$rootScope'];

export default ProjectFeedItemCtrl;
