export default `
  <div class="course-track-course" ng-class="{'current-course': $ctrl.navCourse.id === $ctrl.selectedCourse.id}">
    <a class="course-track-link" ui-sref="track-course({trackId: $ctrl.courseTrack.id, courseId: $ctrl.navCourse.slug})">
      <span style="width: 26px;">
        <i class="material-icons incorrect" ng-if="$ctrl.courseFailed && !$ctrl.navCourse.viewed_by_current_user && $ctrl.navCourse.id !== $ctrl.selectedCourse.id" aria-label="Revisit this course">
          highlight_off
        </i>
      </span>
      <span class="course-track-title">{{$ctrl.navCourse.title}}</span>
      <i class="material-icons" ng-class="{completed: $ctrl.navCourse.viewed_by_current_user || $ctrl.navCourse.id === $ctrl.selectedCourse.id}" aria-label="{{$ctrl.navCourse.viewed_by_current_user || $ctrl.navCourse.id === $ctrl.selectedCourse.id ? 'Completed Course' : 'Incomplete Course'}}">
        {{$ctrl.navCourse.viewed_by_current_user || $ctrl.navCourse.id === $ctrl.selectedCourse.id ? 'check_circle_outline' : 'panorama_fish_eye'}}
      </i>
      <div aria-label="{{$ctrl.navCourse.id === $ctrl.selectedCourse.id ? 'Current Course' : ''}}">
      </div>
    </a>
  </div>
`;
