import template from './custom-dropdown.component.html';
import controller from './custom-dropdown.controller';

const customDropdown = {
  bindings: {
    options: '<',
    model: '=',
    onChange: '=',
  },
  template,
  controller,
};

export default customDropdown;
