/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, Optional, SkipSelf, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { OptNode } from '../opt-node';
import { OptionHoverService } from '../option-hover.service';
import { OptionSelectionService } from '../selection/option-selection.service';
import { SelectionEvent } from '../selection/selection-event';
import { Option } from './option';

@Component({
  selector: 'ngf-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {provide: OptNode, useExisting: OptionComponent}
  ]
})
export class OptionComponent extends Option implements OnInit {

  @Input()
  value: any;
  @Input()
  disabled = false;

  @ViewChild('content', {static: true})
  content: ElementRef<HTMLElement>;

  selected$: Observable<boolean>;
  hovered$: Observable<boolean>;

  constructor(private service: OptionSelectionService,
              private hoverService: OptionHoverService,
              @SkipSelf() @Optional() public parent: OptNode,
              public elementRef: ElementRef<HTMLElement>) {
    super();
  }

  ngOnInit(): void {
    this.selected$ = this.service.isSelected$(this);
    this.hovered$ = this.hoverService.isHovered$(this);
  }

  get textContent(): string {
    return this.content.nativeElement.textContent.trim();
  }

  @HostListener('click')
  handleClick() {
    if (this.disabled) {
      return;
    }
    const event: SelectionEvent = {value: this.value, userInput: true};
    this.service.select(event);
  }

  @HostListener('mousedown', ['$event'])
  preventFocusLose(event: MouseEvent) {
    event.preventDefault();
  }

  @HostListener('mouseenter')
  handleMouseEnter() {
    this.hoverService.hover(this);
  }

}
