/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

class SocialSharingCtrl {
  constructor($scope, featureFlags, $element, $timeout, UserService, LightTesterErrorService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.isPopoverOpen = false;
      ctrl.text += ' - via @uTest';

      UserService.me().then((res) => {
        ctrl.isLightTester = res?.data?.is_light_tester;
      });
    };

    const getAllChildComponents = targetElem => targetElem.getElementsByTagName('*');

    const outsideElemClickListener = (event) => {
      if (event.isTrusted && $element[0] !== event.target && ![].slice.call(getAllChildComponents($element[0])).includes(event.target)) {
        // click outside the panel
        ctrl.isPopoverOpen = false;
      }
    };

    const closeOnEsc = (event) => {
      if (event.key === 'Escape') {
        ctrl.isPopoverOpen = false;
      }
    };

    $scope.$watch(() => ctrl.isPopoverOpen, (newVal) => {
      if (newVal) {
        // handle outside click and setup focus with lock
        document.addEventListener('click', outsideElemClickListener);
        $element.attr('outside-event-attached', true);
        $timeout(() => {
          const icons = $element[0].getElementsByTagName('i');
          const firstIcon = icons[0];
          const lastIcon = icons.length > 0 ? icons[icons.length - 1] : undefined;
          $element[0].addEventListener('keydown', closeOnEsc);
          if (firstIcon !== undefined) {
            firstIcon.focus();
            firstIcon.onblur = () => lastIcon.focus();
            lastIcon.onblur = () => firstIcon.focus();
            for (let i = 0; i < icons.length; i++) {
              icons[i].onkeydown = (event) => {
                if (event.code === 'Enter' || event.code === 'Space') {
                  event.target.click();
                  event.preventDefault();
                }
              };
            }
          }
        }, 200);
      } else {
        document.removeEventListener('click', outsideElemClickListener);
        if ($element.attr('outside-event-attached')) {
          $element.attr('outside-event-attached', false);
          $timeout(() => $element.find('button')[0].focus(), 600);
        }
      }
    });

    $scope.checkLightTester = (e) => {
      if (LightTesterErrorService.show()) {
        e.preventDefault();
      }
    };
  }
}

SocialSharingCtrl.$inject = ['$scope', 'featureFlags', '$element', '$timeout', 'UserService', 'LightTesterErrorService'];

export default SocialSharingCtrl;
