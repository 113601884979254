import CommunityRegistrationService from '../community-registration/community-registration.service';
import communityRegistrationTemplate from '../layouts/community-registration.html';
import CommunityRegistrationCtrl from '../community-registration/community-registration.controller';

const CommunityRegistrationsRouter = {
  route: (module) => {
    module
      .controller('CommunityRegistrationCtrl', CommunityRegistrationCtrl)
      .service('CommunityRegistrationService', CommunityRegistrationService)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('communityregistration', {
            url: '/community_registrations/:communityRegistrationId',
            template: communityRegistrationTemplate,
            controller: 'CommunityRegistrationCtrl',
            resolve: {
              registration: ['$transition$', 'CommunityRegistrationService', '$state', function ($transition$, CommunityRegistrationService, $state) {
                const registrationPromise = CommunityRegistrationService.find($transition$.params().communityRegistrationId);
                registrationPromise.catch(() => $state.go('fourohfour'));
                return registrationPromise.then(res => res.data);
              }],
              title: ['registration', registration => `uTest Community Registration - ${registration.display_name}`],
            },
          });
      }]);
  },
};

export default CommunityRegistrationsRouter;
