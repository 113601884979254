import communityFeedItemHideOverlay from './community-feed-item-hide-overlay.component.html';

class CommunityFeedItemHideCtrl {
  constructor(AlertService, $uibModal, ArticleService, StatusService, DiscussionService, CommentService) {
    const ctrl: ng.IController = this;

    ctrl.successCallback = (res) => {
      ctrl.item.state = res.data.state;
      if (res.data.state === 'hidden') {
        AlertService.add('Successfully unpublished');
      } else {
        AlertService.add('Successfully republished');
      }
    };

    ctrl.errorCallback = () => {
      AlertService.add('There was an error', 'danger');
    };

    ctrl.hideItem = () => {
      switch (ctrl.item.type) {
        case 'article':
          ArticleService.toggleHide(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
          break;
        case 'status':
          StatusService.toggleHide(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
          break;
        case 'comment':
          CommentService.toggleHide(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
          break;
        case 'topic':
          DiscussionService.toggleHide(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
          break;
        default:
      }
    };

    ctrl.hidePopover = () => {
      ctrl.hideModal = $uibModal.open({
        animation: true,
        template: communityFeedItemHideOverlay,
        controller: 'ModalCtrl',
        windowClass: 'community-feed-item-hide-modal',
        resolve: {
          data() {
            return {
              item: ctrl.item,
              hide: ctrl.hideItem,
              closeOverlay() {
                ctrl.hideModal.close();
              },
            };
          },
        },
      }).result.catch((resp) => {
        if (['cancel', 'backdrop click', 'escape key press'].indexOf(resp) === -1) throw resp;
      });
    };
  }
}

CommunityFeedItemHideCtrl.$inject = ['AlertService', '$uibModal', 'ArticleService', 'StatusService', 'DiscussionService', 'CommentService'];

export default CommunityFeedItemHideCtrl;
