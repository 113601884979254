class UrlShortenerCtrl {
  constructor($scope, $state, AlertService, UrlService, user) {
    if (user?.user_type !== 'admin') {
      $state.go('fourohone', null, { location: false });
    }

    $scope.shorten = {
      url: '',
      alias: '',
    };

    $scope.shortBase = 'https://link.utest.com/';

    $scope.doShortening = function () {
      if (!$scope.shorten.url || !$scope.shorten.url.match(/^(https?:\/\/)?(www\.)?utest.com\//)) {
        AlertService.addDanger('Please use a valid uTest URL');
      } else if ($scope.shorten.alias && !$scope.shorten.alias.match(/^[a-zA-Z0-9-_]{0,50}$/)) {
        AlertService.addDanger('Please use a valid alias shorter than 50 and without any special characters');
      } else {
        UrlService.shorten($scope.shorten.url, $scope.shorten.alias).then(({ url }) => {
          $scope.success = {
            original: $scope.shorten.url,
            short: url,
          };
          $scope.shorten = {
            url: '',
            alias: '',
          };
        }, ({ error }) => {
          AlertService.addDanger(error);
        });
      }
    };
  }
}

UrlShortenerCtrl.$inject = ['$scope', '$state', 'AlertService', 'UrlService', 'user'];

export default UrlShortenerCtrl;
