import { StateService } from '@uirouter/core';

class CommunityCtrl {
  constructor(featureFlags, $state:StateService, UserService, HomeFeedService, ConfigService, LightTesterErrorService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      if (!ctrl.user.has_viewed_community_page) {
        UserService.setViewedCommunityPage();
      }

      HomeFeedService.trending().then((res) => {
        ctrl.trending = res.data;
      });

      HomeFeedService.pinned().then((res) => {
        ctrl.featured = res.data;
      });

      ctrl.statusPosted = (status) => {
        $scope.$broadcast('statusPosted', status);
      };

      ConfigService.config().then((res) => {
        ctrl.testerAppUrl = `${res.data.profile_url}/account/my-profile/`;
      });

      ctrl.checkLightTester = (e) => {
        if (LightTesterErrorService.show()) {
          e.preventDefault();
        }
      };
    };
    $scope.$on('feedItemLiked', (event, likeable) => {
      $scope.$broadcast('feedItemLikedUpdateTrending', likeable);
    });
    $scope.$on('feedItemCommented', (event, commentable) => {
      $scope.$broadcast('feedItemCommentedUpdateTrending', commentable);
    });
  }
}

CommunityCtrl.$inject = ['featureFlags', '$state', 'UserService', 'HomeFeedService', 'ConfigService', 'LightTesterErrorService', '$scope'];

export default CommunityCtrl;
