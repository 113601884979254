import _ from 'lodash';

class FlaggedContentCtrl {
  constructor($scope, $state, AdminService, FeedStore, user) {
    if (user.user_type !== 'moderator' && user.user_type !== 'admin') {
      $state.go('fourohone', null, { location: false });
    }

    $scope.store = new FeedStore(_.bind(AdminService.flaggedContent, AdminService));
    $scope.store.load();

    $scope.removeContent = function (item) {
      _.remove($scope.store.feedItems, { id: item.id });
    };
  }
}

FlaggedContentCtrl.$inject = ['$scope', '$state', 'AdminService', 'FeedStore', 'user'];

export default FlaggedContentCtrl;
