import template from './nav-sidebar-dropdown-toggle.component.html';

const navSidebarDropdownToggle = {
  bindings: {
    onClick: '&',
    expanded: '<',
    name: '@',
  },
  template,
  transclude: true,
};

export default navSidebarDropdownToggle;
