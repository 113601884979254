class NtuxLoadMoreCtrl {
  constructor() {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.loadMore = () => {
        ctrl.loadCallback();
      };
    };
  }
}

export default NtuxLoadMoreCtrl;
