import template from './dashboard-activity-project-list-item.component.html';
import controller from './dashboard-activity-project-list-item.controller';

const dashboardActivityListItem = {
  bindings: {
    project: '<',
    itemType: '@',
    testerAppUrl: '@',
  },
  template,
  controller,
};

export default dashboardActivityListItem;
