import controller from './academy-course.controller';
import template from './academy-course.component.html';

const academyCourse = {
  bindings: {
    course: '<',
    courseTrack: '<',
    user: '<',
  },
  template,
  controller,
};

export default academyCourse;
