<ng-template cdkConnectedOverlay
             cdkConnectedOverlayLockPosition
             [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
             [cdkConnectedOverlayOrigin]="origin"
             [cdkConnectedOverlayOpen]="isOpen"
             [cdkConnectedOverlayPositions]="positions"
             [cdkConnectedOverlayMinWidth]="triggerRect?.width"
             (detach)="close()">
  <ng-content></ng-content>
</ng-template>
