export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w890">
      <div class="section-home-cover-random">
        <h3>uTest Überblick und häufig gestellte Fragen</h3>
        <!--<p><a href="#Was ist uTest?">Was ist uTest?</a> | <a href="#Bezahlung">Bezahlung</a> | <a href="#Sandbox">Sandbox</a> | <a href="#Bug reports">Bug reports</a> | <a href="#Was gibt es auf uTest?">Was gibt es auf uTest?</a> | <a href="#Profilupdates">Profilupdates</a> | <a href="#deutsches uTest">deutsches uTest</a> | <a href="#Trivia">uTest Trivia</a></p>-->
      </div>
    </div>
  </div>
  <div class="grid w890">
    <div class="c12">
      <h2 id "Was ist uTest?">Was ist uTest?</h2>
    </div>
    <div class="c12">
      <h3>Was macht man hier als Tester?</h3>
      <p>Als App-Tester testest du regelmäßig die neuesten Webseiten, neue Software und neue Apps. Nachdem du dich bei uns angemeldet hast, bekommst du Einladungen zu den unterschiedlichsten Tests. Diese finden direkt bei uns auf der Plattform statt, hier reichts du die Bugs ein, die du gefunden hast, hier beantwortest du Umfragen und prüfst eine App auf Herz und Nieren. Du hilfst also dabei, dass Unternehmen qualitativ hochwertige Apps gestalten. Das beste daran ist: Du testest von zu Hause aus, an deinem eigenen PC, mit deinem eigenen Telefon oder Tablet, TV, Konsole, Smartwatch oder was auch immer der Kunde gerne testen möchte. <br>
        Da du mit uns kein Beschäftigungsverhältnis eingehst, kannst du auch neben deinem normalen Beruf testen.</p>
    </div>
    <div class="c12">
      <h3>Alles rund um die Mitgliedschaft</h3>
      <p>Jeder, der mindestens 18 Jahre alt ist, darf sich bei uns als Tester registrieren und Teil unserer Community werden. Tester aus jeglichen Arbeitsverhältnisses sind bei uns willkommen. Jeder Test ist unterschiedlich und um für unsere Kunden bestmögliche Testergebnisse garantieren zu können, benötigen wir vielseitige Tester mit den unterschiedlichsten Fähigkeiten. Es ist keine Voraussetzung, dass du vorher bereits Erfahrungen im Testbereich hattest. <br>
        <br>
        Die Mitgliedschaft ist natürlich komplett kostenlos - du sollst bei uns Geld verdienen! </p>
    </div>
    <div class="c12">
      <h3> Wo kann ich mich als Tester anmelden? </h3>
      <p>Folge diesem Link. Er führt dich direkt zu unserer Registrierung. Hast du alles ausgefüllt, bist du als Tester zugelassen und kannst zu Projekten eingeladen werden. <a title="Anmeldung" href="https://www.utest.com/signup" target="_blank">Anmeldung</a></p>
    </div>

    <!-- Bezahlung -->

    <div class="c12">
      <h2 id="Bezahlung">Bezahlung</h2>
    </div>
          <div class="c12">
      <h3>Mit wie viel Geld kann ich rechnen?</h3>
      <p>Die Frage, die am häufigsten gestellt wird, und trotzdem kann sie so pauschal nicht beantwortet werden. Die Gründe dafür sind divers, bitte lies den folgenden Abschnitt für ein besseres Verständnis zu diesem Thema.</p>
    </div>
    <div class="c12">
      <h3>Wofür werde ich bezahlt?</h3>
      <p>Stell es dir so vor: Auf uTest gibt es für Tester die Möglichkeit, verschiedene Testarten durchzuführen. Diese umfassen funktionales Testen, Nutzerfreundlichkeit, Sicherheit, Belastung und Übersetzung. Letztlich entscheidest du selbst, welche Testart dir am besten liegt. Es steht dir natürlich frei, auch mehrere Testarten auszuführen, wobei Sicherheit und Belastung durchaus einiges an Wissen über Testmechaniken voraussetzen.
Dementsprechend gliedern sich auch die Verdienstmöglichkeiten. Bei Usability Tests bekommt man in der Regel für die reine Durchführung und Beantwortung einer Umfrage einen Betrag ausgezahlt. Bei funktionalen Tests bezieht sich die Bezahlung pro Fehler, den ein Tester gefunden hat. Hier pauschal zu sagen, welchen zeitlichen Aufwand du für Summe X benötigst, ist dementsprechend schwer. Es ist bei jedem Test Cycle unterschiedlich, wie viel Geld man bekommen kann. Je höher du im Rating steigst, desto besser wird auch die Bezahlung. Du kannst so viel testen, wie du willst! Das Thema Rating sprechen wir später noch einmal an.
</p>
    </div>
    <div class="c12">
      <h3>Wann werde ich bezahlt?</h3>
      <p>Die Antwort ist einfach: alle 2 Wochen. Immer zur Mitte (15.) und zum Ende eines Monats (30.) erhältst du die Bezahlung. Eine Verschiebung tritt ein, wenn der 15. oder 30. (31.) eines Monats auf einen Feiertag oder ein Wochenende fallen. Die Auszahlung wird dann am nächstmöglichen Werktag ausgeführt. Hinweis: Die Auszahlung wird aus Amerika durchgeführt. Für deutschsprachige Tester heißt dies, dass die Auszahlung erst am Abend verfügbar sein wird.</p>
    </div>
    <div class="c12">
      <h3>Über welche Methode werde ich bezahlt?</h3>
      <p>uTest bietet 2 Methoden der Auszahlung an: PayPal und Payoneer. Payoneer ist hier nicht sehr bekannt, weshalb 99,9% der deutschsprachigen Tester PayPal bevorzugen.</p>
    </div>
    <div class="c12">
      <h3>Und wie funktioniert das jetzt genau?</h3>
      <p>Du loggst dich bei <a title="Anmeldung" href="https://platform.utest.com/" target="_blank">uTest</a> ein, klickst danach rechts oben auf deinen Namen und wählst "Payments" aus. Scrolle nun nach unten und lege deine Payment Method fest. Du trägst hier einfach deine PayPal E-Mail-Adresse ein und wir überweisen deine Auszahlung auf dein PayPal Konto. Bitte stelle sicher, dass du auch über PayPal Geld empfangen kannst.</p>
    </div>
    <div class="c12">
      <h3>Wie sieht das steuerrechtlich aus?</h3>
      <p>Das Geld, was ihr hier verdient, ist unversteuert. In Deutschland gibt es einen gewissen Steuerfreibetrag. Da dieser jedoch variieren kann, können wir hier nur auf Steuerberater verweisen, die genauere Auskunft geben können. Wenn du hauptberuflich selbstständig bist, melde dich bitte direkt bei Nico Grieger (<a href="mailto:ngrieger@utest.com?Subject=Steuerfrage%-%Selbstständigkeit" target="_top">ngrieger@utest.com</a>)</p>
    </div>


  </div>
</div>
`;
