export default `
<footer class="unauthenticated-footer" role="contentinfo">
  <div class="unauthenticated-footer__ctas container-fluid">
    <div class="col-md-6">
      <div class="unauthenticated-footer__logo col-md-3">
        <img alt="Utest Logo Icon" src="assets/images/utestLogoIconBlue.svg">
      </div>
      <div class="col-md-9">
        <div class="unauthenticated-footer__cta-heading">Join uTest, a Community Like No Other!</div>
        <p class="unauthenticated-footer__cta-subheading">Learn, earn and connect with a global community of software testers.</p>
        <a class="unauthenticated-footer__cta-link" ui-sref="signup.personal">Sign Me Up!</a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="unauthenticated-footer__social">
        <a href="https://twitter.com/utest" target="_blank" title="Follow on X">
          <img src="assets/images/social/twitter-x.svg" alt="X">
        </a>
        <a href="https://www.facebook.com/utest" target="_blank" title="Follow on Facebook">
          <img src="assets/images/social/facebook.svg" alt="Facebook">
        </a>
        <a href="https://www.instagram.com/utestinc/" target="_blank" title="Follow on Instagram">
          <img src="assets/images/social/instagram.svg" alt="Instagram">
        </a>
        <a href="https://www.linkedin.com/company/utest" target="_blank" title="Follow on LinkedIn">
          <img src="assets/images/social/linkedin.svg" alt="LinkedIn">
        </a>
      </div>
      <div class="unauthenticated-footer__apps">
        <div class="unauthenticated-footer__apps-text col-sm-6">Bring uTest wherever you go with the mobile app.</div>
        <div class="unauthenticated-footer__app-icons col-md-6">
          <a href="https://apps.apple.com/tt/app/utest/id1492960703?ign-mpt=uo%3D2" target="_blank" aria-label="iOS App, download on the app store">
            <img src="assets/images/download-on-the-app-store-badge.svg" alt="App Store Badge">
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.applause.utestqa&hl=en_US" target="_blank" aria-label="Android App, get it on google play" ng-hide="$ctrl.isIOS">
            <img src="assets/images/google-play-store-badge.svg" alt="Play Store Badge">
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="unauthenticated-footer__nav container-fluid">
    <div class="unauthenticated-footer__nav-items col-md-6">
      <ul>
        <li class="unauthenticated-footer__nav-item">
          <a href="/utest-guidelines">uTest Guidelines</a>
        </li>
        <li class="unauthenticated-footer__nav-item">
          <a href="/privacy-policy">Privacy Policy</a>
        </li>
        <li class="unauthenticated-footer__nav-item">
          <a href="/terms-and-conditions">Terms & Conditions</a>
        </li>
        <li class="unauthenticated-footer__nav-item--onetrust">
          <!-- OneTrust Cookies Settings button start -->
          <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
          <!-- OneTrust Cookies Settings button end -->
        </li>
        <li class="unauthenticated-footer__nav-item">
          <a href="/phishing-notice">Phishing Notice</a>
        </li>
        <li class="unauthenticated-footer__nav-item">
          <a href="https://support.utest.com/csp" target="_blank">Help & Support</a>
        </li>
      </ul>
    </div>
    <div class="unauthenticated-footer__copyright col-md-6">
      © {{$ctrl.year}} Applause App Quality, Inc.
    </div>
  </div>
</footer>
`;
