class DocsService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  find(id: number) {
    return this.$http.get(`/api/v1/docs/${id}`);
  }
}

DocsService.$inject = ['$http'];

export default DocsService;
