import template from './community-trending.component.html';
import controller from './community-trending.controller';

const communityTrending = {
  bindings: {
    feed: '<',
  },
  template,
  controller,
};

export default communityTrending;
