export default `
<div id="mainContent" class="footer-wrapper">
  <div class="section-home-cover">
    <div class="section-home-cover-bg">
      <div bg-img="{{coverBg}}" swap="assets/images/hero/articles-bg.jpg" ></div>
    </div>
    <div class="container block">
      <h3 class="section-title">URL Shortener</h3>
      <p class="section-desc text-center">
        Paste your uTest.com link below to create a shortened URL that you can share.
      </p>
      <form ng-submit="doShortening()" class="form-hero" >
        <div class="row">
          <div class="col-sm-8 col-sm-offset-2">
            <label for="url">Destination URL</label>
            <div class="input-group">
              <input ng-model="shorten.url" type="text" placeholder="https://www.utest.com/..." id="url" >
            </div>
            <label for="basic-url">Custom alias (optional)</label>
            <div class="input-group">
              <span class="input-group-addon" id="custom-alias" ng-bind="shortBase"></span>
              <input ng-model="shorten.alias" type="text" class="form-control" id="basic-url" aria-describedby="custom-alias">
            </div>
          </div>
        </div>
        <div class="text-center">
          <button type="submit" class="btn btn-filled btn-yellow" >
            Shorten URL
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="container block">

    <div ng-if="success" class="shortening-success">
      <h2>New URL successfully created!</h2>
      <br>
      <div class="input-group">
        <input ng-model="success.short" disabled type="text" class="form-control" id="short-done" aria-describedby="short-done">
        <span title="Copy to clipboard" class="input-group-addon" id="short-done" clipboard data-clipboard-text="{{'http://' + success.short}}"><i class="icon-clipboard"></i>Copy Link</span>
        <a class="input-group-addon" title="Open link in new tab" ng-href="{{'http://' + success.short}}" target="_blank" ><i class="icon-link"></i></a>
      </div>
    </div>

  </div>
</div>
`;
