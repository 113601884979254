import Bowser from 'bowser';

const DeviceDetector = function ($window) {
  function getFirstBrowserLanguage() {
    const { navigator } = $window;
    const browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage'];

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(navigator.languages)) {
      for (let i = 0; i < navigator.languages.length; i++) {
        const language = navigator.languages[i];
        if (language && language.length) {
          return language;
        }
      }
    }

    // support for other well known properties in browsers
    for (let i = 0; i < browserLanguagePropertyKeys.length; i++) {
      const language = navigator[browserLanguagePropertyKeys[i]];
      if (language && language.length) {
        return language;
      }
    }

    return null;
  }

  const result: any = Bowser.parse(navigator.userAgent);
  result.language = getFirstBrowserLanguage();

  return result;
};

DeviceDetector.$inject = ['$window'];
export default DeviceDetector;
