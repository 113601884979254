export default `
<div class="text-right clearfix">
  <h2 class="pull-left">Emoji</h2>
  <span class="emoji-tone-picker" ng-class="{active: tone == activeTone, clickable: activeCategory == 'activity' || activeCategory == 'people'}" ng-repeat="tone in tones" ng-click="setActiveTone(tone)" ng-bind-html="emojione.shortnameToImage(':' + tone + ':')"></span>
</div>
<ul class="nav nav-tabs">
  <li ng-repeat="(category, whatever) in emojiByCategory" ng-class="{active: category === activeCategory}" >
    <a ui-sref="emoji.category({category: category})" ng-bind="category | capitalize" ></a>
  </li>
</ul>
<div class="tab-content mt4">
  <div class="clearfix">
    <ul class="emoji-list">
      <li ng-repeat="emo in emojiByCategory[activeCategory]" ng-show="toneCheck(emo)" >
        <span ng-bind-html="shortnameToImage(emo.shortname)" ></span>
        <code clipboard data-clipboard-text="{{emo.shortname}}" ng-bind="emo.shortname" ></code>
      </li>
    </ul>
  </div>
</div>
`;
