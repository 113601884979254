export default `
<div id="mainContent" >
  <community-nav ng-if="currentUser"></community-nav>
  <div class="discussion-category-page-cover" ng-style="{background: category.color}" >
    <div class="grid w890">
      <div class="discussion-category-page-cover-search" >
        <ui-select role="combobox" aria-expanded="{{$select.open}}"
                   class="select2-theme-form" ng-model="filterCategory.id">
          <ui-select-match>{{$select.selected.fullName}}</ui-select-match>
          <ui-select-choices minimum-input-length="-1" repeat="category.id as category in categories | filter: $select.search">
          <div ng-bind-html="category.fullName | highlight: $select.search"></div>
          </ui-select-choices>
        </ui-select>
      </div>
      <div class="discussion-category-page-cover-action">
        <a ng-if="currentUser" ui-sref="newDiscussion" id="coverBtn" ng-click="checkLightTester($event)" class="btn btn-rounded small btn-filled">Create a New Topic</a>
      </div>
      <p class="clearfix" >{{category.description}}</p>
    </div>
  </div>
  <div class="grid w890">
    <uib-tabset>
      <uib-tab heading="Topics" >
        <feed-item class="compressed" is-sticky="discussion.sticky" item='discussion' ng-repeat='discussion in discussions.feedItems'></feed-item>
        <load-more load-callback="discussions.loadMore()" is-loading="discussions.isLoading" is-last-page="discussions.isLastPage" fade-target="footer"></load-more>
      </uib-tab>
    </uib-tabset>
  </div>
  <section class="overlay overlay-scale">
    <h2 class="center">Categories</h2>
    <article ng-repeat="category in categories" class="discussion-cate">
      <span discussion-category="{{category.type}}">{{category.type}}:</span> {{category.name}}
      <p class="overlay-small-type">{{category.description}}</p>
    </article>
  </section>
</div>
`;
