export default `
<div id="mainContent">
  <div class="container block">
    <h2>Earning uPoints &amp; Badges</h2>
    <p>Collect badges and earn uPoints to boost the visibility of your profile to your peers. The more you participate (reading, rating, writing and sharing content), the more uPoints you’ll earn. <a ui-sref="leaderboard">View the Leaderboard</a></p>
    <div class="gamification clearfix">
      <div class="feed gamification " ng-repeat='badge in badges | filter: gamificationFilter'>
        <div class="gamification-icon">
          <span class="profile-thumb large" bg-img="{{badge.icon}}">
            <span  ng-if="checkDone(badge.id)" title="Done" class="avatar-badges-wrapper" >
              <span class="avatar-badge avatar-badge-check"><i class="icon-check"></i></span>
            </span>
          </span>
        </div>
        <div class='gamification-content'>
          <h5>{{badge.name}}</h5>
          <p>{{badge.description}}</p>
        </div>
        <div class="gamification-points">{{badge.points}} pts</div>
      </div>
    </div>
  </div>
</div>
`;
