import angular from 'angular';

class CommunityRegistrationCtrl {
  constructor($scope, $timeout, registration, $window) {
    $scope.registration = registration;
    $scope.$watch(
      '$viewContentLoaded',
      () => {
        $timeout(() => {
          $('#side_banner').css({ height: $('#com-reg-form-area').height() });
        }, 100);
      },
    );

    angular.element($window).on('resize', () => {
      $('#side_banner').css({ height: $('#com-reg-form-area').height() });
    });
  }
}

CommunityRegistrationCtrl.$inject = ['$scope', '$timeout', 'registration', '$window'];

export default CommunityRegistrationCtrl;
