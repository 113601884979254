import template from './activity-list-item.component.html';
import controller from './activity-list-item.controller';

const activityListItem = {
  bindings: {
    activity: '<',
    currentUser: '<',
  },
  template,
  controller,
};

export default activityListItem;
