import controller from './community-feed-status-update.controller';
import template from './community-feed-status-update.component.html';

const communityFeedStatusUpdate = {
  bindings: {
    item: '<',
    content: '<',
    user: '<',
    editing: '=',
  },
  template,
  controller,
};

export default communityFeedStatusUpdate;
