<div id="mainContent">
  <div id="top" class="section-home-unauth-cover">
    <div class="section-home-unauth-cover-bg">
      <div [ngStyle]="{'background-image': 'url(' + bgUrl + ')'}" class="unauthenticated-project-bg"></div>
    </div>
    <div class="block container about-us-main-panel">
      <div class="row">
        <div class="col-sm-3 hidden-xs"></div>
        <div class="col-sm-6 col-xs-12 horizontal-center padding-sides-40">
          <h1 class="section-big-title-unauth">About Us</h1>
          <p class="section-desc-unauth">uTest is home to a growing family of freelance software testers who want to develop new skills, earn extra money, and make a real impact on the digital products of their favorite brands.</p>
        </div>
        <div class="col-sm-3 hidden-xs"></div>
      </div>
    </div>
  </div>

  <div id="mission" class="block container unauth-section-margin">
    <div class="row">
      <div class="col-sm-6 col-xs-12 padding-bottom-20">
        <div class="overflow-y-visible">
          <img class="mission-image" src="{{ images.mission }}" alt="">
          <h2>Our Mission</h2>
          <div>Our community is the heart and soul of who we are. At uTest, our mission is to provide a safe, inclusive environment that allows members to “Learn, earn and connect.”</div>
        </div>
      </div>
      <div class="col-sm-6 col-xs-12">
        <div *ngFor="let row of learnEarnConnectContent">
          <div class="bold">{{ '- ' + row.type }}</div>
          <h3 class="margin-top-1">{{ row.header }}</h3>
          <p>{{ row.txt }}</p>
        </div>
      </div>
    </div>
  </div>

  <div id="stats" class="block container unauth-section-margin unauth-shadow-box higher-z-index">
    <div class="row horizontal-center bold">
      <div class="margin-bottom-8">Quick Stats</div>
    </div>
    <div class="row" class="padding-sides-20-px">
      <div class="col-sm-3 col-xs-10 statistics" *ngFor="let stat of stats">
        <h3 class="margin-bottom-top-0">{{stat.stat}}</h3>
        <p>{{stat.desc}}</p>
      </div>
    </div>
  </div>

  <div id="timeline" class="overflow-y-visible low-z-index">
    <div class="smoke-panel">
      <div class="history-container">
        <ut-content-slider class="unauth-section-history" header="Our History" text="Today, the uTest community is over 1 million strong, with testers in virtually every country in the world. Take a look at how we have grown since starting our journey in 2007.">
          <ul>
            <li *ngFor="let info of history; let index=index;let odd=odd;let even=even;">
              <div class="info-container">
                <h2 *ngIf="even">{{info.title}}</h2>
                <p *ngIf="even">{{info.paragraph}}</p>
              </div>
              <span class="year">{{info.year}}</span>
              <span class="line"></span>
              <div class="info-container">
                <h2 *ngIf="odd">{{info.title}}</h2>
                <p *ngIf="odd">{{info.paragraph}}</p>
              </div>
            </li>
          </ul>
        </ut-content-slider>
      </div>
    </div>
  </div>

  <ut-content-slider id="ourTeam" class="unauth-section-margin" header="Our Team" text="Meet the uTest Community Management team. Our goal is to help connect our community members to testing opportunities, resources, and with one another.">
    <a *ngFor="let emp of employees" href="/{{ emp.static_page_slug }} " class="link-without-decorations">
      <img class="employee-image" src="{{ emp.avatar_image_url }}" alt="{{ emp.name }}">
      <div class="employee-image-header">{{ emp.name }}</div>
      <div>{{ emp.title }}</div>
    </a>
  </ut-content-slider>

  <div id="faq" class="section-home-unauth-cover unauth-section-margin">
    <div class="section-home-cover-bg">
      <div class="section-bottom-bg unauthenticated-project-bg"></div>
    </div>
    <div class="block container padding-60px horizontal-center">
      <div class="col-sm-1 hidden-xs"></div>
      <div class="col-sm-10 col-xs-12 central">
        <h2>Frequently Asked Questions</h2>
        <ut-accordion>
          <ut-accordion-group *ngFor="let qa of faq" [header]="qa.question" class="row">
            <div [innerHTML]="qa.answer"></div>
          </ut-accordion-group>
        </ut-accordion>
      </div>
      <div class="col-sm-1 hidden-xs"></div>
    </div>
  </div>
</div>
