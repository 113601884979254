export default `
<div class="alert-service">
  <uib-alert dismiss-on-timeout="{{10000}}" ng-repeat="alert in $ctrl.alerts | orderBy:'id':true" close="alert.close()"
         type="{{alert.type}}" ng-if="alert.autoClose" role="alert" aria-live="assertive">{{alert.message}}
  </uib-alert>
  <uib-alert ng-repeat="alert in $ctrl.alerts | orderBy:'id':true" close="alert.close()"
         type="{{alert.type}}" ng-if="!alert.autoClose" role="alert" aria-live="assertive">
    <div ng-bind-html="$ctrl.html(alert.message)"></div>
  </uib-alert>
</div>
`;
