export default `
<span class="flag-this clickable">
  <span title="Report" class="icon-flag" ng-class="{'active': flagable.flagged_by_current_user}" ng-click="!flagable.flagged_by_current_user && toggleSlideUp(flagContainerId)"></span>
  <section class="slide-up slide-up-move flag-form {{flagable.type}}" id="{{flagContainerId}}">
    <h4>Report Abuse</h4>
    <form class="flag-form" ng-submit="flagIt()">
      <div>If you wish to report the {{flagable.type}} for abuse, fill out the reason below and submit:</div>
      <textarea name="flag-reason" ng-model="flagreason.content"></textarea>
      <div>
        <input type="submit" value="Report Abuse" class="btn btn-blue">
      </div>
    </form>
  </section>

</span>
`;
