import _ from 'lodash';

const groupBy = function () {
  const memoKey = function (dataArray, groupProperty) {
    return groupProperty + _(dataArray).map('id').value().join(',');
  };

  return _.memoize((dataArray, groupProperty) => _.groupBy(dataArray, groupProperty), memoKey);
};

export default groupBy;
