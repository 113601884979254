export default `
<div>
  <form name="forms.userForm" class="user-form" ng-class="{'invalid': (!forms.userForm.$valid && forms.userForm.$$submitted)}">
    <div class="form-group-box">
      <div class="step-intro">
        By telling us where you live, you enable our customers to truly test their products and software throughout the globe - in the hands of real people like you.<em> All fields required.</em>
      </div>
      <div class="form-control-static"></div>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 location">
          <div class="form-group address col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div>
              <label for="city">City:</label>
            </div>
            <ngf-autocomplete
              (selected-change)="fillInAddressFromGeo($event)"
              (search)="handleLocationSearch($event)"
              [display-with]="displayGeoLabel"
              [has-error]="!forms.userForm.city.$valid"
              id="city" name="city" ng-model="registrationData.city"
              ng-class="{'ng-touched': forms.userForm.city.$valid, 'auto-detected': autoDetectAddress.city}"
            >
              <ngf-options-container>
                <ngf-option ng-repeat="option in geoOptions" [value]="option">
                  {{option.description}}
                </ngf-option>
              </ngf-options-container>
            </ngf-autocomplete>
            <input required type="hidden" id='city' name="city"  ng-change="autoDetectAddress.city=false" ng-model="registrationData.city">
            <i aria-hidden="true" class="validation-icon material-icons">{{forms.userForm.city.$valid ? 'done': 'warning'}}</i>
            <span class="error-msg" ng-show="!forms.userForm.city.$valid" ng-messages="forms.userForm.city.$error" aria-live="polite">
              <ng-message when="required">City is required</ng-message>
              <ng-message when="minlength">Minimum 2 characters required</ng-message>
              <ng-message when="maxlength">Maximum 50 characters</ng-message>
              <ng-message when="validationMatch">Select a valid city from the list</ng-message>
            </span>
            <span ng-show="autoDetectAddress.city" class="check-mark">
              <i class="material-icons" aria-hidden="true">{{autoDetectAddress.city? 'done': ''}}</i>
              Autodetected
            </span>
          </div>

          <div class="form-group address col-xs-12 col-sm-12 col-md-12 col-lg-12 select2-theme-form clear" ng-show="states!=undefined">
            <div>
              <label>State, Province or Region:</label>
            </div>
            <div>
              <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                         ng-disabled="!(states && states.length)" on-select="geocodeAddress()" ng-model="registrationData.stateId" ng-required="states && states.length"  ng-change="autoDetectAddress.state=false" name="stateId" ng-class="{'auto-detected': autoDetectAddress.state}">
                <ui-select-match placeholder="Select a state">{{$select.selected.name}}</ui-select-match>
                <ui-select-choices repeat="state.id as state in states | filter: $select.search">
                  <div ng-bind-html="state.name | highlight: $select.search"></div>
                </ui-select-choices>
              </ui-select>
              <span class="error-msg" ng-show="!forms.userForm.stateId.$valid" aria-live="polite">
                State is required
              </span>
              <span ng-show="autoDetectAddress.state" class="check-mark">
                <i class="material-icons" aria-hidden="true">{{autoDetectAddress.state? 'done': ''}}</i>
                Autodetected
              </span>
            </div>
          </div>

          <div class="form-group address col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div>
              <label for="zip">Zip or Postal Code:</label>
            </div>
            <input required type="text" ng-blur="geocodeAddress()" id='zip' name="zip"  ng-change="autoDetectAddress.zip=false" ng-model="registrationData.zip" ng-minlength="2" ng-maxlength="20" ng-class="{'ng-touched': forms.userForm.zip.$valid, 'auto-detected': autoDetectAddress.zip}">
            <i aria-hidden="true" class="validation-icon material-icons">{{forms.userForm.zip.$valid ? 'done': 'warning'}}</i>
            <span class="error-msg" ng-show="!forms.userForm.zip.$valid" ng-messages="forms.userForm.zip.$error" aria-live="polite">
              <ng-message when="required">Zip is required</ng-message>
              <ng-message when="minlength">Minimum 2 characters required</ng-message>
              <ng-message when="maxlength">Maximum 20 characters</ng-message>
            </span>
            <span ng-show="autoDetectAddress.zip" class="check-mark">
              <i class="material-icons" aria-hidden="true">{{autoDetectAddress.zip? 'done': ''}}</i>
              Autodetected
            </span>
          </div>

          <div class="form-group address col-xs-12 col-sm-12 col-md-12 col-lg-12 select2-theme-form clear">
            <div>
              <label>Country:</label>
            </div>

            <div class="ui-select">
              <div>
                <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                           on-select="selectCountry()" ng-model="registrationData.countryId"  ng-required="true" name="countryId" ng-class="{'auto-detected': autoDetectAddress.country}" focus-on="countryIdFocus">
                  <ui-select-match placeholder="Select a country">{{$select.selected.name}}</ui-select-match>
                  <ui-select-choices repeat="country.id as country in countries | filter: $select.search">
                    <div ng-bind-html="country.name | highlight: $select.search"></div>
                  </ui-select-choices>
                </ui-select>

                <span class="error-msg" ng-show="!forms.userForm.countryId.$valid" aria-live="polite">
                  Country is required
                </span>
                <span ng-show="autoDetectAddress.country" class="check-mark">
                  <i class="material-icons" aria-hidden="true">{{autoDetectAddress.country? 'done': ''}}</i>
                  Autodetected
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6 hidden-xs hidden-sm">
          <div id="map" class="signup-map" ng-focus="setIsGoogleMapsFocused(true)" ng-blur="setIsGoogleMapsFocused(false)" aria-label="Google Maps"aria-describedby="googleMapsInstructions" ng-keydown="handleMapTab($event)" tabindex="0"></div>
          <span ng-show="isGoogleMapsFocused" id="googleMapsInstructions" class="google-maps-instructions">Press the M key to enter Google Maps</span>
        </div>
      </div>
    </div>
    <div>
      <button class="btn btn-grey btn-rounded pull-left" ng-click="navigateToStep('personal');" ng-keydown="handleBackBtnTab($event)">
        <i aria-hidden="true" class="material-icons">chevron_left</i>
        <span>Back</span>
      </button>
      <div class="pull-right next-step">
        <button class="btn btn-blue" class="btn btn-blue pull-right" ng-click="validateAddressInfoStep(forms.userForm);">
          <span>Next: Devices</span>
          <i class="material-icons" aria-hidden="true">chevron_right</i>
        </button>
      </div>
    </div>
  </form>
</div>
`;
