import _ from 'lodash';
import angular from 'angular';

// Attribute options
// - infinite-scroll *required* = the function that will be called when infinite scroll is triggered
//      * place this on the element that will have items appened to it
// - infinite-scroll-disabled = disables the function call set in infinite-scroll (set when at the end of a set of data)
// - infinite-scrolling-element *required* = the element with the scroll bar that triggers scroll events
// - infinite-scroll-buffer-multiplier (defaults to 1) = multiplies the height at which the infinite scroll is triggered

const infiniteScroll = function () {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      // The element in which the scrolling occurs
      const scrollingElement = angular.element(document).find(attrs.infiniteScrollingElement);

      scrollingElement.on('scroll keypress mousewheel', _.throttle(() => {
        if (infiniteScrollTriggered()) {
          scope.$eval(attrs.infiniteScroll);
          attrs.loadingMore = false;
        }
      }, 200, { trailing: false }));

      // Determines if the criteria have been met to trigger the function defined in infinite-scroll
      const infiniteScrollTriggered = function () {
        if (!attrs.infinteScrollDisabled && !attrs.loadingMore) {
          const scrollTop = scrollingElement.scrollTop();
          const scollingElementHeight = scrollingElement.height();
          const distanceToBottom = scrollingElement[0].scrollHeight - scrollingElement.height() - scrollTop;

          const infiniteScrollBuffer = scollingElementHeight * (attrs.infinteScrollBufferMuliplier || 1);
          if (distanceToBottom < infiniteScrollBuffer) {
            attrs.loadingMore = true;
            return true;
          }
          return false;
        }
      };
    },
  };
};

export default infiniteScroll;
