import _ from 'lodash';

class CommunityFeedItemSubscribeCtrl {
  constructor(AlertService, SubscribeService, LightTesterErrorService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.processing = false;
      ctrl.title = ctrl.item.subscriptions.subscribed_by_current_user ? 'Unsubscribe' : 'Subscribe';
    };

    ctrl.successCallback = (res) => {
      ctrl.item.subscriptions = res.data.subscriptions;
      if (ctrl.item.subscriptions.subscribed_by_current_user) {
        AlertService.add(`You successfully subscribed to this ${ctrl.item.type}`);
        ctrl.title = 'Unsubscribe';
      } else {
        AlertService.add(`You successfully unsubscribed from this ${ctrl.item.type}`);
        ctrl.title = 'Subscribe';
      }
      ctrl.processing = false;
    };

    ctrl.errorCallback = () => {
      ctrl.processing = false;
      AlertService.add('Request failed', 'danger');
    };

    ctrl.subscribeToggle = () => {
      if (LightTesterErrorService.show()) return;

      if (!ctrl.processing) {
        ctrl.processing = true;
        if (_.isUndefined(ctrl.item.subscriptions) || !ctrl.item.subscriptions.subscribed_by_current_user) {
          SubscribeService.subscribe(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
        } else {
          SubscribeService.unsubscribe(ctrl.item).then(ctrl.successCallback, ctrl.errorCallback);
        }
      }
    };
  }
}

CommunityFeedItemSubscribeCtrl.$inject = ['AlertService', 'SubscribeService', 'LightTesterErrorService'];

export default CommunityFeedItemSubscribeCtrl;
