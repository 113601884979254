import template from './community.component.html';
import controller from './community.controller';

const community = {
  bindings: {
    user: '<',
  },
  template,
  controller,
};

export default community;
