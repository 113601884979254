export default `
<div id="mainContent">
  <div class="section-home-cover" ng-if="!$ctrl.user">
    <div class="section-home-cover-bg">
      <div ng-style="{'background-image': 'url(' + $ctrl.unauthHeaderBg + ')'}" class="unauthenticated-project-bg"> </div>
    </div>
    <div class="block container">
      <h1 class="section-title-unauth">{{$ctrl.headerData.title}}</h1>
      <p class="section-desc-unauth">{{$ctrl.headerData.unauthDescription}}</p>
    </div>
  </div>

  <div ng-if="$ctrl.projectData.state === 'archived' && $ctrl.currentUser.user_type !== 'admin'" class="projects-action-card">
    <p>We are no longer recruiting for this project and this post has been archived. Please browse our Projects Board for current projects</p>
    <a ng-href="{{$ctrl.utestUrl}}/projects">Visit the uTest Projects Board</a>
    <div><i class="material-icons" aria-hidden="true">assignment</i></div>
    <p>Review the uTest Projects Board and apply to Projects where you may be a fit</p>
  </div>

  <div class="section-single-cover project" ng-if="$ctrl.projectData.state !== 'archived' || $ctrl.currentUser.user_type === 'admin'">
    <div class="block container">
      <navigate-prev-next item="$ctrl.projectData">Previous/Next Project Listing</navigate-prev-next>
      <div class="feed project" ng-class="{ongoing: !$ctrl.project.urgent, urgent: $ctrl.project.urgent}">
        <div class="feed-content">
          <h1>
            {{$ctrl.projectData.title}}
            <span class="icon-check green" ng-if="$ctrl.projectData.user_has_applied"></span>
          </h1>
          <br />
          <h4 class="feed-date">Start date: <span>{{$ctrl.projectData.start_date | date: 'MMM dd'}}</span></h4>
          <div ng-show="$ctrl.projectData.countries.length != 0" class="feed-countries">
            <i class="material-icons" aria-hidden="true">location_on</i>
            <span>{{$ctrl.projectData.countries.join(', ')}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container" ng-if="$ctrl.projectData.state !== 'archived' || $ctrl.currentUser.user_type === 'admin'">
    <div class="section-single-toolbar">
      <hr>
      <div class="clearfix">
        <div class="section-single-toolbar__cell_right">
          <social-sharing target="fullUrl" text="$ctrl.projectData.title" type="Project"></social-sharing>
        </div>
      </div>
    </div>
  </div>
  <div class="section-single-body projects" ng-if="$ctrl.projectData.state !== 'archived' || $ctrl.currentUser.user_type === 'admin'">
    <div class="block container">
      <div class="section-single-body-content">
        <h4>Description</h4>
        <div class="project-description" ng-bind-html="$ctrl.projectData.content"></div>
        <div class="project-description">
          <p>
            <b>PLEASE NOTE: Due to the volume of submissions, you will only be contacted if you are invited to the project.</b>
          </p>
        </div>
        <div class="verify-checkbox cus-checkbox inline" ng-if="$ctrl.currentUser && (($ctrl.currentUser.registration_type == 'EXTENDED') || $ctrl.currentUser.is_employee)">
          <div>
            <a class="btn btn-blue" href="" ng-click="$ctrl.applyForProject($ctrl.projectData)">Apply</a>
            <span class="btn-helper-text">By selecting "Apply", I verify that I meet the requirements above</span>
          </div>
        </div>
        <div class="alert alert-warning" ng-if="$ctrl.currentUser && ($ctrl.currentUser.registration_type != 'EXTENDED') && !$ctrl.currentUser.is_employee">
          Please <a href="{{globalConfig.profile_url}}">complete your profile</a> to become eligible for paid projects
        </div>
        <div class="alert  alert-warning" ng-if="!$ctrl.currentUser">
          Please <a href="/login?return_to={{$ctrl.$location.absUrl()}}" target="_self">sign in</a> or <a ui-sref="signup">join uTest</a> today
        </div>
      </div>
    </div>
  </div>
</div>
`;
