export default `
<div class="modal-header">
  <button type="button" class="close" ng-click="cancel()" aria-label="Close">
    <i class="icon-cancel"></i>
  </button>
</div>
<div class="modal-body">
  <div class="feed-item-delete-message">
    Are you sure you want to delete this post?
  </div>
  <button class="community-cta__button unfilled" autofocus ng-click="cancel()">Cancel</button>
  <button class="community-cta__button" ng-click="delete()" ng-mouseup="cancel()">Delete</button>
</div>
`;
