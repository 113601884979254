import template from './alert-message.component.html';
import controller from './alert-message.controller';

const alertMessage = {
  bindings: {
    currentUser: '<',
  },
  template,
  controller,
};

export default alertMessage;
