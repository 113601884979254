import _ from 'lodash';
import GamificationCtrl from '../gamification/gamification.controller';
import gamificationTemplate from '../gamification/gamification.html';
import GamificationService from '../components/gamification/gamification.service';
import HallOfFameCtrl from '../hall-of-fame/hall-of-fame.controller';
import hallOfFameTemplate from '../hall-of-fame/hall-of-fame.html';
import EmojiCtrl from '../emoji/emoji.controller';
import emojiTemplate from '../emoji/emoji.html';
import EmojiCategoryCtrl from '../emoji/emoji-category.controller';
import emojiCategoryTemplate from '../emoji/emoji-category.html';
import FaqPaymentsTemplate from '../faq-payments/faq-payments.html';
import randomColorTemplate from '../randomcolor/randomcolor.html';
import DocsService from '../components/docs/docs.service';
import UserResolver from '../authentication/user-resolver';
import { FourOhFourComponent } from '../ng-app/four-oh/four-oh-four.component';
import { FourOhOneComponent } from '../ng-app/four-oh/four-oh-one.component';

const PseudoStaticRouter = {
  route: (module) => {
    module
      .controller('GamificationCtrl', GamificationCtrl)
      .controller('HallOfFameCtrl', HallOfFameCtrl)
      .controller('EmojiCtrl', EmojiCtrl)
      .controller('EmojiCategoryCtrl', EmojiCategoryCtrl)
      .service('GamificationService', GamificationService)
      .service('DocsService', DocsService);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('fourohfour', {
          url: '/404',
          parent: 'root',
          component: FourOhFourComponent,
          resolve: {
            title: [() => 'uTest - 404'],
            user: UserResolver,
          },
        })
        .state('fourohone', {
          url: '/401',
          parent: 'root',
          component: FourOhOneComponent,
          resolve: {
            title: [() => 'uTest - 401'],
            user: UserResolver,
            returnToUrl: ['$location', ($location) => $location.absUrl()],
          },
        })
        .state('faqpayments', {
          url: '/faq-payments',
          parent: 'root',
          template: FaqPaymentsTemplate,
          resolve: {
            title: [() => 'uTest - Tester Payments FAQs'],
          },
        })
        .state('gamification', {
          url: '/upoints',
          parent: 'root',
          template: gamificationTemplate,
          controller: 'GamificationCtrl',
          resolve: {
            title: [() => 'uTest - Earning uPoints & Badges'],
          },
        })
        .state('halloffame', {
          url: '/hall-of-fame',
          parent: 'root',
          resolve: {
            periods: ['HallOfFameService', '$state', function (HallOfFameService, $state) {
              HallOfFameService.periods().then((response) => {
                const years = response.data;
                // @ts-ignore
                const { year } = _.last(years);
                // @ts-ignore
                const period = _.last(years).periods[0];

                $state.go('halloffamedate', { year, period });
              });
            }],
            title: [() => 'uTest Hall of Fame'],
          },
        })
        .state('emoji', {
          url: '/emoji',
          template: emojiTemplate,
          controller: 'EmojiCtrl',
          parent: 'root',
          resolve: {
            emojiData: ['$http', function ($http) {
              return $http.get('/assets/emoji.json').then();
            }],
            title: [() => 'uTest Emojis'],
          },
        })
        .state('emoji.category', {
          url: '/:category',
          params: {
            category: '',
          },
          controller: 'EmojiCategoryCtrl',
          template: emojiCategoryTemplate,
          resolve: {
            title: [() => 'uTest Emojis'],
          },
        })
        .state('randomcolor', {
          url: '/randomcolor',
          parent: 'root',
          template: randomColorTemplate,
          controller: ['$scope', function ($scope) {
            $scope.offset = 80;
            $scope.notSoRandom = new Array(221);
          }],
          resolve: {
            title: [() => 'uTest Colors'],
          },
        })
        .state('docs', {
          url: '/docs/{encryptedUrl}',
          parent: 'root',
          resolve: {
            encryptedUrl: ['$transition$', 'DocsService', '$state', '$window', function ($transition$, DocsService, $state, $window) {
              DocsService.find($transition$.params().encryptedUrl)
                .then((response) => {
                  $window.open(response.data.redirect_url, '_self');
                })
                .catch((_response) => {
                  $state.go('fourohfour');
                });
            }],
          },
        })
        .state('halloffamedate', {
          url: '/hall-of-fame/:year/:period',
          template: hallOfFameTemplate,
          controller: 'HallOfFameCtrl',
          parent: 'root',
          resolve: {
            periods: ['HallOfFameService', function (HallOfFameService) {
              return HallOfFameService.periods().then(_.property('data'));
            }],
            title: [() => 'uTest Hall of Fame'],
          },
        });
    }]);
  },
};

export default PseudoStaticRouter;
