class DashboardCardActivityCtrl {
  constructor(ActivityService, FeedStore, UserService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      UserService.projects().then((res) => {
        ctrl.projects = res.data.data;
        ctrl.projectsCount = res.data.count;
      });

      UserService.invitations().then((res) => {
        ctrl.invitations = res.data.data;
        ctrl.invitationsCount = res.data.count;
      });

      ActivityService.feed().then((res) => {
        ctrl.activities = ActivityService.combine(res.data);
      });

      ActivityService.communityActivities().then((res) => {
        ctrl.communityActivities = ActivityService.combine(res.data);
      });

      ctrl.activeTab = 'All';
      ctrl.navTabs = document.getElementsByClassName('nav-tabs');
      // Sets aria roles for <ul> <li> and <a>
      setTimeout(() => {
        ctrl.navTabs[0].setAttribute('role', 'tablist');
        const tabElements = ctrl.navTabs[0].children;
        for (let i = 0; i < tabElements.length; i++) {
          tabElements[i].setAttribute('role', 'presentation');
          tabElements[i].firstElementChild.setAttribute('role', 'tab');
        }
      });
    };

    ctrl.setSelectedTab = (elem) => {
      ctrl.activeTab = elem;
      const tabElements = ctrl.navTabs[0].children;
      for (let i = 0; i < tabElements.length; i++) {
        tabElements[i].firstElementChild.setAttribute('aria-selected', ctrl.activeTab === tabElements[i].type);
      }
      if (ctrl.activeTab === 'Projects') {
        ActivityService.clearProjectActivities();
        $scope.$emit('clearProjectNotificationCounter');
      } else if (ctrl.activeTab === 'Community') {
        ActivityService.clearCommunityActivities();
        $scope.$emit('clearCommunityNotificationCounter');
      }
    };
  }
}

DashboardCardActivityCtrl.$inject = ['ActivityService', 'FeedStore', 'UserService', '$scope'];

export default DashboardCardActivityCtrl;
