import _ from 'lodash';

const appendCount = () => (input, count) => {
  if (_.isNumber(count)) {
    return `${input} (${count})`;
  }
  return input;
};

export default appendCount;
