export default `
<div class="user-form registration-intro">
  <div>
    <p>Congratulations! Your account has been created with <b>tester ID {{user.platform_id}}</b> and you are almost done. Next steps:</p>
    <ul>
      <li>Check out the instructions below</li>
      <li>Complete the form on this page asking about some additional details to see if you qualify.</li>
      <li>And finally confirm your email address to start getting test cycle invitations.</li>
    </ul>
  </div>
  <div ng-if="customRegistration.final_page">
    <article ng-bind-html="customRegistration.final_page | uMarkdown | trustAsHtml"></article>
  </div>
  <div ng-if="customRegistration.embedded_form">
    <div ng-bind-html="customRegistration.embedded_form | trustAsHtml"></div>
  </div>
</div>

`;
