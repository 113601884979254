import _ from 'lodash';

class CommunityFeaturedListItemCtrl {
  item: any;
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.title = ctrl.item.title || ctrl.item.content;
      ctrl.author = _.get(ctrl.item, 'user');
      ctrl.date = ctrl.item.published_at;
      ctrl.route = ctrl.urlForItem(ctrl.item);
      ctrl.showStats = ctrl.item.type !== 'project' && ctrl.item.type !== 'referral_campaign' && ctrl.item.type !== 'course';
    };
  }

  urlForItem(item) {
    switch (item.type) {
      case 'article':
        return `singlearticle({articleId: '${item.slug}'})`;
      case 'course':
        return `singlecourse({courseId: '${item.slug}'})`;
      case 'project':
        return `singleproject({projectId: '${item.slug}'})`;
      case 'topic':
        return `singlediscussionpage({page: 1, discussionId: '${item.id}'})`;
      case 'status':
        return `singlestatus({statusId: '${item.id}'})`;
      case 'referral_campaign':
        return `referralCampaignsShow({referralCampaignId: '${item.slug}'})`;
      default:
    }
  }

  get type() {
    switch (this.item.type) {
      case 'topic':
        return 'Discussion';
      case 'referral_campaign':
        return 'Referral Campaign';
      default:
        return _.capitalize(this.item.type);
    }
  }

  get numLikes() {
    return _.get(this.item, 'likes.count');
  }

  get numComments() {
    const numComments = this.item.comments ? this.item.comments.count : this.item.post_count;
    return numComments > 0 ? numComments : 0;
  }
}

CommunityFeaturedListItemCtrl.$inject = [];

export default CommunityFeaturedListItemCtrl;
