import _ from 'lodash';
import Dropzone from 'dropzone';

class DropzoneInputCtrl {
  constructor($scope, $element, $timeout, $cookies, UploadService, $rootScope) {
    const ctrl: ng.IController = this;
    let maxFiles;
    const urlIdMap = {};

    ctrl.$onInit = () => {
      maxFiles = ctrl.maxFiles ? parseInt(ctrl.maxFiles) : null;
      ctrl.initializeDropzone();
    };

    ctrl.initializeDropzone = () => {
      $timeout(() => {
        new Dropzone($element[0], {
          dictDefaultMessage: ctrl.placeholder || 'Drop files here or click to upload.',
          url: '/api/v1/uploads',
          uploadMultiple: false,
          maxFiles,
          addRemoveLinks: true,
          headers: {
            'X-Csrf-Token': $cookies.get($rootScope.csrfCookieName),
          },
          init() {
            ctrl.on('success', addSuccess);
            ctrl.on('removedfile', removedFile);
          },
        });
      });
    };

    function addSuccess(file, res) {
      urlIdMap[file.name] = res.id;
      if (maxFiles === 1) {
        ctrl.ngModelCtrl.$setViewValue(res.id);
      } else {
        const v = ctrl.ngModel || [];
        v.push(res.id);
        ctrl.ngModelCtrl.$setViewValue(v);
      }
      $scope.$apply();
    }

    function removedFile(file) {
      const removedId = urlIdMap[file.name];
      UploadService.delete(removedId);
      if (maxFiles === 1) {
        ctrl.ngModelCtrl.$setViewValue(null);
      } else {
        const v = ctrl.ngModel;
        _.remove(v, item => removedId === item);
        ctrl.ngModelCtrl.$setViewValue(v);
      }
      $scope.$apply();
    }
  }
}

DropzoneInputCtrl.$inject = ['$scope', '$element', '$timeout', '$cookies', 'UploadService', '$rootScope'];

export default DropzoneInputCtrl;
