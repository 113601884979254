class LightTesterErrorService {
  isLightTester = false;
  testerAppUrl: string;
  show: () => boolean;
  isCurrentUserLightTester: () => boolean;

  constructor(UserService, ConfigService, AlertService) {
    UserService.me().then((res) => {
      this.isLightTester = res?.data?.is_light_tester;
    });

    ConfigService.config().then((res) => {
      this.testerAppUrl = `${res.data.profile_url}/account/my-profile/`;
    });

    this.isCurrentUserLightTester = () => this.isLightTester;

    this.show = () => {
      if (!this.isLightTester) {
        return false;
      }

      AlertService.addPermanentDanger(`<div>You are unable to perform this action until you update your profile and add a testing device.</div> <div style="float: right"><a href="${this.testerAppUrl}">Update Profile</a></divi>`);
      return true;
    };
  }
}

LightTesterErrorService.$inject = ['UserService', 'ConfigService', 'AlertService'];

export default LightTesterErrorService;
