class ConfigService {
  $http: ng.IHttpService;
  cachedConfigPromise: any;
  constructor($http) {
    this.$http = $http;
    this.cachedConfigPromise = null;
  }

  config() {
    if (!this.cachedConfigPromise) {
      this.cachedConfigPromise = this.$http.get('/api/v1/config');
    }

    return this.cachedConfigPromise;
  }
}

ConfigService.$inject = ['$http'];

export default ConfigService;
