class ServiceByTypeService {
  $injector: any;
  serviceMap: any;
  constructor($injector) {
    this.$injector = $injector;

    this.serviceMap = {
      article: 'ArticleService',
      course: 'CourseService',
      tool: 'ToolService',
      project: 'ProjectService',
      status: 'StatusService',
      comment: 'CommentService',
      topic: 'DiscussionService',
      view: 'PageViewService',
    };
  }

  get(type) {
    return this.$injector.get(this.serviceMap[type]);
  }
}

ServiceByTypeService.$inject = ['$injector'];

export default ServiceByTypeService;
