export default `
<div class="modal-header">
  <button type="button" class="close" ng-click="cancel()" autofocus aria-label="Close" tabindex="0">
    <i class="icon-cancel"></i>
  </button>
  <h3  ng-bind="likeable.likes.count|pluralize:'Like'" class="modal-title"></h3>
</div>
<div class="modal-body">
  <div class="user-list" >
    <user-list-item user="user" ng-repeat="user in users.feedItems"></user-list-item>
  </div>
  <load-more load-callback="users.loadMore()" is-loading="users.isLoading" is-last-page="users.isLastPage"></load-more>
</div>
`;
