const LoadingIndicator = ($transitions, $state, ProgressBarService) => ({
  restrict: 'A',
  link: () => {
    $transitions.onStart({}, ProgressBarService.start);
    $transitions.onSuccess({}, ProgressBarService.done);
    $transitions.onError({}, ProgressBarService.done);
  },
});

LoadingIndicator.$inject = ['$transitions', '$state', 'ProgressBarService'];

export default LoadingIndicator;
