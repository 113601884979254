export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w890">
      <div class="section-home-cover-random">
        <h3>{{page.title}}</h3>
        <p style="white-space: pre-line" ng-bind-html="page.top_content"></p>
        <a ng-if="!externalSignupLink" class="btn btn-yellow" href="https://www.utest.com/signup" target="_self">SIGN UP NOW</a>
        <a ng-if="externalSignupLink" class="btn btn-yellow" ng-href="{{page.signup_link | prependHttp}}" target="_blank" rel="noopener noreferrer">SIGN UP NOW</a>
      </div>
    </div>
    <div class='lp-row-h' >
      <p>Work with the world's best brands</p>
    </div>
  </div>
</div>

<div class="lp-img-row">
  <div class="grid w890">
    <ul>
      <li><img src="assets/images/customers/google.png" alt=""></li>
      <li><img src="assets/images/customers/runkeeper.png" alt=""></li>
      <li><img src="assets/images/customers/amazon.png" alt=""></li>
      <li><img src="assets/images/customers/netflix.png" alt=""></li>
      <li><img src="assets/images/customers/coca-cola.png" alt=""></li>
      <li class="break">&nbsp;</li>
    </ul>
  </div>
</div>

<div class="grid w890" ng-bind-html="page.bottom_content">
</div>

`;
