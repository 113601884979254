import { Component } from '@angular/core';
import {
  UNAUTH_WHY_UTEST_IMG,
  UNAUTH_WHY_UTEST_TESTER_QUOTES,
} from '../../../../constants';

@Component({
  selector: 'ut-quotes-nav',
  templateUrl: './quotes-nav.component.html',
  styleUrls: ['./quotes-nav.component.scss'],
})
class QuotesNavComponent {
  testerQuotes: any = UNAUTH_WHY_UTEST_TESTER_QUOTES;
  images: any = UNAUTH_WHY_UTEST_IMG;

  testerQuoteSelected = this.testerQuotes[0];

  mouseUp = true;
  mouseDown = false;
  mouseXPosition = 0;

  onMouseDown = (event: MouseEvent) => {
    this.mouseUp = false;
    this.mouseDown = true;
    this.mouseXPosition = event.screenX;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.mouseDown && this.mouseXPosition !== 0) {
      if ((this.mouseXPosition - event.screenX) > 100) {
        const nextId = this.testerQuoteSelected.id !== 5 ? (this.testerQuoteSelected.id + 1) : 1;
        this.mouseXPosition = 0;
        this.testerQuoteNavigateClick(nextId);
      } else if ((this.mouseXPosition - event.screenX) < -100) {
        const prevId = this.testerQuoteSelected.id !== 1 ? (this.testerQuoteSelected.id - 1) : 5;
        this.mouseXPosition = 0;
        this.testerQuoteNavigateClick(prevId);
      }
    }
  };

  onMouseUp = () => {
    this.mouseUp = true;
    this.mouseDown = false;
    this.mouseXPosition = 0;
  };

  testerQuoteNavigateLeftClick() {
    this.testerQuoteSelected = this.testerQuoteSelected.id > 1 ? this.testerQuotes.filter(testeQuote => testeQuote.id === (this.testerQuoteSelected.id - 1))[0] : this.testerQuotes.filter(testeQuote => testeQuote.id === 5)[0];
  }

  testerQuoteNavigateRightClick() {
    this.testerQuoteSelected = this.testerQuoteSelected.id < 5 ? this.testerQuotes.filter(testeQuote => testeQuote.id === (this.testerQuoteSelected.id + 1))[0] : this.testerQuotes.filter(testeQuote => testeQuote.id === 1)[0];
  }

  testerQuoteNavigateClick(testerQuoteId: number) {
    this.testerQuoteSelected = this.testerQuotes.filter(testeQuote => testeQuote.id === testerQuoteId)[0];
  }
}

export { QuotesNavComponent };
