import controller from './course-track-nav.controller';
import template from './course-track-nav.component.html';

const courseTrackNav = {
  bindings: {
    courseTrack: '<',
    user: '<',
  },
  template,
  controller,
};

export default courseTrackNav;
