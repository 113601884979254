class UploadService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  upload(file) {
    const formData = new FormData();
    formData.append('file', file);

    return this.$http.post('/api/v1/uploads', formData, { headers: { 'Content-Type': undefined } });
  }

  delete(id) {
    return this.$http.delete(`/api/v1/uploads/${id}`);
  }
}

UploadService.$inject = ['$http'];

export default UploadService;
