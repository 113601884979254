import template from './slug-input.component.html';
import controller from './slug-input.controller';

const slugInput = {
  bindings: {
    urlPre: '@',
    toSlug: '<',
    theSlug: '<',
    hasError: '<',
    onSlugChange: '&',
  },
  template,
  controller,
};

export default slugInput;
