/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { forwardRef, Provider, Type } from '@angular/core';
import { OptionHoverService } from './option-hover.service';
import { OptionsRoot } from './options-root';
import { OptionsRootHolder } from './options-root-holder';
import { DelegateOptionSelectionService } from './selection/delegate-option-selection.service';
import { OptionMultiSelectionService } from './selection/multi-selection/option-multi-selection.service';
import { OptionSelectionService } from './selection/option-selection.service';
import { OptionSingleSelectionService } from './selection/signle-selection/option-single-selection.service';
import { OptionOpenService } from "@ng-falcon/controls/option/option-open.service";

export function OptionProviders<T extends OptionsRoot>(optionRootType: Type<T>): Provider[] {
  return [
    { provide: OptionsRoot, useExisting: forwardRef(() => optionRootType) },
    OptionsRootHolder,
    OptionHoverService,
    OptionOpenService,
    /**
     * both OptionSingleSelectionService and OptionMultiSelectionService are provided,
     * because which one will be used is resolved in runtime based on "optionRoot.multiselect" property
     */
    OptionSingleSelectionService,
    OptionMultiSelectionService,
    {
      provide: OptionSelectionService,
      useClass: DelegateOptionSelectionService
    },
  ];
}

export function SingleSelectionOptionProviders<T extends OptionsRoot>(optionRootType: Type<T>): Provider[] {
  return [
    { provide: OptionsRoot, useExisting: forwardRef(() => optionRootType) },
    OptionsRootHolder,
    OptionHoverService,
    OptionOpenService,
    OptionSingleSelectionService,
    {
      provide: OptionSelectionService,
      useExisting: OptionSingleSelectionService
    },
  ];
}
