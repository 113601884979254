import _ from 'lodash';
import emojione from 'emojione';

class EmojiController {
  constructor($scope, $http, $state, emojiData) {
    $scope.emojione = emojione;

    $scope.emojiByCategory = {};
    _.forEach(emojiData.data, (em) => {
      if (!$scope.emojiByCategory[em.category]) {
        $scope.emojiByCategory[em.category] = [];
      }
      const toneMatch = em.shortname.match(/:.+(tone[1-5]):/);
      if (toneMatch) {
        em.tone = toneMatch[1];
      }
      $scope.emojiByCategory[em.category].push(em);
    });

    $scope.tones = _.map($scope.emojiByCategory.modifier, modif => modif.shortname.replace(/:/g, ''));

    $scope.setActiveTone = function (tone) {
      $scope.activeTone = tone;
    };

    $scope.setActiveTone($scope.tones[0]);

    delete $scope.emojiByCategory.modifier;

    if ($state.current.name === 'emoji') {
      $state.go('emoji.category', { category: 'people' }, { location: 'replace' });
    }
  }
}

EmojiController.$inject = ['$scope', '$http', '$state', 'emojiData'];

export default EmojiController;
