import { StateService, TransitionService } from '@uirouter/core';

class NavSidebarDropdownItemCtrl {
  constructor($state: StateService, $transitions: TransitionService, UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
        if (ctrl.highlight && ctrl.highlight()) {
          ctrl.isSelected = true;
          ctrl.expandParent();
        }
      });

      if (ctrl.reload === undefined) {
        ctrl.reload = false;
      }

      ctrl.state = $state;
      ctrl.isSelected = false;

      $transitions.onSuccess({}, (_transition) => {
        if (ctrl.highlight && ctrl.highlight()) {
          ctrl.isSelected = true;
          ctrl.expandParent();
        } else {
          ctrl.isSelected = false;
          ctrl.collapseParent();
        }
      });
    };
  }
}

NavSidebarDropdownItemCtrl.$inject = ['$state', '$transitions', 'UserService'];

export default NavSidebarDropdownItemCtrl;
