export default `
<!-- this should be visible all the time with the flag off, but only visible to signed in users with the flag on  -->
<nav-sidebar ng-if="showNtuxNavigation"></nav-sidebar>
<alert-service ng-if="showNtuxNavigation"></alert-service>
<div ng-if="showNtuxNavigation" loading-indicator class="ntux-wrapper-all" id="wrapperAll">
  <nav-header></nav-header>

  <div auto-padding="nav-bar-container" auto-padding-event="adjust-nav-padding" id="mainUiView" class='main-ui-view' scrolly scroll-target='#navBar' autoscroll="true">
    <div ui-view id="main-content-container" ng-class="{'ntux-background-color' : ($state.includes('home')
    || $state.includes('activity')
    || $state.includes('community')
    || $state.includes('courses')
    || $state.includes('academy')
    || $state.includes('tracks')
    || $state.includes('singlecourse')
    || $state.includes('referralCampaignsIndex'))}"></div>
  </div>
</div>
<landing-messages></landing-messages>

<unauthenticated-container ng-if="!showNtuxNavigation"></unauthenticated-container>
</div>


  <!-- unauthenticated-footer -->
`;
