import _ from 'lodash';

class SignUpEmbedCtrl {
  constructor($scope, StaticValuesService, UserService) {
    $scope.registrationData = {};

    $scope.isUserLoading = true;

    UserService.me().then((res) => {
      $scope.isUserLoading = false;
      if (res !== null) {
        $scope.currentUser = res.data;
      } else {
        $scope.currentUser = null;
      }
    }, () => {
      $scope.isUserLoading = false;
    });

    $scope.loadInGender = function () {
      if ($scope.genders) { return; }
      StaticValuesService.genders().then((genders) => {
        $scope.genders = genders.data;
      });
    };

    $scope.loadInCountries = function () {
      if ($scope.countries) { return; }
      StaticValuesService.countriesAndStates().then((cs) => {
        $scope.countries = cs.countries.data;
        $scope.statesByCountryId = _.mapValues(cs.states, 'data');
      });
    };

    $scope.selectCountry = function () {
      $scope.states = $scope.statesByCountryId[$scope.registrationData.countryId];
    };
  }
}

SignUpEmbedCtrl.$inject = ['$scope', 'StaticValuesService', 'UserService'];

export default SignUpEmbedCtrl;
