class DashboardAllTabCtrl {
  constructor(ActivityService, UserService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      UserService.me().then((res) => {
        ctrl.currentUser = res.data;

        if ((ctrl.currentUser.unread_activities_count > 0) && (ctrl.currentUser.unread_activities_count <= ctrl.maxActivitiesCount)) {
          ActivityService.clearAll().then(() => {
            ctrl.currentUser.unread_activities_count = 0;
          });
        }
      });
    };
  }
}

DashboardAllTabCtrl.$inject = ['ActivityService', 'UserService'];

export default DashboardAllTabCtrl;
