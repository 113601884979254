export default `
<div class="dashboard-card dashboard-new-joiner-card">
  <div class="dashboard-new-joiner-checklist__header">
    <div class="dashboard-new-joiner-checklist__heading">
      <div class="dashboard-new-joiner-checklist__heading-icon">
        <i class="material-icons">star</i>
      </div>
      <h2 class="dashboard-new-joiner-checklist__heading-text">New Joiner Checklist</h2>
    </div>
    <div class="dashboard-new-joiner-checklist__subheading">
      Complete the following tasks to maximize your chance of getting selected for projects and to start off strong in the uTest community.
    </div>
  </div>
  <ul class="dashboard-new-joiner-checklist">
    <new-joiner-checklist-item checked="true" static-on-completion="true" url-text="Create your Account: Welcome to uTest!"></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasConfirmedEmailAddress" static-on-completion="true" url="{{$ctrl.testerAppProfileUrl}}" url-text="Confirm your Email Address" description="Click the link in your email as the final step to create your uTest account."></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasPaymentMethod" url="{{$ctrl.paymentMethodUrl}}" url-text="Setup your Payment Method" description="Enable yourself to get paid."></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasViewedCommunityPage" route="community" url-text="Connect with the Community" description="Share experiences and stay up to date."></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasCompletedProfile" url="{{$ctrl.testerAppProfileUrl}}" url-text="Complete your Profile" description="Make yourself available to participate in Projects that match your expertise."></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasAppliedToProject" route="project({tab: suggested})" url-text="Scan the Projects Board" description="Find test opportunities that match your skills and interests."></new-joiner-checklist-item>
    <new-joiner-checklist-item checked="$ctrl.hasPassedAcademyCourse" route="academy-home" url-text="Take a course at the Academy" description="Learn best practices and how uTest works."></new-joiner-checklist-item>
  </ul>
</div>
`;
