export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w890">
      <div class="section-home-cover-random">
        <h1>#WhyJoinuTest</h1>
        <p align="left">The Community Management Team hears great stories from uTesters everyday about why they decided to join uTest. Our global software testing community runs the gamut from QA professionals who are gaining new experience in a different testing type to stay-at-home parents who want to keep their testing skills sharp. We even have testers who were able to quit their job to work on uTest Paid Projects full-time!</p>
        <p align="left">Join our #WhyJoinuTest campaign and share your story of why you are a part of the world's largest software testing community. </p>
        <p align="left">Click below to join the discussion and to share your story now!</p>
        <a class="aButton bgYellow txtBlack trackThis" href="http://forums.utest.com/" target="_blank">Join the Discussion</a>
        <div class="fb-share-button" data-href="http://www.utest.com/why-join-utest" data-layout="button"></div>
        <a class="aButton bgYellow txtBlack trackThis" href="https://twitter.com/intent/tweet?text=I+joined+uTest+because...&via=uTest&hashtags=WhyJoinuTest" target="_blank">Tweet YOUR Story!</a> </div>
    </div>
  </div>
</div>
<div class="grid w960">
  <div class="c6">
    <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/textbook72x73.png" />
      <h3>uTest Academy</h3>
      <p>Get access to educational materials about all aspects of testing.</p>
      <a class="aButton aButtonSmall bgYellow" href="http://www.utest.com/courses" target="_blank;">Visit Now</a> </div>
  </div>
  <div class="c6">
    <div class="bandBox smallCard"> <img src="/assets/images/legacy/icons/QA72x73.png" />
      <h3>uTest Tester Forums</h3>
      <p>Join discussions on software testing trends, QA practices, advice and webinars by some of the best QA professionals from around the world.</p>
      <a class="aButton aButtonSmall bgYellow" href="http://forums.utest.com/" target="_blank;">Visit Now</a> </div>
  </div>
</div>
<div class="grid w960">
  <div class="c2"> <img alt="Contact Us" src="/assets/images/legacy/icons/map_contactus.png"> </div>
  <div class="c10">
    <div class="cf">
      <h3 class="c12">Not a uTester yet? </h3>
      <p class="c7">Join today and share your story with our global community of software testing professionals. Signing up is free and only takes a few minutes! </p>
      <div class="c5 ctaBandButton"> <a class="aButton bgBlue" target="_blank" href="http://www.utest.com/getting-started">Join now!</a> </div>
    </div>
  </div>
</div>
`;
