import { StateService } from '@uirouter/core';

class NavHeaderCtrl {
  constructor(UserService, ConfigService, $state: StateService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ConfigService.config().then((res) => {
        ctrl.chatUrl = res.data.chat_url;
      });
      ctrl.loadingUser = true;
      ctrl.isMobile = window.matchMedia('(max-width:480px)').matches;
      UserService.me().then((res) => {
        ctrl.loadingUser = false;
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
      }, () => {
        ctrl.loadingUser = false;
      });
      ctrl.query = $state.current.name === 'searchresult' ? $state.params.query : '';
    };

    ctrl.search = (event) => {
      ctrl.query = event.query;
      if (ctrl.query.trim().length) {
        $state.go('searchresult', { contentType: 'all', query: ctrl.query });
      }
    };
  }
}

NavHeaderCtrl.$inject = ['UserService', 'ConfigService', '$state'];

export default NavHeaderCtrl;
