export default `
<div id="mainContent">

  <div class="grid w890">
      <project-feed-item project="project" ng-repeat="project in projects.featured" class="section-home-feed"></project-feed-item>
      <load-more load-callback="projects.loadMore()" is-loading="projects.isLoading" is-last-page="projects.isLastPage" fade-target="footer"></load-more>
  </div>

</div>
`;
