export default `
<div class="unauthenticated-container">
  <div>
    <unauthenticated-header></unauthenticated-header>

    <div auto-padding="nav-bar-container" auto-padding-event="adjust-nav-padding" id="mainUiView" class='main-ui-view unauthenticated-view' scrolly scroll-target='#navBar' autoscroll="true">
      <ui-view></ui-view>
    </div>
  </div>
  <unauthenticated-footer></unauthenticated-footer>
</div>
`;
