import template from './toggle-link.component.html';

const toggleLink = {
  bindings: {
    active: '<',
  },
  template,
};

export default toggleLink;
