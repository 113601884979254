import template from './nav-sidebar-toggle.component.html';
import controller from './nav-sidebar-toggle.controller';

const navSidebarToggle = {
  bindings: {
    action: '@',
  },
  template,
  controller,
};

export default navSidebarToggle;
