import { Component, Input } from '@angular/core';

@Component({
  selector: 'ut-wistia-popover-player',
  template: '<span class="wistia_embed wistia_async_{{hashed_id}} popover=true fullscreenButton=false">&nbsp;</span>',
})
class WistiaPopoverPlayerComponent {
  @Input() hashed_id;
}

export { WistiaPopoverPlayerComponent };
