import _ from 'lodash';
import ArticleService from '../components/article/article.service';
import ArticleFormCtrl from '../article/form/article-form.controller';
import articleFormTemplate from '../article/form/article-form.html';
import UserAuthenticator from '../authentication/user-authenticator';

const ArticleRouter = {
  route: (module) => {
    module
      .service('ArticleService', ArticleService)
      .controller('ArticleFormCtrl', ArticleFormCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('articles', {
          url: '/articles',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          template: '<div ui-view="articles-index" ng-show="$state.includes(\'article\')"></div><div ui-view="articles-show" ng-show="$state.includes(\'singlearticle\')" ></div>',
          parent: 'root',
          authenticated: UserAuthenticator,
        })
        .state('article', {
          url: '',
          parent: 'articles',
          sticky: true,
          resolve: {
            headerData: ['StaticValuesService', (StaticValuesService) => {
              const defaultData = {
                title: 'Software Testing Articles',
                description: 'Check out articles from professional testers -- on all topics related to software testing, QA, career advice, devices and more -- and write your own.',
                coverBg: {
                  small: 'assets/images/hero/articles-bg-xs.jpg',
                  large: 'assets/images/hero/articles-bg.jpg',
                },
              };
              return StaticValuesService.pageData('articles').then((response) => {
                response.data = _.defaultsDeep(response.data, defaultData);
                return response;
              }, _error => ({
                data: defaultData,
              }));
            }],
            title: [() => 'uTest Community Articles'],
            coverBg: ['ImageDataService', 'headerData', (ImageDataService, headerData) => (headerData.data.coverBg.small === 'assets/images/hero/articles-bg-xs.jpg'
              ? ImageDataService.getData(headerData.data.coverBg.small) : headerData.data.coverBg.small)],
            authenticated: UserAuthenticator,
          },
          views: {
            'articles-index': {
              component: 'articles',
            },
          },
        })
        .state('singlearticle', {
          parent: 'articles',
          url: '/:articleId?comments?sort',
          reloadOnSearch: false,
          views: {
            'articles-show': {
              component: 'singleArticle',
            },
          },
          resolve: {
            article: ['ArticleService', '$transition$', '$state', '$q', '$timeout', function (ArticleService, $transition$) {
              return ArticleService.find($transition$.params().articleId).then(res => res.data);
            }],
            title: ['article', article => `uTest Community Article - ${article.title}`],
            authenticated: UserAuthenticator,
          },
        })
        .state('pendingarticle', {
          url: '/pending-articles',
          parent: 'root',
          component: 'pendingArticle',
          resolve: {
            title: [() => 'uTest Community Pending Articles'],
            authenticated: UserAuthenticator,
          },
        })
        .state('newarticle', {
          url: '/new/article',
          parent: 'root',
          template: articleFormTemplate,
          controller: 'ArticleFormCtrl',
          resolve: {
            user: UserAuthenticator,
            item() {
              return {
                data: {
                  id: null,
                  title: '',
                  content: '',
                  slug: '',
                  tags: [],
                },
              };
            },
            title: ['item', (item) => {
              let pageTitle;
              if (item.data.id) {
                pageTitle = 'uTest Community - Edit Article';
              } else {
                pageTitle = 'uTest Community - Create New Article';
              }
              return pageTitle;
            }],
            authenticated: UserAuthenticator,
          },
        })
        .state('updatearticle', {
          url: '/update/article/:articleId',
          parent: 'root',
          template: articleFormTemplate,
          controller: 'ArticleFormCtrl',
          resolve: {
            user: UserAuthenticator,
            item: ['$transition$', 'ArticleService', function ($transition$, ArticleService) {
              return ArticleService.find($transition$.params().articleId);
            }],
            title: ['item', (item) => {
              let pageTitle;
              if (item.data.id) {
                pageTitle = 'uTest Community - Edit Article';
              } else {
                pageTitle = 'uTest Community - Create New Article';
              }
              return pageTitle;
            }],
            authenticated: UserAuthenticator,
          },
        });
    }]);
  },
};

export default ArticleRouter;
