export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w890">
      <div class="section-home-cover-random">
        <h1>Welcome to uTest</h1>
        <div class="c12">
          <h2>Your Top 3 Questions answered right here:</h2>
        </div>
        <div class="c12">
          <h3>What is uTest?</h3>
          <p>Companies come to us to test their websites, apps, wearable apps, smart connection, and many more things in their final surroundings. 'Final surroundings' means that they want to test applications in that environment that the user would use them in real life. This is the biggest difference between what we do - community testing - and testing in a lab.</p>
          <p>Our customers understood that it is essential for product cycles to have an outstanding product right from the beginning. If you like to be confronted with exciting, outstanding apps and services before anyone else sees it, you're at the right place here. You will be invited to several testing projects and of course you will get paid for it.</p>
        </div>
        <div class="c12">
          <h3>Why do I need an account here?</h3>
          <p>The tests take place on our platform. All testing information is provided in so called 'test cycles'. You will learn here what is to be done in the project, how to get the apps and everything else that is essential information for the test. Everything that you have done and all invitations will come in through your very own account, so everything is fully scalable for you.</p>
        </div>
        <div class="c12">
          <h3> How do I get paid?</h3>
          <p>Within your account settings you have the option to either provide your PayPal email address or your payoneer email address. We process our payments every 2nd week and it wil lthen be released to your account.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="grid w890">
    <div class="c12">
      <h2>Your Checklist:</h2>
    </div>
    <div class="c12">
      <p>
      <form>
        <input type="checkbox" name="checkreg" value="account created">
        I already created my uTest account. Scroll down for help. <br><br>
        <input type="checkbox" name="checkreg" value="Payments">
        I set up my payment method (you can also do this later). Scroll down for help. <br><br>
        <input type="checkbox" name="checkreg" value="Payments">
        I participated in Sandbox. <br><br>
        <input type="checkbox" name="checkreg" value="Payments">
        I participated in my first paid project.
      </form>
      </p>
    </div>
    <div class="c12">
      <h2 id="Registration"></h2>
      <a name="Registration new tester acquisition"></a>
    </div>
    <div class="c12">
      <h3>Where do I sign up?</h3>
      <p>Click on this <a title="registration" href="https://www.utest.com/signup" target="_blank">link</a>. </p>
    </div>
    <div class="c12">
      <p>
      <ul>
        <li>Step 1: All fields that are marked as required.</li>
        <li>Step 2:
          <ul>
            <li>Availability: How much time do you want to spend on testing projects within a week?</li>
            <li>Testing Types: For the beginning I recommend to select "Functional" and "Usability". Functional is testing an appp or website to see if their functions work properly. This is either exploratory (you do whatever you want) or test case guided (we tell you what to do). If you know any other foreign language, you might want to check "Localization", too.</li>
            <li>Application types: Definitely check "Desktop" and "Web". These are basically the same and only indicate, that you have a computer. If you also have a mobile phone or a tablet, also check "Mobile". You can also list your Smart TV or other hardware under "Other"</li>
            <li>Native language: List all languages that you know and feel comfortable with.</li>
          </ul>
        <li>Step 3: Under "Functional" you want to check "Manual - Exploratory" at Novice. Select other points as well if you are a QA professional, accordning to your skills, but this is not mandatory! Under "Usability" you want to check "Expert Usability Review" at Novice. Now mark your experience with foreign languages.</li>
        <li>Step 4: Indicate the hardware that you want to test on. Do you have an iPhone? Tell us! Do you have a Samsung Tablet? Great, we might have projects for this device!</li>
      </ul>
      </p>
      <p>Click on save, then finish and that's it! You set up your account and you can now log into uTest using your credentials. Make sure to confirm your account beforehand. We've sent you an email. </p>
    </div>
    <div class="c12">
      <h3>Where do I set up my payment method?</h3>
      <p>When you're done creating an account, log into my.utest.com. Click on your name in the top right corner and select "Payments". Now scroll down to the end of the page and set up your payments method. </p>
    </div>
    <div class="c12">
      <h3>What happens next?</h3>
      <p>When you created your profile, we will invite you to your first project - the Sandbox class. This project will help you to understand the platform and to learn what you have to do. Once this is done (it will be quick) you're eligible to get invited to paid projects. Welcome to uTest, we're glad to have you on board. Feel free to send an email to <a href="mailto:ngrieger@utest.com?Subject=Sign-up%20Question" target="_top">Nico Grieger</a> with any questions you might have.</p>
    </div>
  </div>
</div>
`;
