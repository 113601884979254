const quoteExtension = () => {
  const quoteRegexp = /\[quote(="(.+)")?]([\s\S]+?)\[\/quote]/;

  return [{
    type: 'lang',
    filter: (text, converter) => {
      let match;
      let modifiedText = text;

      while ((match = modifiedText.match(quoteRegexp))) {
        const innerText = match[3];
        const innerHtml = converter.makeHtml(innerText);

        let opener;

        if (match[2]) {
          opener = `<blockquote data-poster-name="${match[2]}">`;
        } else {
          opener = '<blockquote>';
        }

        modifiedText = modifiedText.replace(quoteRegexp, `${opener + innerHtml}</blockquote>`);
      }

      return modifiedText;
    },
  }];
};

export default quoteExtension;
