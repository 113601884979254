const validationApi = function (IsValidService, $q) {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      ngModel: '=',
    },
    link(scope, element, attrs, ctrl) {
      const originalValue = scope.ngModel;

      ctrl.$asyncValidators.validationApi = function (modelValue) {
        const def = $q.defer();
        if (originalValue === modelValue) {
          def.resolve();
        } else {
          IsValidService.check(attrs.validationApi, modelValue).then((r) => {
            if (r.data[attrs.validationApi]) {
              def.resolve();
            } else {
              def.reject();
            }
          });
        }
        return def.promise;
      };
    },
  };
};
validationApi.$inject = ['IsValidService', '$q'];
export default validationApi;
