import _ from 'lodash';
import template from './write-status-fs.html';

const writeStatusFs = function ($timeout, $window, $state, StatusService, AlertService, UploadService, ServerMessages, ProgressBarService) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      status: '=?',
    },
    template,
    link(scope, el) {
      function setDefaultStatus() {
        scope.status = {
          content: '',
        };
      }

      if (!scope.status) {
        setDefaultStatus();
      }

      scope.submit = function () {
        StatusService.create(scope.status.content, _.get(scope.status, 'attachment.value', null)).then(() => {
          scope.close();
          setDefaultStatus();
          if (_.isFunction(scope.onAdd)) {
            scope.onAdd();
          }
        });
      };

      const unregister = scope.$watch('simplemde', (newV) => {
        if (newV) {
          unregister();
          if (el.hasClass('open')) {
            newV.codemirror.focus();
            newV.codemirror.setCursor(newV.codemirror.lineCount(), 0);
          }
          newV.codemirror.on('keyHandled', (editor, event) => {
            if (event === 'Enter') {
              const text = newV.codemirror.getValue();
              if (text.match(/\n/)) {
                newV.codemirror.setValue(text.replace(/\n/, ''));
                scope.submit();
              }
            }
          });
          newV.codemirror.setOption('mode', 'null');
          scope.$watch('isOpen', (newOpen, oldOpen) => {
            if (newOpen && newOpen !== oldOpen) {
              $timeout(() => {
                newV.codemirror.focus();
              }, 200);
            }
          });
        }
      });

      scope.setAttachment = function (att) {
        if (_.get(scope.status, 'attachment.id', false)) {
          UploadService.delete(scope.status.attachment.id);
        }
        scope.status.attachment = att;
        $timeout(() => {
          scope.innerStyle = {
            height: $window.innerHeight - (el.find('.write-status-fs-attachments').height() + 92),
          };
        });
      };

      scope.setAttachment({});

      scope.submit = function () {
        const attachmentUrl = _.get(scope.status, 'attachment.value', null);
        if (!scope.status.id) {
          StatusService.create(scope.status.content, attachmentUrl).then(() => {
            setDefaultStatus();
            AlertService.add('Status successfully posted');
            $state.go('community', {}, { reload: true });
            scope.close();
          }, (response) => {
            AlertService.addDanger(ServerMessages.prettyMessage(response));
            ProgressBarService.done();
          });
        } else {
          StatusService.update(scope.status).then(() => {
            AlertService.add('Status successfully updated');
            scope.close();
            $state.go('singlestatus', { statusId: scope.status.id }, { reload: true });
          });
        }
      };

      scope.close = function () {
        el.remove();
      };
    },
  };
};

writeStatusFs.$inject = ['$timeout', '$window', '$state', 'StatusService', 'AlertService', 'UploadService'];

export default writeStatusFs;
