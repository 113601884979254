import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { UnauthenticatedHomeComponent } from './unauthenticated-home/unauthenticated-home.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { WhyUtestComponent } from './why-utest/why-utest.component';
import { WistiaPopoverPlayerComponent } from './why-utest/wistia-popover-player.component';
import { CommunityNavComponent } from './why-utest/community-nav/community-nav.component';
import { QuotesNavComponent } from './why-utest/quotes-nav/quotes-nav.component';
import { AccordionComponent } from '../common/accordion/accordion.component';
import { AccordionGroupComponent } from '../common/accordion/accordion-group.component';
import { ContentSliderComponent } from '../common/contentslider/content-slider.component';
import { FirstWordLowerCase } from '../pipes/first-word-lower-case';
import { FourOhFourComponent } from '../four-oh/four-oh-four.component';
import { FourOhOneComponent } from '../four-oh/four-oh-one.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule.forRoot(),
  ],
  declarations: [
    FirstWordLowerCase,
    UnauthenticatedHomeComponent,
    AboutUsComponent,
    WhyUtestComponent,
    WistiaPopoverPlayerComponent,
    CommunityNavComponent,
    QuotesNavComponent,
    AccordionGroupComponent,
    AccordionComponent,
    ContentSliderComponent,
    FourOhFourComponent,
    FourOhOneComponent,
  ],
  exports: [
    UnauthenticatedHomeComponent,
    AboutUsComponent,
    WhyUtestComponent,
    FourOhFourComponent,
    FourOhOneComponent,
  ],
  entryComponents: [
    UnauthenticatedHomeComponent,
    AboutUsComponent,
    WhyUtestComponent,
  ],
})
class HomeModule {}

export { HomeModule };
