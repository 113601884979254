export default `
<p ng-if="!$ctrl.isGif" class="embedly-wrapper status-attachment" data-embedly-status-url="{{$ctrl.url}}" data-status-id="{{$ctrl.status.id}}">
  <a class="img-crop" ui-sref="singlestatus({statusId: $ctrl.status.id})" ng-if="$ctrl.localImage">
    <img ng-src="{{$ctrl.url}}">
  </a>
</p>
<div ng-if="$ctrl.isGif" ng-class="{'gif-control' : $ctrl.showGifControl}">
  <p class="embedly-wrapper status-attachment" data-embedly-status-url="{{$ctrl.url}}" data-status-id="{{$ctrl.status.id}}" id="status-url-{{$ctrl.id}}">
  </p>
  <button ng-if="$ctrl.showGifControl" class="sr-only" ng-click="$ctrl.togglePlayGif()" aria-label="{{$ctrl.isPlaying ? 'Pause' : 'Play'}} gif"></button>
</div>
`;
