import template from './user-list-item.component.html';

const userList = {
  bindings: {
    user: '<',
  },
  template,
};

export default userList;
