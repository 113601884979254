const internalLinkExtension = UrlService => [
  {
    type: 'output',
    regex: /(discussion|article|tool|course|project):\s*(\d+)/,
    replace(fullMatch, type, id) {
      let item: any = { type, slug: id };
      item = UrlService.addItemUrl(item);
      return item.url;
    },
  },
];

export default internalLinkExtension;
