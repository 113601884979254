export default `
<div ui-view="course" class="academy" id="mainContent">
  <div class="container-fluid">
    <div class="academy-header" ng-if="$ctrl.user && $ctrl.courseTrack">
      <a ui-sref="academy-home">Academy</a> /
      <span>{{$ctrl.courseTrack.name}}</span>
    </div>

    <div class="row">
      <div class="academy-course-track" ng-class="$ctrl.user && $ctrl.courseTrack ? 'col-md-8' : 'col-md-12'">
        <div class="academy-course" ng-if="$ctrl.course">
          <div class="course-view">
            <h1 class="course-title">{{$ctrl.course.title}}</h1>
            <div ng-if="!$ctrl.user">
              <div ng-bind="$ctrl.course.description"></div>
              <p>This is only preview of the full content. To view the whole course, please <a href="/login" target="_self">log in</a>.
            </div>
            <academy-course-section section="section" is-first-section="$index == 0" ng-repeat="section in $ctrl.course.sections"></academy-course-section>
          </div>
          <div class="course-view-actions" ng-if="$ctrl.user && $ctrl.courseTrack">
            <a class="academy-link-button previous-course" aria-label="Previous Course" ui-sref="track-course({trackId: $ctrl.courseTrack.id, courseId: $ctrl.previous.slug})" ng-hide="!$ctrl.previous">Previous</a>
            <a class="academy-link-button next-course" aria-label="Next Course" ui-sref="track-course({trackId: $ctrl.courseTrack.id, courseId: $ctrl.next.slug})" ng-hide="!$ctrl.next">Next</a>
            <a class="academy-link-button return-to-academy" ui-sref="academy-home" ng-show="!$ctrl.next && $ctrl.courseTrack.user_has_completed_course_track">
              <i class="material-icons" aria-hidden="true">
                school
              </i>
              Return to Academy
            </a>
            <a class="academy-link-button course-call-to-action" ui-sref="quiz({trackId: $ctrl.courseTrack.id})" ng-if="!$ctrl.courseTrack.user_has_completed_course_track" ng-show="!$ctrl.next && $ctrl.courseTrack.user_can_take_quiz && !$ctrl.courseTrack.user_has_passed_quiz">
              Take Quiz
            </a>
            <button class="course-call-to-action" ng-if="!$ctrl.courseTrack.user_has_completed_course_track"
                    ng-show="!$ctrl.next && $ctrl.courseTrack.has_test_cycle_link && $ctrl.courseTrack.user_can_apply_to_test_cycle"
                    ng-click="$ctrl.testCycleSignUp()">
              Sign Up
            </button>
          </div>
        </div>

        <div class="no-course" ng-if="!$ctrl.course && $ctrl.courseTrack">
          <span>No such course in {{$ctrl.courseTrack.name}}.</span>
          <a ui-sref="academy-home" class="clickable">Return to the Academy.</a>
        </div>
      </div>

      <course-track-nav user="$ctrl.user" course-track="$ctrl.courseTrack" ng-if="$ctrl.courseTrack && $ctrl.user"></course-track-nav>
    </div>
  </div>
</div>
`;
