/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

class AcademyHomeCtrl {
  constructor(UserService, ConfigService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ConfigService.config().then((res) => {
        ctrl.testerAppUrl = `${res.data.profile_url}/account/my-profile/`;
      });
      UserService.me().then((res) => {
        ctrl.isLightTester = res?.data?.is_light_tester;
      });
    };
  }
}

AcademyHomeCtrl.$inject = ['UserService', 'ConfigService'];

export default AcademyHomeCtrl;
