import _ from 'lodash';
import { StateService } from '@uirouter/core';

class ReferralCampaignService {
  $state: StateService;
  UrlService: any;
  $http: ng.IHttpService;
  constructor($state: StateService, UrlService, $http: ng.IHttpService) {
    this.$state = $state;
    this.UrlService = UrlService;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/referral_campaigns/${id}`).then(this.UrlService.addResponseUrl);
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10, filter: { state: 'published' } });

    return this.$http.get('/api/v1/referral_campaigns', { params: parameters }).then(this.UrlService.addResponseUrls);
  }
}

ReferralCampaignService.$inject = ['$state', 'UrlService', '$http'];

export default ReferralCampaignService;
