import _ from 'lodash';

class QuizResultItemCtrl {
  constructor() {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.question = _.find(ctrl.questions, { id: ctrl.resultItem.quiz_question_id });
      ctrl.answerIndex = _.findIndex(ctrl.question.answers, answer => (answer as any).id === ctrl.resultItem.id);
      ctrl.answerPosition = ['A', 'B', 'C', 'D', 'E'][ctrl.answerIndex];
    };
  }
}

export default QuizResultItemCtrl;
