export default `
<div id="mainContent">
  <div class="grid w890">
    <div class="section-submit-cover clearfix">
      <div class="feed-content">
        <h2>Submit a Testing Tool</h2>
        <div class="feed-content-footer">
          <span style="font-style:italic">If you see we're missing your favorite tool, submit it below and we'll take a look.</span>
        </div>
      </div>
    </div>
    <div class="section-submit-body">
      <div class="section-submit-body-content">
        <div ng-show="currentUser">
          <div ng-repeat="message in submitMessages track by $index" >
            <flash-message type="message.type" message="message.message" ></flash-message>
          </div>
          <form class="submit-tool-form" ng-submit="submitTool()" novalidate >
            <div class="clearfix" >
              <div class="c12 text-left">
                <label>*Required</label>
                <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true" ng-model="tool.category_id" >
                  <ui-select-match placeholder="Select a category" >{{$select.selected.name}}</ui-select-match>
                  <ui-select-choices repeat="item.id as item in tool_categories | filter: $select.search">
                    <div ng-bind-html="item.name | highlight: $select.search"></div>
                  </ui-select-choices>
                </ui-select>
              </div>
            </div>
            <div class="clearfix" >
              <div class="c3">
                <dropzone-input ng-model="tool.icon_id" max-files="1" placeholder="Upload Tool Icon" class="dropzone form-control dropzone-icon"></dropzone-input>
              </div>
              <div class="c9">
                <div class="c6">
                  <input required type="text" placeholder="Tool Name" ng-model="tool.name">
                </div>
                <div class="c6">
                  <input required type="url" placeholder="Tool Website URL" ng-model="tool.website">
                </div>
                <div class="c12">
                  <textarea required placeholder="Full Tool Description" ng-model="tool.description"></textarea>
                </div>
              </div>
            </div>
            <div class="text-left">
              <label>Gallery images</label>
              <dropzone-input ng-model="tool.gallery_ids" class="dropzone form-control"></dropzone-input>
            </div>
            <div style="text-align: center"><input type="submit" value="Submit Tool" class="btn btn-yellow"></div>
          </form>
        </div>
        <div ng-if="!currentUser">
          <p>You must be logged in to submit a tool.</p>
        </div>
      </div>
    </div>
  </div>
</div>
`;
