export default `
<div class="course-track-report-card" ng-hide="$ctrl.loading" id="mainContent">
  <!-- Report Card for Quiz Failure -->
  <div class="report-card-header" ng-show="!$ctrl.quizPassed">
    <h1 class="report-title">
      Oops!
      <span class="report-title-emoji" aria-hidden="true"></span>
    </h1>
    <p class="report-message">You answered at least one question incorrectly. Review your results below and retake the quiz when you're ready.</p>
  </div>
  <!-- Report Card for Quiz Success and no practice test cycle -->
  <div class="report-card-header" ng-show="$ctrl.quizPassed && !$ctrl.courseTrack.has_test_cycle_link">
    <h1 class="report-title">
      Congratulations!
      <span class="report-title-emoji" aria-hidden="true"></span>
    </h1>
    <p class="report-message">You answered everything correctly and passed the ‘{{$ctrl.courseTrack.name}}’ quiz. Nice work!</p>
    <a ui-sref="academy-home" class="academy-link-button academy-return">Return to Academy</a>
  </div>
  <!-- Report Card for Quiz Success and practice test cycle -->
  <div class="report-card-header" ng-show="$ctrl.quizPassed && $ctrl.courseTrack.has_test_cycle_link">
    <h1 class="report-title">
      Congratulations!
      <span class="report-title-emoji" aria-hidden="true"></span>
    </h1>
    <p class="report-message">You answered everything correctly and passed the ‘{{$ctrl.courseTrack.name}}’ quiz. Nice work!</p>
    <p class="test-cycle-signup-message">You are now eligible to participate in a practice test cycle, where you can practice what you have learned. Click below to sign up for the test cycle.</p>
    <button class="academy-return" ng-click="$ctrl.testCycleSignUp()">Sign Up</button>
  </div>
  <div class="report-card-body">
    <h2>Quiz Results</h2>
    <ol class="quiz-results">
      <quiz-result-item questions="$ctrl.courseTrack.quiz.questions" course-track="$ctrl.courseTrack" result-item="item" ng-repeat="item in $ctrl.quizResults.userChoice" role="listitem"></quiz-result-item>
    </ol>
  </div>
</div>
`;
