export default `
<div id="mainContent">
  <community-nav></community-nav>
  <uib-alert type="{{$ctrl.instruction.type}}" ng-repeat="instruction in $ctrl.instructions">{{instruction.message}}</uib-alert>
  <div class="section-single-cover article">
    <div class="block container-fluid">
      <a class="feed-header-info" ui-sref="profile.about({profileId: $ctrl.article.user.slug})" aria-label="Article Author, {{$ctrl.article.user.name}}"
        style="display: inline-block;">
        <div class="feed-header-info-icon">
          <div avatar-badges="$ctrl.article.user" class="profile-thumb"></div>
        </div>
        <div class="feed-header-info-name" style="display: inline-block;">
          <span class="feed-header-info-name__text">{{$ctrl.article.user.name}}</span>
          <br/>
          <span class="time-stamp">{{$ctrl.article.published_at | relativeDate}}</span>
        </div>
      </a>
      <span tabindex="0"
            class="fa fa-info-circle"
            role="button"
            style="vertical-align: top;"
            aria-label="{{ 'Open ' + $ctrl.article.user.name + ' tooltip' }}"
            dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: $ctrl.article.user})"
            ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: $ctrl.article.user})"
            ng-click="showTooltip('user-tooltip', {user: $ctrl.article.user});$event.preventDefault(); $event.stopImmediatePropagation()"
            ng-mouseleave="hideTooltip()"></span>
      <br/>
      <a class="btn btn-rounded btn-filled small edit-article" ui-sref="updatearticle({articleId: $ctrl.article.id})" ng-show="{{$ctrl.article.current_user_permissions.update}}">edit</a>
      <h1 aria-label="{{$ctrl.article.title}}, article by {{$ctrl.article.user.name}}">{{$ctrl.article.title}}</h1>
      <br/>
      <div class="tags" style="padding-top: 0; padding-bottom: 0;">
        <ut-tag-btn [tag]="tag" ng-repeat="tag in $ctrl.article.tags" on-finish-render="ngRepeatFinished"></ut-tag-btn>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="section-single-toolbar">
      <div class="clearfix">
        <div class="section-single-toolbar__cell_left">
          <ut-community-feed-item-like-btn [likeable]="$ctrl.article"></ut-community-feed-item-like-btn>
          <ut-community-feed-item-like-count [likeable]="$ctrl.article"></ut-community-feed-item-like-count>
        </div>
        <div class="section-single-toolbar__cell_right">
          <social-sharing target="$ctrl.article.url" text="$ctrl.article.title" type="Article"></social-sharing>
          <community-feed-item-hide item="$ctrl.article" ng-show="$ctrl.article.current_user_permissions.hide"></community-feed-item-hide>
          <community-feed-item-flag item="$ctrl.article" ng-show="$ctrl.article.current_user_permissions.flag"></community-feed-item-flag>
          <community-feed-item-subscribe item="$ctrl.article"></community-feed-item-subscribe>
          <community-archive-btn item="$ctrl.article"></community-archive-btn>
        </div>
      </div>
      <hr aria-hidden="true">
    </div>
  </div>
  <div class="section-single-body article">
    <div class="block container-fluid">
      <div class="section-single-body-content">
        <article ng-bind-html="$ctrl.article.content"></article>
      </div>
      <recommendations items="$ctrl.article.recommendations"></recommendations>
    </div>
  </div>
  <comment-section service="$ctrl.service" commentable="$ctrl.article" ></comment-section>
</div>
`;
