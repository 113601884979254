class CourseTrackReportCardCtrl {
  constructor($rootScope: ng.IRootScopeService, $element: JQuery, localStorageService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.emojiSpan = $element.find('.report-title-emoji');
      ctrl.loading = true;
      ctrl.loadQuizResults();
      ctrl.sortQuestions();
    };

    ctrl.sortQuestions = () => {
      if (ctrl.quizResults !== undefined && ctrl.quizResults.userChoice !== undefined) {
        ctrl.quizResults.userChoice.sort((q1, q2) => ((q1.quiz_question_id > q2.quiz_question_id) ? 1 : -1));
      }
    };

    ctrl.loadQuizResults = () => {
      const quizStorageKey = `courseTrackQuiz${ctrl.courseTrack.id}`;
      const userChoicesWithAnswers = localStorageService.get(quizStorageKey);
      ctrl.quizResults = userChoicesWithAnswers;
      ctrl.loadQuizStatus();
    };

    ctrl.loadQuizStatus = () => {
      ctrl.gradeQuiz();
      let resultEmoji = null;
      if (ctrl.quizPassed) {
        resultEmoji = ("<img src='assets/images/congratulations.png'>");
      } else {
        resultEmoji = ("<img src='assets/images/thinking-face.png'>");
      }
      ctrl.emojiSpan.html(resultEmoji);
    };

    ctrl.gradeQuiz = () => {
      ctrl.quizPassed = ctrl.courseTrack.user_has_passed_quiz;
      ctrl.loading = false;
    };

    ctrl.testCycleSignUp = () => {
      $rootScope.$broadcast('testCycleSignUp');
    };
  }
}

CourseTrackReportCardCtrl.$inject = ['$rootScope', '$element', 'localStorageService'];

export default CourseTrackReportCardCtrl;
