import angular from 'angular';
import template from './load-more.html';

const loadMore = function ($timeout) {
  return {
    restrict: 'AEC',
    replace: true,
    template,
    transclude: true,
    scope: {
      isLoading: '=',
      isLastPage: '=',
      loadCallback: '&',
      label: '@?',
    },
    link(scope, element, attrs) {
      const fadeTarget = angular.element(attrs.fadeTarget);

      if (!scope.label) {
        scope.label = 'Load More';
      }

      function reappear() {
        fadeTarget.removeClass('gone');
        element.removeClass('gone');
      }

      scope.loadMore = function () {
        fadeTarget.addClass('gone');
        element.addClass('gone');
        $timeout(reappear, 2000);
        scope.loadCallback();
      };
    },
  };
};

loadMore.$inject = ['$timeout'];

export default loadMore;
