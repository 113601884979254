import _ from 'lodash';
import { StateService } from '@uirouter/core';
import { STATUS_POST_MAX_CHARS } from '../../../constants';
import communityFeedItemDeleteOverlay from './community-feed-item-delete-overlay.component.html';

class CommunityFeedStatusUpdateCtrl {
  constructor(StatusService, AlertService, EmbedlyService, ServerMessages, UploadService, $state: StateService, $scope: ng.IScope, $uibModal) {
    const ctrl: ng.IController = this;
    ctrl.STATUS_POST_MAX_CHARS = STATUS_POST_MAX_CHARS;

    ctrl.$onInit = () => {
      ctrl.status = _.clone(ctrl.item);
      ctrl.status.attachment = {};
      if (ctrl.status.attached_url) {
        ctrl.status.attachment.label = ctrl.status.attached_url.substring(ctrl.status.attached_url.lastIndexOf('/') + 1);
        ctrl.status.attachment.icon = 'insert_drive_file';
        ctrl.status.attachment.value = ctrl.status.attached_url;
      }
      ctrl.activePopover = null;
      ctrl.isPopoverOpen = false;

      ctrl.dropzone = {
        onSuccess(file, res, dz) {
          ctrl.setAttachment({
            label: res.file_name,
            value: res.url,
            icon: 'insert_photo',
            type: 'upload',
            id: res.id,
          });
          ctrl.closePopover();
          dz.removeAllFiles(true);
        },
        onError(err, dz) {
          dz.element.focus();
          if (_.isString(err)) {
            AlertService.add(err, 'danger');
          } else {
            switch (err.xhr.status) {
              case 400:
                setTimeout(() => {
                  AlertService.add('File type not allowed', 'danger');
                }, 500);
                break;
              case 0:
              case 413:
                AlertService.add('File too large', 'danger');
                break;
              case 429:
                AlertService.add('You reached the maximum allowed uploads in an hour. Please try again later.', 'danger');
                break;
              default:
                AlertService.add('File couldn\'t be uploaded', 'danger');
                break;
            }
          }
          dz.removeAllFiles(true);
          $scope.$apply();
        },
      };

      $scope.$on('statusDelete', (_event, _status) => {
        ctrl.deletePopover();
      });
    };

    ctrl.setAttachment = (attachment) => {
      // If user had uploaded an attachment earlier, delete it
      if (_.get(ctrl.item, 'attachment.id', false)) {
        UploadService.delete(ctrl.item.attachment.id);
      }
      ctrl.status.attachment = attachment;
    };

    ctrl.togglePopover = (event, attachmentType) => {
      event.preventDefault();
      const attachmentPopover = $(`.attachment_popover_${ctrl.item.id}`);

      if (attachmentType !== ctrl.activePopover) {
        ctrl.isPopoverOpen = true;
        const $target = $(event.target);
        attachmentPopover.css('left', $target.position().left + ($target.outerWidth(true) / 2));
        ctrl.activePopover = attachmentType;
        setTimeout(() => {
          $('button.active').focus();
        }, 100);
      } else {
        ctrl.closePopover();
      }
    };

    ctrl.closePopover = () => {
      const contentBtn: any = ctrl.activePopover === 'image' ? document.getElementsByClassName(`community-feed-status-update__image-button material-icons ${ctrl.item.id}`) : document.getElementsByClassName(`community-feed-status-update__video-button material-icons ${ctrl.item.id}`);
      const index = contentBtn.length > 1 ? 1 : 0;
      contentBtn[index].focus();
      ctrl.activePopover = null;
      ctrl.isPopoverOpen = false;
    };

    ctrl.submitAttachment = () => {
      EmbedlyService.getEmbed(ctrl.attachmentUrl).then(({ data }) => {
        if (ctrl.activePopover === 'image') {
          ctrl.setAttachment({
            label: ctrl.attachmentUrl.substring(ctrl.attachmentUrl.lastIndexOf('/') + 1),
            value: ctrl.attachmentUrl,
            icon: 'insert_photo',
          });
        } else {
          ctrl.setAttachment({
            label: data.title,
            value: ctrl.attachmentUrl,
            icon: 'play_circle_filled',
          });
        }

        ctrl.attachmentUrl = null;
      }).catch((err) => {
        if (err.data && err.data.errors) {
          AlertService.add(err.data.errors[0].code, 'danger');
        } else {
          AlertService.add('An unexpected error occured. Please try a different file.', 'danger');
        }
      });

      ctrl.closePopover();
    };

    ctrl.deletePopover = () => {
      ctrl.deleteModal = $uibModal.open({
        animation: true,
        template: communityFeedItemDeleteOverlay,
        controller: 'ModalCtrl',
        windowClass: 'community-feed-status-delete-modal',
        resolve: {
          data() {
            return {
              item: ctrl.item,
              delete: ctrl.deleteItem,
              closeOverlay() {
                ctrl.deleteModal.close();
              },
            };
          },
        },
      }).result.catch((resp) => {
        if (['cancel', 'backdrop click', 'escape key press'].indexOf(resp) === -1) throw resp;
      });
    };

    ctrl.deleteItem = () => {
      if (ctrl.item.type === 'status') {
        StatusService.delete(ctrl.item.id).then(() => {
          $scope.$emit('statusDeleted', ctrl.item);
          AlertService.add('Status successfully deleted');
        }, () => {
          AlertService.add('There was an error', 'danger');
        });
      }
    };

    ctrl.reset = (editing) => {
      ctrl.editing = editing;
      ctrl.status.content = ctrl.item.content || '';
    };

    ctrl.updateStatus = () => {
      ctrl.status.attached_url = _.get(ctrl.status, 'attachment.value', null);

      if (_.isEmpty(_.trim(ctrl.status.content)) && !ctrl.status.attached_url) {
        AlertService.addDanger('Can\'t post empty status!');
        return;
      }

      ctrl.posting = true;
      StatusService.update(ctrl.status, ctrl.status.attached_url).then((_response) => {
        AlertService.add('Status successfully updated');
      }, (response) => {
        ctrl.reset(true);
        AlertService.addDanger(ServerMessages.prettyMessage(response));
      }).finally(() => {
        ctrl.item.content = ctrl.status.content;
        ctrl.content = ctrl.item.content;
        ctrl.item.attached_url = ctrl.status.attached_url;
        ctrl.item = ctrl.status;
        ctrl.posting = false;
        ctrl.editing = false;
        $scope.$emit('statusUpdated', ctrl.item);
        $state.go('community', {}, { reload: true });
      });
    };
  }
}

CommunityFeedStatusUpdateCtrl.$inject = ['StatusService', 'AlertService', 'EmbedlyService', 'ServerMessages', 'UploadService', '$state', '$scope', '$uibModal'];

export default CommunityFeedStatusUpdateCtrl;
