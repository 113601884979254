/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

const embed = ({
  $scope, $element, $compile, easymde,
}) => {
  const template = `
    <div class="codeMirror-toolbar-dropdown embed-toolbar">
        <span class="arrow"></span>
        <div class="embedzone" >
        <div>
            <label for="embedzoneInput">Paste a URL</label>
            <input id="embedzoneInput" class="form-control" type="text" ng-model="embed.url" placeholder="Paste a URL to create an Embed..." >
        </div>
        <button type="button" ng-click="embed.action()" class="btn embed-button">Add</button>
        </div>
    </div>
  `;

  const youtubeScope = $scope.$new();
  const embedWindow = $compile(template)(youtubeScope);

  youtubeScope.embed = {
    url: '',
    action() {
      easymde.then((easyMDE) => {
        const linkifiedYoutube = `![](${youtubeScope.embed.url})`;
        easyMDE.codemirror.replaceSelection(linkifiedYoutube);
        youtubeScope.embed.url = '';
      });
    },
  };

  const toggleWindow = (editor) => {
    const inputInFocus = $element.find('input').is(':focus');
    const toolbarSlot = $(editor.toolbarElements.embed);

    const displaying = embedWindow.is(':visible');

    if (displaying && !inputInFocus) {
      $element.append(embedWindow);
    } else if (!displaying) {
      const otherDropdowns = toolbarSlot.parent().find('.codeMirror-toolbar-dropdown');
      toolbarSlot.append(embedWindow);
      $element.append(otherDropdowns);
    }
  };

  return {
    embed: {
      name: 'embed',
      title: 'Embed',
      className: 'fa fa-share-square-o',
      action: toggleWindow,
    },
  };
};

export default embed;
