/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EasymdeModule } from 'ngx-easymde';
import { FormsModule } from '@angular/forms';
import { UtEditorComponent } from './ut-editor.component';
import angularjsDependencyProviders from '../../angularjs.providers';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    EasymdeModule,
  ],
  declarations: [
    UtEditorComponent,
  ],
  exports: [
    UtEditorComponent,
  ],
  entryComponents: [
    UtEditorComponent,
  ],
  providers: angularjsDependencyProviders,
})
class UtEditorModule {}

export { UtEditorModule };
