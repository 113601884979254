const FeatureToggleResolver = ['FeatureToggleService', '$q', 'featureFlags', (FeatureToggleService, $q, featureFlags) => {
  const deferred = $q.defer();
  FeatureToggleService.getEnabledFeatures(1800).then((result) => {
    deferred.resolve(featureFlags.set(result.data));
  }, () => {
    deferred.resolve(null);
  });

  return deferred.promise;
}];

export default FeatureToggleResolver;
