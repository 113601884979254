export default `
<div id="mainContent">
  <div class="section-home-cover">
    <div class="section-home-cover-bg">
      <div bg-img="{{coverBg}}" swap="{{headerData.coverBg.large}}"></div>
    </div>
    <div class="block container">
      <a ui-sref="submitatool" id="coverBtn" class="btn btn-rounded small btn-filled">Submit a Tool</a>
      <h3 class="section-title">{{headerData.title}}</h3>
      <p class="section-desc" ng-bind-html="headerData.description"></p>
    </div>
  </div>
  <div class="block container">
    <tool-category-item tool-category="category" ng-repeat="category in tool_categories" class="section-home-feed"></tool-category-item>
  </div>
  <div class="block container clearfix tool">
    <uib-tabset>
      <uib-tab heading="Tools">
        <tool-feed-item tool="tool" ng-repeat="tool in groups.highestRated.feedItems" class="section-home-feed"></tool-feed-item>
        <load-more load-callback="groups.highestRated.loadMore()" is-loading="groups.highestRated.isLoading" is-last-page="groups.highestRated.isLastPage" fade-target="footer"></load-more>
      </uib-tab>
      <uib-tab heading="Recently Added" select="groups.recentlyAdded.loadOnce()">
        <tool-feed-item tool="tool" ng-repeat="tool in groups.recentlyAdded.feedItems" class="section-home-feed"></tool-feed-item>
        <load-more load-callback="groups.recentlyAdded.loadMore()" is-loading="groups.recentlyAdded.isLoading" is-last-page="groups.recentlyAdded.isLastPage" fade-target="footer"></load-more>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
