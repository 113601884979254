import { shortnameToImage } from 'emojione';

class EmojiCategoryController {
  constructor($scope, $state, $transition$) {
    $scope.activeCategory = $transition$.params().category || 'activity';

    $scope.toneCheck = function (em) {
      return !em.tone || em.tone === $scope.activeTone;
    };

    $scope.shortnameToImage = shortnameToImage;
  }
}

EmojiCategoryController.$inject = ['$scope', '$state', '$transition$'];

export default EmojiCategoryController;
