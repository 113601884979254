const autoPadding = function ($timeout) {
  return {
    restrict: 'A',
    scope: {
      autoPaddingEvent: '@',
    },
    link(scope, element, attrs) {
      scope.$on(scope.autoPaddingEvent, () => {
        $timeout(() => {
          const height = $(`#${attrs.autoPadding}`).height();
          $(element).css('padding-top', `${height}px`);
        }, 500);
      });
    },
  };
};
autoPadding.$inject = ['$timeout'];

export default autoPadding;
