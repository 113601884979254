class ViewMoreCtrl {
  constructor($timeout) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.label = 'View More';
    };

    ctrl.loadMore = () => {
      $timeout(ctrl.loadCallback(), 2000);
    };
  }
}

ViewMoreCtrl.$inject = ['$timeout'];

export default ViewMoreCtrl;
