import _ from 'lodash';

const FeedStoreFactory = function (AlertService) {
  return function (fetchFeed, storeParameters) {
    const self = this;
    let latestHeaders;

    self.parameters = {};
    self.fetchFeed = fetchFeed;

    self.feedItems = [];
    self.isLastPage = true;
    self.isLoading = false;

    const handleResponse = function (response) {
      latestHeaders = response.headers;
      Array.prototype.push.apply(self.feedItems, response.data);
      self.isLastPage = !latestHeaders('X-Next-Page');
      self.isLoading = false;

      return response;
    };

    const handleErrorResponse = function (response) {
      AlertService.addDanger(response.statusText);
      self.isLoading = false;
    };

    const selfPromise = {
      then(callback) {
        callback(self);
      },
    };

    const doFetch = function (page?) {
      if (self.isLoading) {
        return selfPromise;
      }

      const pagination = {
        page: (page || 1),
        per_page: 10,
      };

      const parameters = _.merge({}, pagination, storeParameters, self.parameters);

      self.isLoading = true;
      return self.fetchFeed(parameters).then(handleResponse, handleErrorResponse).then(() => self);
    };

    self.reset = function () {
      self.feedItems = [];
    };

    self.loadOnce = function () {
      if (_.isEmpty(self.feedItems)) {
        return self.load();
      }
      return selfPromise;
    };

    self.load = function () {
      self.feedItems = [];

      return doFetch();
    };

    self.loadMore = function () {
      if (_.isEmpty(self.feedItems)) {
        return self.load();
      }

      const nextPage = latestHeaders('X-Next-Page');

      if (nextPage) {
        return doFetch(nextPage);
      }
      return selfPromise;
    };
  };
};

FeedStoreFactory.$inject = ['AlertService'];

export default FeedStoreFactory;
