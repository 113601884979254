/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import { FormControl, FormControlOptions, FormControlStatus, ValidationErrors } from '@angular/forms';
import { createNgfControlEnhancement, NgfControlEnhancement } from '@ng-falcon/ngf-form/+model/enhancement/ngf-control-enhancement';
import { Observable } from 'rxjs';

export type NgfFormControlOptions = Omit<FormControlOptions, 'nonNullable'> & { disabled?: boolean; }

export class NgfFormControl<T> extends FormControl<T> implements NgfControlEnhancement<FormControl<T>['value']> {
  // keeps track of every pristine value when set or marked
  private pristineValue: T;

  readonly value$: Observable<FormControl<T>['value']>;
  readonly errors$: Observable<ValidationErrors | null>;
  readonly touched$: Observable<boolean>;
  readonly untouched$: Observable<boolean>;
  readonly pristine$: Observable<boolean>;
  readonly dirty$: Observable<boolean>;
  readonly status$: Observable<FormControlStatus>;
  readonly valid$: Observable<boolean>;
  readonly invalid$: Observable<boolean>;
  readonly pending$: Observable<boolean>;
  readonly enabled$: Observable<boolean>;
  readonly disabled$: Observable<boolean>;

  constructor(value: T, opts?: NgfFormControlOptions) {
    super(value, { ...opts, nonNullable: true });

    const ngfControlEnhancement = createNgfControlEnhancement(this);
    this.value$ = ngfControlEnhancement.value$;
    this.errors$ = ngfControlEnhancement.errors$;
    this.touched$ = ngfControlEnhancement.touched$;
    this.untouched$ = ngfControlEnhancement.untouched$;
    this.pristine$ = ngfControlEnhancement.pristine$;
    this.dirty$ = ngfControlEnhancement.dirty$;
    this.status$ = ngfControlEnhancement.status$;
    this.valid$ = ngfControlEnhancement.valid$;
    this.invalid$ = ngfControlEnhancement.invalid$;
    this.pending$ = ngfControlEnhancement.pending$;
    this.enabled$ = ngfControlEnhancement.enabled$;
    this.disabled$ = ngfControlEnhancement.disabled$;

    if (opts?.disabled) {
      this.disable();
    }

    this.pristineValue = value;
  }

  getPristineValue(): T {
    return this.pristineValue;
  }

  // every time control is marked as pristine,
  // we want to remember current value
  // this allows us to later mark control as pristine or dirty
  // or reset control to pristine value
  override markAsPristine(opts?: { onlySelf?: boolean }): void {
    super.markAsPristine(opts);
    this.pristineValue = this.value;
  }

  override setValue(value: T, options?: { onlySelf?: boolean; emitEvent?: boolean; emitModelToViewChange?: boolean; emitViewToModelChange?: boolean }): void {
    super.setValue(value, options);
    this.markAsPristineOrDirty();
  }

  override patchValue(value: T, options?: { onlySelf?: boolean; emitEvent?: boolean; emitModelToViewChange?: boolean; emitViewToModelChange?: boolean }): void {
    super.patchValue(value, options);
    this.markAsPristineOrDirty();
  }

  /**
   * 
   * @param value
   * @param options 
   * Note: this is different the FormControl<T> version as that
   * uses the default value and this resets to pristine if nothing provided
   */
  override reset(value: T = this.pristineValue, options?: { onlySelf?: boolean; emitEvent?: boolean }): void {
    super.reset(value, options);
  }


  private markAsPristineOrDirty(): void {
    if (this.isValuePristine(this.value, this.pristineValue)) {
      super.markAsPristine();
    } else {
      super.markAsDirty();
    }
  }

  protected isValuePristine(value: T, pristineValue: T): boolean {
    return value === pristineValue;
  }

}
