import controller from './single-article.controller';
import template from './single-article.component.html';

const singleArticle = {
  bindings: {
    article: '<',
  },
  template,
  controller,
};

export default singleArticle;
