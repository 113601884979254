import _ from 'lodash';

class HallOfFameCtrl {
  constructor($scope, $state, $transition$, HallOfFameService, $timeout, UserService, $location, periods) {
    $scope.search = {
      year: $transition$.params().year,
      period: $transition$.params().period,
    };

    $scope.searchOptions = {
      years: _.map(periods, 'year'),
      periods: _.find(periods, ['year', $transition$.params().year]).periods,
    };

    function setNavigation() {
      const i = _.indexOf($scope.searchOptions.periods, $scope.search.period);
      const nextI = i + 1;
      const prevI = i - 1;
      if (!_.isUndefined($scope.searchOptions.periods[nextI])) {
        $scope.next = { year: $transition$.params().year, period: $scope.searchOptions.periods[nextI] };
      }
      if (!_.isUndefined($scope.searchOptions.periods[prevI])) {
        $scope.previous = { year: $transition$.params().year, period: $scope.searchOptions.periods[prevI] };
      }
    }

    setNavigation();

    $scope.isLoading = true;

    HallOfFameService.winners($transition$.params().year, $transition$.params().period).then(({ data }) => {
      $scope.data = data;
      $scope.isLoading = false;
    });

    $scope.searchYearChange = function () {
      const selectedYear = _.find(periods, ['year', $scope.search.year]);
      $state.go('halloffamedate', { year: $scope.search.year, period: selectedYear.periods[0] });
    };

    $scope.searchPeriodChange = function () {
      $state.go('halloffamedate', { year: $scope.search.year, period: $scope.search.period });
    };
  }
}

HallOfFameCtrl.$inject = ['$scope', '$state', '$transition$', 'HallOfFameService', '$timeout', 'UserService', '$location', 'periods'];

export default HallOfFameCtrl;
