import {
  SET_CURRENT_USER,
  SET_CURRENT_USER_UNREAD_ACTIVITIES_COUNT,
} from '../actions';

const defaultState = {
  currentUser: {
    unread_activities_count: 0,
    unread_chats_count: 0,
  },
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const userReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SET_CURRENT_USER:
      state.currentUser = action.user;
      return state;
    case SET_CURRENT_USER_UNREAD_ACTIVITIES_COUNT:
      state.currentUser.unread_activities_count = action.activitiesCount;
      return state;
    default:
      return state;
  }
};

export default userReducer;
