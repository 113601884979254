import _ from 'lodash';

class GamificationCtrl {
  constructor($scope, GamificationService, UserService) {
    GamificationService.all().then((badges) => {
      $scope.badges = badges;
    });

    let myBadges = [];

    UserService.me().then((res) => {
      if (res !== null) {
        myBadges = _.map(res.data.badges, b => b.badge_type);
      }
    });

    $scope.checkDone = function (badge) {
      return myBadges.indexOf(badge) >= 0;
    };
  }
}

GamificationCtrl.$inject = ['$scope', 'GamificationService', 'UserService'];

export default GamificationCtrl;
