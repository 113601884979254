/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Attribute, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'ngf-pending',
  templateUrl: './pending.component.html',
  styleUrls: ['./pending.component.scss']
})
export class PendingComponent {
  constructor(@Attribute('inline') public inline: any) {}

  @HostBinding('class.as-overlay')
  asOverlay = false;
}
