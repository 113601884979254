export default `
<div class="ntux-select">
  <button class="ntux-select-value" ng-click="$ctrl.toggleOptions();" title="{{$ctrl.selectedDisplay}}" aria-haspopup="listbox" aria-labelledby="ntuxSelectLabel" aria-expanded="{{$ctrl.isOpen}}">
    {{$ctrl.selectedDisplay}}<i class="material-icons" aria-hidden="true">keyboard_arrow_down</i>
  </button>
  <ul class="ntux-select-options" role="listbox" tabindex="-1">
    <li class="ntux-select-option" ng-click="$ctrl.setValue(option);$ctrl.hideOptions('setFocus')" ng-keydown="$ctrl.handleKeyup($event, option)" ng-repeat="option in $ctrl.options" on-finish-render="ngRepeatFinished" ng-bind-html="option.display | trustAsHtml" tabindex="0" role="option" aria-selected="{{$ctrl.selectedValue}}"></li>
  </ul>
</div>
`;
