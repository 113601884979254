/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import angular from 'angular';

const easymdeInstance = () => {
  const parent = document.createElement('div');
  parent.style.display = 'none';
  const editorElement = document.createElement('textarea');
  parent.appendChild(editorElement);
  // @ts-ignore
  return new EasyMDE({
    placeholder: ' ', element: editorElement, toolbar: false, status: false,
  });
};

const markdownInstance = easymdeInstance();
const markdownFunction = Object.getPrototypeOf(markdownInstance).markdown;

const uMarkdownFilter = (EmbedlyService, CompileService, $filter, CookieAgreementService) => (input) => {
  if (_.isString(input)) {
    const urlRegex = /^(((http|https|ftp|ftps):\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?)|((\/[a-z0-9]+)+(([\-\.]{1}[a-z0-9]+)?)+)|(\#[a-z0-9]+)|(mailto:(([\w+\-].?)+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+))$/igm;
    input = input.replace(/\[quote[^\]]*\](.*)\[\/quote[^\]]*\]/g, (match, quote) => `<blockquote>${quote}</blockquote>`);

    input = input.replace(/!\[[^\]]*\]\((https?:\/\/[^) ]*)\)/g, (match, url) => {
      const key = Date.now().toString();
      EmbedlyService.extract(url, null, key);
      return `<p class="embedly-wrapper" data-embedly-${key}="${_.escape(url)}"></p>\n`;
    });

    input = input.replace(/!\[([^\]]*)\]\(([^)]*[jpe?g|png|gif][^) ]+)\)/g, (match, alt, src) => {
      const id = `img-crop-${src}-${alt}`;

      let template = '<a class="status-attachment_link" target="_blank" rel="noopener noreferrer" ng-href="{{::src}}" >{{::src}}</a>';
      if (CookieAgreementService.hasAcceptedTargetingCookies()) {
        template = '<img-crop src="{{::src}}" alt="{{::alt}}"></img-crop>';
      }

      CompileService.replace(id, template, { src, alt });
      return `<div data-compile-replace="${_.escape(id)}"></div>\n`;
    });

    input = $filter('ulinky')(input);
    // check for emoji followed by newLine and add space inbetween to force newLine when markdownFunction is called
    input = input.replace(/(<img.*class=.emojione.*>)(\n)/g, (match, imgTag, newLine) => `${imgTag} ${newLine}${newLine}`);

    input = markdownFunction.call(markdownInstance, input);
    input = input.replace('<head></head><body>', '').replace('</body>', '');

    const replacement = input.includes('target="_blank"') ? '<a rel=no-opener no-referer href=' : '<a target="_blank" rel=no-opener no-referer href=';
    input = input.replace(/<a\s+href=/gi, replacement);

    const urlsWithHref = input.match(/<a(.*?)\s+href="([^"]*)"(.*?)>(.*?)<\s*\/a>/gi);
    if (urlsWithHref !== undefined && urlsWithHref !== null && urlsWithHref.length > 0) {
      angular.forEach(urlsWithHref, url => {
        url.replace(/<a(.*?)\s+href="([^"]*)"(.*?)>(.*?)<\s*\/a>/gi, (match, alt, src) => {
          const desc = (/>(.*?)<\s*\/a>/gi).exec(match)[1]; // description of 'a' tag
          if (match !== undefined && src !== undefined && !src.match(urlRegex)) {
            input = input.split(match).join(desc); // let's remove an invalid link
          }
        });
      });
    }
    return input;
  }
  return input;
};

uMarkdownFilter.$inject = ['EmbedlyService', 'CompileService', '$filter', 'CookieAgreementService'];

export default uMarkdownFilter;
