export default `
<div class="feed project" ng-class="{ongoing: !$ctrl.project.urgent, urgent: $ctrl.project.urgent, archived: $ctrl.project.state == 'archived', featured: $ctrl.project.featured}">
  <div class="feed-header clearfix">
    <div class="feed-header-info">
      <div class="feed-header-info-icon">
        <i ng-show="!$ctrl.project.urgent" class="material-icons">assignment</i>
        <i ng-show="$ctrl.project.urgent" class="material-icons">error_outline</i>
      </div>
      <div class="feed-header-info-name">
        <h5 ng-show="!$ctrl.project.urgent">Ongoing Projects</h5>
        <h5 ng-show="$ctrl.project.urgent">Urgent Projects</h5>
      </div>
      <div class="feed-header-featured" ng-show="$ctrl.project.featured">
        <i class="material-icons" >stars</i>
        <h5>Featured</h5>
      </div>
    </div>
    <div class="func-btns">
      <social-sharing is-popover="true" target="$ctrl.project.url" text="$ctrl.project.title" type="Project"></social-sharing>
    </div>
  </div>
  <div class="feed-content">
    <div class="feed-content-heading clearfix">
      <h2>
        <a id="project_title_{{$ctrl.project.id}}" class="project_title_{{$ctrl.project.id}}" ng-click="$ctrl.setFeed()" ui-sref="singleproject({projectId : $ctrl.project.slug})">{{$ctrl.project.title}}</a>
        <span class="icon-check green" ng-if="$ctrl.project.user_has_applied"></span>
      </h2>
      <div class="feed-content-main" ng-if="!$ctrl.featured">
        <p>{{$ctrl.project.content | cleanUp}}</p>
      </div>
      <h4 class="feed-date">Start date: <span>{{$ctrl.project.start_date | date: 'MMM dd'}}</span></h4>
      <div ng-show="$ctrl.project.countries.length != 0" class="feed-countries"><i class="material-icons">location_on</i><span>{{$ctrl.project.countries.join(', ')}}<span></div>
    </div>
  </div>
</div>
`;
