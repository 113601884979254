export default `
<div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
    <div>
        <h3 class="form-header-text">Oops!</h3>
    </div>
    <p>There was a problem creating your account.</p>

    <p>Please follow the instructions in your email to finish creating a uTest account and set a payment method.</p>

    <div class="simple-registration-image-container">
        <img class="utest-registration-finished-logo" src="/assets/images/simple-registration-error.png" alt="">
    </div>
</div>
`;
