export default `
<div class="modal-header">
  <button type="button" class="close" ng-click="cancel()" aria-label="Close">
    <i class="icon-cancel"></i>
  </button>
</div>
<div class="modal-body">
  <div class="feed-item-hide-message">
    {{item.state === 'hidden' ? 'Show this item?' : 'Hide this item?'}}
  </div>
  <button class="community-cta__button unfilled" autofocus ng-click="cancel()">Cancel</button>
  <button class="community-cta__button" ng-click="hide()" ng-mouseup="cancel()">{{item.state === 'hidden' ? 'Show' : 'Hide'}}</button>
</div>
`;
