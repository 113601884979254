import _ from 'lodash';
import angular from 'angular';

class TransferDiscussionCtrl {
  constructor($scope, $state, DiscussionCategoryService, DiscussionService, AlertService, ProgressBarService, ServerMessages, user, $timeout, $element) {
    if (user.user_type !== 'admin') {
      $state.go('fourohone', null, { location: false });
    }

    DiscussionCategoryService.allForSelect().then((categories) => { $scope.categories = categories; });

    $scope.instructions = [{
      type: 'danger',
      message: 'Please note that this operation cannot be undone. Once you moved topics from one category to another you cannot move them back easily.',
    }];

    $scope.closeAlert = function (index) {
      $scope.instructions.splice(index, 1);
    };

    $scope.transfer = {};

    $scope.saveInProgress = false;

    $scope.transfer = function () {
      if ($scope.transfer && $scope.transfer.fromCategory && $scope.transfer.toCategory && !$scope.saveInProgress) {
        if ($scope.transfer.fromCategory === $scope.transfer.toCategory) {
          AlertService.add('From and To category must be different', 'danger');
        } else {
          $scope.saveInProgress = true;

          ProgressBarService.start();

          DiscussionService.transfer({
            from_category_id: $scope.transfer.fromCategory.id,
            to_category_id: $scope.transfer.toCategory.id,
          }).then((_res) => {
            AlertService.add('Discussions successfully Trasfered');
            ProgressBarService.done();
          }, (response) => {
            AlertService.addDanger(ServerMessages.prettyMessage(response));
            $scope.saveInProgress = false;
            ProgressBarService.done();
          });
        }
      } else {
        AlertService.add('Please fill out all the fields', 'danger');
      }
    };

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-match').attr('aria-label', txt);
          angular.element(elem).find('.ui-select-toggle').attr('aria-label', txt);
        });
      }
    }, 250);
  }
}

TransferDiscussionCtrl.$inject = ['$scope', '$state', 'DiscussionCategoryService', 'DiscussionService', 'AlertService', 'ProgressBarService', 'ServerMessages', 'user', '$timeout', '$element'];

export default TransferDiscussionCtrl;
