class NavHeaderChatCtrl {
  constructor($interval: ng.IIntervalService, UserService, ConfigService) {
    const ctrl: ng.IController = this;

    ConfigService.config().then((res) => {
      ctrl.chatUrl = res.data.chat_url;
    });

    ctrl.$onInit = () => {
      ctrl.checkForUnreadMessages();
    };

    ctrl.checkForUnreadMessages = () => {
      if (document.hidden) {
        return;
      }
      UserService.unreadMessages().then((res) => {
        ctrl.hasUnreadMessages = res.data.has_unread_messages;
        ctrl.unreadMessageCount = res.data.unseen_mention_count > 0 ? res.data.unseen_mention_count : '';
        if (ctrl.unreadMessageCount >= 99) {
          ctrl.unreadMessageCount = '99';
        }
      });
    };

    ctrl.chatInterval = $interval(ctrl.checkForUnreadMessages, 30000);
  }
}

NavHeaderChatCtrl.$inject = ['$interval', 'UserService', 'ConfigService', '$scope', 'featureFlags'];

export default NavHeaderChatCtrl;
