import _ from 'lodash';

const mentions = (stateStore, LinkExtractor) => (input, mentions) => {
  const le = new LinkExtractor(input);

  let leText = le.text;

  _.forEach(mentions, (mention, handle) => {
    const url = stateStore({
      type: 'user',
      slug: mention.slug,
    });
    leText = leText.replace(handle, `<a title="${mention.name}" href="${url}">@${mention.slug}</a>`);
  });

  return le.returnLinks(leText);
};

mentions.$inject = ['stateStore', 'LinkExtractor'];

export default mentions;
