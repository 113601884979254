import template from './course-track-quiz-progress-bar.component.html';

const courseTrackQuizProgressBar = {
  bindings: {
    questions: '<',
    step: '<',
  },
  template,
};

export default courseTrackQuizProgressBar;
