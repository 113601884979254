import _ from 'lodash';

const flashMessagePromise = function () {
  return {
    restrict: 'E',
    replace: false,
    scope: {
      promise: '=',
    },
    template: '<flash-message type="type" message="message" ></flash-message>',
    link(scope) {
      scope.promise.then(() => {
        scope.message = 'Changes have been successfully saved.';
        scope.type = 'success';
      }, (e) => {
        if (_.isArray(e.data.errors) && !_.isUndefined(e.data.errors[0].code)) {
          scope.message = e.data.errors[0].code;
        } else {
          scope.message = 'Error occurred!';
        }
        scope.type = 'danger';
      });
    },
  };
};

export default flashMessagePromise;
