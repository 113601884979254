import template from './academy-course-track-report-card.component.html';

const academyCourseTrackReportCard = {
  bindings: {
    courseTrack: '<',
    user: '<',
  },
  template,
};

export default academyCourseTrackReportCard;
