import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { CommentBtnComponent } from './comment-btn/comment-btn.component';
import { RelativeDate } from '../pipes/relative-date';
import { BgImgDirective } from './directives/bg-img/bg-img.directive';

@NgModule({
  imports: [
    CommonModule,
    UIRouterModule,
  ],
  declarations: [
    CommentBtnComponent,
    RelativeDate,
    BgImgDirective,
  ],
  exports: [
    CommentBtnComponent,
    RelativeDate,
    BgImgDirective,
  ],
})
class SharedModule {}

export { SharedModule };
