import _ from 'lodash';

class ActivityFeedCtrl {
  constructor($timeout, ActivityService, UserService, FeedStore) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.notificationFilterOptions = [
        { display: 'All Notifications', value: 'all' },
        { display: 'Projects Notifications', value: 'projects' },
        { display: 'Community Notifications', value: 'community' },
      ];

      ctrl.updateFeed = function (feedType) {
        ctrl.feedType = feedType;
      };

      ctrl.feedType = ctrl.notificationFilterOptions[0].value;

      ctrl.allActivities = new FeedStore(_.bind(ActivityService.feed, ActivityService));
      ctrl.communityActivities = new FeedStore(_.bind(ActivityService.communityActivities, ActivityService));
      ctrl.projectActivities = new FeedStore(_.bind(ActivityService.projectActivities, ActivityService));

      ctrl.allActivities.load().then(() => {
        $timeout(() => {
          ActivityService.clearAll().then(() => {
            UserService.me(30).then((res) => {
              if (res !== null) {
                ctrl.currentUser = res.data;
              } else {
                ctrl.currentUser = null;
              }
            });
          });
        }, 1000);
      });

      ctrl.communityActivities.load();
      ctrl.projectActivities.load();
    };
  }
}

ActivityFeedCtrl.$inject = ['$timeout', 'ActivityService', 'UserService', 'FeedStore'];

export default ActivityFeedCtrl;
