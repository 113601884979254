export default `
<nav class="text-center" ng-if="paginationData.totalPages > 1" role="navigation" aria-label="Pagination">
  <ul class="pagination" >
    <li ng-if="paginationData.previousPage && paginationData.totalPages > 2" >
      <a href="#" ng-click="loadPage(1)" aria-label="Goto First Page">
        <span aria-hidden="true">&laquo;</span>
      </a>
    </li>
    <li ng-if="paginationData.previousPage" >
      <a href="#" ng-click="loadPage(paginationData.previousPage)" aria-label="Goto Previous Page" >
        <span aria-hidden="true">&lsaquo;</span>
      </a>
    </li>
    <li ng-repeat="pageNumber in paginationData.paginationNumbers" ng-class="{active: paginationData.currentPage === pageNumber}" >
      <a ng-if="paginationData.currentPage !== pageNumber" href="#" ng-click="loadPage(pageNumber)" aria-label="Goto Page {{pageNumber}}">{{pageNumber}}</a>
      <a ng-if="paginationData.currentPage === pageNumber" href="#" ng-click="loadPage(pageNumber)" aria-label="Page {{pageNumber}}, Current page" aria-current="true">{{pageNumber}}</a>
    </li>
    <li ng-if="paginationData.nextPage" >
      <a href="#" ng-click="loadPage(paginationData.nextPage)" aria-label="Goto Next Page">
        <span aria-hidden="true">&rsaquo;</span>
      </a>
    </li>
    <li ng-if="paginationData.nextPage && paginationData.totalPages > 2" >
      <a href="#" ng-click="loadPage(paginationData.totalPages)" aria-label="Goto Last Page">
        <span aria-hidden="true">&raquo;</span>
      </a>
    </li>
  </ul>
</nav>
`;
