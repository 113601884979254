import template from './community-registration-form.component.html';
import controller from './community-registration-form.controller';

const communityRegistrationForm = {
  bindings: {
    registration: '<',
  },
  template,
  controller,
};

export default communityRegistrationForm;
