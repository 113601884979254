import controller from './dropzone-input.controller';

const dropzoneInput = {
  bindings: {
    ngModel: '=',
    class: '@',
    maxFiles: '@',
    placeholder: '@',
  },
  require: { ngModelCtrl: 'ngModel' },
  controller,
  template: '<div></div>',
};

export default dropzoneInput;
