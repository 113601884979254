import template from './nav-sidebar-item.component.html';
import controller from './nav-sidebar-item.controller';

const navSidebarItem = {
  bindings: {
    icon: '@',
    name: '@',
    route: '@',
    highlight: '@',
  },
  template,
  controller,
};

export default navSidebarItem;
