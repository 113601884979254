import imgCropModalTemplate from './img-crop-modal.html';

class ImgCropCtrl {
  constructor($uibModal, $element: JQuery) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.img = $element.find('.img-crop');
      ctrl.isBigger = true;
      $('img').on('load', (ev) => {
        // @ts-ignore
        ctrl.isBigger = (ev.currentTarget.naturalWidth > ctrl.img[0].scrollWidth) || (ev.currentTarget.naturalHeight > ctrl.img[0].scrollHeight);
      });
    };

    ctrl.showFullImage = () => {
      if (ctrl.isBigger) {
        $uibModal.open({
          animation: true,
          template: imgCropModalTemplate,
          controller: 'ModalCtrl',
          windowClass: 'img-crop-modal',
          resolve: {
            data() {
              return {
                src: ctrl.src,
                alt: ctrl.alt,
              };
            },
          },
        });
      }
    };
  }
}

ImgCropCtrl.$inject = ['$uibModal', '$element'];

export default ImgCropCtrl;
