/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import Dropzone from 'dropzone';

const template = `
  <div class="codeMirror-toolbar-dropdown dropzone-toolbar">
    <span class="arrow"></span>
    <button class="close" aria-label="Close Upload File Popup"><i class="material-icons" aria-hidden="true">close</i></button>
    <div id="dropzone{{$id}}" class="dropzone"></div>
  </div>
`;

// This should really be a directive.
const setupDropzone = ({
  $scope, easymde, AlertService, $element, $cookies, uploadWindow, csrfCookieName,
}) => {
  const dz = new Dropzone(`#dropzone${$scope.$id}`, {
    url: '/api/v1/uploads',
    uploadMultiple: false,
    maxFiles: 1,
    dictDefaultMessage: '<button class="activation-btn">Activate to upload a file</button>',
    acceptedFiles: 'image/gif,image/jpeg,image/png',
    headers: {
      'X-Csrf-Token': $cookies.get(csrfCookieName),
    },
    renameFilename(filename) {
      return filename.replace(/[\(|\)]/g, '');
    },
  });

  dz.on('success', (ev, res) => {
    $scope.$broadcast('imageUploaded', res.url);
  });

  dz.on('error', (ev, res) => {
    let errorTitle;
    if (_.isString(res)) {
      errorTitle = res;
    } else if (_.isArray(res.errors)) {
      if (_.isString(res.errors[0].title)) {
        errorTitle = res.errors[0].title;
        errorTitle = errorTitle.charAt(0).toUpperCase() + errorTitle.slice(1);
      } else {
        errorTitle = res.errors[0].code;
      }
    }
    AlertService.add(errorTitle, 'danger');
    $scope.$apply();
    dz.removeAllFiles(true);
  });

  $scope.$on('imageUploaded', (event, url) => {
    const title = easymde.codemirror.getSelection();
    easymde.codemirror.replaceSelection(`![${title}](${url})`);
    if (uploadWindow.is(':visible')) {
      $element.append(uploadWindow);
    }
    dz.removeAllFiles();
  });
  dz.autoDiscover = false;
  return dz;
};

const upload = ({
  $element, $compile, $scope, AlertService, easymde, $q, $cookies, csrfCookieName,
}) => {
  const uploadWindow = $compile(template)($scope);
  $element.append(uploadWindow);

  const deferredEditor = $q.defer();

  const toggleWindow = (editor) => {
    deferredEditor.resolve(editor);

    const toolbarSlot = $(editor.toolbarElements.uploadImage);

    if (uploadWindow.is(':visible')) {
      $element.append(uploadWindow);
    } else {
      const otherDropdowns = toolbarSlot.parent().find('.codeMirror-toolbar-dropdown');
      toolbarSlot.append(uploadWindow);
      $element.append(otherDropdowns);
    }
  };

  deferredEditor.promise.then((editor) => {
    easymde.then((easymde) => {
      const dz = setupDropzone({
        easymde, AlertService, $scope, $element, $cookies, uploadWindow, csrfCookieName,
      });
      dz.on('addedfile', () => {
        toggleWindow(editor);
      });
    });
  });

  return {
    uploadImage: {
      name: 'uploadImage',
      action: toggleWindow,
      title: 'Upload Image',
      className: 'fa fa-picture-o',
    },
  };
};

export default upload;
