export default `
<div>
  <div class="form-group-box">
    <div class="step-intro">
      When you participate in uTest projects, you need to have at least one device that you perform the test on. Please list a device (the more devices you have, the more invitations to projects you receive).
    </div>
    <div class="form-control-static"></div>
    <div class="devices-box">
      <div id="web-device" class="row" ng-class="isMobile ? 'second': 'first'">
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Your Computer:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                       ng-model="registrationData.webDevice.osId" on-select="getWebOSVersions(); fixDropdownFocusOrderAccessibility();" name="osId" ng-class="{'auto-detected': autoDetectDevice.webDevice.osId}" focus-on="osIdFocus">
              <ui-select-match placeholder="Select OS">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Computer OS selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
              </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="device.id as device in webDevices | filter: {'name': $select.search}">
                <div ng-bind-html="device.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.webDevice.osId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.webDevice.osId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Version:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                       ng-model="registrationData.webDevice.osVersionId" ng-disabled="!registrationData.webDevice.osId" on-select="autoDetectDevice.webDevice.osVersionId = false; fixDropdownFocusOrderAccessibility();" ng-class="{'auto-detected': autoDetectDevice.webDevice.osVersionId}" name="osVersionId">
              <ui-select-match placeholder="Select a Version">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Version selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
              </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="version.id as version in webOSVersions | filter: {'name': $select.search}">
                <div ng-bind-html="version.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.webDevice.osVersionId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.webDevice.osVersionId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Language:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                       ng-model="registrationData.webDevice.osLanguageId" on-select="autoDetectDevice.webDevice.osLanguageId = false; fixDropdownFocusOrderAccessibility();" ng-class="{'auto-detected': autoDetectDevice.webDevice.osLanguageId}" name="osLanguageId">
              <ui-select-match placeholder="Select OS language">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Language selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
              </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="language.id as language in languages | filter: {'name': $select.search}">
                <div ng-bind-html="language.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.webDevice.osLanguageId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.webDevice.osLanguageId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
      </div>
      <div id="mobile-device" class="row" ng-class="isMobile ? 'first': 'second'">
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Your Mobile Device:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}"
                       ng-model="registrationData.mobileDevice.handsetMakerId" on-select="getMobileDeviceModels(); fixDropdownFocusOrderAccessibility();" name="handsetMakerId" ng-class="{'auto-detected': autoDetectDevice.mobileDevice.handsetMakerId}" focus-on="handsetMakerIdFocus">
              <ui-select-match placeholder="Select Brand">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Brand selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
              </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="device.id as device in mobileDevices | filter: {'name': $select.search}">
                <div ng-bind-html="device.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.mobileDevice.handsetMakerId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.mobileDevice.handsetMakerId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Model:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                       ng-model="registrationData.mobileDevice.handsetModelId" name="handsetModelId" on-select="getMobileDeviceOSVersions(); fixDropdownFocusOrderAccessibility();" ng-disabled="!registrationData.mobileDevice.handsetMakerId" focus-on="handsetModelIdFocus">
              <ui-select-match placeholder="Select a Model">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Model selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
              </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="model.id as model in mobileDeviceModels | filter: {'name': $select.search}">
                <div ng-bind-html="model.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.mobileDevice.handsetModelId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.mobileDevice.handsetModelId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm-4 col-md-4 col-lg-4 select2-theme-form">
          <div>
            <label>Operating System:</label>
          </div>
          <div class="ui-select-box">
            <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                       ng-model="registrationData.mobileDevice.handsetOSId" ng-disabled="!registrationData.mobileDevice.handsetModelId" name="handsetOSId" ng-class="{'auto-detected': autoDetectDevice.mobileDevice.handsetOSId}" on-select="autoDetectDevice.mobileDevice.handsetOSId=false; fixDropdownFocusOrderAccessibility();">
              <ui-select-match placeholder="Select OS">
                {{$select.selected.name}}
                <button class="clear-btn pull-right" ng-click="$select.clear($event);" aria-label="Clear Mobile OS selection">
                  <i class="material-icons" aria-hidden="true">close</i>
                </button>
            </ui-select-match>
              <ui-select-choices minimum-input-length="-1" repeat="osVersion.id as osVersion in mobileDeviceOSVersions | filter: {'name': $select.search}">
                <div ng-bind-html="osVersion.name | highlight: $select.search"></div>
              </ui-select-choices>
            </ui-select>
            <span ng-show="autoDetectDevice.mobileDevice.handsetOSId" class="check-mark">
              <i class="material-icons">{{autoDetectDevice.mobileDevice.handsetOSId? 'done': ''}}</i>
              Autodetected
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <button class="btn btn-grey btn-rounded pull-left" ng-click="navigateToStep('address');">
      <i class="material-icons" aria-hidden="true">chevron_left</i>
      <span>Back</span>
    </button>
    <div class="pull-right next-step">
      <span class="btn-inline-error" ng-show="isInValidDevice" aria-live="polite">One device is required to complete registration.</span>
      <button class="btn btn-blue pull-right" ng-click="validateDevices(userForm);">
        <span>Next: Last Step</span>
        <i class="material-icons" aria-hidden="true">chevron_right</i>
      </button>
    </div>
  </div>
</div>
`;
