import angular from 'angular';
import {
  ChangeDetectionStrategy, Component, Input, OnInit,
} from '@angular/core';
import {
  APPLICATION_SIZE_MODES, UNAUTH_HOME_BG, UNAUTH_HOME_IMG, UNAUTH_HOW_UTEST_WORKS_VIDEO_ID,
} from '../../../constants';

@Component({
  selector: 'ut-unauthenticated-home',
  templateUrl: './unauthenticated-home.component.html',
  styleUrls: ['./unauthenticated-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
class UnauthenticatedHomeComponent implements OnInit {
  @Input('brands') brands: any[];

  bgUrl: string;

  isSmallMode: boolean;

  unauthImages: any = UNAUTH_HOME_IMG;
  videoId: string = UNAUTH_HOW_UTEST_WORKS_VIDEO_ID;

  tableContent = [{
    title: 'Create your uTest Account',
    content: 'Complete your uTest registration, confirm your email, and fill out your Tester Profile so we can match you with paid testing projects just right for you.',
    img: this.unauthImages.howItWorks.img1,
  }, {
    title: 'Build Your Skills in the Academy',
    content: 'Learn how to test with us by completing courses, passing quizzes and participating in live practice test cycles in our uTest Academy.',
    img: this.unauthImages.howItWorks.img2,
  }, {
    title: 'Receive and Accept Project Invitation',
    content: 'As your Tester Rating increases, you\'ll receive project invitations or survey requests that match with your Tester Profile.',
    img: this.unauthImages.howItWorks.img3,
  }, {
    title: 'Complete Projects and Earn',
    content: 'Report bugs or share your opinion about apps or products, and earn payments for your approved work. We pay testers twice a month using PayPal and Payoneer.',
    img: this.unauthImages.howItWorks.img4,
  }];

  calculateBgStyle() {
    this.bgUrl = UNAUTH_HOME_BG.medium;
    if (window.innerWidth > APPLICATION_SIZE_MODES.WIDE) {
      this.bgUrl = UNAUTH_HOME_BG.wide;
    }
    this.isSmallMode = window.innerWidth <= APPLICATION_SIZE_MODES.MEDIUM;
  }

  ngOnInit() {
    this.calculateBgStyle();
    angular.element(window).on('resize', () => {
      this.calculateBgStyle();
    });
  }
}

export { UnauthenticatedHomeComponent };
