export default `
<div>
  <nav class="nav-sidebar">
    <div id="skiptocontent"><a href="#mainContent" class="skip" ng-keydown="$ctrl.handleSkipKeydown($event)">skip to main content</a></div>
    <nav-sidebar-toggle action="close"></nav-sidebar-toggle>
    <div class="nav-sidebar-logo-container">
      <div class="nav-sidebar-logo-image">
        <a ui-sref="home"><img src="assets/images/uTestLogoNav.svg" alt="uTest home page"></a>
      </div>
    </div>

    <div class="nav-sidebar-scrollable">
      <nav-sidebar-user-info user="$ctrl.currentUser" ng-show="$ctrl.currentUser"></nav-sidebar-user-info>
      <nav-sidebar-item icon="home" name="Home" route="home" highlight="root,home" ng-show="$ctrl.currentUser"></nav-sidebar-item>
      <nav-sidebar-item icon="school" name="Academy" route="academy-home" highlight="academy,tracks,course,courses,academy-home,track-course,quiz,report-card,singlecourse" ng-show="$ctrl.currentUser"></nav-sidebar-item>
      <nav-sidebar-projects ng-show="$ctrl.currentUser"></nav-sidebar-projects>
      <nav-sidebar-item icon="people" name="Community" route="community" highlight="community,article,singlearticle,discussion,singlediscussionpage,discussioncategory,singlediscussion,referralCampaignsIndex,referralCampaignsShow,singlestatus" ng-show="$ctrl.currentUser"></nav-sidebar-item>

      <nav-sidebar-item icon="trip_origin" name="Projects Board" route="project" highlight="project,singleproject,projectlist" ng-show="!$ctrl.currentUser"></nav-sidebar-item>
      <nav-sidebar-item icon="description" name="Articles" route="article" highlight="article,singlearticle" ng-show="!$ctrl.currentUser"></nav-sidebar-item>
      <nav-sidebar-item icon="forum" name="Forums" route="discussion" highlight="discussion,singlediscussionpage,discussioncategory,singlediscussion" ng-show="!$ctrl.currentUser"></nav-sidebar-item>

      <div ng-if="$ctrl.currentUser && ($ctrl.currentUser.user_type === 'admin' || $ctrl.currentUser.user_type === 'moderator')">
        <nav-sidebar-item icon="folder_shared" name="Review Articles" route="pendingarticle" highlight="pendingarticle"></nav-sidebar-item>
        <nav-sidebar-item icon="flag" name="Flagged Content" route="flaggedcontent" highlight="flaggedcontent"></nav-sidebar-item>
        <nav-sidebar-item icon="keyboard_tab" name="Transfer Forums" route="transferDiscussions" highlight="transferDiscussions" ng-show="$ctrl.currentUser.user_type === 'admin'"></nav-sidebar-item>
        <nav-sidebar-item icon="call_split" name="URL Shortener" route="urlshortener" highlight="urlshortener" ng-show="$ctrl.currentUser.user_type === 'admin'"></nav-sidebar-item>
      </div>
      <nav-sidebar-footer></nav-sidebar-footer>
    </div>
  </nav>
</div>
`;
