import _ from 'lodash';

class ToolCtrl {
  constructor($scope, ToolCategoryService, ToolService, tools, FeedStore, coverBg, headerData) {
    const fetchFeed = _.bind(ToolService.feed, ToolService);
    $scope.coverBg = coverBg;
    $scope.headerData = headerData.data;
    $scope.tool_categories = [];

    $scope.groups = {
      highestRated: tools,
      recentlyAdded: new FeedStore(fetchFeed, { sort: '-published_at' }),
    };

    ToolCategoryService.all().then((categories) => {
      $scope.tool_categories = _.filter(categories.data, c => c.tools.count > 0);
    });
  }
}

ToolCtrl.$inject = ['$scope', 'ToolCategoryService', 'ToolService', 'tools', 'FeedStore', 'coverBg', 'headerData'];

export default ToolCtrl;
