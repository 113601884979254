class DashboardCtrl {
  constructor(UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      UserService.me().then((res) => {
        if (res !== null) {
          this.currentUser = res.data;
          const joinDate = new Date(this.currentUser.site_stats.joined_at);
          this.showCompletionCard = showCompletionCard(joinDate);
          this.showNewJoinerChecklist = showNewJoinerChecklist(this.currentUser, joinDate);
        } else {
          this.currentUser = null;
          this.showCompletionCard = false;
          this.showNewJoinerChecklist = false;
        }
      });
    };
  }
}

function showCompletionCard(userRegistrationDate) {
  const dayInMillis = 1000 * 60 * 60 * 24;
  const now = Date.now();

  const daysSinceRegistration = Math.round((now - userRegistrationDate) / dayInMillis);

  const isFirst30Days = daysSinceRegistration <= 30;
  const isEvery14Days = ((daysSinceRegistration - 30) % 14) === 0;

  return isFirst30Days || isEvery14Days;
}

function showNewJoinerChecklist(user, userRegistrationDate) {
  const dayInMillis = 1000 * 60 * 60 * 24;
  const now = Date.now();

  const daysSinceRegistration = Math.round((now - userRegistrationDate) / dayInMillis);

  const isFirst30Days = daysSinceRegistration <= 30;

  const completeChecklist = user.has_confirmed_email
                            && user.has_payment_method
                            && user.has_viewed_community_page
                            && user.has_completed_profile
                            && user.has_applied_to_project
                            && user.has_passed_academy;

  return isFirst30Days && !completeChecklist;
}

DashboardCtrl.$inject = ['UserService'];

export default DashboardCtrl;
