import { StateService } from '@uirouter/core';
import _ from 'lodash';
import {
  COOKIE_OPT_IN_ACCEPTED, COOKIE_OPT_IN_DECLINED, COOKIE_OPT_IN_PENDING, ONETRUST_CATEGORIES,
} from '../../constants';

class CookieAgreementService {
  $cookies: any;
  $state: StateService;
  $window: ng.IWindowService;
  UserService: any;
  ConfigService: any;
  $rootScope: any;
  featureFlags: any;
  constructor($cookies, UserService, ConfigService, $state: StateService, $window: ng.IWindowService, $rootScope, featureFlags) {
    this.$cookies = $cookies;
    this.UserService = UserService;
    this.ConfigService = ConfigService;
    this.$state = $state;
    this.$window = $window;
    this.$rootScope = $rootScope;
    this.featureFlags = featureFlags;
  }

  loadServices() {
    if (this.featureFlags.isOn('UTEST_ONETRUST_COOKIE_CONSENT')) {
      this._loadOneTrustScripts();
      const oneTrustAgreements = this._getOneTrustAgreements();
      let isFirstLoad = _.isEmpty(oneTrustAgreements);

      this.$rootScope.$watch(() => this.$cookies.get(this.$rootScope.oneTrustCookieName), (updatedCookie, previousCookie) => {
        // the agreement status is only one of several things that could change about the cookie, hence the need to clarify
        if (updatedCookie !== previousCookie && !_.isEqual(oneTrustAgreements, this._parseAgreements(updatedCookie))) {
          // this is to prevent a case in which the OneTrust cookie did not exist (incognito window, user has literally never accessed uTest before, etc.) and the OneTrust scripts eventually load async and the cookie suddenly exists (therefore being technically changed, even though the user did not do it).
          if (!isFirstLoad) {
            this.$window.location.reload();
          }
          isFirstLoad = false;
        }
      });
    }

    // the mapping between categories and external services can be found in the OneTrust configuration (app.onetrust.com)
    if (this.hasAcceptedTargetingCookies()) {
      // this._loadMautic();
      this._loadEmbedly(); // Embedly does not set its own cookies, but embeds YouTube videos, which do. So we need to load it here.
    } else {
      this._deleteMauticCookies();
      this._deleteYoutubeCookies();
    }

    if (this.hasAcceptedPerformanceCookies()) {
      // these are not actually used for now, and are just a placeholder for when we re-add them.
      this._loadGoogleTagManager();
      this._loadMetaPixel();
    } else {
      this._deleteGoogleAnalyticsCookies();
    }
  }

  agree() {
    const nowInOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    this.$cookies.put(this.$rootScope.cookieOptInCookieName, 'true', { expires: nowInOneYear, domain: this.$rootScope.cookieDomain, secure: true });

    this._setServerSideCookieOptIn(true, true);
  }

  disagree() {
    this.$cookies.put(this.$rootScope.cookieOptInCookieName, 'false', { domain: this.$rootScope.cookieDomain });

    this._setServerSideCookieOptIn(false);
  }

  reset() {
    this.$cookies.remove(this.$rootScope.cookieOptInCookieName, { domain: this.$rootScope.cookieDomain, secure: true });

    this._setServerSideCookieOptIn(false, true);
  }

  hasAgreed() {
    const currentState = this._currentState();
    return currentState === COOKIE_OPT_IN_ACCEPTED;
  }

  isPending() {
    const currentState = this._currentState();
    return currentState === COOKIE_OPT_IN_PENDING;
  }

  hasAcceptedPerformanceCookies() {
    if (this.featureFlags.isOn('UTEST_ONETRUST_COOKIE_CONSENT')) {
      return this._getOneTrustAgreements()[ONETRUST_CATEGORIES.PerformanceCookies] === '1';
    }
    return this.hasAgreed();
  }

  hasAcceptedTargetingCookies() {
    if (this.featureFlags.isOn('UTEST_ONETRUST_COOKIE_CONSENT')) {
      return this._getOneTrustAgreements()[ONETRUST_CATEGORIES.TargetingCookies] === '1';
    }
    return this.hasAgreed();
  }

  _loadOneTrustScripts() {
    const id = 'onetrust-js';
    if (!document.getElementById(id)) {
      const oneTrustScriptTag = document.createElement('script');
      oneTrustScriptTag.id = id;
      oneTrustScriptTag.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      oneTrustScriptTag.setAttribute('type', 'text/javascript');
      oneTrustScriptTag.setAttribute('charset', 'UTF-8');
      oneTrustScriptTag.setAttribute('data-domain-script', this.$rootScope.oneTrustDataDomain);
      document.head.appendChild(oneTrustScriptTag);

      const oneTrustFunctionScriptTag = document.createElement('script');
      oneTrustFunctionScriptTag.type = 'text/javascript';
      oneTrustFunctionScriptTag.innerHTML = 'function OptanonWrapper() { }';
      document.head.appendChild(oneTrustFunctionScriptTag);
    }
  }

  _getOneTrustAgreements() {
    const oneTrustCookie = this.$cookies.get(this.$rootScope.oneTrustCookieName);
    return this._parseAgreements(oneTrustCookie);
  }

  _parseAgreements(oneTrustCookie) {
    const agreements = {};
    try {
      const decodedCookie = decodeURIComponent(oneTrustCookie);
      const groupString = decodedCookie.split('&').find((e) => e.startsWith('groups=')).split('=')[1];
      const groups = groupString.split(',');
      groups.forEach((group) => {
        const [key, value] = group.split(':');
        agreements[key] = value;
      });
    } catch (e) {
      console.debug(e);
    }
    return agreements;
  }

  _currentState() {
    const cookieValue = this.$cookies.get(this.$rootScope.cookieOptInCookieName, { domain: this.$rootScope.cookieDomain });

    if (cookieValue === 'true') {
      return COOKIE_OPT_IN_ACCEPTED;
    }

    if (cookieValue === 'false') {
      return COOKIE_OPT_IN_DECLINED;
    }

    return COOKIE_OPT_IN_PENDING;
  }

  _setServerSideCookieOptIn(optInBool, reload = false) {
    const { UserService, $window } = this;

    const loggedInUser = UserService.me();
    if (loggedInUser !== null) {
      UserService.updateMe({ cookie_opt_in: optInBool }).then(() => {
        if (reload === true) {
          $window.location.reload();
        }
      });
    }
  }

  _loadMautic() {
    (function (w: any, d, t, u, n, a, m) {
      w.MauticTrackingObject = n;
      /* eslint-disable */
      w[n] = w[n] || function () { (w[n].q = w[n].q || []).push(arguments); }, a = d.createElement(t),
      /* eslint-enable */
      m = d.getElementsByTagName(t)[0]; a.async = 1; a.src = u; m.parentNode.insertBefore(a, m);
    }(window, document, 'script', 'https://utest.mautic.net/mtc.js', 'mt'));
  }

  _loadEmbedly() {
    const d = document;
    const w: any = window;

    const id = 'embedly-platform',
      n = 'script';
    if (!d.getElementById(id)) {
      /* eslint-disable */
      w.embedly = w.embedly || function () { (w.embedly.q = w.embedly.q || []).push(arguments); };
      /* eslint-enable */
      const e = d.createElement(n); e.id = id; e.async = true;
      e.src = `${document.location.protocol === 'https:' ? 'https' : 'http'}://cdn.embedly.com/widgets/platform.js`;
      const s = d.getElementsByTagName(n)[0];
      s.parentNode.insertBefore(e, s);
    }
  }

  _deleteMauticCookies() {
    this.$cookies.remove('mautic_device_id', { domain: 'www.utest.com' });
    this.$cookies.remove('mtc_id', { domain: 'www.utest.com' });
    this.$cookies.remove('mtc_sid', { domain: 'www.utest.com' });
    this.$cookies.remove('mautic_device_id', { domain: 'www.m.utest.com' });
    this.$cookies.remove('mtc_id', { domain: 'www.m.utest.com' });
    this.$cookies.remove('mtc_sid', { domain: 'www.m.utest.com' });
  }

  _deleteGoogleAnalyticsCookies() {
    const allCookies = this.$cookies.getAll();
    const googleAnalyticsCookieKeys = Object.keys(allCookies).filter((key) => key.startsWith('_ga') || key.startsWith('_gat') || key.startsWith('_gid'));
    googleAnalyticsCookieKeys.forEach(key => this.$cookies.remove(key));
  }

  _deleteYoutubeCookies() {
    this.$cookies.remove('VISITOR_INFO1_LIVE', { domain: '.youtube.com' });
    this.$cookies.remove('VISITOR_PRIVACY_METADATA', { domain: '.youtube.com' });
    this.$cookies.remove('YSC', { domain: '.youtube.com' });
    this.$cookies.remove('PREF', { domain: '.youtube.com' });
  }

  _loadGoogleTagManager() {}

  _loadMetaPixel() {}
}

CookieAgreementService.$inject = ['$cookies', 'UserService', 'ConfigService', '$state', '$window', '$rootScope', 'featureFlags'];

export default CookieAgreementService;
