import template from './ntux-load-more.component.html';
import controller from './ntux-load-more.controller';

const ntuxLoadMore = {
  bindings: {
    isLoading: '<',
    isLastPage: '<',
    loadCallback: '&',
  },
  template,
  controller,
  transclude: true,
};

export default ntuxLoadMore;
