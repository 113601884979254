import controller from './social-sharing.controller';
import template from './social-sharing.component.html';

const socialSharing = {
  bindings: {
    target: '<',
    text: '<',
    isPopover: '<',
    type: '@',
  },
  controller,
  template,
};

export default socialSharing;
