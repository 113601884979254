import template from './feed-paginator.html';

const feedPaginator = function () {
  return {
    scope: {
      paginationData: '=',
      loadPage: '=',
    },
    template,
  };
};

export default feedPaginator;
