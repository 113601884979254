export default `
<ul class="course-track-quiz-progress-bar" aria-label="Quiz Progress Bar">
  <li class="course-track-quiz-progress-bar-step" ng-repeat="questions in $ctrl.questions" aria-label="Question {{$index + 1}}{{ $ctrl.step > $index ? ', completed' : $ctrl.step < $index ? ', not completed' : ', current'}}">
    <div class="course-track-quiz-progress-bar-step__number"
      ng-class="{'course-track-quiz-progress-bar-step__number--selected' : $ctrl.step == $index}" aria-hidden="true">
      {{$index + 1}}
    </div>
    <div class="course-track-quiz-progress-bar-step__chunk"
      ng-class="{'course-track-quiz-progress-bar-step__chunk--selected' : $ctrl.step == $index, 'course-track-quiz-progress-bar-step__chunk--completed' : $ctrl.step > $index}" aria-hidden="true">
    </div>
  </li>
</ul>
`;
