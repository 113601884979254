import _ from 'lodash';

class CommentService {
  $http: ng.IHttpService;
  ServiceByType: any;
  constructor(ServiceByType, $http: ng.IHttpService) {
    this.ServiceByType = ServiceByType;
    this.$http = $http;
  }

  postComment(type, id, comment) {
    return this.ServiceByType.get(type).postComment(id, comment);
  }

  postCommentAndReturnCommentable(type, id, comment) {
    return this.ServiceByType.get(type).postCommentAndReturnCommentable(id, comment);
  }

  deleteComment(id) {
    return this.$http.delete(`/api/v1/comments/${id}`);
  }

  editComment(id, content) {
    return this.$http.put(`/api/v1/comments/${id}`, content);
  }

  flag(id, reason) {
    return this.$http.post(`/api/v1/comments/${id}/flag`, { reason });
  }

  like(id) {
    return this.$http.post(`/api/v1/comments/${id}/like`, '');
  }

  unlike(id) {
    return this.$http.delete(`/api/v1/comments/${id}/like`);
  }

  hide(id) {
    return this.$http.post(`/api/v1/comments/${id}/hide`, '');
  }

  publish(id) {
    return this.$http.post(`/api/v1/comments/${id}/publish`, '');
  }

  toggleHide(item) {
    if (item.state === 'hidden') {
      return this.publish(item.id);
    }
    return this.hide(item.id);
  }

  addIndentation(comment, indentations) {
    if (_.isUndefined(indentations[comment.id])) {
      indentations[comment.id] = 0;
    }
    if (comment.parent_id) {
      if (_.isUndefined(indentations[comment.parent_id])) {
        indentations[comment.parent_id] = 0;
      }
      indentations[comment.id] = indentations[comment.parent_id] + 1;
    }
    comment.indentation = indentations[comment.id];
    return comment;
  }

  getCommentableLabel(item) {
    if (item.type === 'status') {
      return `a ${item.type}`;
    }
    if (!_.isUndefined(item.title)) {
      return item.title;
    }
    if (!_.isUndefined(item.name)) {
      return item.name;
    }
  }

  likedUsers(id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return this.$http.get(`/api/v1/comments/${id}/liked_users`, { params: parameters });
  }

  feed(params) {
    let type = '';
    switch (params.type) {
      case 'status':
        type = 'statuses';
        break;
      case 'topic':
      case 'article':
      case 'course':
        type = `${params.type}s`;
        break;
      default:
    }
    const filteredParams = {
      after_id: params.after_id,
      per_page: params.per_page,
    };
    return this.$http.get(`/api/v1/${type}/${params.id}/comments/flat`, { params: filteredParams });
  }
}

CommentService.$inject = ['ServiceByType', '$http'];

export default CommentService;
