import _ from 'lodash';

class GamificationService {
  $http: ng.IHttpService;
  badgesById: any;
  badgesPromise: Promise<any>;

  constructor($http) {
    const badges = [
      {
        id: 'first_follow_badge',
        type: 'Social',
        description: 'You followed your first tester',
        level: 5,
        name: '1st Follow',
        icon: '1stFollow.svg',
      },
      {
        id: 'first_response_badge',
        type: 'Social',
        description: 'Comment gets 1 response',
        level: 5,
        name: '1st Response',
        icon: '1stResponse.svg',
      },
      {
        id: 'follow_ten_badge',
        type: 'Social',
        description: 'You followed 10 testers',
        level: 10,
        name: 'Making Friends',
        icon: 'makingFriends.svg',
      },
      {
        id: 'follow_twenty_badge',
        type: 'Social',
        description: 'you followed 20 testers',
        level: 20,
        name: 'The Subscriber',
        icon: 'theSubscriber.svg',
      },
      {
        id: 'follow_thirty_badge',
        type: 'Social',
        description: 'you followed 30 testers',
        level: 20,
        name: 'Feed Frenzy',
        icon: 'feedFrenzy.svg',
      },
      {
        id: 'first_follower_badge',
        type: 'Social',
        description: 'You received your 1st follower',
        level: 5,
        name: '1st Follower',
        icon: '1stFollower.svg',
      },
      {
        id: 'ten_followers_badge',
        type: 'Social',
        description: 'You have 10 followers',
        level: 50,
        name: 'Gaining Popularity',
        icon: 'gainingPopularity.svg',
      },
      {
        id: 'five_responses_badge',
        type: 'Social',
        description: 'Comment gets 5 responses',
        level: 50,
        name: 'Rise and Shine',
        icon: 'riseAndShine.svg',
      },
      {
        id: 'six_responses_badge',
        type: 'Social',
        description: 'Comment gets 6 responses',
        level: 100,
        name: 'Getting Warmer',
        icon: 'gettingWarmer.svg',
      },
      {
        id: 'ten_forum_comments_badge',
        description: 'Authored 10 forum posts',
        level: 100,
        name: 'Intrigued',
        icon: 'intrigued.svg',
      },
      {
        id: 'ten_forum_responses_badge',
        description: 'Replied to 10 forum topics',
        level: 25,
        name: 'Opinionated',
        icon: 'opinionated.svg',
      },
      {
        id: 'at_mention_in_status_badge',
        description: 'Use an @mention in a status',
        level: 10,
        name: 'Shout Out',
        icon: 'shoutOut.svg',
      },
      {
        id: 'five_statuses_badge',
        description: 'Write 5 status updates',
        level: 25,
        name: 'Shout it Loud',
        icon: 'shoutItLoud.svg',
      },
      {
        id: 'five_status_replies_in_a_row_badge',
        description: 'Reply to a status 5 days in a row',
        level: 25,
        name: 'Attentive',
        icon: 'attentive.svg',
      },
      {
        id: 'five_statuses_in_a_row_badge',
        description: 'Write a status update five days in a row',
        level: 25,
        name: 'Outgoing',
        icon: 'outgoing.svg',
      },
      {
        id: 'one_tool_rating_badge',
        description: 'Rate at least 1 tool',
        level: 10,
        name: '1st Tool Rating',
        icon: '1stToolRating.svg',
      },
      {
        id: 'ice_breaker_badge',
        description: 'Create a forums topic with at least 5 "likes" twice in one week',
        level: 25,
        name: 'Ice Breaker',
        icon: 'iceBreaker.svg',
      },
      {
        id: 'twenty_followers_badge',
        type: 'Social',
        description: 'You have 20 followers',
        level: 100,
        name: 'So Popular',
        icon: 'soPopular.svg',
      },
      {
        id: 'twelve_responses_badge',
        type: 'Social',
        description: 'Comment gets 12 responses',
        level: 150,
        name: 'Heating Up',
        icon: 'heatingUp.svg',
      },
      {
        id: 'fifty_followers_badge',
        type: 'Social',
        description: 'You have 50 followers',
        level: 250,
        name: 'Rock Star',
        icon: 'rockStar.svg',
      },
      {
        id: 'fifty_responses_badge',
        type: 'Social',
        description: 'Comment gets 50 responses',
        level: 500,
        name: 'On Fire',
        icon: 'onFire.svg',
      },
      {
        id: 'hundred_followers_badge',
        type: 'Social',
        description: 'You have 100 followers',
        level: 1000,
        name: 'Stardom',
        icon: 'stardom.svg',
      },
      {
        id: 'five_hundred_followers_badge',
        type: 'Social',
        description: 'You have 500 followers',
        level: 250,
        name: 'Superstardom',
        icon: 'superStardom.svg',
      },
      {
        id: 'thousand_followers_badge',
        type: 'Social',
        description: 'You have 1000 followers',
        level: 500,
        name: 'Megastardom',
        icon: 'megaStardom.svg',
      },
      {
        id: 'ten_likes_badge',
        type: 'Social',
        description: 'Have your authored content "liked" 10 times',
        level: 50,
        name: 'Writing Contest',
        icon: 'writingContest.svg',
      },
      {
        id: 'fifty_likes_badge',
        type: 'Social',
        description: 'Have your authored content "liked" 50 times',
        level: 200,
        name: 'Best of the Net',
        icon: 'bestOfNet.svg',
      },
      {
        id: 'five_likes_three_days_in_a_row_badge',
        description: 'Leave a comment with at least 5 likes 3 days in a row',
        level: 50,
        name: 'Respected',
        icon: 'respected.svg',
      },
      {
        id: 'two_hundred_fifty_likes_badge',
        type: 'Social',
        description: 'Have your authored content "liked" 250 times',
        level: 1250,
        name: 'Pulitzer Prize',
        icon: 'pulitzerPrize.svg',
      },
      {
        id: 'thousand_likes_badge',
        type: 'Social',
        description: 'Have your authored content "liked" 1000 times',
        level: 2500,
        name: 'Nobel Prize',
        icon: 'nobelPrize.svg',
      },
    ];

    const addPointsAndIconURLs = response => _.map(response.data, (badge) => {
      const badgeData = _.find(badges, ['id', badge.name]);
      return {
        name: badgeData.name,
        points: badge.points,
        icon: `assets/images/badges/${badgeData.icon}`,
        type: badgeData.type,
        id: badgeData.id,
        description: badgeData.description,
      };
    });

    this.badgesPromise = $http.get('/api/v1/static_values/badges').then(addPointsAndIconURLs);
    this.badgesById = _.reduce(badges, (acc, value) => {
      acc[value.id] = value;
      return acc;
    }, {});
  }

  all() {
    return this.badgesPromise;
  }
}

GamificationService.$inject = ['$http'];

export default GamificationService;
