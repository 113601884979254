import _ from 'lodash';

class FeatureToggleService {
  $http: ng.IHttpService;
  $q: ng.IQService;
  cachedFeatureTogglePromise: any;
  lastFeatureToggleFetch: any;
  constructor($http, $q) {
    this.$http = $http;
    this.lastFeatureToggleFetch = null;
    this.cachedFeatureTogglePromise = null;
    this.$q = $q;
  }

  getEnabledFeatures(allowedAge = 1800) {
    const allowedAgeMs = allowedAge * 1000;
    const now = Date.now();

    if (!this.cachedFeatureTogglePromise
      || _.isNull(this.lastFeatureToggleFetch)
      || (now - this.lastFeatureToggleFetch) > allowedAgeMs) {
      this.lastFeatureToggleFetch = now;
      this.cachedFeatureTogglePromise = this.$http.get('/api/v1/enabled_features');
    }

    return this.$q.when(this.cachedFeatureTogglePromise);
  }
}

FeatureToggleService.$inject = ['$http', '$q'];

export default FeatureToggleService;
