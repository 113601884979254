<div class="option"
     [class.hovered]="hovered$ | async"
     [class.selected]="selected$ | async"
     [class.disabled]="disabled">
  <div #content>
    <ng-content></ng-content>
  </div>

  <mat-icon *ngIf="selected$ | async" class="selected-icon">done</mat-icon>
</div>
