export default `
<div class="campaign-feed">
  <div class="campaign-header">Referral Program</div>
  <p>Earn money by referring your friends to the following projects.</p>
  <div class="campaign-feed-item" ng-repeat="item in $ctrl.feed" >
    <div class="campaign-feed-item-main">
      <div class="campaign-feed-item-header">
        <div class="campaign-feed-item-header-info">
          <div class="campaign-feed-item-header__avatar campaign-feed-item-header__avatar--glyph"><i class="material-icons">group_add</i></div>
          <a class="campaign-feed-item-header__author">
            Referral Program
            <small ng-if="item.countries.length > 0">/ {{item.countries.join(', ')}}</small>
          </a>
        </div>
      </div>
      <div class="campaign-feed-item-body">
        <a class="campaign-feed-item-body__title" ui-sref="referralCampaignsShow({referralCampaignId: item.slug})">{{item.title}}</a>
        <a class ="campaign-feed-item-body__content" ui-sref="referralCampaignsShow({referralCampaignId: item.slug})" ng-bind-html="item.content | cleanUp | ulinky"></a>
      </div>
    </div>
  </div>
</div>
`;
