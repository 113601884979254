import _ from 'lodash';
import { KEY_CODES } from 'src/app/constants';
import angular from 'angular';

class ArticleFormCtrl {
  constructor($scope, $timeout, ArticleService, $state, StaticValuesService, AlertService, item, user, ProgressBarService, ServerMessages, $element) {
    $scope.instructions = [];

    $scope.item = item.data;

    $scope.currentUser = user;

    $scope.confirmDeletion = false;

    if (!$scope.item.id) {
      $scope.instructions.push({
        type: 'warning',
        message: 'When creating an article, please make sure that you are properly <a target="_blank" href="https://www.utest.com/articles/2728">citing</a> your sources if you are drawing from previously authored material. Additionally, please note that articles are meant for longer content, and material such as quotes or questions should be left to the forums or posted as a status.',
      });
    }

    $scope.closeAlert = function ($index) {
      $scope.instructions.splice($index, 1);
    };

    $scope.tags = { select: [] };

    StaticValuesService.tags().then((res) => {
      $scope.preselectedTags = res.data;
      _.forEach(item.data.tags, (tagTitle) => {
        const tag = _.find($scope.preselectedTags, _.iteratee({ title: tagTitle }));
        if (tag) { $scope.tags.select.push(tag); }
      });
    });

    function addValidationErrorMessage(text, autosave) {
      if (autosave) {
        $scope.autosaveError = text;
      } else {
        AlertService.add(text, 'danger');
      }
    }

    const validation = function (autosave) {
      let valid = true;
      let alertMessage = 'Please fill in all required fields';
      if (!$scope.item.slug) {
        $scope.slugError = autosave ? '' : 'error';
        $scope.autosaveError = 'Slug field is empty';
        valid = false;
      }
      if (!$scope.item.content.trim()) {
        $scope.contentError = autosave ? '' : 'error';
        $scope.autosaveError = 'Content field is empty';
        valid = false;
      }
      if (!$scope.item.title) {
        $scope.titleError = autosave ? '' : 'error';
        $scope.autosaveError = 'Title field is empty';
        valid = false;
      }
      if (!['moderator', 'admin'].includes($scope.currentUser.user_type) && $scope.item.content.trim().split(' ').length < 250) {
        $scope.contentError = autosave ? '' : 'error';
        const msg = 'Article must not contain fewer than 250 words.';
        $scope.autosaveError = msg;
        alertMessage = msg;
        valid = false;
      }
      if (!valid && !autosave) {
        AlertService.add(alertMessage, 'danger');
      }
      return valid;
    };

    $scope.saveBtnSaved = false;

    function successCallback(action, res) {
      $scope.saveBtnSaved = true;
      $scope.item.id = res.data.id;
      if (action === 'publish') {
        ArticleService.publish($scope.item.id).then(() => {
          AlertService.add('Article successfully published');
          $state.go('singlearticle', { articleId: $scope.item.slug });
        }, _.bind(errorCallback, null, action));
      } else if (action === 'review') {
        ArticleService.review($scope.item.id).then(() => {
          AlertService.add('Article successfully submitted for review');
          $state.go('singlearticle', { articleId: $scope.item.slug });
        }, _.bind(errorCallback, null, action));
      } else if (action === 'autosave') {
        $scope.saveBtnSaved = $scope.autosaveStatus !== 'disabled';
        setAutosaveStatus('success');
        if ($scope.item.state === 'rejected') {
          ArticleService.draft($scope.item.id).then((res) => {
            $scope.item = res.data;
          });
        }
      } else {
        AlertService.add('Article successfully saved');
        if (res.data.state === 'published') {
          $state.go('singlearticle', { articleId: res.data.slug });
        }
      }
      ProgressBarService.done();
    }

    function errorCallback(action, res) {
      const error = (typeof res.data === 'string') ? res.data : res.data.errors[0];
      const autosave = action === 'autosave';
      if (autosave) {
        setAutosaveStatus('error');
      }
      if (error.source && error.source.parameter === 'slug') {
        if (!autosave) {
          $scope.slugError = 'error';
        }
        if (error.title === 'already exists') {
          addValidationErrorMessage('An article with this title already exists', autosave);
        } else if ($scope.item.slug.match(/^[0-9]+$/)) {
          addValidationErrorMessage('Slug can\'t only contain numbers.', autosave);
        } else {
          addValidationErrorMessage('Slug can only contain letters, numbers, dashes and underscores.', autosave);
        }
      } else {
        addValidationErrorMessage(ServerMessages.prettyMessage(res), autosave);
      }

      ProgressBarService.done();
    }

    $scope.action = function (action) {
      if (!validation(action === 'autosave')) {
        return false;
      }
      $scope.item.tags = _.map($scope.tags.select, 'title');
      ProgressBarService.start();
      if ($scope.item.id) {
        ArticleService.update($scope.item).then(_.bind(successCallback, null, action), _.bind(errorCallback, null, action));
      } else {
        ArticleService.create($scope.item).then(_.bind(successCallback, null, action), _.bind(errorCallback, null, action));
      }
    };

    $scope.onDelete = function () {
      ArticleService.remove($scope.item.id).then(() => {
        $state.go('article');
        AlertService.addSuccess('Article deleted.');
      }).catch((response) => {
        AlertService.addDanger(ServerMessages.prettyMessage(response));
      });
    };

    const autoSaveConfig = {
      debounceTime: 3000,
      debounceTimeout: false,
    };

    const autosavingStart = function () {
      if ($scope.action('autosave') === false) {
        setAutosaveStatus('error');
      }
    };

    setAutosaveStatus(null);

    const watchAndSave = function (newV, oldV, clearError) {
      if (newV !== oldV) {
        if (clearError) {
          $scope[clearError] = '';
        }
        $scope.saveBtnSaved = false;
        if ($scope.autosaveStatus !== 'disabled') {
          setAutosaveStatus(null);
          if (autoSaveConfig.debounceTimeout) {
            $timeout.cancel(autoSaveConfig.debounceTimeout);
          }
          autoSaveConfig.debounceTimeout = $timeout(autosavingStart, autoSaveConfig.debounceTime);
        }
      }
    };

    function setAutosaveTooltip() {
      if ($scope.autosaveToggleStatus === 'disabled') {
        $scope.autosaveToggleTooltip = 'Enable autosave';
      } else {
        watchAndSave(0, 1, null);
        $scope.autosaveToggleTooltip = 'Disable autosave';
      }
    }

    if (_.get($scope.item, 'state') !== 'published') {
      $scope.$watch('item.title', (newV, oldV) => {
        watchAndSave(newV, oldV, 'titleError');
      });
      $scope.$watch('item.slug', (newV, oldV) => {
        watchAndSave(newV, oldV, 'slugError');
      });
      $scope.$watch('item.content', watchAndSave);
      $scope.$watch('tags.select', watchAndSave);
    }

    $scope.toggleAutosave = function () {
      $scope.autosaveToggleStatus = $scope.autosaveToggleStatus === 'disabled' ? null : 'disabled';
      setAutosaveStatus($scope.autosaveToggleStatus);
      setAutosaveTooltip();
    };

    $scope.toggleAutosaveByKeyDown = function (event) {
      const keyDown = event.key !== undefined ? event.key : event.keyCode;
      if ((keyDown === 'Enter' || keyDown === KEY_CODES.RETURN) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === KEY_CODES.SPACE)) {
        event.preventDefault();
        $scope.toggleAutosave();
      }
    };

    $scope.actionByKeydown = function (event, actionType: string) {
      const keyDown = event.key !== undefined ? event.key : event.keyCode;
      if ((keyDown === 'Enter' || keyDown === KEY_CODES.RETURN) || (['Spacebar', ' '].indexOf(keyDown) >= 0 || keyDown === KEY_CODES.SPACE)) {
        event.preventDefault();
        $scope.action(actionType);
      }
    };

    $scope.onSlugChange = (theSlug) => {
      $scope.item.slug = theSlug;
      $scope.slugError = false;
    };

    function setAutosaveStatus(status) {
      if ($scope.autosaveToggleStatus === 'disabled') {
        $scope.autosaveStatus = 'disabled';
      } else {
        $scope.autosaveStatus = status;
      }
    }

    $timeout(() => {
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          const txt = angular.element(elem).find('.ui-select-match').attr('placeholder');
          elem.setAttribute('aria-label', txt);
          angular.element(elem).find('.ui-select-search').removeAttr('aria-label');
        });
      }
    }, 250);

    $scope.updateSelectedTagsAriaLabel = () => {
      const ariaLabel = $scope.tags.select.map(tag => tag.title).join(', ');
      const container = $element.find('.ui-select-container');
      if (container.length > 0) {
        _.forEach(container, (elem) => {
          angular.element(elem).find('.ui-select-search').attr('aria-label', ariaLabel);
        });
      }
    };
  }
}

ArticleFormCtrl.$inject = ['$scope', '$timeout', 'ArticleService', '$state', 'StaticValuesService', 'AlertService', 'item', 'user', 'ProgressBarService', 'ServerMessages', '$element'];

export default ArticleFormCtrl;
