export default `
<div class="course-track-quiz-question" ng-show="$ctrl.step === $ctrl.questionNumber">
  <div id="quizQuestionNumber_{{$ctrl.questionNumber}}" name="quizQuestionNumber_{{$ctrl.questionNumber}}" class="course-track-quiz-question-header">
    Question {{$ctrl.questionNumber + 1}}
  </div>
  <div class="course-track-quiz-question-body">
    <h1 id="quizQuestionBody_{{$ctrl.questionNumber}}" class="course-track-quiz-question-body__text" ng-bind="$ctrl.question.questionText" tabindex="-1" aria-label="Question {{$ctrl.questionNumber + 1}}, {{$ctrl.question.questionText}}">
    </h1>
    <div class="course-track-quiz-question-answers" aria-describedby="quizQuestionBody_{{$ctrl.questionNumber}}">
      <course-track-quiz-question-choice user-choice="$ctrl.userChoice" question-id="$ctrl.question.id" choice="choice" choice-index="$index" question-number="$ctrl.questionNumber" ng-repeat="choice in $ctrl.question.answers" role="list"></course-track-quiz-question-choice>
    </div>
  </div>
  <div class="course-track-quiz-question-nav">
    <div class="course-track-quiz-question__next-button">
      <button type="button" ng-disabled="!$ctrl.userChoice[$ctrl.questionNumber].answer" ng-click="$ctrl.goToNextQuestion()" ng-if="!$ctrl.isLastQuestion">
        Continue
      </button>
      <button type="submit" ng-disabled="!$ctrl.userChoice[$ctrl.questionNumber].answer" form="quiz_for_track_{{$ctrl.courseTrack.id}}" ng-if="$ctrl.isLastQuestion">
        Submit
      </button>
    </div>
  </div>
</div>
`;
