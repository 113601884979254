import template from './dashboard-card-my-devices-list-item.component.html';

const dashboardCardMyDevicesListItem = {
  bindings: {
    device: '<',
  },
  template,
};

export default dashboardCardMyDevicesListItem;
