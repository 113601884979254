/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PendingComponent } from './pending.component';
import { PendingDirective } from './pending.directive';
import { EntireWindowPendingService } from './entire-window/entire-window-pending.service';
import { EntireWindowPendingComponent } from './entire-window/entire-window-pending.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    PendingComponent,
    PendingDirective,
    EntireWindowPendingComponent
  ],
  exports: [
    PendingComponent,
    PendingDirective
  ],
  providers: [EntireWindowPendingService]
})
export class PendingModule {}
