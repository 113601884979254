export default `
<div class=" dashboard-card-list-item dashboard-card-list-item--my-devices">
  <div class="dashboard-card-list-item-icon">
    <i class="material-icons dashboard-card-list-item-icon-image {{$ctrl.device.icon}}"></i>
  </div>
  <div class="dashboard-card-list-item-description dashboard-card-list-item-description--my-devices">
    <div class="dashboard-card-list-item-description dashboard-card-list-item-description--primary">
      {{$ctrl.device.primary}}
    </div>
    <div class="dashboard-card-list-item-description dashboard-card-list-item-description--secondary">
      {{$ctrl.device.secondary}}
    </div>
  </div>
</div>
`;
