class AlertMessageService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getAll() {
    return this.$http.get('/api/v1/alert_messages');
  }

  dismiss(id) {
    return this.$http.post(`/api/v1/alert_messages/${id}/dismiss`, '');
  }
}

AlertMessageService.$inject = ['$http'];

export default AlertMessageService;
