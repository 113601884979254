import CourseService from '../components/course/course.service';
import SingleCourseCtrl from '../course/single/single-course.controller';
import singleCourseTemplate from '../course/single/single-course.html';
import UserAuthenticator from '../authentication/user-authenticator';
import UserResolver from '../authentication/user-resolver';
import CourseTrackService from '../components/course/course-track.service';

const CoursesRouter = {
  route: (module) => {
    module
      .service('CourseService', CourseService)
      .controller('SingleCourseCtrl', SingleCourseCtrl)
      .service('CourseTrackService', CourseTrackService);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('courses', {
          url: '/courses?track',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          template: '<div ui-view="courses-index" ng-show="$state.includes(\'course\')"></div><div ui-view="courses-show" ng-show="$state.includes(\'singlecourse\')"></div>',
          parent: 'root',
        })
        .state('academy', {
          url: '/academy',
          abstract: true,
          parent: 'root',
        })
        .state('academy-home', {
          url: '',
          component: 'academyHome',
          parent: 'academy',
          resolve: {
            title: [() => 'uTest Academy'],
            tracks: ['CourseTrackService', function (CourseTrackService) {
              return CourseTrackService.all().then((tracks) => tracks.data);
            }],
          },
        })
        .state('track-course', {
          url: '/tracks/:trackId/courses/:courseId',
          component: 'academyCourse',
          parent: 'academy',
          resolve: {
            courseTrack: ['CourseTrackService', '$transition$', function (CourseTrackService, $transition$) {
              return CourseTrackService.trackWithCourse($transition$.params().trackId, $transition$.params().courseId).then(res => res.data);
            }],
            title: ['courseTrack', courseTrack => `uTest Academy Course - ${courseTrack.name}: ${courseTrack.current_course.title}`],
          },
        })
        .state('tracks', {
          url: '/tracks/:trackId',
          component: 'academyCourse',
          parent: 'academy',
          resolve: {
            courseTrack: ['CourseTrackService', '$transition$', function (CourseTrackService, $transition$) {
              return CourseTrackService.track($transition$.params().trackId).then(res => res.data);
            }],
            title: ['courseTrack', courseTrack => `uTest Academy Course - ${courseTrack.name}: ${courseTrack.current_course.title}`],
          },
        })
        .state('quiz', {
          url: '/tracks/:trackId/quiz',
          component: 'academyCourseTrackQuiz',
          parent: 'academy',
          resolve: {
            courseTrack: ['CourseTrackService', '$transition$', function (CourseTrackService, $transition$) {
              return CourseTrackService.trackWithQuiz($transition$.params().trackId).then(res => res.data);
            }],
            user: UserAuthenticator,
            title: ['courseTrack', courseTrack => `uTest Academy Course - ${courseTrack.name}: Quiz`],
          },
        })
        .state('report-card', {
          url: '/tracks/:trackId/quiz/report-card',
          component: 'academyCourseTrackReportCard',
          parent: 'academy',
          resolve: {
            courseTrack: ['CourseTrackService', '$transition$', function (CourseTrackService, $transition$) {
              return CourseTrackService.trackWithQuiz($transition$.params().trackId).then(res => res.data);
            }],
            user: UserAuthenticator,
            title: ['courseTrack', courseTrack => `uTest Academy Course - ${courseTrack.name}: Results`],
          },
        })
        .state('singlecourse', {
          url: '/:courseId',
          parent: 'courses',
          params: {
            sectionId: { squash: true, value: null },
          },
          resolve: {
            course: ['CourseService', '$transition$', function (CourseService, $transition$) {
              return CourseService.find($transition$.params().courseId);
            }],
            user: UserResolver,
            title: ['course', course => `uTest Academy Course - ${course.data.title}`],
          },
          views: {
            'courses-show': {
              template: singleCourseTemplate,
              controller: 'SingleCourseCtrl',
            },
          },
          sticky: true,
        });
    }]);
  },
};

export default CoursesRouter;
