export default `
<main class="community-registration {{registration.slug}}-registration">
  <aside class="col-md-4 col-lg-4 hidden-sm hidden-xs" id="side_banner" bg-img="{{registration.banner_url}}" swap="{{registration.banner_url}}">
    <div class="left-content">
      <div class="h1">
      </div>
      <div class="h4">
      </div>
    </div>
    <div class="utest-icon-container">
      <img id="side-banner-utest-icon" src="/assets/images/utestLogoBlue.svg" alt="">
    </div>
  </aside>
  <alert-service></alert-service>
  <section id="com-reg-form-area" class="col-md-8 col-lg-8 col-sm-12 col-xs-12">
    <div class="container-fluid">
      <div class="clearfix">
        <div class="text-right col-xs-12 sign-in"> <span>Already have an account?</span>
          &nbsp;<a href="/login?return_to={{$location.absUrl()}}" target="_self">Sign in</a>
        </div>
        <community-registration-form registration="registration"></community-registration-form>
      </div>
    </div>
    <div class="utest-icon-container">
      <img id="subform-utest-icon" src="/assets/images/utestLogoBlue.svg" alt="">
    </div>
  </section>
</main>
`;
