class LandingMessagesCtrl {
  constructor($location) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.emailconfirmed = $location.search().emailconfirmed;
    };
  }
}

LandingMessagesCtrl.$inject = ['$location'];

export default LandingMessagesCtrl;
