import template from './new-joiner-checklist-item.component.html';

const newJoinerChecklistItem = {
  bindings: {
    checked: '<',
    description: '@',
    route: '@',
    url: '@',
    urlText: '@',
    staticOnCompletion: '<',
  },
  template,
};

export default newJoinerChecklistItem;
