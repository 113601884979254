import controller from './community-archive-btn.controller';
import template from './community-archive-btn.component.html';

const archiveBtn = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default archiveBtn;
