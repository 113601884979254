export default `
<div id="mainContent">
  <community-nav></community-nav>
  <div class="section-single-cover discussions small-icons">
    <div class="block container-fluid">

      <ul class="breadcrumb">
       <li>
          <a ui-sref="discussion">Forums</a>&#xA0;<span class="cr">&#xBB;</span>&#xA0;
        </li>
        <li>
          <a ui-sref="discussioncategory({categoryId : discussion.category.id})" class="discussion-cate">
          <span discussion-category="{{discussion.type}}">{{discussion.type}}:</span> {{discussion.category.name }}</a>&#xA0;
        </li>
      </ul>

      <a class="topic-feed-header-info" ui-sref="profile.about({profileId: discussion.user.slug})" aria-label="{{discussion.type}} Author, {{discussion.user.name}}">
        <div class="feed-header-info-icon">
          <div avatar-badges="discussion.user" class="profile-thumb"></div>
        </div>
        <div class="feed-header-info-name" >
          <span class="feed-header-info-name__text">{{discussion.user.name}}</span>
          <span class="time-stamp">{{discussion.published_at | relativeDate}}</span>
        </div>
      </a>
      <span tabindex="0"
            class="fa fa-info-circle"
            role="button"
            aria-label="{{ 'Open ' + discussion.user.name + ' tooltip' }}"
            dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: discussion.user})"
            ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: discussion.user})"
            ng-click="showTooltip('user-tooltip', {user: discussion.user});$event.preventDefault(); $event.stopImmediatePropagation()"
            ng-mouseleave="hideTooltip()"></span>
      <div class="discussion-cate"><a ui-sref="discussioncategory({categoryId : discussion.category.id})"><span discussion-category="{{discussion.type}}">{{discussion.type}}:</span> {{discussion.category.name }}</a></div>
      <h1 ng-bind-html="discussion.title | cleanUp | ulinky" aria-label="{{discussion.title}}, forums post by {{discussion.user.name}}"></h1>
      <div ng-show="discussion.current_user_permissions.destroy" class="func-btns small" ng-class="deleteUI">
        <div class="main-btns">
          <button id="deleteBtn" class="btn btn-rounded small delete-button btn-outline" ng-click="deleteUI = 'deleted';focusBtn('delete')" aria-hidden="false">Delete</button>
          <button id="editBtn" ui-sref="editDiscussion({discussionId: discussion.id})" class="btn btn-rounded small btn-grey btn-outline" aria-hidden="{{deleteUI === 'deleted' ? 'true' : 'false'}}">Edit</button>
        </div>
        <div class="delete-confirmation">
          Are you sure?
          <button id="deleteBtnNo" class="btn btn-rounded small" ng-click="deleteUI = 'normal';focusBtn('no')" ng-keydown="handleKeydownDeleteConformation($event)" aria-label="Are you sure you want to delete?, No" tabindex="{{deleteUI === 'deleted' ? 0 : -1}}" aria-hidden="true"><div>No</div></button>
          <button id="deleteBtnYes"class="btn btn-rounded small delete" ng-click="deleteDiscussion(discussion.id)" ng-keydown="handleKeydownDeleteConformation($event)" aria-label="Are you sure you want to delete?, Yes, Delete" tabindex="{{deleteUI === 'deleted' ? 0 : -1}}" aria-hidden="{{deleteUI === 'deleted' ? 'false' : 'true'}}"><div>Yes, Delete</div></button>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="section-single-toolbar">
      <div class="clearfix">
        <div class="section-single-toolbar__cell_left">
          <ut-community-feed-item-like-btn [likeable]="discussion"></ut-community-feed-item-like-btn>
          <ut-community-feed-item-like-count [likeable]="discussion" number="true"></ut-community-feed-item-like-count>
        </div>
        <div class="section-single-toolbar__cell_right">
          <social-sharing target="fullUrl" text="discussion.title" type="Topic"></social-sharing>
          <community-feed-item-hide item="discussion" ng-show="discussion.current_user_permissions.hide"></community-feed-item-hide>
          <community-feed-item-flag item="discussion" ng-show="discussion.current_user_permissions.flag"></community-feed-item-flag>
          <toggle-link ng-show="discussion.current_user_permissions.lock" ng-click="toggleLock(discussion)" active="discussion.locked"></toggle-link>
          <community-feed-item-subscribe item="discussion"></community-feed-item-subscribe>
        </div>
      </div>
      <hr aria-hidden="true">
    </div>
  </div>
  <div class="container-fluid">
    <article ng-bind-html="discussion.content_built"></article>
    <hr aria-hidden="true">
  </div>
  <div class="section-single-body discussion">
    <div class="section-single-body-content discussion">
        <comment-section service="service" commentable="discussion" ></comment-section>
    </div>
  </div>
  <section class="slide-up slide-up-move" id="editSingleDiscussion">
    <form ng-submit="update();toggleSlideUp('editSingleDiscussion')" >
      <div class="form-group">
        <label for="">Title</label>
        <input type="text" class="form-control" ng-model="discussion.title" required>
      </div>
      <div class="form-group text-center">
        <button type="submit" class="btn btn-yellow">Save Changes</button>
      </div>
    </form>
  </section>
</div>
`;
