export default `
<div class="dashboard-activity-no-list-items">
  <div class="dashboard-activity-no-list-items__primary">
    You don't have any {{$ctrl.haveNo}}.
  </div>

  <div class="dashboard-activity-no-list-items__secondary">
    Browse the Projects Board to find new testing opportunities.
  </div>

  <div class="dashboard-activity-no-list-items-button-container">
    <a ui-sref="project" class="dashboard-link-button">
      Projects Board
    </a>
  </div>
</div>
`;
