const prependHttp = function () {
  return function (input) {
    const regexp = /^\s*(https?|ftp|mailto):/;
    if (!regexp.test(input)) {
      return `http://${input}`;
    }
    return input;
  };
};

export default prependHttp;
