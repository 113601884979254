export default `
<div id="mainContent">
    <div class="cover" bg-img="assets/images/aboutUs-bg.jpg">
      <div class="grid w890">
        <h1>Tester Payments FAQ Page</h1>
      </div>
    </div>

    <div class="grid w890">

        <h5>Q: Who does this impact?</h5>
        <p>A: All testers participating in single Fn cycles will be impacted by this change.</p>

        <br>
        <h5>Q: When does this change take effect?</h5>
        <p>A: The change will take place for all future Functional cycles created after launch (scheduled for Tuesday, April 12th).</p>

        <br>
        <h5>What will it look like for me?</h5>
        <p>A: Within test cycles the default was previously round $1.00 increments (e.g. $15.00, $24.00, $32.00, etc.). Going forward you will see payments in $0.05 increments (e.g. $15.45, $24.10, $31.90, etc).</p>

        <br>
        <h5>Q: Are you only rounding payments down?</h5>
        <p>A: No, payments will be rounded to the nearest $0.05, both up and down.</p>

        <br>
        <h5>Q: Where should I expect to see the changes applied?</h5>
        <p>A: Currently it is only being applied to Functional bugs and test cases. It does not impact Non-Functional cycles, TTL payments, or bonuses, BFV, continuous cycles, etc.</p>

        <br>
        <h5>Q: Why aren’t these changes being applied to everything (ie bonuses, TTL Payments)?</h5>
        <p>A: We are initially starting with Functional bugs/issues and test cases only and then will expand this process to all testing types as necessary. This will allow us execute the rollout of this next iteration of payments in the most efficient and seamless way possible.</p>

        <br>
        <h5>Q: Who should I contact with additional questions?</h5>
        <p>A: Any unanswered questions may be directed to <a href="mailto:testers@utest.com">testers@utest.com</a>, where the CM team will reply with answers.</p>

    </div>
</div>
`;
