class ToolCategoryService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  all() {
    return this.$http.get('/api/v1/tool_categories', { params: { per_page: 50 } });
  }

  find(id) {
    return this.$http.get(`/api/v1/tool_categories/${id}`);
  }
}

ToolCategoryService.$inject = ['$http'];

export default ToolCategoryService;
