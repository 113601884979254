import template from './community-feed-item-flag.component.html';
import controller from './community-feed-item-flag.controller';

const communityFeedItemFlag = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default communityFeedItemFlag;
