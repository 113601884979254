import template from './referral-campaign-detail.component.html';
import controller from './referral-campaign-detail.controller';

const referralCampaignDetail = {
  bindings: {
    campaign: '<',
    currentUser: '<',
    utestUrl: '<',
  },
  template,
  controller,
};

export default referralCampaignDetail;
