import _ from 'lodash';

class ArchiveBtn {
  constructor(ArchiveService, AlertService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.processing = false;
      ctrl.getTitle = function () {
        if (ctrl.item.state === 'archived') {
          return 'Unarchive';
        }
        return 'Archive';
      };
    };
    function successCallback(res) {
      ctrl.item.state = res.data.state;
      if (ctrl.item.state === 'archived') {
        AlertService.add(`You successfully archived this ${ctrl.item.type}`);
      } else {
        AlertService.add(`You successfully unarchived this ${ctrl.item.type}`);
      }
      ctrl.processing = false;
    }

    function errorCallback() {
      ctrl.processing = false;
      AlertService.add('Request failed', 'danger');
    }

    ctrl.toggleArchiveStatus = function () {
      if (!ctrl.processing && !_.isUndefined(ctrl.item.state)) {
        ctrl.processing = true;
        if (ctrl.item.state === 'published') {
          ArchiveService.archive(ctrl.item).then(successCallback, errorCallback);
        } else {
          ArchiveService.unarchive(ctrl.item).then(successCallback, errorCallback);
        }
      }
    };
  }
}

ArchiveBtn.$inject = ['ArchiveService', 'AlertService'];

export default ArchiveBtn;
