class IsValidService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  check(attr, value) {
    const params = {};
    params[attr] = value;

    return this.$http.get('/api/v1/is_valid', { params });
  }
}

IsValidService.$inject = ['$http'];

export default IsValidService;
