import simpleRegistrationTemplate from '../layouts/simple-registration.html';
import SimpleRegistrationCtrl from '../simple-registration/simple-registration.controller';
import setPasswordTemplate from '../simple-registration/set-password/simple-registration-set-password.component.html';
import SimpleRegistrationSetPasswordCtrl
  from '../simple-registration/set-password/simple-registration-set-password.controller';

const SimpleRegistrationsRouter = {
  route: (module) => {
    module
      .controller('SimpleRegistrationCtrl', SimpleRegistrationCtrl)
      .controller('SimpleRegistrationSetPasswordCtrl', SimpleRegistrationSetPasswordCtrl)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('simpleregistration', {
            url: '/signup/5z1sp95',
            template: simpleRegistrationTemplate,
            controller: 'SimpleRegistrationCtrl',
            resolve: {
              title: [() => 'uTest Simplified Sign Up Screen'],
              meta: ['title', 'Meta', function (title, Meta) {
                Meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
              }],
            },
          })
          .state('setpassword', {
            url: '/set-password?code&userCode',
            template: setPasswordTemplate,
            controller: 'SimpleRegistrationSetPasswordCtrl',
            controllerAs: '$ctrl',
            resolve: {
              title: [() => 'uTest Simplified Sign Up Set Password Screen'],
              meta: ['title', 'Meta', function (title, Meta) {
                Meta.updateTag({ name: 'og:title', property: 'og:title', content: title });
              }],
            },
          });
      }]);
  },
};

export default SimpleRegistrationsRouter;
