class ReferralCampaignDetailCtrl {
  constructor() {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.referralLink = `${ctrl.utestUrl}/signup/personal?utm_campaign=${ctrl.campaign.slug}&utm_medium=landing_page&utm_source=referral&referral_tester_id=${ctrl.currentUser.platform_id}`;
    };
  }
}

ReferralCampaignDetailCtrl.$inject = [];

export default ReferralCampaignDetailCtrl;
