class DashboardCardProfileCompletionCtrl {
  constructor(ConfigService, UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.svgSize = 120;
      ctrl.meterWidth = 8;
      ctrl.svgCenter = ctrl.svgSize / 2;
      ctrl.meterRadius = ctrl.svgCenter - (ctrl.meterWidth / 2);
      ctrl.meterCircumference = (2 * Math.PI * ctrl.meterRadius) * 0.75;

      ConfigService.config().then((res) => {
        ctrl.completeMyProfileUrl = `${res.data.profile_url}/account/my-profile/`;
      });

      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
          ctrl.dashOffset = ctrl.meterCircumference * (1 - (ctrl.currentUser.tester_profile_completion_percentage / 100));
        } else {
          ctrl.currentUser = null;
          ctrl.dashOffset = ctrl.meterCircumference;
        }
      });
    };
  }
}

DashboardCardProfileCompletionCtrl.$inject = ['ConfigService', 'UserService'];

export default DashboardCardProfileCompletionCtrl;
