/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DisplayValuePipe } from './display-value.pipe';
import { OptErrorComponent } from './opt-error/opt-error.component';
import { OptGroupComponent } from './opt-group/opt-group.component';
import { OptHintComponent } from './opt-hint/opt-hint.component';
import { OptionComponent } from './option/option.component';
import { OptionsContainerComponent } from './options-container/options-container.component';
import { OptionsOverlayComponent } from './options-overlay/options-overlay.component';
import { OptionsSearchBoxComponent } from './options-search-box/options-search-box.component';
import { SearchFieldModule } from "@ng-falcon/search-field/search-field.module";
import { OptionsSearchContainerComponent } from "@ng-falcon/controls/option/options-search-container/options-search-container.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { PendingModule } from "@ng-falcon/pending/pending.module";

@NgModule({
  imports: [
    CommonModule,
    OverlayModule,
    MatIconModule,
    SearchFieldModule,
    InfiniteScrollModule,
    PendingModule
  ],
  declarations: [
    OptionComponent,
    OptGroupComponent,
    OptionsContainerComponent,
    OptionsOverlayComponent,
    DisplayValuePipe,
    OptHintComponent,
    OptErrorComponent,
    OptionsSearchBoxComponent,
    OptionsSearchContainerComponent
  ],
  exports: [
    OptionComponent,
    OptGroupComponent,
    OptionsContainerComponent,
    OptionsOverlayComponent,
    DisplayValuePipe,
    OptHintComponent,
    OptErrorComponent,
    OptionsSearchContainerComponent
  ]
})
export class OptionModule {}
