import { TransitionService } from '@uirouter/core';

class NavSidebarToggleCtrl {
  constructor($transitions: TransitionService, $window: ng.IWindowService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      this.toggleNavMenu = function (action) {
        $('body').toggleClass('nav-menu-open');
        if (action === 'close') {
          setTimeout(() => { $('nav-sidebar').addClass('nav-sidebar__hide'); }, 250);
        } else {
          $('nav-sidebar').removeClass('nav-sidebar__hide');
        }
        $('.nav-sidebar-toggle').focus();
      };

      $transitions.onSuccess({}, (_transition) => {
        if ($window.innerWidth < 769) {
          $('body').removeClass('nav-menu-open');
          $('nav-sidebar').addClass('nav-sidebar__hide');
        }
      });
    };
  }
}

NavSidebarToggleCtrl.$inject = ['$transitions', '$window'];

export default NavSidebarToggleCtrl;
