export default `
<div class="campaign-detail">
  <div class="campaign-feed-item-header">
    <div class="campaign-feed-item-header-info">
      <div class="campaign-feed-item-header__avatar campaign-feed-item-header__avatar--glyph"><i class="material-icons">group_add</i></div>
      <a class="campaign-feed-item-header__author">
        Referral Program
        <small ng-if="$ctrl.campaign.countries.length > 0">/ {{$ctrl.campaign.countries.join(', ')}}</small>
      </a>
    </div>
  </div>
  <div class="campaign-detail-body__title">{{$ctrl.campaign.title}}</div>
  <div class="campaign-detail-body__content" ng-bind-html="$ctrl.campaign.content"></div>
  <div class="campaign-detail-referral">
    <div class="campaign-detail-referral-cta">
      To participate, have your friends use your referral link below to sign up.
    </div>
    <div class="campaign-detail-referral-widget">
      <div class="campaign-detail-referral-widget__header">
        Your referral link
      </div>
      <div class="campaign-detail-referral-widget-link">
        <div class="campaign-detail-referral-widget-link__url">{{$ctrl.referralLink}}</div>
        <button class="campaign-detail-referral-widget-link__button" clipboard data-clipboard-text="{{$ctrl.referralLink}}">Copy Link</button>
      </div>
    </div>
  </div>
</div>
`;
