export default `
<div class="feed leaderboard" >
  <div class="leader-info">
    <a ui-sref="profile.about({profileId: user.slug})"><div class="profile-thumb" bg-img="{{user.avatar_url}}"></div></a>
    <h5><a ui-sref="profile.about({profileId: user.slug})">{{user.name}}</a>
      <span tabindex="0"
            class="fa fa-info-circle"
            role="button"
            aria-label="{{ 'Open ' + user.name + ' tooltip' }}"
            dynamic-tooltip ng-mouseenter="showTooltip('user-tooltip', {user: user})"
            ng-keypress="showTooltipWithKey($event, 'user-tooltip', {user: user})"
            ng-click="showTooltip('user-tooltip', {user: user});$event.preventDefault(); $event.stopImmediatePropagation()"
            ng-mouseleave="hideTooltip()"></span></h5>
  </div>
  <div class="leaderboard-points">{{user.points}}</div>
  <follow-btn user="user" ng-if="user.current_user_permissions['follow']"></follow-btn>
  <div class="cus-checkbox-follow" ng-if="!user.current_user_permissions['follow']"></div>
  <div class="leaderboard-badge">
    <img ng-repeat="badge in user.badges" ng-attr-title={{badgePresentation[badge.badge_type].name}} ng-src="assets/images/badges/{{badgePresentation[badge.badge_type].icon}}">
  </div>
</div>
`;
