class FeedbackService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getMyFeedback(featureName) {
    return this.$http.get('/api/v1/feedback', { params: { feature: featureName } });
  }

  postPositiveFeedback(featureName) {
    return this.$http.post('/api/v1/feedback', { feature: featureName, is_positive: true });
  }

  postNegativeFeedback(featureName) {
    return this.$http.post('/api/v1/feedback', { feature: featureName, is_positive: false });
  }
}

FeedbackService.$inject = ['$http'];

export default FeedbackService;
