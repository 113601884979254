class CommunityRegistrationService {
  UrlService: any;
  $http: ng.IHttpService;
  constructor(UrlService, $http) {
    this.UrlService = UrlService;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/community_registrations/${id}`).then(this.UrlService.addResponseUrl);
  }
}

CommunityRegistrationService.$inject = ['UrlService', '$http'];

export default CommunityRegistrationService;
