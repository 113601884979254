import template from './like-btn.html';

const likeBtn = function (LikeService, UserService, $analytics, $compile, $uibModal) {
  return {
    restrict: 'E',
    replace: true,
    scope: {
      likeable: '=',
      number: '=',
      reverse: '=',
      removeLikeIcon: '=',
    },
    template,
    link(scope) {
      UserService.me().then((res) => {
        if (res !== null) {
          scope.currentUser = res.data;
        } else {
          scope.currentUser = null;
        }
      });
      const updateLikeStats = function (likeResponse) {
        if (likeResponse) {
          scope.likeable.likes.count = likeResponse.data.likes.count;
          scope.likeable.likes.liked_by_current_user = likeResponse.data.likes.liked_by_current_user;
        }
        return likeResponse;
      };

      const trackLiking = (options) => {
        $analytics.eventTrack(options.action, { category: 'Likes', label: options.type });
      };

      scope.likeIt = function () {
        if (scope.likeable.current_user_permissions.like) {
          if (!scope.likeable.likes.liked_by_current_user) {
            LikeService.like(scope.likeable.type, scope.likeable.id).then(updateLikeStats);
            trackLiking({ action: 'like', type: scope.likeable.type });
          } else {
            LikeService.unlike(scope.likeable.type, scope.likeable.id).then(updateLikeStats);
            trackLiking({ action: 'unlike', type: scope.likeable.type });
          }
        } else {
          // TODO(agitter): display a message like "login to like items"?
        }
      };

      scope.openOverlay = function () {
        $analytics.eventTrack('likeOverlay', {
          category: 'click',
          label: scope.likeable.id,
        });
        scope.theModal = $uibModal.open({
          animation: true,
          template: '<div class="like-list-overlay"></div>',
          controller: 'ModalCtrl',
          resolve: {
            data() {
              return {
                likeable: scope.likeable,
                closeOverlay() {
                  scope.theModal.close();
                },
              };
            },
          },
        });
      };
    },
  };
};

likeBtn.$inject = ['LikeService', 'UserService', '$analytics', '$compile', '$uibModal'];
export default likeBtn;
