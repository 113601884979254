const RedirectService = function ($q, $location, $window) {
  return {
    response(res) {
      if (res.status === 401) {
        $window.location = '/login';
      }
      return res;
    },
    responseError(res) {
      if (res.status === 401) {
        $window.location = '/login';
      }
      return $q.reject(res);
    },
  };
};

RedirectService.$inject = ['$q', '$location', '$window'];
export default RedirectService;
