const LandingPageService = ($http) => {
  const get = id => $http.get(`/api/v1/landing_pages/${id}`);

  return {
    get,
  };
};

LandingPageService.$inject = ['$http'];
export default LandingPageService;
