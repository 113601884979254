import _ from 'lodash';
import UserAuthenticator from '../authentication/user-authenticator';

import LikedContentCtrl from '../liked-content/liked-content.controller';
import likedContentTemplate from '../liked-content/liked-content.html';

import LeaderboardCtrl from '../leaderboard/leaderboard.controller';
import LeaderboardService from '../components/leaderboard/leaderboard.service';
import feedLeaderboard from '../components/leaderboard/feed-leaderboard.directive';
import leaderboardTemplate from '../leaderboard/leaderboard.html';

import StaticValuesService from '../components/static-values/static-values.service';

import ProfileCtrl from '../profile/profile.controller';
import UserService from '../components/user/user.service';
import profileTemplate from '../profile/profile.html';
import profileAboutTemplate from '../profile/profile.about.html';
import profileArticlesTemplate from '../profile/profile.articles.html';
import profileStatusTemplate from '../profile/profile.status.html';
import profileCommentsTemplate from '../profile/profile.comments.html';
import profileMentionsTemplate from '../profile/profile.mentions.html';
import profileTopicsTemplate from '../profile/profile.topics.html';
import profileLikesTemplate from '../profile/profile.likes.html';

import FollowersCtrl from '../followers/followers.controller';
import FollowingCtrl from '../following/following.controller';
import followersTemplate from '../followers/followers.html';
import followingTemplate from '../following/following.html';

import ActivityService from '../components/activity/activity.service';

const UserRouter = {
  route: (module) => {
    module
      .controller('LikedContentCtrl', LikedContentCtrl)

      .controller('LeaderboardCtrl', LeaderboardCtrl)
      .service('LeaderboardService', LeaderboardService)
      .directive('feedLeaderboard', feedLeaderboard)

      .service('StaticValuesService', StaticValuesService)

      .controller('ProfileCtrl', ProfileCtrl)
      .service('UserService', UserService)

      .controller('FollowersCtrl', FollowersCtrl)
      .service('ActivityService', ActivityService)
      .controller('FollowingCtrl', FollowingCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('leaderboard', {
          url: '/leaderboard',
          parent: 'root',
          template: leaderboardTemplate,
          controller: 'LeaderboardCtrl',
          resolve: {
            title: [() => 'uTest Leaderboard'],
          },
        })
        .state('likedcontent', {
          url: '/liked-content',
          parent: 'root',
          template: likedContentTemplate,
          controller: 'LikedContentCtrl',
          resolve: {
            user: UserAuthenticator,
            title: [() => 'uTest Liked Content'],
          },
        })
        .state('profile', {
          url: '/profile/:profileId',
          parent: 'root',
          template: profileTemplate,
          controller: 'ProfileCtrl',
          redirectTo: 'profile.about',
          resolve: {
            user: ['UserService', '$transition$', '$state', '$q', 'store', (UserService, $transition$, $state, $q) => {
              const pommes = $q.defer();

              const userPromiseResolver = (promise) => {
                const newPromise = UserService.find($transition$.params().profileId);
                newPromise.catch(() => $state.go('fourohfour', null, { location: false }));
                newPromise.then(data => promise.resolve(data));
              };

              const currentUserPromise = UserService.me().then(() => userPromiseResolver(pommes));

              currentUserPromise.catch(() => {
                userPromiseResolver(pommes);
              });
              return pommes.promise;
            }],
          },
        })
        .state('profile.about', {
          url: '/about',
          template: profileAboutTemplate,
          resolve: {
            title: [() => 'uTest Profile'],
          },
        })
        .state('profile.articles', {
          url: '/articles',
          controller: ['$scope', ($scope) => {
            $scope.articleStore.loadOnce();
          }],
          template: profileArticlesTemplate,
          resolve: {
            title: [() => 'uTest Profile Articles'],
          },
        })
        .state('profile.status', {
          url: '/status',
          controller: ['$scope', ($scope) => {
            $scope.statusStore.loadOnce();
          }],
          template: profileStatusTemplate,
          resolve: {
            title: [() => 'uTest Profile Status Updates'],
          },
        })
        .state('profile.comments', {
          url: '/comments',
          controller: ['$scope', '$sce', '$filter', ($scope, $sce, $filter) => {
            $scope.commentsStore.loadOnce().then((commentStore) => {
              commentStore.feedItems = _.map(commentStore.feedItems, (o) => {
                o.content_built = $sce.trustAsHtml($filter('uMarkdown')(o.content));
                return o;
              });
            });
          }],
          template: profileCommentsTemplate,
          resolve: {
            title: [() => 'uTest Profile Status Comments'],
          },
        })
        .state('profile.mentions', {
          url: '/mentions',
          controller: ['$scope', ($scope) => {
            $scope.mentionsStore.loadOnce();
          }],
          template: profileMentionsTemplate,
          resolve: {
            title: [() => 'uTest Profile Status Mentions'],
          },
        })
        .state('profile.topics', {
          url: '/topics',
          controller: ['$scope', ($scope) => {
            $scope.topicsStore.loadOnce();
          }],
          template: profileTopicsTemplate,
          resolve: {
            title: [() => 'uTest Profile Status Topics'],
          },
        })
        .state('profile.likes', {
          url: '/likes',
          controller: ['$scope', ($scope) => {
            $scope.likesStore.loadOnce();
          }],
          template: profileLikesTemplate,
          resolve: {
            title: [() => 'uTest Profile Status Likes'],
          },
        })
        .state('activity', {
          url: '/activity',
          parent: 'root',
          component: 'activityFeed',
          resolve: {
            title: [() => 'uTest - Notifications'],
          },
        })
        .state('followers', {
          url: '/followers/:profileId',
          parent: 'root',
          template: followersTemplate,
          controller: 'FollowersCtrl',
          resolve: {
            user: ['UserService', '$transition$', '$state', (UserService, $transition$, $state) => {
              const userPromise = UserService.find($transition$.params().profileId);
              userPromise.catch(() => $state.go('fourohfour'));
              return userPromise;
            }],
            title: [() => 'uTest Followers'],
          },
        })
        .state('following', {
          url: '/following/:profileId',
          parent: 'root',
          template: followingTemplate,
          controller: 'FollowingCtrl',
          resolve: {
            user: ['UserService', '$transition$', '$state', (UserService, $transition$, $state) => {
              const userPromise = UserService.find($transition$.params().profileId);
              userPromise.catch(() => $state.go('fourohfour'));
              return userPromise;
            }],
            title: [() => 'uTest Following'],
          },
        });
    }]);
  },
};

export default UserRouter;
