import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'ngf-options-container',
  templateUrl: './options-container.component.html',
  styleUrls: ['./options-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OptionsContainerComponent {
}
