import template from './course-track-report-card.component.html';
import controller from './course-track-report-card.controller';

const courseTrackReportCard = {
  bindings: {
    courseTrack: '<',
  },
  template,
  controller,
};

export default courseTrackReportCard;
