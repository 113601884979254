import angular from 'angular';

class NavSidebarCtrl {
  constructor(UserService, ConfigService, $window: ng.IWindowService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
      });

      if ($window.innerWidth >= 769) {
        $('body').addClass('nav-menu-open');
        $('nav-sidebar').removeClass('nav-sidebar__hide');
      } else {
        $('nav-sidebar').addClass('nav-sidebar__hide');
      }

      ctrl.onResize = function () {
        if ($window.innerWidth >= 769) {
          $('body').addClass('nav-menu-open');
          $('nav-sidebar').removeClass('nav-sidebar__hide');
        }
      };

      ctrl.removeEventListeners = function () {
        angular.element($window).off('resize', ctrl.onResize);
      };

      angular.element($window).on('resize', ctrl.onResize);
    };
    ctrl.handleSkipKeydown = (e) => {
      if ([13].includes(e.keyCode)) {
        $('#mainContent')[0].tabIndex = -1;
        $('#mainContent')[0].focus();
      } else if ([9].includes(e.keyCode)) {
        const element = $('nav-sidebar');
        if ($window.innerWidth <= 769) {
          const targetToggle = element[0].classList.contains('nav-sidebar__hide') ? 1 : 0;
          e.preventDefault();
          $('.nav-sidebar-toggle')[targetToggle].focus();
        }
      }
    };

    ctrl.$onDestroy = function () {
      ctrl.removeEventListeners();
    };
  }
}

NavSidebarCtrl.$inject = ['UserService', 'ConfigService', '$window'];

export default NavSidebarCtrl;
