/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { $SimpleChanges } from '@ng-falcon/+utils/on-changes/simple-changes';
import { NgfFormControl } from '@ng-falcon/ngf-form/+model/ngf-form-control';
import { noop, Subscription } from 'rxjs';
import { distinctUntilChanged, skip, tap } from 'rxjs/operators';

@Component({
  selector: 'ngf-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutocompleteInputComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  placeholder: string;
  @Input()
  hasError = false;
  @Input()
  hasShadow = false;
  @Input()
  displayValue: string | null;
  @Input()
  isOpen: boolean;
  @Input()
  disabled = false;
  @Input()
  disableClear: boolean;

  @Output()
  search = new EventEmitter<string>();
  @Output()
  toggleOptions = new EventEmitter<boolean>();
  @Output()
  clearValue = new EventEmitter();
  @Output()
  focusLost = new EventEmitter();

  @ViewChild('inputRef')
  inputRef: ElementRef<HTMLInputElement>;

  constructor(@Inject(DOCUMENT) private document: Document,
              private elementRef: ElementRef<HTMLElement>) {}

  private sub: Subscription;

  searchControl = new NgfFormControl('');

  ngOnInit(): void {
    this.sub = this.handleSearchChange$().subscribe(noop);
  }

  ngOnChanges(changes: $SimpleChanges<this>): void {
    if (changes.disabled) {
      this.disabled ? this.searchControl.disable() : this.searchControl.enable();
      this.clearSearch();
    }

    if (changes.displayValue) {
      if (changes.displayValue.currentValue == null) {
        this.clearSearch();
      }

      // preserve focus while toggling between search input and display input
      if (this.elementRef.nativeElement.contains(this.document.activeElement)) {
        this.document.defaultView.setTimeout(() => this.inputRef.nativeElement.focus());
      }
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  handleToggle() {
    this.toggleOptions.emit(!this.isOpen);
  }

  handleClear() {
    if (this.displayValue) {
      this.clearValue.emit();
    }

    this.clearSearch();
  }

  handleBlur() {
    this.focusLost.emit();
  }

  private clearSearch() {
    this.searchControl.setValue('');
  }

  private handleSearchChange$() {
    return this.searchControl.value$.pipe(
      distinctUntilChanged(),
      skip(1),
      tap(value => this.search.emit(value))
    );
  }

}
