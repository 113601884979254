import template from './dashboard-all-tab.component.html';
import controller from './dashboard-all-tab.controller';

const dashboardAllTab = {
  bindings: {
    activities: '<',
    maxActivitiesCount: '<',
  },
  template,
  controller,
};

export default dashboardAllTab;
