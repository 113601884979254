import {
  Component,
} from '@angular/core';
import { UNAUTH_WHY_UTEST_COMMUNITY_UTESTERS } from '../../../../constants';

@Component({
  selector: 'ut-commmunity-nav',
  templateUrl: './community-nav.component.html',
  styleUrls: ['./community-nav.component.scss'],
})
class CommunityNavComponent {
  communityUtesters: any = UNAUTH_WHY_UTEST_COMMUNITY_UTESTERS;

  communityUtestersSelected = this.communityUtesters[0];

  mouseUp = true;
  mouseDown = false;
  mouseXPosition = 0;

  onMouseDown = (event: MouseEvent) => {
    this.mouseUp = false;
    this.mouseDown = true;
    this.mouseXPosition = event.screenX;
  };

  onMouseMove = (event: MouseEvent) => {
    if (this.mouseDown && this.mouseXPosition !== 0) {
      if ((this.mouseXPosition - event.screenX) > 100) {
        const nextId = this.communityUtestersSelected.id !== 3 ? (this.communityUtestersSelected.id + 1) : 1;
        this.mouseXPosition = 0;
        this.uTesterNavigateClick(nextId);
      } else if ((this.mouseXPosition - event.screenX) < -100) {
        const prevId = this.communityUtestersSelected.id !== 1 ? (this.communityUtestersSelected.id - 1) : 3;
        this.mouseXPosition = 0;
        this.uTesterNavigateClick(prevId);
      }
    }
  };

  onMouseUp = () => {
    this.mouseUp = true;
    this.mouseDown = false;
    this.mouseXPosition = 0;
  };

  uTesterNavigateClick(communityUtesterId: number) {
    this.communityUtestersSelected = this.communityUtesters.filter(commUtester => commUtester.id === communityUtesterId)[0];
  }
}

export { CommunityNavComponent };
