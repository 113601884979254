import { KEY_CODES } from '../../constants';

const accessibleFocusTrapService = function accessibleFocusTrapService() {
  const keyCodes = KEY_CODES;

  function trapAndSetFocus(el, scope) {
    const supportedTags = ['a[href]:not([disabled])', 'button:not([disabled])', 'textarea:not([disabled])', 'select:not([disabled])', 'input:not([disabled])'];
    const children = el[0].querySelectorAll(supportedTags);
    const firstElem = children[0];
    let lastElem;
    setTimeout(() => {
      for (let i = 0; i < children.length; i++) {
        const elem = angular.element(children[i]);
        if (elem.is(':visible')) {
          addListener(children[i]);
          lastElem = children[i];
        }
      }
    }, 100);

    const keydownEventListener = (event) => {
      if (event.keyCode === keyCodes.ESC) {
        scope.$broadcast('closeModal');
        removeEventListeners();
        return;
      }
      const isTab = event.keyCode === keyCodes.TAB || event.key === 'Tab';
      if (!isTab) {
        return;
      }
      if (event.shiftKey) {
        if (document.activeElement === firstElem) {
          event.preventDefault();
          lastElem.focus();
        }
      } else if (document.activeElement === lastElem) {
        event.preventDefault();
        firstElem.focus();
      }
    };

    const outsideElemClickListener = (event) => {
      const childrenArray = Object.values(el[0].getElementsByTagName('*'));
      if (event.isTrusted && el[0] !== event.target && !childrenArray.includes(event.target)) {
        scope.$broadcast('closeModal');
      }
    };

    function addListener(elem) {
      elem.addEventListener('keydown', keydownEventListener);
    }

    function removeEventListeners() {
      for (let i = 0; i < children.length; i++) {
        const elem = angular.element(children[i]);
        if (elem.is(':visible')) {
          children[i].removeEventListener('keydown', keydownEventListener);
        }
      }
    }

    if (document.activeElement === undefined || document.activeElement !== firstElem) {
      firstElem.focus();
    }
    setTimeout(() => {
      document.addEventListener('click', outsideElemClickListener);
    }, 100);
    scope.$on('removeEventListener', () => {
      document.removeEventListener('click', outsideElemClickListener);
    });
  }

  return {
    trapAndSetFocus,
  };
};

export default accessibleFocusTrapService;
