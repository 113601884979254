import _ from 'lodash';
import { KEY_CODES } from '../../constants';

class CustomDropdownCtrl {
  constructor() {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.dropdownShown = false;

      if (!ctrl.model) {
        ctrl.model = _.keys(ctrl.options)[0];
        ctrl.selectedItemValue = _.values(ctrl.options)[0];
      } else {
        ctrl.label = ctrl.options[ctrl.model];
        ctrl.selectedItemValue = ctrl.model;
      }
    };

    ctrl.setValue = (value) => {
      const oldValue = ctrl.model;
      ctrl.label = ctrl.options[value];
      ctrl.model = value;
      ctrl.dropdownShown = false;

      if (oldValue !== value && _.isFunction(ctrl.onChange)) {
        ctrl.onChange(value);
      }
      document.getElementById('customDropdownBtn').focus();
    };

    ctrl.toggleDropdown = () => {
      ctrl.dropdownShown = !ctrl.dropdownShown;
      if (ctrl.dropdownShown) {
        setTimeout(() => {
          $('ul > li.selected')[0].focus();
        }, 10);
      } else {
        ctrl.selectedItemValue = ctrl.model;
        document.getElementById('customDropdownBtn').focus();
      }
    };

    ctrl.handleOptionKeydown = (event, value) => {
      const codes = [KEY_CODES.TAB, KEY_CODES.RETURN,
        KEY_CODES.ESC, KEY_CODES.SPACE,
        KEY_CODES.UP, KEY_CODES.DOWN];
      if (codes.includes(event.keyCode)) {
        switch (event.keyCode) {
          case KEY_CODES.SPACE:
            ctrl.setValue(value);
            break;
          case KEY_CODES.UP:
            event.preventDefault();
            focusNextListItem('up');
            break;
          case KEY_CODES.DOWN:
            event.preventDefault();
            focusNextListItem('down');
            break;
          case KEY_CODES.ESC:
            ctrl.toggleDropdown();
            break;
          case KEY_CODES.TAB:
            ctrl.toggleDropdown();
            break;
          case KEY_CODES.RETURN:
            ctrl.setValue(value);
            break;
          default: return true;
        }
      }
    };

    ctrl.handleOnBlur = (event) => {
      if (event.relatedTarget === null) {
        ctrl.toggleDropdown();
      }
    };

    function focusNextListItem(direction) {
      const optionKeys = Object.keys(ctrl.options);
      const currentItemIndex = optionKeys.indexOf(ctrl.selectedItemValue);

      if (direction === 'down') {
        if (currentItemIndex < (optionKeys.length - 1)) {
          // focus next list item
          ctrl.selectedItemValue = optionKeys[currentItemIndex + 1];
          const temp = currentItemIndex + 1;
          const nextListItem = $(`li#filter-option-${temp}`)[0];
          nextListItem.focus();
        }
      } else if (direction === 'up') {
        if (currentItemIndex > 0) {
          // focus previous item
          ctrl.selectedItemValue = optionKeys[currentItemIndex - 1];
          const temp = currentItemIndex - 1;
          const prevListItem = $(`li#filter-option-${temp}`)[0];
          prevListItem.focus();
        }
      }
    }
  }
}

export default CustomDropdownCtrl;
