import germanFaqsTemplate from '../landing-pages/german-faqs.html';
import germanStarterWebsiteTemplate from '../landing-pages/german-starter-website.html';
import paidTestingFranceTemplate from '../landing-pages/paid-testing-france.html';
import paidTestingJapanTemplate from '../landing-pages/paid-testing-japan.html';
import testermonialsTemplate from '../landing-pages/tester-monials.html';
import wasIstUtestTemplate from '../landing-pages/was-ist-utest.html';
import welcomeTestersFranceTemplate from '../landing-pages/welcome-testers-france.html';
import welcomeTestersSwedenTemplate from '../landing-pages/welcome-testers-sweden.html';
import whyJoinUtestTemplate from '../landing-pages/why-join-utest.html';

const LandingPagesRouter = {
  route: (module) => {
    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('germanfaqs', {
          url: '/german-faqs',
          parent: 'root',
          template: germanFaqsTemplate,
          resolve: {
            title: [() => 'uTest Überblick und häufig gestellte Fragen'],
          },
          data: {
            routeClasses: 'lp lp-german-faqs',
          },
        })
        .state('whyjoinutest', {
          url: '/why-join-utest',
          parent: 'root',
          template: whyJoinUtestTemplate,
          resolve: {
            title: [() => 'uTest - Why Join uTest?'],
          },
          data: {
            routeClasses: 'lp lp-why-join-utest',
          },
        })
        .state('welcometestersfrance', {
          url: '/welcome-testers-france',
          parent: 'root',
          template: welcomeTestersFranceTemplate,
          resolve: {
            title: [() => 'uTest - Welcome: Testers from France'],
          },
          data: {
            routeClasses: 'lp lp-welcome-testers-france',
          },
        })
        .state('welcometesterssweden', {
          url: '/welcome-testers-sweden',
          parent: 'root',
          template: welcomeTestersSwedenTemplate,
          resolve: {
            title: [() => 'uTest - Welcome: Testers from Sweden'],
          },
          data: {
            routeClasses: 'lp lp-welcome-testers-sweden',
          },
        })
        .state('testermonials', {
          url: '/tester-monials',
          parent: 'root',
          template: testermonialsTemplate,
          resolve: {
            title: [() => 'uTest Tester-monials'],
          },
          data: {
            routeClasses: 'lp lp-tester-monials',
          },
        })
        .state('germanstarterwebsite', {
          url: '/german-starter-website',
          parent: 'root',
          template: germanStarterWebsiteTemplate,
          resolve: {
            title: [() => 'uTest - VON ZU HAUSE AUS GELD VERDIENEN'],
          },
          data: {
            routeClasses: 'lp lp-german-starter-website',
          },
        })
        .state('wasistutest', {
          url: '/was-ist-utest',
          parent: 'root',
          template: wasIstUtestTemplate,
          resolve: {
            meta: [() => 'uTest - Was ist uTest?'],
          },
          data: {
            routeClasses: 'lp lp-was-ist-utest',
          },
        })
        .state('paidtestingjapan', {
          url: '/paid-testing-japan',
          parent: 'root',
          template: paidTestingJapanTemplate,
          resolve: {
            title: [() => 'uTest - Paid testing in Japan!'],
          },
          data: {
            routeClasses: 'lp lp-paid-testing-japan',
          },
        })
        .state('paidtestingfrance', {
          url: '/paid-testing-france',
          parent: 'root',
          template: paidTestingFranceTemplate,
          resolve: {
            title: [() => 'uTest - Paid testing in France!'],
          },
          data: {
            routeClasses: 'lp lp-paid-testing-france',
          },
        });
    }]);
  },
};

export default LandingPagesRouter;
