import template from './dashboard-activity-project-list-footer.component.html';

const dashboardActivityProjectListFooter = {
  bindings: {
    itemCount: '@',
    itemType: '@',
    testerAppUrl: '@',
  },
  template,
};

export default dashboardActivityProjectListFooter;
