/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import angular from 'angular';
import { EMPTY_CONTENT_MARKDOWNS, KEY_CODES } from '../../../constants';

class CommunityFeedItemCtrl {
  item: any;
  user: any;
  constructor($filter: ng.IFilterFunction, $sce: ng.ISCEService, AlertService, CommentService, LightTesterErrorService, $window: ng.IWindowService, $scope: ng.IScope, KeysetFeedStore, $element: JQuery, $timeout: ng.ITimeoutService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.KEY_CODES = KEY_CODES;
      switch (ctrl.item.type) {
        case 'article':
        case 'topic':
          ctrl.content = $filter('uMarkdown')(ctrl.item.content);
          break;
        default:
          ctrl.content = ctrl.item.content || ctrl.item.description;
      }

      ctrl.editing = false;
      ctrl.showComments = false;
      ctrl.fullScreenOpen = false;
      ctrl.disablePostComment = false;
      ctrl.$element = $element;
      ctrl.hideSeeMore = false;

      const broadcastOpening = () => {
        $scope.$broadcast('openCommentEditor', 'singleCommentSection');
      };

      ctrl.toggleComments = () => {
        if (LightTesterErrorService.show()) return;

        ctrl.showComments = !ctrl.showComments;
        $timeout(ctrl.setFocus, 0);
        broadcastOpening();
      };

      ctrl.setFocus = () => {
        if (ctrl.showComments) {
          if ($window.innerWidth >= 769) {
            const toolbarIcons = angular.element(ctrl.$element.find('button .fa'));
            toolbarIcons[0].parentNode.focus();
          } else {
            const button = angular.element(ctrl.$element.find('button.community-feed-item-comment-form__fullscreen-button.hidden-lg.hidden-md.hidden-sm'));
            button[0].focus();
          }
        }
      };

      ctrl.openFullScreen = () => {
        ctrl.fullScreenOpen = true;
        setTimeout(() => {
          const button = $('button.community-feed-item-comment-form-fullscreen__cancel');
          button[0].focus();
        }, 100);
      };

      ctrl.closeFullScreen = () => {
        ctrl.fullScreenOpen = false;
        setTimeout(() => {
          const button = $('button.community-feed-item-comment-form__fullscreen-button.hidden-lg.hidden-md.hidden-sm');
          button[0].focus();
        }, 100);
      };

      ctrl.newComment = {
        content: '',
      };

      ctrl.editor = {};

      ctrl.submitComment = () => {
        ctrl.disablePostComment = true;
        const trimmedComment = _.trim(ctrl.newComment.content);
        if (_.isEmpty(trimmedComment) || EMPTY_CONTENT_MARKDOWNS.includes(trimmedComment)) {
          AlertService.addDanger('Can\'t post empty comment!');
          ctrl.disablePostComment = false;
          return;
        }
        CommentService.postCommentAndReturnCommentable(ctrl.item.type, ctrl.item.id, ctrl.newComment).then((res) => {
          // reset likes count, comments count, comments feed, and recent comments
          if (res.data.comments) {
            ctrl.item.comments.count = res.data.comments.count;
          } else {
            ctrl.item.post_count = res.data.post_count;
          }
          ctrl.item.likes.count = res.data.likes.count;
          ctrl.item.comment_feed = createCommentFeed(res.data.id, res.data.type);
          ctrl.item.recent_comments = buildRecentComments(res.data.recent_comments);
          ctrl.fullScreenOpen = false;
          ctrl.disablePostComment = false;
          ctrl.newComment.content = '';
          broadcastOpening();
          $scope.$emit('feedItemCommented', ctrl.item);
        });
      };

      ctrl.item.recent_comments = buildRecentComments(ctrl.item.recent_comments);

      ctrl.item.comment_feed = createCommentFeed(ctrl.item.id, ctrl.item.type);

      ctrl.isOverflowed = function () {
        const elementCopy = this.$element.find('.community-feed-item-body__content')[0];

        if (elementCopy.scrollHeight > elementCopy.clientHeight && elementCopy.clientHeight > 25) {
          return true;
        }

        return false;
      };

      ctrl.onResize = function () {
        if ($window.innerWidth >= 769) {
          ctrl.closeFullScreen();
          $scope.$apply(); // lifecycle removes this *very slowly* if not called explicitly
        }
      };

      ctrl.editStatus = () => {
        ctrl.editing = true;
        $scope.$broadcast('closeMenu');
      };

      ctrl.deleteStatus = () => {
        ctrl.editing = false;
        $scope.$broadcast('statusDelete', ctrl.item);
        $scope.$broadcast('closeMenu');
      };

      ctrl.removeEventListeners = function () {
        angular.element($window).off('resize', ctrl.onResize);
      };

      ctrl.loadMoreComments = () => {
        ctrl.item.comment_feed.loadMore(ctrl.item.recent_comments[1].id).then((res) => {
          // remove last item from a forum post, as it's also the body of the feed item
          if (ctrl.item.type === 'topic' && res.isLastPage) {
            ctrl.item.comment_feed.feedItems.pop();
          }
        });
      };

      angular.element($window).on('resize', ctrl.onResize);
    };

    ctrl.expandStatusCard = function (e) {
      e.preventDefault();
      this.hideSeeMore = true;
      const statusCard = this.$element.find('.community-feed-item-body__content')[0];
      const statusCardScrollHeight = ((statusCard.scrollHeight).toString()).concat('px');
      const middleContentCard = this.$element.find('div.community-feed-item-middle-content')[0];

      statusCard.style.overflow = 'inherit';
      statusCard.style.display = 'inherit';
      middleContentCard.style.height = statusCardScrollHeight;
      middleContentCard.style.display = 'contents';
    };

    ctrl.menuItemKeydown = function (event, action) {
      const targetId = $(event.target).closest('ul').attr('id');
      const btnId = targetId.match(/\d+/g).toString();
      const btn = document.getElementById(`hiddenMenuBtn-${btnId}`);
      const menuItems = $(`#${targetId} > li`);
      const keyCodes = this.KEY_CODES;
      switch (event.keyCode) {
        case keyCodes.TAB: // tab
          $scope.$broadcast('closeMenu');
          break;
        case keyCodes.RETURN:
          $scope.$broadcast('closeMenu');
          return (action === 'edit') ? this.editStatus() : this.deleteStatus();
        case keyCodes.ESC:
          $scope.$broadcast('closeMenu');
          btn.focus();
          break;
        case keyCodes.SPACE:
          event.preventDefault();
          $scope.$broadcast('closeMenu');
          return (action === 'edit') ? this.editStatus() : this.deleteStatus();
        case keyCodes.UP:
          event.preventDefault();
          toggleFocus(menuItems);
          break;
        case keyCodes.DOWN:
          event.preventDefault();
          toggleFocus(menuItems);
          break;
        default:
      }
    };

    ctrl.$onDestroy = function () {
      this.removeEventListeners();
    };

    function toggleFocus(menuItems) {
      const item = (document.activeElement === menuItems[0]) ? menuItems[1] : menuItems[0];
      item.focus();
    }

    function buildRecentComments(recentComments) {
      return _.map(recentComments, (comment) => {
        comment.content_built = $sce.trustAsHtml($filter('uMarkdown')(comment.content));
        return comment;
      });
    }

    function createCommentFeed(id, type) {
      return new KeysetFeedStore(_.bind(CommentService.feed, CommentService), {
        id,
        type,
      });
    }
  }

  get authorGlyph() {
    switch (this.item.type) {
      case 'course':
        return 'school';
      case 'project':
        return 'assignment';
      case 'referral_campaign':
        return 'group_add';
      default:
        return null;
    }
  }

  get authorName() {
    switch (this.item.type) {
      case 'project':
        return this.projectHeading;
      case 'referral_campaign':
        return 'Referral Campaign';
      case 'course':
        return 'uTest Academy';
      default:
        return this.author.name;
    }
  }

  get projectHeading() {
    if (this.item.urgent) {
      return 'Urgent Project';
    }
    return 'Ongoing Project';
  }

  get author() {
    return _.get(this.item, 'user');
  }

  get authorRoute() {
    switch (this.item.type) {
      case 'article':
        return `profile.about({profileId: '${this.author.slug}'})`;
      case 'course':
        return `singlecourse({courseId: '${this.item.slug}'})`;
      case 'project':
        return `singleproject({projectId: '${this.item.slug}'})`;
      case 'topic':
        return `profile.about({profileId: '${this.author.slug}'})`;
      case 'status':
        return `profile.about({profileId: '${this.author.slug}'})`;
      case 'referral_campaign':
        return `referralCampaignsShow({referralCampaignId: '${this.item.slug}'})`;
      default:
        return null;
    }
  }

  get type() {
    switch (this.item.type) {
      case 'topic':
        return 'Discussion';
      case 'referral_campaign':
        return 'Referral Campaign';
      default:
        return _.capitalize(this.item.type);
    }
  }

  get date() {
    return this.item.published_at;
  }

  get title() {
    return this.item.title;
  }

  get itemRoute() {
    switch (this.item.type) {
      case 'article':
        return `singlearticle({articleId: '${this.item.slug}'})`;
      case 'course':
        return `singlecourse({courseId: '${this.item.slug}'})`;
      case 'project':
        return `singleproject({projectId: '${this.item.slug}'})`;
      case 'topic':
        return `singlediscussionpage({page: 1, discussionId: '${this.item.id}'})`;
      case 'status':
        return `singlestatus({statusId: '${this.item.id}'})`;
      case 'referral_campaign':
        return `referralCampaignsShow({referralCampaignId: '${this.item.slug}'})`;
      default:
        return null;
    }
  }

  get isLikeable() {
    return (this.type !== 'Project'
             && this.type !== 'Referral Campaign'
             && this.type !== 'Course'
             && this.user);
  }

  get numLikes() {
    return _.get(this.item, 'likes.count');
  }

  get isCommentable() {
    return (this.type !== 'Project'
             && this.type !== 'Referral Campaign'
             && this.type !== 'Course'
             && this.user);
  }

  get numComments() {
    const numComments = this.item.comments ? this.item.comments.count : this.item.post_count;
    return numComments > 0 ? numComments : 0;
  }

  get authorSubtext() {
    if (this.author && !['project', 'referral_campaign', 'course'].includes(this.item.type)) {
      if (this.user && this.user.id === this.author.id) {
        return 'You';
      }
      if (this.author.user_title) {
        return this.author.user_title;
      }
      if (this.author.country) {
        return this.author.country.name;
      }
    }
    return null;
  }
}

CommunityFeedItemCtrl.$inject = ['$filter', '$sce', 'AlertService', 'CommentService', 'LightTesterErrorService', '$window', '$scope', 'KeysetFeedStore', '$element', '$timeout', 'featureFlags'];

export default CommunityFeedItemCtrl;
