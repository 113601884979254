export default `
<div class="pass-instruction">
  <h4 ng-if="passStrengthText" aria-label="Password Strength is {{passStrengthText}}" data-strength="{{passwordStrength}}" aria-live="polite">
    {{passStrengthText}}
  </h4>
  <ul aria-label="Password Instructions">
    <li>Use 10 to 64 characters.</li>
    <li>Must include a lower-case letter, an upper-case letter, and a number.</li>
    <li>Must not include email address.</li>
    <li>For added security, we do not allow common passwords.</li>
    <li>Password is case sensitive.</li>
    <li>Avoid using the same password for multiple sites.</li>
  </ul>
</div>
`;
