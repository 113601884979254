const categoryColor = function ($filter) {
  return function (name) {
    const type = $filter('categoryType')(name);
    switch (type) {
      case 'WELCOME':
        return '#00a8cc';
      case 'EARN':
        return '#18a78b';
      case 'LEARN':
        return '#fdb900';
      case 'SOCIAL':
        return '#ff017e';
      default:
        return '#dddddd';
    }
  };
};

categoryColor.$inject = ['$filter'];

export default categoryColor;
