import _ from 'lodash';
import { StateService } from '@uirouter/core';
import setTabNavRoles from '../helpers/tab-nav-roles';

const TYPE_MAP = {
  articles: 'Article',
  tools: 'Tool',
  projects: 'Project',
  users: 'User',
  courses: 'Course',
  forums: 'Topic',
};

const SEARCH_BY_TAG = 'searchresultbytag';
const SEARCH_BY_HASHTAG = 'searchresultbyhashtag';

class SearchCtrl {
  constructor(SearchService, FeedStore, $location, $state: StateService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.open = ($event, value) => {
        $event.stopPropagation();
        ctrl[value] = !ctrl[value];
      };

      ctrl.sorting = $location.search().sort || 'newest';
      ctrl.sortingOptions = {
        oldest: 'Oldest first',
        newest: 'Newest first',
      };

      if (ctrl.user) {
        ctrl.types = ['all', 'articles', 'tools', 'projects', 'users', 'courses', 'forums'];
      } else {
        ctrl.types = ['all', 'articles', 'tools', 'projects', 'courses', 'forums'];
      }

      ctrl.contentType = ctrl.$transition$.params().contentType;

      let { query } = ctrl.$transition$.params();

      if ($state.current.name === SEARCH_BY_TAG) {
        _.remove(ctrl.types, (type: any) => ['forums', 'projects', 'users'].includes(type));
        ctrl.searchByTag = ctrl.$transition$.params().query;
      } else if ($state.current.name === SEARCH_BY_HASHTAG) {
        query = `#${query}`;
      }

      if (!ctrl.types.includes(ctrl.contentType)) {
        ctrl.setActiveTab('all');
        return;
      }

      ctrl.setActiveTab(ctrl.contentType);

      const setupSearchFeed = (query) => {
        let search;
        if (ctrl.contentType === 'all') {
          search = _.bind(SearchService.searchMixed, SearchService);
        } else {
          search = SearchService.searchOneFactory(
            TYPE_MAP[ctrl.contentType],
            SEARCH_BY_TAG === $state.current.name,
          );
        }

        ctrl.resultsFeed = new FeedStore(search, { query, sort: ctrl.sortKeyword() });
        ctrl.resultsFeed.loadOnce();
      };

      if (query) {
        setupSearchFeed(query);
      }

      ctrl.sortingChanged = (val) => {
        ctrl.sorting = val;
        $location.search('sort', val);

        setupSearchFeed(query);
      };

      setTabNavRoles(document.getElementsByClassName('nav-tabs'));
    };

    ctrl.selectTab = (type: string) => {
      const { contentType, query } = ctrl.$transition$.params();
      if (type !== contentType) {
        $state.go($state.current.name, { contentType: type, query });
      }
    };

    ctrl.sortable = () => !['user', 'users'].includes((ctrl.contentType || '').toLowerCase());

    ctrl.sortKeyword = () => {
      switch (ctrl.sorting) {
        case 'oldest':
          return 'published_at';
        case 'newest':
          return '-published_at';
        default:
      }
    };

    ctrl.setActiveTab = (tab: string) => {
      // @ts-ignore
      ctrl.tabStates = Object.fromEntries(ctrl.types.map(type => [type, false]));
      ctrl.tabStates[tab] = true;
    };
  }
}

SearchCtrl.$inject = ['SearchService', 'FeedStore', '$location', '$state'];

export default SearchCtrl;
