import template from './community-feed-item-like-list-overlay.component.html';

const communityFeedItemLikeListOverlay = {
  bindings: {
    likeable: '=',
  },
  template,
};

export default communityFeedItemLikeListOverlay;
