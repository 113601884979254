/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { CompareWithFn } from '../../+utils/compare-with-fn';
import { OptNode } from './opt-node';

export abstract class OptionsRoot {
  readonly children: OptNode[];
  readonly compareWith: CompareWithFn<any> | null;
  readonly multiselect: boolean;
}
