import template from './community-trending-list-item.component.html';
import controller from './community-trending-list-item.controller';

const communityTrendingListItem = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default communityTrendingListItem;
