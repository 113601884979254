const validationMatch = function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      validationMatch: '=',
    },
    link(scope, elem, attrs, ctrl) {
      ctrl.$validators.validationMatch = function (modelValue) {
        return scope.validationMatch === modelValue;
      };

      scope.$watch('validationMatch', () => {
        ctrl.$validate();
      });
    },
  };
};

export default validationMatch;
