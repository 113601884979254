export default `
<div class="tool-category">
  <div class="cat-header">
    <h2><a ui-sref="toolcategory({categoryId : $ctrl.toolCategory.id})">{{$ctrl.toolCategory.name | limitTo: 50}} ({{$ctrl.toolCategory.tools.count}})</a></h2>
  </div>
  <div class="cat-content">
    <div class="cat-content-main">
      <p>{{$ctrl.toolCategory.description | cleanUp}}</p>
    </div>
  </div>
</div>
`;
