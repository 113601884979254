export default `
<div class="user-card" >
  <div class="user-card-cover"  bg-img="{{profile_banner.url}}" ng-bind-html="user_points" >
  </div>
  <a class="user-card-avatar" ng-show="user.slug" ui-sref="profile.about({profileId: user.slug})">
    <div bg-img="{{user.avatar_url}}" class="profile-thumb xlarge"></div>
  </a>
  <div class="user-card-content">
    <div ng-show="!user.slug" bg-img="{{user.avatar_url}}" class="profile-thumb xlarge"></div>
    <h4>
      <a ng-show="user.slug" ui-sref="profile.about({profileId: user.slug})" ng-bind="user.name" ></a>
      <span ng-show="!user.slug" ng-bind="user.name" ></span>
    </h4>
    <p><strong ng-show="user.slug" ng-bind="'@'+ user.slug" ></strong><small ng-bind="user.country.name"></small></p>
    <follow-btn user="user" ng-if="user.current_user_permissions['follow']"></follow-btn>
  </div>
</div>
`;
