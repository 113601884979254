import _ from 'lodash';
import { StateService } from '@uirouter/core';

class ArticleService {
  $http: ng.IHttpService;
  $state: StateService;
  UrlService: any;
  constructor($state, UrlService, $http) {
    this.$state = $state;
    this.UrlService = UrlService;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/articles/${id}`).then(this.UrlService.addResponseUrl);
  }

  feed(parameters) {
    const defaultParameters = {
      page: 1,
      per_page: 10,
      filter: { state: 'published', published_before: new Date().toISOString() },
    };

    parameters = _.defaultsDeep(parameters || {}, defaultParameters);

    if (parameters.filter.state === 'review' || parameters.filter.state === 'rejected') {
      delete parameters.filter.published_before;
    }

    if (parameters.filter.user_id) {
      delete parameters.filter.published_before;
      delete parameters.filter.state;
    }

    return this.$http.get('/api/v1/articles', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  like(id) {
    return this.$http.post(`/api/v1/articles/${id}/like`, '');
  }

  unlike(id) {
    return this.$http.delete(`/api/v1/articles/${id}/like`);
  }

  flag(id, reason) {
    return this.$http.post(`/api/v1/articles/${id}/flag`, { reason });
  }

  create(article) {
    return this.$http.post('/api/v1/articles', article);
  }

  update(article) {
    return this.$http.put(`/api/v1/articles/${article.id}`, article);
  }

  publish(articleId) {
    return this.$http.post(`/api/v1/articles/${articleId}/publish`, '');
  }

  review(articleId) {
    return this.$http.post(`/api/v1/articles/${articleId}/review`, '');
  }

  reject(articleId, reason) {
    return this.$http.post(`/api/v1/articles/${articleId}/reject`, { reject_reason: reason });
  }

  draft(articleId) {
    return this.$http.post(`/api/v1/articles/${articleId}/draft`, '');
  }

  hide(articleId) {
    return this.$http.post(`/api/v1/articles/${articleId}/hide`, '');
  }

  archive(articleId) {
    return this.$http.post(`/api/v1/articles/${articleId}/archive`, '');
  }

  toggleHide(article) {
    if (article.state === 'hidden') {
      return this.publish(article.id);
    }
    return this.hide(article.id);
  }

  comments(articleId) {
    return this.$http.get(`/api/v1/articles/${articleId}/comments`);
  }

  commentsPage(articleId, page, sort) {
    const params = {
      page: !page ? 1 : page,
      sort: sort || 'threaded',
    };

    return this.$http.get(`/api/v1/articles/${articleId}/comments/flat`, { params });
  }

  postComment(id, comment) {
    return this.$http.post(`/api/v1/articles/${id}/comments`, comment);
  }

  postCommentAndReturnCommentable(id, comment) {
    return this.$http.post(`/api/v1/articles/${id}/comments`, Object.assign(comment, { return_commentable: true }));
  }

  remove(id) {
    return this.$http.delete(`/api/v1/articles/${id}`);
  }

  addTodo(id) {
    return this.$http.post(`/api/v1/articles/${id}/todo`, '');
  }

  removeTodo(id) {
    return this.$http.delete(`/api/v1/articles/${id}/todo`);
  }

  likedUsers(id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/articles/${id}/liked_users`, { params: parameters });
  }

  subscribe(id) {
    return this.$http.post(`/api/v1/articles/${id}/subscribe`, '');
  }

  unsubscribe(id) {
    return this.$http.delete(`/api/v1/articles/${id}/subscribe`);
  }
}

ArticleService.$inject = ['$state', 'UrlService', '$http'];

export default ArticleService;
