import template from './course-track-quiz-question-choice.component.html';

const courseTrackQuizQuestionChoice = {
  bindings: {
    questionId: '<',
    choice: '<',
    choiceIndex: '<',
    userChoice: '<',
    questionNumber: '<',
  },
  template,
};

export default courseTrackQuizQuestionChoice;
