import _ from 'lodash';

const capitalizeFilter = () => (input) => {
  if (_.isString(input)) {
    const words = input.split(' ');
    const capitalizedWords = words.map(word => word[0].toUpperCase() + word.slice(1));
    return capitalizedWords.join(' ');
  }
  return input;
};

export default capitalizeFilter;
