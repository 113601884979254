import {
  Component, Inject, Input, OnInit,
} from '@angular/core';

@Component({
  selector: 'ut-community-feed-item-like-btn',
  templateUrl: './community-feed-item-like-btn.component.html',
})

export class CommunityFeedItemLikeBtnComponent implements OnInit {
  @Input() likeable: any;
  @Input() showLiked: boolean;
  currentUser: any;

  constructor(
    @Inject('$analytics') private $analytics: any,
    @Inject('$scope') private $scope: ng.IScope,
    @Inject('LikeService') private LikeService: any,
    @Inject('UserService') private UserService: any,
    @Inject('LightTesterErrorService') private LightTesterErrorService: any,
  ) {
  }

  ngOnInit(): void {
    this.UserService.me().then((res) => {
      if (res !== null) {
        this.currentUser = res.data;
      } else {
        this.currentUser = null;
      }
    });
  }

  trackLiking(options) {
    this.$analytics.eventTrack(options.action, { category: 'Likes', label: options.type });
  }

  toggleLike = () => {
    if (this.LightTesterErrorService.show()) return;

    if (this.likeable.current_user_permissions.like) {
      if (!this.likeable.likes.liked_by_current_user) {
        this.LikeService.like(this.likeable.type, this.likeable.id).then(this.updateLikeStats);
        this.trackLiking({ action: 'like', type: this.likeable.type });
      } else {
        this.LikeService.unlike(this.likeable.type, this.likeable.id).then(this.updateLikeStats);
        this.trackLiking({ action: 'unlike', type: this.likeable.type });
      }
    }
  };

  updateLikeStats = (likeResponse) => {
    if (likeResponse) {
      this.likeable.likes.count = likeResponse.data.likes.count;
      this.likeable.likes.liked_by_current_user = likeResponse.data.likes.liked_by_current_user;
      this.$scope.$emit('feedItemLiked', this.likeable);
    }
    return likeResponse;
  };
}
