import template from './ntux-select.component.html';
import controller from './ntux-select.controller';

const ntuxSelect = {
  bindings: {
    selectOptions: '=',
    selectCallback: '&',
  },
  template,
  controller,
};

export default ntuxSelect;
