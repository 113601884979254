export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w890">
      <div class="section-home-cover-random">
        <h1>Tester-monials</h1>
        <p>Our proudest moments are when we hear about how much of an impact uTest has on a uTester’s life. See some of the great stories that come our way. </p>
      </div>
    </div>
  </div>
  <div class="grid w890">
    <div class="c12">
      <div id="testermonialList" class="cf">
        <div class="wistia_embed wistia_async_31sltd7ntk popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialAaronWeintrob.jpg)">
                <h3 class="title">Aaron Weintrob</h3>
                <p class="location">United States</p>
              </div>
            </div>
          </a>
        </div>
        <div class="wistia_embed wistia_async_xnw270opdc popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialAlexandruDobinda.jpg)">
                <h3 class="title">Alexandru Dobinda</h3>
                <p class="location">Moldova</p>
              </div>
            </div>
           </a>
        </div>
        <div class="wistia_embed wistia_async_9j9vf0mll1 popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialEduardoZambrano.jpg)">
                <h3 class="title">Eduardo Zambrano</h3>
                <p class="location">Venezuela</p>
              </div>
            </div>
          </a>
        </div>
        <div class="wistia_embed wistia_async_krgax4jnow popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialJeffSchmitz.jpg)">
                <h3 class="title">Jeff Schmitz</h3>
                <p class="location">United States</p>
              </div>
            </div>
           </a>
        </div>
        <div class="wistia_embed wistia_async_0ra6xedjii popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialJosephPena.jpg)">
                <h3 class="title">Joseph Pena</h3>
                <p class="location">Japan</p>
              </div>
            </div>
          </a>
        </div>
        <div class="wistia_embed wistia_async_qilx7yxjhh popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialRadhaSubrmanian.jpg)">
                <h3 class="title">Radha Subrmanian</h3>
                <p class="location">India</p>
              </div>
            </div>
          </a>
        </div>
        <div class="wistia_embed wistia_async_bcynbqv6vw popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialSilvanoParodi.jpg)">
                <h3 class="title">Silvano Parodi</h3>
                <p class="location">Italy</p>
              </div>
            </div>
          </a>
        </div>
        <div class="wistia_embed wistia_async_jfekac46gb popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialStephanieRusson.jpg)">
                <h3 class="title">Stephanie Russon</h3>
                <p class="location">United States</p>
              </div>
            </div>
           </a>
        </div>
        <div class="wistia_embed wistia_async_j5hh3vdc1m popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialToddMiller.jpg)">
                <h3 class="title">Todd Miller</h3>
                <p class="location">United States</p>
              </div>
            </div>
           </a>
        </div>
        <div class="wistia_embed wistia_async_r7te8t52dz popover=true popoverContent=link">
          <a href="#">
            <div class="testermonialItem c6">
              <div class="testermonialBox" style="background-image: url(assets/images/testerSpot/testermonialYaminiRamesh.jpg)">
                <h3 class="title">Yamini Ramesh</h3>
                <p class="location">India</p>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
`;
