class SingleArticleCtrl {
  constructor(ArticleService, $sce: ng.ISCEService, $filter, MetaService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.service = ArticleService;
      ctrl.updateMetaService();
      ctrl.article.content = $sce.trustAsHtml($filter('uMarkdown')(ctrl.article.content));
      ctrl.setState(ctrl.article.state);
    };

    ctrl.updateMetaService = () => {
      if (ctrl.article.canonical) {
        MetaService.set('canonical', ctrl.article.canonical);
      }
    };

    ctrl.setState = (state) => {
      switch (state) {
        case 'draft':
          ctrl.instructions = [{
            type: 'warning',
            message: 'This article is not published yet',
          }];
          break;
        case 'hidden':
          ctrl.instructions = [{
            type: 'danger',
            message: 'This article is hidden',
          }];
          break;
        case 'archived':
          ctrl.instructions = [{
            type: 'warning',
            message: 'This article has been archived. Its contents may be outdated. Please check the articles page for up-to-date content.',
          }];
          break;
        case 'rejected':
          ctrl.instructions = [{
            type: 'warning',
            message: `This article has been rejected for the following reason: ${ctrl.article.reject_reason}. Please update the article and re-submit.`,
          }];
          break;
        case 'review':
          ctrl.instructions = [{
            type: 'warning',
            message: 'Article is currently pending review.',
          }];
          break;
        default:
          ctrl.instructions = [];
          break;
      }
    };
  }
}

SingleArticleCtrl.$inject = ['ArticleService', '$sce', '$filter', 'MetaService'];

export default SingleArticleCtrl;
