class ImageDataService {
  $q: ng.IQService;
  constructor($q) {
    this.$q = $q;
  }

  getData(src, outputFormat) {
    const deferred = this.$q.defer();
    const img: any = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
      const canvas: any = document.createElement('CANVAS');
      const ctx = canvas.getContext('2d');
      canvas.height = this.height;
      canvas.width = this.width;
      ctx.drawImage(this, 0, 0);
      const dataURL = canvas.toDataURL(outputFormat);
      deferred.resolve(dataURL);
    };
    img.src = src;
    if (img.complete || img.complete === undefined) {
      img.src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==';
      img.src = src;
    }
    return deferred.promise;
  }
}

ImageDataService.$inject = ['$q'];

export default ImageDataService;
