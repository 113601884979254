import _ from 'lodash';
import { StateService, TransitionService } from '@uirouter/core';

class NavSidebarDropdown {
  constructor($state: StateService, $transitions: TransitionService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.isSelected = false;
      ctrl.state = $state;

      ctrl.checkSelected();

      $transitions.onSuccess({}, ctrl.checkSelected);
    };

    ctrl.toggleDropdown = function () {
      ctrl.isExpanded = !ctrl.isExpanded;
    };

    ctrl.checkSelected = (transition) => {
      const name = transition ? transition.to().name : ctrl.state.current.name;
      ctrl.isSelected = _.intersection(name.split('.'), ctrl.highlight?.split(',')).length > 0;
      ctrl.isExpanded = ctrl.isSelected;
    };
  }
}

NavSidebarDropdown.$inject = ['$state', '$transitions'];

export default NavSidebarDropdown;
