export default `
<div class="write-status-attach">
  <span class="label label-default" ng-show="status.attachment.value" >
    <i class="{{status.attachment.icon}}"></i> {{status.attachment.label}}
    <i class="icon-cancel" ng-click="setAttachment({})" role="button" aria-label="Cancel attachment {{status.attachment.label}}" tabindex="0"></i>
  </span>
  <button class="icon-picture status-update__image-button" ng-click="popover($event, 'image')" ng-class="{active: attachmentPopoverShow == 'image'}" aria-label="Insert an Image" aria-expanded="false">
    <i></i>
  </button>
  <button class="icon-youtube-play status-update__video-button" ng-click="popover($event, 'video')" ng-class="{active: attachmentPopoverShow == 'video'}" aria-label="Insert a Video" aria-expanded="false">
    <i></i>
  </button>
  <div class="popover fade in" ng-class="popoverPlacement" role="dialog">
    <div class="arrow"></div>
    <div class="popover-inner" ng-show="attachmentPopoverShow == 'image'">
      <h3 class="popover-title">
        <button class="icon-cancel clickable" ng-class="{active: attachmentPopoverShow == 'image'}" ng-click="closePopover()" aria-label="Close"><i></i></button>
        Insert an Image
      </h3>
      <div class="popover-content" >
        <button dropzone="dropzone" class="dropzone dropzone-btn" ng-show="attachmentPopoverShow === 'image'"></button>
        <p>OR</p>
        <input type="text" class="ut-form-control" ng-model="imageFromUrl.url" placeholder="Enter URL here" aria-label="Enter URL here">
        <button type="button" ng-click="imageFromUrl.action()" class="btn btn-sm btn-yellow">Insert</button>
      </div>
    </div>
    <div class="popover-inner"  ng-show="attachmentPopoverShow == 'video'">
      <h3 class="popover-title">
        <button class="icon-cancel clickable" ng-class="{active: attachmentPopoverShow == 'video'}" ng-click="closePopover()" aria-label="Close"><i></i></button>
        Insert a Video
      </h3>
      <div class="popover-content">
        <p>
          <input type="text" class="ut-form-control" ng-model="embed.url" placeholder="Enter URL here" >
        </p>
        <button type="button" ng-click="embed.action()" class="btn btn-sm btn-yellow">Insert</button>
      </div>
    </div>
  </div>
</div>
`;
