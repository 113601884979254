export default `
<div class="col-md-4 col-sm-6">
  <div class="dashboard-card">
    <h2 class="dashboard-card__heading">
      My Devices
    </h2>
    <div class="dashboard-card-list-fade dashboard-card-list-fade--top" ng-if="$ctrl.devices.length > 0"></div>
    <div class="dashboard-card-list dashboard-card-list--my-devices" ng-if="$ctrl.devices.length > 0" tabindex="0">
      <dashboard-card-my-devices-list-item device="device" ng-repeat="device in $ctrl.devices"></dashboard-card-my-devices-list-item>
    </div>
    <div class="dashboard-card-list-fade dashboard-card-list-fade--bottom" ng-if="$ctrl.devices.length > 0"></div>
    <a class="dashboard-card-footer" href="{{$ctrl.addDevicesUrl}}">
      + Add devices
    </a>
  </div>
</div>
`;
