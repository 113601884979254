import _ from 'lodash';
import angular from 'angular';
import template from './user-tooltip.html';

const userTooltip = function (UserService) {
  return {
    restrict: 'E',
    replace: true,
    template,
    link(scope) {
      if (!scope.user.points) {
        scope.user.points = !scope.user.badges ? 0 : _.reduce(scope.user.badges, (sum, o) => sum + o.points, 0);
      }
      scope.userRole = UserService.userRoleTitle(scope.user);
      scope.userLocation = _.get(scope.user, 'country.name', null);
      const state = _.get(scope.user, 'state', null);
      if (scope.userLocation && state) {
        let countryAbbr;
        switch (scope.userLocation) {
          case 'United States':
            countryAbbr = 'US';
            break;
          case 'Canada':
            countryAbbr = 'CA';
            break;
          default:
        }
        scope.userLocation = state.name;
        if (countryAbbr) {
          scope.userLocation += `, ${countryAbbr}`;
        }
      }

      const closeBtn = angular.element(document).find('#closeUserTooltipBtn')[0];
      if (closeBtn !== undefined) {
        closeBtn.onfocus = () => angular.element(closeBtn).css('border', 'solid lightblue 1px');
        closeBtn.onmouseenter = closeBtn.onfocus;
        closeBtn.onblur = () => angular.element(closeBtn).css('border', 'none');
        closeBtn.onmouseleave = closeBtn.onblur;
      }
    },
  };
};

userTooltip.$inject = ['UserService'];

export default userTooltip;
