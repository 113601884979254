export default `
<div class="community-feed-status-update">
  <div class="community-feed-status-update__form">
    <input suggestion-typeahead typeahead-template-url="app/components/status/suggestion-typeahead.html"
    typeahead-on-select="replaceSuggestion($item)" typeahead-loading="typeaheadLoading" typeahead="user for user in loadSuggestions($viewValue)" class="community-status-update__input ng-pristine ng-untouched ng-valid ng-empty ng-valid-maxlength"
    type="text" name="status" maxlength="{{$ctrl.STATUS_POST_MAX_CHARS}}" placeholder="Write your status update here"
    ng-model="$ctrl.status.content" ng-trim="false" autocomplete="false" aria-label="Write your status update here">
    </input>
    <span class="community-status-update-attachment" ng-if="$ctrl.status.attachment.label">
      <i class="material-icons">{{$ctrl.status.attachment.icon}}</i>
      <span class="community-status-update-attachment__label">{{$ctrl.status.attachment.label}}</span>
      <button class="community-status-update-attachment__remove material-icons" ng-click="$ctrl.setAttachment(null)" aria-label="Cancel attachment {{$ctrl.status.attachment.label}}">close</button>
    </span>
    <div class="community-status-update__attachment-chooser">
      <button class="community-feed-status-update__image-button material-icons {{$ctrl.item.id}}" ng-click="$ctrl.togglePopover($event, 'image')" aria-label="Insert an Image" aria-expanded="false">insert_photo</button>
      <button class="community-feed-status-update__video-button material-icons {{$ctrl.item.id}}" ng-click="$ctrl.togglePopover($event, 'video')" aria-label="Insert a Video" aria-expanded="false">play_circle_filled</button>
      <div class="community-status-update-popover attachment_popover_{{$ctrl.item.id}}" ng-show="$ctrl.activePopover" role="dialog">
        <div class="community-status-update-popover__body">
          <div class="community-status-update-popover__title">
            <span ng-if="$ctrl.activePopover === 'image'">Insert an Image</span>
            <span ng-if="$ctrl.activePopover === 'video'">Insert a Video</span>
            <button class="community-status-update-popover__close material-icons" ng-class="{'active' : $ctrl.isPopoverOpen}" ng-click="$ctrl.closePopover()">close</button>
          </div>
          <div class="community-status-update-popover__content">
            <button dropzone="$ctrl.dropzone" class="dropzone dropzone-btn" ng-show="$ctrl.activePopover === 'image'"></button>
            <p ng-if="$ctrl.activePopover === 'image'">OR</p>
            <p><input type="text" class="ut-form-control" ng-model="$ctrl.attachmentUrl" placeholder="Enter URL here" aria-label="Enter URL here"></p>
            <button type="button" ng-click="$ctrl.submitAttachment()" class="community-cta__button">Insert</button>
          </div>
        </div>
        <div class="community-status-update-popover__arrow"></div>
      </div>
    </div>
  </div>
  <div class="community-feed-status-update__buttons">
    <button class="community-cta__button unfilled" ng-click="$ctrl.reset(false)">Cancel</button>
    <button class="community-cta__button" ng-click="$ctrl.updateStatus()" ng-disabled="$ctrl.posting">Save</button>
  </div>
</div>
`;
