import template from './dashboard-activity-projects-tab.component.html';
import controller from './dashboard-activity-projects-tab.controller';

const dashboardActivityProjectsTab = {
  bindings: {
    projects: '<',
    projectsCount: '<',
  },
  template,
  controller,
};

export default dashboardActivityProjectsTab;
