class LikeService {
  ServiceByType: any;
  constructor(ServiceByType) {
    this.ServiceByType = ServiceByType;
  }

  like(type, id) {
    return this.ServiceByType.get(type).like(id);
  }

  unlike(type, id) {
    return this.ServiceByType.get(type).unlike(id);
  }

  likedUsers(item, parameters) {
    return this.ServiceByType.get(item.type).likedUsers(item.id, parameters);
  }
}

LikeService.$inject = ['ServiceByType'];

export default LikeService;
