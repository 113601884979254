const relativeDate = function () {
  return function (date) {
    const relativeDateTranslations = {
      just_now: 'just now',
      seconds_ago: '{{time}} seconds ago',
      a_minute_ago: 'a minute ago',
      minutes_ago: '{{time}} minutes ago',
      an_hour_ago: 'an hour ago',
      hours_ago: '{{time}} hours ago',
      a_day_ago: 'yesterday',
      days_ago: '{{time}} days ago',
      a_month_ago: 'a month ago',
      months_ago: '{{time}} months ago',
      a_year_ago: 'a year ago',
      years_ago: '{{time}} years ago',
      seconds_from_now: '{{time}} seconds from now',
      a_minute_from_now: 'a minute from now',
      minutes_from_now: '{{time}} minutes from now',
      an_hour_from_now: 'an hour from now',
      hours_from_now: '{{time}} hours from now',
      a_day_from_now: 'tomorrow',
      days_from_now: '{{time}} days from now',
      a_month_from_now: 'a month from now',
      months_from_now: '{{time}} months from now',
      a_year_from_now: 'a year from now',
      years_from_now: '{{time}} years from now',
    };

    let delta = null;
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const year = month * 12;
    const now = new Date();

    if (!(date instanceof Date)) {
      date = new Date(date);
    }

    function calculateDelta(now, date) {
      return Math.round(Math.abs(now - date) / 1000);
    }

    function relative(phrase, timeValue?) {
      let key;
      if (now >= date) {
        key = `${phrase}_ago`;
      } else {
        key = `${phrase}_from_now`;
      }
      return relativeDateTranslations[key].replace('{{time}}', timeValue);
    }

    delta = calculateDelta(now, date);

    if (delta < 30 || Number.isNaN(delta)) return 'just now';
    if (delta < minute) return relative('seconds', delta);
    if (delta < 2 * minute) return relative('a_minute');
    if (delta < hour) return relative('minutes', Math.floor(delta / minute));
    if (Math.floor(delta / hour) === 1) return relative('an_hour');
    if (delta < day) return relative('hours', Math.floor(delta / hour));
    if (delta < (day * 2)) return relative('a_day');
    if (delta < month) return relative('days', Math.floor(delta / day));
    if (delta < (month * 2)) return relative('a_month');
    if (delta < (month * 12)) return relative('months', Math.floor(delta / month));
    if (delta < (month * 19)) return relative('a_year');

    return relative('years', Math.round(delta / year));
  };
};

relativeDate.$inject = ['$filter'];

export default relativeDate;
