import template from './nav-header-search-field.component.html';
import controller from './nav-header-search-field.controller';

const navHeaderSearchField = {
  bindings: {
    query: '<',
    onSubmit: '&',
  },
  template,
  controller,
};

export default navHeaderSearchField;
