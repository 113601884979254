import _ from 'lodash';
import template from './user-card.html';

const userCard = function (UserService) {
  return {
    restrict: 'E',
    replace: true,
    template,
    scope: {
      user: '=',
    },
    link(scope) {
      function additionalFields() {
        scope.profile_banner = UserService.getProfileBanner(scope.user);
        scope.user.points = !scope.user.badges ? 0 : _.reduce(scope.user.badges, (sum, o) => sum + o.points, 0);
        scope.user_points = scope.user.points ? `<span>${scope.user.points}</span> uPoints` : '';
        if (!scope.user.avatar_url) {
          scope.user.avatar_url = 'assets/images/default-avatar.png';
        }
      }

      if (scope.user) {
        additionalFields();
      }

      scope.$watch('user', () => {
        if (_.isString(scope.user)) {
          const uSplit = scope.user.split(',');
          scope.user = {
            name: uSplit[0],
            country: {
              name: uSplit[1],
            },
          };
          additionalFields();
        }
      });
    },
  };
};

userCard.$inject = ['UserService'];

export default userCard;
