import { StateService } from '@uirouter/core';

class CourseTrackQuizCtrl {
  constructor(CourseTrackService, $state: StateService, localStorageService, $scope: ng.IScope) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.userChoice = [];
      ctrl.step = 0;
      ctrl.quiz = ctrl.courseTrack.quiz;
      ctrl.setQuizQuestionFocus();
      $scope.$on('showNextQuestion', (_event, nextStep) => {
        ctrl.step = nextStep;
        ctrl.setQuizQuestionFocus();
      });
    };

    ctrl.submitQuiz = () => {
      CourseTrackService.submitQuiz(ctrl.courseTrack.id, ctrl.userChoice).then((response) => {
        const userChoicesWithAnswers = {
          courseTrackId: ctrl.courseTrack.id,
          userChoice: response.data,
        };
        const quizStorageKey = `courseTrackQuiz${ctrl.courseTrack.id}`;
        localStorageService.set(quizStorageKey, userChoicesWithAnswers);
        $state.go('report-card', { trackId: ctrl.courseTrack.id });
      });
    };

    ctrl.setQuizQuestionFocus = () => {
      setTimeout(() => {
        (<HTMLElement>document.getElementsByClassName('course-track-quiz-question-body__text')[ctrl.step]).focus();
      }, 100);
    };
  }
}

CourseTrackQuizCtrl.$inject = ['CourseTrackService', '$state', 'localStorageService', '$scope'];

export default CourseTrackQuizCtrl;
