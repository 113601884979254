import { REQUESTED_DEVICES_FEATURE_NAME } from '../../../constants';

class DashboardCardRequestedDevicesCtrl {
  constructor(UserDeviceService, FeedbackService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ctrl.devices = [];
      ctrl.currentUserHasSentFeedback = true;
      ctrl.hideCard = false;

      UserDeviceService.getRequestedDevices().then((res) => {
        ctrl.devices = res.data;
      });

      FeedbackService.getMyFeedback(REQUESTED_DEVICES_FEATURE_NAME).then((res) => {
        ctrl.currentUserHasSentFeedback = true;
        if (!res.data.is_positive) {
          ctrl.hideCard = true;
        }
      }).catch(() => {
        ctrl.currentUserHasSentFeedback = false;
      });
    };

    ctrl.helpful = () => {
      ctrl.currentUserHasSentFeedback = true;
      FeedbackService.postPositiveFeedback(REQUESTED_DEVICES_FEATURE_NAME).catch(() => {
        ctrl.currentUserHasSentFeedback = false;
      });
    };

    ctrl.notHelpful = () => {
      ctrl.hideCard = true;
      ctrl.currentUserHasSentFeedback = true;
      FeedbackService.postNegativeFeedback(REQUESTED_DEVICES_FEATURE_NAME).catch(() => {
        ctrl.currentUserHasSentFeedback = false;
      });
    };
  }
}

DashboardCardRequestedDevicesCtrl.$inject = ['UserDeviceService', 'FeedbackService'];

export default DashboardCardRequestedDevicesCtrl;
