import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable()
export class OptionOpenService {

  #focus = new Subject<void>();
  focus$ = this.#focus.pipe();

  #opened = new BehaviorSubject<boolean>(false);
  opened$ = this.#opened.pipe();

  focusSelect(): void {
    this.#focus.next();
  }

  isOpened(): boolean {
    return this.#opened.value;
  }

  setOpened(value: boolean) {
    this.#opened.next(value);
  }
}
