import ReferralCampaignService from '../components/referral-campaign/referral-campaign.service';
import UserAuthenticator from '../authentication/user-authenticator';

const ReferralCampaignsRouter = {
  route: (module) => {
    module
      .service('ReferralCampaignService', ReferralCampaignService);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('referralCampaigns', {
          url: '/referrals',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          template: `
            <main id="mainContent">
              <div class="campaign">
                <div class="container">
                  <div ui-view="referral-campaigns-index" ng-show="$state.includes(\'referralCampaignsIndex\')"></div><div ui-view="referral-campaigns-show" ng-show="$state.includes(\'referralCampaignsShow\')" ></div>
                </div>
              </div>
            </div>
          `,
          parent: 'root',
        })
        .state('referralCampaignsIndex', {
          url: '',
          parent: 'referralCampaigns',
          sticky: true,
          resolve: {
            feed: ['ReferralCampaignService', function (ReferralCampaignService) {
              return ReferralCampaignService.feed().then(res => res.data);
            }],
            title: [() => 'uTest Referral Campaigns'],
          },
          views: {
            'referral-campaigns-index': {
              component: 'referralCampaignFeed',
            },
          },
        })
        .state('referralCampaignsShow', {
          url: '/:referralCampaignId',
          parent: 'referralCampaigns',
          sticky: true,
          resolve: {
            campaign: ['$transition$', 'ReferralCampaignService', function ($transition$, ReferralCampaignService) {
              return ReferralCampaignService.find($transition$.params().referralCampaignId).then(res => res.data);
            }],
            currentUser: UserAuthenticator,
            utestUrl: ['ConfigService', function (ConfigService) {
              return ConfigService.config().then(res => res.data.utest_url);
            }],
            title: ['campaign', campaign => `uTest Referral Campaigns - ${campaign.title}`],
          },
          views: {
            'referral-campaigns-show': {
              component: 'referralCampaignDetail',
            },
          },
        });
    }]);
  },
};

export default ReferralCampaignsRouter;
