class FlagService {
  ServiceByType: any;
  constructor(ServiceByType) {
    this.ServiceByType = ServiceByType;
  }

  flag(type, id, reason) {
    return this.ServiceByType.get(type).flag(id, reason);
  }
}

FlagService.$inject = ['ServiceByType'];

export default FlagService;
