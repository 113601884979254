export default `
<community-nav></community-nav>
<main id="mainContent">
  <h1 class="sr-only">Community feed page</h1>
  <div class="community">
    <div class="container-fluid">
      <h2 class="community-header">Welcome back, {{$ctrl.user.name}}</h2>
      <div class="row">
        <div class="col-md-8 col-sm-12">
          <community-featured feed="$ctrl.featured"></community-featured>
          <community-status-update on-submit="$ctrl.statusPosted(status)" ng-if="!$ctrl.user.is_light_tester"></community-status-update>

          <div class="community-light-tester-upgrade" ng-if="$ctrl.user.is_light_tester">
            <span class="fa fa-info-circle"></span>
            <div class="community-light-tester-upgrade__title">
              You are unable to post or interact with the community until you complete the required fields in your tester profile and add at least one testing device.
            </div>
            <div class="community-light-tester-upgrade__cta">
              <a class="community-light-tester-upgrade__button " href="{{$ctrl.testerAppUrl}}">Update Profile</a>
            </div>
          </div>
          <community-feed user="$ctrl.user"></community-feed>
        </div>

        <div class="col-md-4 hidden-sm hidden-xs">
          <community-trending feed="$ctrl.trending"></community-trending>
          <div class="community-cta">
            <div class="community-cta__title">
              Share your knowledge
            </div>
            <div class="community-cta__icon">
              <img src="/assets/images/article.svg" alt="Icon of article pages" />
            </div>
            <div class="community-cta__footer">
              <a class="community-cta__button" ui-sref="newarticle" ng-click="$ctrl.checkLightTester($event)">Publish an Article</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
`;
