import _ from 'lodash';
import {
  GOOGLE_API_KEY, STATIC_VALS_COUNTRIES, STATIC_VALS_LANGUAGES, STATIC_VALS_OS, STATIC_VALS_OS_VERSIONS, STATIC_VALS_HANDSET_MAKER, STATIC_VALS_HANDSET_MODEL, STATIC_VALS_HANDSET_OS_VERSION, STATIC_VALS_STATES,
} from '../../constants';

class StaticValuesService {
  $http: ng.IHttpService;
  $q: ng.IQService;
  constructor($q: ng.IQService, $http: ng.IHttpService) {
    this.$q = $q;
    this.$http = $http;
  }

  geolocate() {
    return this.$http.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`, '');
  }

  getValues(key) {
    return this.$http.get('/api/v1/static_values', { params: { key } });
  }

  getChildValues(key, parentId) {
    return this.$http.get('/api/v1/static_child_values', { params: { key, parent_id: parentId } });
  }

  captcha() {
    return this.$http.get('/api/v1/captcha');
  }

  genders() {
    return this.$http.get('/api/v1/static_values/genders');
  }

  countries() {
    const restrictedCountryIds = [
      157, // Belarus
      191, // Cuba
      236, // Iran
      247, // North Korea
      286, // Netherlands Antilles
      310, // Russian Federation
      334, // Sudan
      340, // Syria
      4883, // South Sudan
      104867, // Testing
    ];
    const importantCountrySort = function (country) {
      const countryOrder = {
        US: 1,
        CA: 2,
        DE: 3,
        IL: 4,
      };
      return countryOrder[country.code] || 9999;
    };
    return this.getValues(STATIC_VALS_COUNTRIES).then((response) => { // @ts-ignore
      response.data = _.sortBy(response.data, country => (country as any).name); // @ts-ignore
      response.data = _.sortBy(response.data, importantCountrySort); // @ts-ignore
      response.data = _.filter(response.data, country => !restrictedCountryIds.includes((country as any).id));

      return response;
    });
  }

  states(countryId) {
    return this.getChildValues(STATIC_VALS_STATES, countryId).then(response => response.data);
  }

  countriesAndStates() {
    const self = this;
    const countries = self.countries();

    const states = countries.then((countries) => { // @ts-ignore
      const countriesWithStates = _.filter(countries.data, (c: any) => c.name === 'Canada' || c.name === 'United States');

      const statesByCountryId: any = {};

      _.forEach(countriesWithStates, (c: any) => {
        statesByCountryId[c.id] = self.states(c.id);
      });

      return self.$q.all(statesByCountryId);
    });

    return self.$q.all({ states, countries });
  }

  tags() {
    return this.$http.get('/api/v1/static_values/tags').then((response: any) => {
      response.data = _.map(response.data, tag => ({ title: tag, value: tag }));

      return response;
    });
  }

  years() {
    const d = new Date();
    const years = [];
    for (let i = (d.getFullYear() - 18); i > (d.getFullYear() - 150); i--) {
      years.push(i);
    }
    return years;
  }

  months(year) {
    const allMonths = [
      { id: 1, name: 'January' },
      { id: 2, name: 'February' },
      { id: 3, name: 'March' },
      { id: 4, name: 'April' },
      { id: 5, name: 'May' },
      { id: 6, name: 'June' },
      { id: 7, name: 'July' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'October' },
      { id: 11, name: 'November' },
      { id: 12, name: 'December' },
    ];
    const eighteenYearsAgo = new Date().getFullYear() - 18;

    if (year && year === eighteenYearsAgo) {
      const currentMonth = new Date().getMonth();
      const validMonths = [];
      for (let i = 0; i <= currentMonth; i++) {
        validMonths.push(allMonths[i]);
      }
      return validMonths;
    }
    return allMonths;
  }

  days(month, year) {
    const eighteenYearsAgo = new Date().getFullYear() - 18;
    const currentMonth = new Date().getMonth() + 1;
    // check days of month in current year if year not given
    year = year || new Date().getFullYear();
    month = month || 1;
    let numberOfDays;

    // limits possible date selections to 18 years ago or older
    if (year === eighteenYearsAgo && month === currentMonth) {
      numberOfDays = new Date().getDate();
    } else {
      numberOfDays = new Date(year, month, 0).getDate();
    }
    return _.map(new Array(numberOfDays), (val, i) => i + 1);
  }

  languages() {
    return this.getValues(STATIC_VALS_LANGUAGES).then(response => response.data);
  }

  mobileDevices() {
    return this.getValues(STATIC_VALS_HANDSET_MAKER).then(response => response.data);
  }

  webDevices() {
    return this.getValues(STATIC_VALS_OS).then(response => response.data);
  }

  osVersions(osId) {
    return this.getChildValues(STATIC_VALS_OS_VERSIONS, osId).then(response => response.data);
  }

  handsetModels(handsetMakerId) {
    return this.getChildValues(STATIC_VALS_HANDSET_MODEL, handsetMakerId).then(response => response.data);
  }

  handsetOs(handsetModelId) {
    return this.getChildValues(STATIC_VALS_HANDSET_OS_VERSION, handsetModelId).then(response => response.data);
  }

  pageData(pageName) {
    return this.$http.get(`/api/v1/page_configurations?page_name=${pageName}`);
  }
}

StaticValuesService.$inject = ['$q', '$http'];

export default StaticValuesService;
