class AlertController {
  constructor(AlertService, $sce: ng.ISCEService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      ctrl.alerts = AlertService.get();
    };

    ctrl.html = (message: string) => $sce.trustAsHtml(message);
  }
}

AlertController.$inject = ['AlertService', '$sce'];

export default AlertController;
