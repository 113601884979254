import _ from 'lodash';

class ModalCtrl {
  constructor($scope, $uibModalInstance, data) {
    $scope = _.merge($scope, data);

    $scope.ok = function () {
      $uibModalInstance.close($scope.selected.item);
    };

    $scope.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }
}

ModalCtrl.$inject = ['$scope', '$uibModalInstance', 'data'];

export default ModalCtrl;
