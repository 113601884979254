import { Component, Input } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { UIRouter } from '@uirouter/core';

@Component({
  selector: 'community-nav-item',
  templateUrl: './community-nav-item.component.html',
})
export class CommunityNavItemComponent {
  @Input('heading') heading: string;
  @Input('url') url: string;
  @Input('highlight') highlight: string;
  isSelected: boolean = false;

  constructor(
    private router: Router,
    private uiRouter: UIRouter,
  ) {}

  ngOnInit() {
    this.isSelected = this.highlight.includes(this.uiRouter.stateService.current.name);

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.urlAfterRedirects;
        // Replace 'highlight' with the appropriate logic to determine the highlighted routes
        this.isSelected = this.highlight.includes(currentRoute);
      }
    });
  }
}
