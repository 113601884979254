import _ from 'lodash';
import angular from 'angular';
import template from './slide-up.html';

const slideUp = function ($timeout, $analytics) {
  return {
    restrict: 'C',
    transclude: true,
    template,
    link(scope) {
      const supportedHtmlTagsFocus = ['a', 'button', 'textarea', 'input'];

      let currentTarget;

      const getAllChildComponents = targetElem => targetElem.getElementsByTagName('*');

      const outsideElemClickListener = (event) => {
        if (event.isTrusted && currentTarget[0] !== event.target && ![].slice.call(getAllChildComponents(currentTarget[0])).includes(event.target)) {
          // click outside the panel
          scope.slideDown();
        }
      };

      const handleAnchorClick: any = (elem, event) => {
        const childTag = elem.tagName.toLowerCase();
        if (childTag === 'a' && (event.code === 'Enter' || event.code === 'Space')) {
          event.target.click();
          event.preventDefault();
        }
      };

      const closeFocusCircuit = (firstFocusElem, lastFocusElem) => {
        if (lastFocusElem !== undefined) {
          lastFocusElem.onkeydown = (event) => {
            handleAnchorClick(lastFocusElem);
            if (!event.shiftKey && event.keyCode === 9) { // tab pressed
              $timeout(() => {
                firstFocusElem.focus();
                angular.element(firstFocusElem).attr('tabindex', 0);
              });
            }
          };
          firstFocusElem.onkeydown = (event) => {
            handleAnchorClick(firstFocusElem);
            if (event.shiftKey && event.keyCode === 9) { // shift and tab pressed
              $timeout(() => {
                lastFocusElem.focus();
                angular.element(lastFocusElem).attr('tabindex', 0);
              });
            }
          };
        }
      };

      scope.slideDown = function (targetSelector) {
        targetSelector = _.isUndefined(targetSelector) ? '.slide-up' : (`#${targetSelector}`);
        const target = $(targetSelector);

        if (target.hasClass('open')) {
          target.removeClass('open');
          document.removeEventListener('mousedown', outsideElemClickListener);
          const commentBtn = $('.comment-btn > a.btn');
          if (commentBtn.length) {
            commentBtn[0].focus();
          }
        }
      };

      scope.toggleSlideUp = function (targetSelector) {
        const currentSelector = _.isUndefined(targetSelector) ? '.slide-up' : (`#${targetSelector}`);
        const target = $(currentSelector);
        currentTarget = target;

        if (target.hasClass('open')) {
          scope.slideDown(targetSelector);
        } else {
          $('.slide-up').removeClass('open');

          $analytics.eventTrack('slideUp', {
            category: 'click',
            label: currentSelector.replace(/[0-9]+/, ''),
          });
          target.addClass('open');

          const allChildren = getAllChildComponents(target[0]);

          let lastFocusElem;
          let firstFocusElem;
          for (let i = -1, l = allChildren.length; ++i < l;) {
            const htmlElem = allChildren[i];
            const childTag = htmlElem.tagName.toLowerCase();
            const elem = angular.element(allChildren[i]);
            if (supportedHtmlTagsFocus.includes(childTag) && elem.is(':visible')) {
              elem.attr('tabindex', 0);
              lastFocusElem = htmlElem;
              if (firstFocusElem === undefined) {
                firstFocusElem = htmlElem;
              }
            }
          }

          if (scope.editor && scope.editor.instance) {
            $timeout(() => {
              scope.editor.instance.codemirror.focus();
              closeFocusCircuit(firstFocusElem, lastFocusElem);
              scope.editor.instance.codemirror.execCommand('goDocEnd');
              document.addEventListener('mousedown', outsideElemClickListener);
              window.addEventListener('popstate', () => { document.removeEventListener('mousedown', outsideElemClickListener); });
            }, 200);
          } else {
            const commentTtxAreas = target.find('textarea, input[type="text"]');
            let idAssigned = false;
            for (let i = 0; i < commentTtxAreas.length; i++) {
              const elem: any = angular.element(commentTtxAreas[i]);
              if (elem.is(':visible')) {
                if (!idAssigned) {
                  elem.attr('id', 'slideUpTextArea');
                  idAssigned = true;
                }
                $timeout(() => {
                  firstFocusElem.focus();
                  elem.attr('tabindex', 0);
                  closeFocusCircuit(firstFocusElem, lastFocusElem);
                  document.addEventListener('mousedown', outsideElemClickListener);
                  window.addEventListener('popstate', () => { document.removeEventListener('mousedown', outsideElemClickListener); });
                }, 200);
                break;
              }
            }
          }
          // adding empty element, so focus from last element won't be intercepted by browser url field
          target.append('<div tabindex="0"></div>');
        }
      };

      scope.$on('toggleSlideUp', (event, data) => {
        if (event.defaultPrevented) {
          return;
        }
        event.preventDefault();
        scope.toggleSlideUp(data);
      });
    },

  };
};

slideUp.$inject = ['$timeout', '$analytics'];

export default slideUp;
