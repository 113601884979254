import angular from 'angular';

const routeClasses = function ($transitions) {
  return {
    restrict: 'A',
    link(scope, elem) {
      $transitions.onSuccess({}, (transition) => {
        const fromClassnames = angular.isDefined(transition.from().data) && angular.isDefined(transition.from().data.routeClasses) ? transition.from().data.routeClasses : null;
        const toClassnames = angular.isDefined(transition.to().data) && angular.isDefined(transition.to().data.routeClasses) ? transition.to().data.routeClasses : null;
        if (fromClassnames !== toClassnames) {
          if (fromClassnames) {
            elem.removeClass(fromClassnames);
          }
          if (toClassnames) {
            elem.addClass(toClassnames);
          }
        }
      });
    },
  };
};

routeClasses.$inject = ['$transitions'];

export default routeClasses;
