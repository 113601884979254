import _ from 'lodash';
import angular from 'angular';
import Bowser from 'bowser';

const dynamicTooltip = function ($compile, $window, $timeout, AccessibleInertService) {
  function isElementInViewport(jEl) {
    const el = jEl[0];
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= $window.innerHeight
        && rect.right <= $window.innerWidth
    );
  }

  return {
    restrict: 'A',
    link(scope, el) {
      const supportedHtmlTagsFocus = ['a', 'button', 'textarea', 'input'];
      AccessibleInertService.initializeInertService(el);
      let originalElRole;
      const browser = Bowser.getParser(window.navigator.userAgent);
      const osName = browser.getOSName();
      if (osName === 'Windows') {
        // set role of tooltip to application to force NVDA into focus mode
        el.attr('role', 'application');
      }

      let tooltipOriginalDescription;

      el.css('position', 'relative');

      let removeTimeout;
      const delay = 200;

      function cancelTimeout(timeout) {
        if (timeout) {
          return $timeout.cancel(timeout);
        }
        return false;
      }

      const setFocusBetweenTwoElements = (firstElem, secondElem, shiftPressed) => {
        firstElem.onkeydown = (event) => {
          if (event.code === 'Escape') {
            scope.hideTooltip();
          }
          const shiftCorrectState = firstElem === secondElem || (shiftPressed && event.shiftKey) || (!shiftPressed && !event.shiftKey);
          if (shiftCorrectState && event.keyCode === 9) { // tab pressed
            $timeout(() => {
              secondElem.focus();
            });
            event.preventDefault();
          }
          if (firstElem.tagName.toLowerCase() === 'a' && (event.code === 'Enter' || event.code === 'Space')) {
            event.target.click();
          }
        };
      };

      const isNavigableLabel = (elem) => {
        const htmlElem = elem[0];
        const htmlTag = htmlElem.tagName.toLowerCase();
        return htmlTag === 'label' && elem.attr('ng-keydown') !== undefined;
      };

      const trapAndSetFocus = (el) => {
        const originalDescription = el.attr('aria-label');
        if (originalDescription !== undefined && originalDescription !== '') {
          tooltipOriginalDescription = originalDescription;
          el.removeAttr('aria-label');
          el.attr('role', 'none');
        }
        const children = el[0].getElementsByTagName('*');
        let lastFocusElem;
        let firstFocusElem;
        for (let i = 0; i < children.length; i++) {
          const htmlElem = children[i];
          const childTag = htmlElem.tagName.toLowerCase();
          const elem = angular.element(htmlElem);
          if ((supportedHtmlTagsFocus.includes(childTag) || isNavigableLabel(elem)) && elem.is(':visible') && elem.attr('tabindex') !== '-1') {
            lastFocusElem = htmlElem;
            if (firstFocusElem === undefined) {
              firstFocusElem = htmlElem;
            }
          }
        }
        if (lastFocusElem !== undefined) {
          setFocusBetweenTwoElements(lastFocusElem, firstFocusElem, false);
          setFocusBetweenTwoElements(firstFocusElem, lastFocusElem, true);
        }
        if (firstFocusElem !== undefined) {
          $timeout(() => {
            firstFocusElem.focus();
          });
        }
      };

      const addOpenClasses = (html) => {
        if (isElementInViewport(html)) {
          html.addClass('dynamic-tooltip-position-bottom');
        } else {
          html.addClass('dynamic-tooltip-position-top');
        }
      };

      const showTooltipLogic = (directive, params, useTimeout, event) => {
        if (!cancelTimeout(removeTimeout)) {
          $compile(`<div class="dynamic-tooltip"><${directive}></${directive}></div>`)(_.merge(scope, params), (html) => {
            el.append(html);
            if (useTimeout) {
              $timeout(() => {
                addOpenClasses(html);
              });
            } else {
              addOpenClasses(html);
            }
          });
          $timeout(() => trapAndSetFocus(el), 200);
          // fix for JAWS, otherwise it propagate click event on tooltip icon and opens it again
          if (el.attr('role') !== undefined && el.attr('role') !== 'none') {
            originalElRole = el.attr('role');
            el.attr('role', 'none');
          }
          if (event !== null) {
            AccessibleInertService.setAriaHidden(true, event.target);
          }
        }
      };

      scope.showTooltip = (directive, params) => {
        showTooltipLogic(directive, params, true, null);
      };

      scope.showTooltipWithKey = (event, directive, params) => {
        if (event.key === 'Enter' || event.key === 'Space' || event.key === ' ') {
          showTooltipLogic(directive, params, false, event);
        }
        event.preventDefault();
        event.stopImmediatePropagation();
      };

      scope.hideTooltip = function () {
        cancelTimeout(removeTimeout);
        const originalDescription = tooltipOriginalDescription;
        if (originalDescription !== undefined && originalDescription !== '') {
          el.attr('aria-label', originalDescription);
          el.attr('role', 'button');
        }
        removeTimeout = $timeout(() => {
          el.find('.dynamic-tooltip').remove();
        }, delay);
        if (originalElRole !== undefined) {
          el.attr('role', originalElRole);
        }
        AccessibleInertService.setAriaHidden(false);
        el.focus();
      };

      scope.hideTooltipOnKeyPress = function (event) {
        if (event.key === 'Enter' || event.key === 'Space' || event.key === ' ') {
          scope.hideTooltip();
          event.preventDefault();
          event.stopImmediatePropagation();
        }
      };
    },
  };
};

dynamicTooltip.$inject = ['$compile', '$window', '$timeout', 'AccessibleInertService'];

export default dynamicTooltip;
