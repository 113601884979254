import template from './academy-course-track-quiz.component.html';

const academyCourseTrackQuiz = {
  bindings: {
    courseTrack: '<',
    user: '<',
  },
  template,
};

export default academyCourseTrackQuiz;
