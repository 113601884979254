import _ from 'lodash';

const categoryType = function () {
  return function (name) {
    if (_.isString(name)) {
      return name.split(' ')[0].toUpperCase();
    }
  };
};

export default categoryType;
