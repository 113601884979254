/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

import { QueryList } from '@angular/core';
import { concat, defer, Observable, of, ReplaySubject } from 'rxjs';
import { map, share } from 'rxjs/operators';

export function contentChildren$<T>(contentChildren: QueryList<T>): Observable<T[]> {
  return concat(
    defer(() => of(contentChildren.toArray())),
    contentChildren.changes.pipe(map(() => contentChildren.toArray()))
  ).pipe(
    share({ connector: () => new ReplaySubject(1) })
  );
}
