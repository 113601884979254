import _ from 'lodash';

const KeysetFeedStoreFactory = function (AlertService) {
  return function (fetchFeed, storeParameters) {
    const self = this;
    let latestHeaders;

    self.parameters = {};
    self.fetchFeed = fetchFeed;

    self.feedItems = [];
    self.isLastPage = true;
    self.isLoading = false;

    const handleResponse = function (response) {
      latestHeaders = response.headers;
      Array.prototype.push.apply(self.feedItems, response.data);
      self.isLastPage = !latestHeaders('X-Next-Id');
      self.isLoading = false;

      return response;
    };

    const handleErrorResponse = function (response) {
      AlertService.addDanger(response.statusText);
      self.isLoading = false;
    };

    const selfPromise = {
      then(callback) {
        callback(self);
      },
    };

    const doFetch = function (nextId) {
      if (self.isLoading) {
        return selfPromise;
      }

      const pagination = {
        after_id: nextId,
        per_page: 10,
      };

      const parameters = _.merge({}, pagination, storeParameters, self.parameters);

      self.isLoading = true;
      return self.fetchFeed(parameters).then(handleResponse, handleErrorResponse).then(() => self);
    };

    self.reset = function () {
      self.feedItems = [];
    };

    self.load = function (nextId) {
      self.feedItems = [];

      return doFetch(nextId);
    };

    self.loadMore = function (nextId) {
      if (_.isEmpty(self.feedItems)) {
        return self.load(nextId);
      }

      nextId = latestHeaders('X-Next-Id');

      if (nextId) {
        return doFetch(nextId);
      }
      return selfPromise;
    };
  };
};

KeysetFeedStoreFactory.$inject = ['AlertService'];

export default KeysetFeedStoreFactory;
