/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Injectable, Injector } from '@angular/core';
import { OptionsRoot } from './options-root';

/**
 * this holder class is used to avoid circular dependencies,
 * while injecting directly OptionsRoot instance
 */
@Injectable()
export class OptionsRootHolder {
  private optionsRoot: OptionsRoot;

  constructor(private injector: Injector) {}

  get(): OptionsRoot {
    if (!this.optionsRoot) {
      this.optionsRoot = this.injector.get(OptionsRoot);
    }

    return this.optionsRoot;
  }
}
