import template from './hidden-menu.html';
import { KEY_CODES } from '../../constants';

function hiddenMenuCtrl($scope) {
  const ctrl = this;
  ctrl.iconClass = this.iconClass || 'icon-arrowDown';
  ctrl.iconName = this.iconName || '';
  ctrl.menuOpen = false;
  ctrl.blurFlag = false;
  this.KEY_CODES = KEY_CODES;
  ctrl.handleKeyDown = (event) => {
    const targetId = ((event.target.id).match(/\d+/g)).toString();
    const hiddenMenuListItems = $(`#hiddenMenuList-${targetId} > li`);
    const keyCodes = this.KEY_CODES;
    if ([keyCodes.SPACE, keyCodes.RETURN, keyCodes.DOWN].includes(event.keyCode)) {
      if (event.keyCode === keyCodes.DOWN) {
        event.preventDefault();
        ctrl.openMenu();
      }
      setTimeout(() => {
        hiddenMenuListItems[0].focus();
      }, 100);
    } else if (event.keyCode === keyCodes.UP) {
      event.preventDefault();
      ctrl.openMenu();
      setTimeout(() => {
        hiddenMenuListItems[1].focus();
      }, 100);
    }
  };
  ctrl.toggleHiddenMenu = (event) => {
    ctrl.menuOpen = !ctrl.menuOpen;
    ctrl.blurFlag = event.originalEvent.pointerId;
  };

  ctrl.closeMenu = () => {
    ctrl.menuOpen = false;
    ctrl.blurFlag = false;
  };

  ctrl.openMenu = () => {
    ctrl.menuOpen = true;
    ctrl.blurFlag = false;
  };

  ctrl.onBlur = () => {
    if (ctrl.blurFlag) {
      ctrl.closeMenu();
    }
  };

  $scope.$on('closeMenu', () => {
    ctrl.closeMenu();
  });
}

hiddenMenuCtrl.$inject = ['$scope'];

const hiddenMenu = {
  bindings: {
    iconClass: '@',
    iconName: '@',
  },
  template,
  controller: hiddenMenuCtrl,
  transclude: true,
};

export default hiddenMenu;
