export default `
<button ng-hide="!$ctrl.isPopover"
   aria-expanded="{{$ctrl.isPopoverOpen}}"
   analytics-on
   analytics-event="click"
   analytics-category="Share"
   href=""
   popover-is-open="$ctrl.isPopoverOpen"
   class="icon-share"
   uib-popover-template="'social-sharing-popover.html'"
   popover-trigger="click"
   ng-class="{'active': active == true}"
   popover-class="social-sharing"
   popover-placement="bottom"
   aria-label="Share {{$ctrl.text.replace('- via @uTest', '')}}{{$ctrl.type}}">
</button>

<span ng-hide="$ctrl.isPopover">
  <a href="#" ng-if="!$ctrl.isLightTester" title="Share on Twitter" class="icon-twitter-material" socialshare socialshare-provider="twitter" socialshare-text="{{$ctrl.text}}" socialshare-url="{{$ctrl.target}}"></a>
  <a href="#" ng-if="$ctrl.isLightTester" title="Share on Twitter" class="icon-twitter-material" ng-click="checkLightTester($event)"></a>

  <a href="#" ng-if="!$ctrl.isLightTester" title="Share on LinkedIn" class="icon-linkedin-material" socialshare socialshare-provider="linkedin" socialshare-text="{{$ctrl.text}}" socialshare-url="{{$ctrl.target}}"></a>
  <a href="#" ng-if="$ctrl.isLightTester" title="Share on LinkedIn" class="icon-linkedin-material" ng-click="checkLightTester($event)"></a>
</span>

<script type="text/ng-template" id="social-sharing-popover.html">
  <div class="social-sharing-pop" ng-if="!$ctrl.isLightTester">
    <i tabindex="0" role="button" class="icon-twitter-material" aria-label="Share on Twitter" aria-hidden="false" socialshare socialshare-provider="twitter" socialshare-text="{{$ctrl.text}}" socialshare-url="{{$ctrl.target}}"></i>
    <i tabindex="0" role="button" class="icon-linkedin-material" aria-label="Share on LinkedIn" aria-hidden="false" socialshare socialshare-provider="linkedin" socialshare-text="{{$ctrl.text}}" socialshare-url="{{$ctrl.target}}"></i>
  </div>

  <div class="social-sharing-pop" ng-if="$ctrl.isLightTester">
    <i tabindex="0" role="button" class="icon-twitter-material" aria-label="Share on Twitter" aria-hidden="false" ng-click="checkLightTester($event)"></i>
    <i tabindex="0" role="button" class="icon-linkedin-material" aria-label="Share on LinkedIn" aria-hidden="false" ng-click="checkLightTester($event)"></i>
  </div>
</script>
`;
