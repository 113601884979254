const validationPassword = function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      validationUserPassword: '=',
    },
    link(scope, elem, attrs, ctrl) {
      ctrl.$validators.validationUserPassword = function (modelValue) {
        if (modelValue) {
          return Boolean(modelValue.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/g)) && modelValue.indexOf(scope.validationUserPassword.email) <= -1 && modelValue.indexOf(scope.validationUserPassword.slug) <= -1;
        }
        return false;
      };
    },
  };
};

export default validationPassword;
