import _ from 'lodash';
import { EMPTY_CONTENT_MARKDOWNS } from '../../constants';

class CommentSectionShortCtrl {
  constructor($scope, $sce, $filter, StatusService, CommentService, AlertService, commentEmojis, ServerMessages, LightTesterErrorService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.slideUpId = `commentSectionShortSlideUp_${ctrl.commentable.type}_${ctrl.commentable.id}`;
      ctrl.setComment = function (comment) {
        ctrl.newComment = comment;
      };

      ctrl.loadComments = function () {
        const markdownComments = (comments) => {
          ctrl.comments = [];
          _.forEach(comments, (o) => {
            o.content_built = $sce.trustAsHtml($filter('uMarkdown')(o.content));
            o.indentation = 0;
            ctrl.comments.push(o);
          });
        };
        if (ctrl.commentable.recent_comments) {
          markdownComments(ctrl.commentable.recent_comments);
        } else {
          StatusService.commentsPage(ctrl.commentable.id, 1, '-created_at', 2).then((res) => {
            markdownComments(res.data);
          });
        }
      };

      ctrl.loadComments();

      ctrl.submitComment = function () {
        const trimmedComment = _.trim(ctrl.newComment.content);
        if (_.isEmpty(trimmedComment) || EMPTY_CONTENT_MARKDOWNS.includes(trimmedComment)) {
          AlertService.addDanger('Can\'t post empty comment!');
          return;
        }
        CommentService.postComment(ctrl.commentable.type, ctrl.commentable.id, ctrl.newComment).then(() => {
          ctrl.loadComments();

          if (ctrl.commentable.comments) {
            ctrl.commentable.comments.count += 1;
          }

          AlertService.add('Comment successfully posted');
          $scope.$broadcast('toggleSlideUp', ctrl.slideUpId);
        }, (response) => {
          AlertService.addDanger(ServerMessages.prettyMessage(response));
        });
      };

      ctrl.emojiImages = commentEmojis;

      ctrl.selectEmoji = function (emojiCode) {
        ctrl.setMode('');
        let space = '';
        if (_.isUndefined(ctrl.newComment.content)) {
          ctrl.newComment.content = '';
        } else {
          space = ctrl.newComment.content.slice(-1) === ' ' ? '' : ' ';
        }
        ctrl.newComment.content += space + emojiCode;
      };

      ctrl.mode = '';
      ctrl.setMode = function (mode) {
        ctrl.mode = ctrl.mode === mode ? '' : mode;
      };

      // this will get populated with the edior instance so the slideup directive can focus on it
      ctrl.editor = {};

      ctrl.openCommentEditor = (comment = { content: '' }) => {
        if (LightTesterErrorService.show()) return false;

        ctrl.setComment(comment);
        $scope.toggleSlideUp(ctrl.slideUpId);
      };
    };
  }
}

CommentSectionShortCtrl.$inject = ['$scope', '$sce', '$filter', 'StatusService', 'CommentService', 'AlertService', 'commentEmojis', 'ServerMessages', 'LightTesterErrorService'];

export default CommentSectionShortCtrl;
