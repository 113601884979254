const writeStatusFsTrigger = ($compile) => {
  const $body = $('body');

  return {
    restrict: 'A',
    scope: {
      status: '=?',
    },
    link(scope, el) {
      el.click(() => {
        $compile('<write-status-fs status="status"></write-status-fs>')(scope, (overlay) => {
          $body.append(overlay);
        });
      });
    },
  };
};

writeStatusFsTrigger.$inject = ['$compile'];

export default writeStatusFsTrigger;
