import _ from 'lodash';

class PendingArticleCtrl {
  constructor(ArticleService, FeedStore, AlertService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      const fetchFeed = _.bind(ArticleService.feed, ArticleService);

      ctrl.pendingArticles = new FeedStore(fetchFeed, { filter: { state: 'review' }, sort: '-updated_at' });
      ctrl.rejectedArticles = new FeedStore(fetchFeed, { filter: { state: 'rejected' }, sort: '-rejected_at' });
      ctrl.pendingArticles.load();
      ctrl.rejectedArticles.load();
      ctrl.reject = {};
      ctrl.rejectReasons = ['Low quality', 'Poor grammar', 'Spam', 'Off-topic'];
    };

    ctrl.rejectArticle = (id, reason) => {
      if (!reason) {
        AlertService.add('Please enter Reason', 'danger');
        return false;
      }
      ArticleService.reject(id, reason).then(() => {
        ctrl.reject = {};
        _.remove(ctrl.pendingArticles.feedItems, { id });
        ctrl.rejectedArticles.load();
        AlertService.add('Article rejected');
        ctrl.currentUser.pending_articles_count -= 1;
      });
    };

    ctrl.publishArticle = (id, source) => {
      ArticleService.publish(id).then(() => {
        ctrl.reject = {};
        if (source === 'reject') {
          _.remove(ctrl.rejectedArticles.feedItems, { id });
        } else {
          _.remove(ctrl.pendingArticles.feedItems, { id });
          ctrl.currentUser.pending_articles_count -= 1;
        }
        AlertService.add('Article successfully published');
      });
    };
  }
}

PendingArticleCtrl.$inject = ['ArticleService', 'FeedStore', 'AlertService'];

export default PendingArticleCtrl;
