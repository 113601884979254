<!--
  ~ Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
  -->

<ngf-autocomplete-input [placeholder]="autocomplete.placeholder"
                        [hasError]="autocomplete.hasError"
                        [hasShadow]="autocomplete.hasShadow"
                        [displayValue]="value$ | async | displayValue: autocomplete.displayWith: autocomplete.nullAsValue"
                        [isOpen]="autocomplete.optionsOverlay.isOpen"
                        [disabled]="autocomplete.disabled || autocomplete.isReadonly"
                        [disableClear]="autocomplete.disableClear"
                        (search)="handleSearch($event)"
                        (clearValue)="handleClearValue()"
                        (toggleOptions)="handleToggle()"
                        (focusLost)="handleBlur()"></ngf-autocomplete-input>
