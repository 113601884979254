import _ from 'lodash';

const userHeartbeatPingService = function userHeartbeatPingService(UserService, localStorageService, $http, $q) {
  // The events listened to to determine if a user is active
  const activeEvents = ['mousewheel', 'keydown', 'mousemove', 'click'];
  const defaultHeartbeatPingInterval = 300000;
  const browserEventListenerThrottleInterval = 10000;

  function initialize($window) {
    activeEvents.forEach((activeEvent) => { addListener(activeEvent, $window); });
    if (!localStorageService.get('nextUtestHeartbeatPingDate')) {
      localStorageService.set('nextUtestHeartbeatPingDate', now());
    }
  }

  function addListener(activeEvent, $window) {
    $window.addEventListener(
      activeEvent,
      _.throttle(() => {
        heartbeatPing();
      }, browserEventListenerThrottleInterval, { trailing: false }),
    );
  }

  function heartbeatPing() {
    UserService.me().then((user) => {
      if (user && localStorageService.get('nextUtestHeartbeatPingDate') < now() && user.id === user.originalUserId) {
        $http.put('/api/v1/users/update_heartbeat_ping')
          .then((response) => {
            localStorageService.set('nextUtestHeartbeatPingDate', now() + response.data);
          })
          .catch((response) => {
            localStorageService.set('nextUtestHeartbeatPingDate', now() + defaultHeartbeatPingInterval);
            return $q.reject(response);
          });
      }
    });
  }

  function now() {
    return new Date().getTime();
  }

  return {
    initialize,
  };
};

userHeartbeatPingService.$inject = ['UserService', 'localStorageService', '$http', '$q'];

export default userHeartbeatPingService;
