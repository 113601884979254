const alertService = function () {
  let alerts = [];

  function closeAlertIdx(index) {
    return alerts.splice(index, 1);
  }

  function closeAlert(alert) {
    return closeAlertIdx(alerts.indexOf(alert));
  }

  function add(msg, type, autoClose = true) {
    return alerts.push({
      message: msg,
      type: !type ? 'success' : type,
      autoClose,
      close() {
        return closeAlert(this);
      },
    });
  }

  function addSuccess(msg) {
    return add(msg, 'success');
  }

  function addDanger(msg) {
    return add(msg, 'danger');
  }

  function addPermanentDanger(msg) {
    return add(msg, 'danger', false);
  }

  function clear() {
    alerts = [];
  }

  function get() {
    return alerts;
  }

  return {
    alerts,
    add,
    addDanger,
    addPermanentDanger,
    addSuccess,
    closeAlert,
    closeAlertIdx,
    clear,
    get,
  };
};

export default alertService;
