import template from './tool-feed-item.component.html';

const toolFeedItem = {
  bindings: {
    tool: '=',
    includeHeaderInfo: '=',
    featured: '=',
  },
  template,
};

export default toolFeedItem;
