import template from './nav-sidebar-dropdown-item.component.html';
import controller from './nav-sidebar-dropdown-item.controller';

const navSidebarDropdownItem = {
  bindings: {
    text: '@',
    url: '@',
    route: '@',
    reload: '@',
    highlight: '&',
    expandParent: '&',
    collapseParent: '&',
  },
  template,
  controller,
};

export default navSidebarDropdownItem;
