export default `
<div id="mainContent">
  <div class="section-home-cover" bg-img="assets/images/test-from-home.jpg">
    <div class="grid w960">
      <div class="section-home-cover-random">
        <h1>uTest - Ein Überblick</h1>
      </div>
    </div>
  </div>
</div>
<div class="grid w960">
  <div class="c12">
    <h2>Was ist uTest?</h2>
  </div>
  <div class="c12">
    <h3>Was macht man hier als Tester?</h3>
    <p>Als App-Tester testest du regelmäßig die neuesten Webseiten, neue Software und neue Apps. Nachdem du dich bei uns angemeldet hast, bekommst du Einladungen zu den unterschiedlichsten Tests. Diese finden direkt bei uns auf der Plattform statt, hier reichts du die Bugs ein, die du gefunden hast, hier beantwortest du Umfragen und prüfst eine App auf Herz und Nieren. Du hilfst also dabei, dass Unternehmen qualitativ hochwertige Apps gestalten. Das beste daran ist: Du testest von zu Hause aus, an deinem eigenen PC, mit deinem eigenen Telefon oder Tablet, TV, Konsole, Smartwatch oder was auch immer der Kunde gerne testen möchte. <br>
      Da du mit uns kein Beschäftigungsverhältnis eingehst, kannst du auch neben deinem normalen Beruf testen.</p>
  </div>
  <div class="c12">
    <h3>Alles rund um die Mitgliedschaft</h3>
    <p>Jeder, der mindestens 18 Jahre alt ist, darf sich bei uns als Tester registrieren und Teil unserer Community werden. Tester aus jeglichen Arbeitsverhältnisses sind bei uns willkommen. Jeder Test ist unterschiedlich und um für unsere Kunden bestmögliche Testergebnisse garantieren zu können, benötigen wir vielseitige Tester mit den unterschiedlichsten Fähigkeiten. Es ist keine Voraussetzung, dass du vorher bereits Erfahrungen im Testbereich hattest. <br>
      <br>
      Die Mitgliedschaft ist natürlich komplett kostenlos - du sollst bei uns Geld verdienen! </p>
  </div>
  <div class="c12">
    <h3> Wo kann ich mich als Tester anmelden? </h3>
    <p>Folge diesem Link. Er führt dich direkt zu unserer Registrierung. Hast du alles ausgefüllt, bist du als Tester zugelassen und kannst zu Projekten eingeladen werden. <a title="Anmeldung" href="https://www.utest.com/signup" target="_blank">Anmeldung</a><br>
      <br>
      In dem nachfolgendem Video findest du weitere Erklärungen.</p>
    <p>Du hast immer noch Fragen? Dann schaue dich in unseren <a href="http://www.utest.com/german-faqs">FAQs</a> um.
  </div>
</div>
`;
