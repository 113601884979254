/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';
import angular from 'angular';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { EasymdeOptions } from 'ngx-easymde/src/config';
import { EasymdeComponent } from 'ngx-easymde';

import customButtonConfig from './ut-editor.custom/buttons';
import emojiConfig from './ut-editor.custom/emoji-config';
import mentionConfigBuilder
  from './ut-editor.custom/mention-config-builder';
import suggestionBoxSetup from './ut-editor.custom/suggestion-box';

@Component({
  selector: 'ut-editor',
  templateUrl: './ut-editor.component.html',
  styleUrls: ['./ut-editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
class UtEditorComponent implements OnInit, AfterViewInit {
  @ViewChild('easymde') private readonly easymde: EasymdeComponent;

  @Input() value = '';
  @Input() id = '';
  @Input() textAreaLabel = 'Write your text here';
  @Input() textRequired = false;
  @Input() disabled = false;
  @Input() placeholder = '';
  @Input() config = '';

  @Output() valueChange = new EventEmitter<string>();

  deferredEasyMDE = this.$q.defer();
  options: EasymdeOptions;
  configurations = {
    default: {
      toolbar: ['bold', 'italic', '|', 'unordered-list', 'ordered-list', 'quote', 'code', '|',
        'link', 'uploadImage', 'embed', 'emoji', '|', 'preview', 'guide'],
      status: false,
      spellChecker: true,
    },
    full: {
      toolbar: ['bold', 'italic', 'heading', '|', 'unordered-list', 'ordered-list', 'quote', 'code',
        '|', 'link', 'uploadImage', 'embed', 'emoji', '|', 'preview', 'side-by-side', 'fullscreen',
        'guide'],
      status: false,
      spellChecker: true,
    },
    clean: {
      toolbar: false,
      status: false,
      spellChecker: true,
    },
  };

  constructor(
    @Inject('$filter') private $filter: ng.IFilterService,
    @Inject('$scope') private $scope: ng.IScope,
    @Inject('$rootScope') private $rootScope: any,
    @Inject('$compile') private $compile: ng.ICompileService,
    @Inject('$q') private $q: ng.IQService,
    @Inject('$cookies') private $cookies: any,
    @Inject('$uibModal') private $uibModal: any,
    @Inject('$state') private $state: StateService,
    @Inject('$sanitize') private $sanitize: any,
    @Inject('$timeout') private $timeout: ng.ITimeoutService,
    @Inject('AlertService') private AlertService: any,
    @Inject('SearchService') private SearchService: any,
    private element: ElementRef,
  ) {
    this.$scope.$on('openCommentEditor', (_a, _b) => {
      if (this.easymde.Instance && this.easymde.Instance.isPreviewActive()) {
        this.easymde.Instance.togglePreview();
      }
    });
  }

  ngOnInit(): void {
    this.options = this.getOptions();
  }

  /*
  walk around for strange style behaviour showing cursor in
  wrong place even when it's calculated correctly it's a bug inside easy mde.
   To reproduce it on the demo page, paste 15 paragraphs of lorem ipsum inside 300px high editor
   */
  fixPastingLongText(): void {
    this.$timeout(() => {
      const pos = this.easymde.Instance.codemirror.getCursor();
      this.easymde.Instance.codemirror.setSelection(pos, pos);
      this.easymde.Instance.codemirror.replaceSelection(' ');
      this.$timeout(() => {
        const newPos = this.easymde.Instance.codemirror.getCursor();
        this.easymde.Instance.codemirror.setSelection(pos, newPos);
        this.easymde.Instance.codemirror.replaceSelection('');
      });
    });
  }

  ngAfterViewInit(): void {
    const easymde = this.easymde.Instance;
    this.deferredEasyMDE.resolve(easymde);
    this.fixUsabilityOnEditor(easymde);
    easymde.codemirror.on('paste', () => {
      this.fixPastingLongText();
    });
  }

  emit(content: string): void {
    if (content !== this.value) {
      this.valueChange.emit(content);
    }
  }

  private getOptions(): EasymdeOptions {
    let config: any = this.configurations.default;
    if (this.config && this.configurations[this.config]) {
      config = this.configurations[this.config];
    }
    config.placeholder = this.placeholder;
    config.previewRender = this.$filter('uMarkdown');

    const element = $(this.element.nativeElement);
    const customBtnCfg = customButtonConfig({
      $scope: this.$scope,
      $element: element,
      $compile: this.$compile,
      $q: this.$q,
      $cookies: this.$cookies,
      $uibModal: this.$uibModal,
      $state: this.$state,
      AlertService: this.AlertService,
      easymde: this.deferredEasyMDE.promise,
      csrfCookieName: this.$rootScope.csrfCookieName,
    });
    const atConfig = [emojiConfig, mentionConfigBuilder(this.SearchService, this.$sanitize)];
    this.deferredEasyMDE.promise.then((easymde) => {
      suggestionBoxSetup({
        atConfig,
        easymde,
        $scope: this.$scope,
        $element: element,
        $timeout: this.$timeout,
      });
    });
    config.toolbar = _.map(config.toolbar, btn => customBtnCfg[btn] || btn);
    return config;
  }

  private fixUsabilityOnEditor(easymde: any): void {
    const element = $(this.element.nativeElement);

    const textareas = element.find('easymde')[0].getElementsByTagName('textarea');
    for (let i = 0; i < textareas.length; i++) {
      const textArea = angular.element(textareas[i]);
      textArea.attr('id', this.id);
      textArea.attr('aria-label', this.textAreaLabel);
      textArea.attr('aria-required', this.textRequired.toString());
    }

    easymde.codemirror.options.extraKeys.Tab = false;
    easymde.codemirror.options.extraKeys['Shift-Tab'] = false;

    element.find('.editor-preview-side').removeClass('editor-preview');
    const editorLinks = element.find('.editor-toolbar')[0].getElementsByTagName('button');
    for (let i = 0; i < editorLinks.length; i++) {
      const elem = angular.element(editorLinks[i]);
      elem.attr('tabindex', 0);
      elem.attr('aria-label', elem.attr('title'));
      elem.attr('role', elem.attr('title') === 'Emoji' ? 'link' : 'button');
      elem.attr('aria-checked', 'false');
      elem.attr('aria-pressed', 'false');
      elem[0].addEventListener('click', (event: any) => {
        let eventTargetElement = angular.element(event.target);
        if (eventTargetElement.prop('tagName').toLowerCase() === 'i') {
          // set attributes on the button instead of the icon
          eventTargetElement = eventTargetElement.parent();
        }

        const buttonClasses = eventTargetElement.attr('class');
        const previewButtonClicked = buttonClasses.includes('preview');
        const sideBySideButtonClicked = buttonClasses.includes('side-by-side');
        const fullscreenButtonClicked = buttonClasses.includes('fullscreen');
        if (previewButtonClicked || sideBySideButtonClicked || fullscreenButtonClicked) {
          const toggleButtons = [];
          angular.forEach(editorLinks, elem => {
            const ele = angular.element(elem);
            if (ele.attr('title').startsWith('Toggle')) {
              toggleButtons.push(ele);
            }
          });
          const previewButton = toggleButtons.find(button => button.attr('class').includes('preview'));
          const sideBySideButton = toggleButtons.find(button => button.attr('class').includes('side-by-side'));
          const fullscreenButton = toggleButtons.find(button => button.attr('class').includes('fullscreen'));

          const ariaPressedState = eventTargetElement.attr('aria-pressed');

          if (ariaPressedState === 'false' && sideBySideButtonClicked) {
            // enabling side-by-side: fullscreen is selected and preview disabled
            sideBySideButton.attr('aria-pressed', 'true');
            fullscreenButton.attr('aria-pressed', 'true');
            previewButton.attr('aria-pressed', 'false');
          } else if (ariaPressedState === 'false' && previewButtonClicked) {
            // enabling preview: disable side-by-side
            previewButton.attr('aria-pressed', 'true');
            sideBySideButton.attr('aria-pressed', 'false');
          } else if (ariaPressedState === 'true' && fullscreenButtonClicked) {
            // disabling fullscreen: disable side-by-side
            fullscreenButton.attr('aria-pressed', 'false');
            sideBySideButton.attr('aria-pressed', 'false');
          } else {
            // otherwise just toggle the aria-selected value
            eventTargetElement.attr('aria-pressed', ariaPressedState === 'true' ? 'false' : 'true');
          }
        }

        angular.forEach(editorLinks, elem => angular.element(elem)
          .attr('aria-checked', 'false'));
        angular.element(eventTargetElement)
          .attr('aria-checked', 'true');
      });
      elem[0].onkeydown = (event: any) => {
        if (event.code === 'Enter' || event.code === 'Space') {
          event.target.click();
          event.preventDefault();
        }
        if (elem[0].title === 'Upload Image') {
          this.setFocusAndTrapUploadModal(document.activeElement.className, event, element);
        }
      };
      elem[0].onfocus = (event: any) => angular.element(event.target)
        .addClass('active');
      elem[0].onblur = (event: any) => angular.element(event.target)
        .removeClass('active');
    }
  }

  private setFocusAndTrapUploadModal(className, event, element): void {
    if (event.shiftKey && event.code !== 'Tab') {
      return;
    }
    const btns = element.find('.dropzone-toolbar')[0].getElementsByTagName('button');
    const closeBtn = btns[0];
    const activateBtn = btns[1];
    // add listener on close so that focus is returned to upload button
    closeBtn.addEventListener('click', () => { element.find('button .fa.fa-picture-o')[0].parentNode.focus(); });
    closeBtn.addEventListener('keydown', (event) => {
      if (event.code === 'Enter' || event.code === 'Space') {
        element.find('button .fa.fa-picture-o')[0].parentNode.focus();
      }
    });
    switch (className) {
      case 'close':
        if (event.shiftKey && event.code === 'Tab') {
          event.preventDefault();
          activateBtn.focus();
        }
        return;
      case 'activation-btn':
        event.preventDefault();
        closeBtn.focus();
        break;
      case 'uploadImage active':
        setTimeout(() => {
          closeBtn.focus();
        }, 300);
        break;
      default:
    }
  }
}

export { UtEditorComponent };
