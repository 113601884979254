import _ from 'lodash';
import angular from 'angular';
import template from './like-list-overlay.html';

const likeListOverlay = function (LikeService, FeedStore, $transitions, $analytics, stateStore, $timeout) {
  return {
    restrict: 'C',
    template,
    link(scope) {
      const removeListener = $transitions.onStart({}, () => {
        scope.closeOverlay();
        removeListener();
      });
      const supportedHtmlTagsFocus = ['a', 'button', 'textarea', 'input'];
      const getAllChildComponents = targetElem => targetElem.getElementsByTagName('*');

      scope.users = new FeedStore(_.bind(LikeService.likedUsers, LikeService, scope.likeable));
      scope.users.load();

      const trackUrl = `${stateStore(scope.likeable)}/likes`;
      $analytics.pageTrack(trackUrl);

      scope.$on('$destroy', () => {
        removeListener();
      });

      const handleAnchorClick = (elem, event) => {
        const childTag = elem.tagName.toLowerCase();
        if (childTag === 'a' && (event.code === 'Enter' || event.code === 'Space')) {
          event.target.click();
          event.preventDefault();
        }
      };

      const closeFocusCircuit = (firstFocusElem, lastFocusElem) => {
        if (lastFocusElem !== undefined) {
          firstFocusElem.onkeydown = (event) => {
            handleAnchorClick(firstFocusElem, event);
            if (event.shiftKey && event.keyCode === 9) { // shift and tab pressed
              $timeout(() => {
                lastFocusElem.focus();
                angular.element(lastFocusElem).attr('tabindex', 0);
              });
            }
          };
          lastFocusElem.onkeydown = (event) => {
            handleAnchorClick(lastFocusElem, event);
            if (!event.shiftKey && event.keyCode === 9) { // tab pressed
              $timeout(() => {
                firstFocusElem.focus();
                angular.element(firstFocusElem).attr('tabindex', 0);
              });
            }
          };
        }
      };

      const initFocus = () => {
        const target = document.getElementsByClassName('like-list-overlay')[0];
        const allChildren = getAllChildComponents(target);
        let lastFocusElem;
        let firstFocusElem;
        for (let i = -1, l = allChildren.length; ++i < l;) {
          const htmlElem = allChildren[i];
          const childTag = htmlElem.tagName.toLowerCase();
          const elem = angular.element(allChildren[i]);
          if (supportedHtmlTagsFocus.includes(childTag) && elem.is(':visible')) {
            elem.attr('tabindex', 0);
            lastFocusElem = htmlElem;
            if (firstFocusElem === undefined) {
              firstFocusElem = htmlElem;
            }
            if (childTag === 'a') {
              allChildren[i].onkeydown = (event) => {
                if (event.code === 'Enter' || event.code === 'Space') {
                  event.target.click();
                  event.preventDefault();
                }
              };
              htmlElem.onfocus = event => angular.element(event.target).addClass('active');
              htmlElem.onblur = event => angular.element(event.target).removeClass('active');
            }
          }
        }
        // adding empty element, so focus from last element won't be intercepted by browser url field
        angular.element(target).append('<div tabindex="0"></div>');
        closeFocusCircuit(firstFocusElem, lastFocusElem);
      };

      $timeout(() => initFocus(), 400);
    },
  };
};

likeListOverlay.$inject = ['LikeService', 'FeedStore', '$transitions', '$analytics', 'stateStore', '$timeout'];

export default likeListOverlay;
