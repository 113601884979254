import Freezeframe from 'freezeframe/dist/freezeframe.min';

const freezeFrameService = function ($compile, $rootScope) {
  function insertGif(statusId, url, key) {
    const $container = $(`#${key}-${statusId}`);
    const imgCrop = ['<div class="img-crop">', '</div>'];
    const linkScope = $rootScope.$new(true);
    const html = $compile(`${imgCrop[0]}<img id="status-id-${statusId}" src="${url}" alt="">${imgCrop[1]}`)(linkScope);
    // check if element is hidden and if html has already been compiled otherwise set the freezeframe
    if ($container.parents('.ng-hide').length > 0) {
      if ($(`#status-id-${statusId}`).length === 0) {
        $container.html(html);
      }
    } else {
      $container.html(html).promise().done(() => {
        new Freezeframe({
          selector: `#status-id-${statusId}`,
          trigger: 'click',
          overlay: true,
          responsive: false,
          warnings: false,
        });
      });
    }
  }

  return {
    insertGif,
  };
};

freezeFrameService.$inject = ['$compile', '$rootScope'];

export default freezeFrameService;
