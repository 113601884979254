class DashboardActivityCommunityTabCtrl {
  constructor(UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.currentUser = res.data;
        } else {
          ctrl.currentUser = null;
        }
      });
    };
  }
}

DashboardActivityCommunityTabCtrl.$inject = ['UserService'];

export default DashboardActivityCommunityTabCtrl;
