<!--
  ~ Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
  -->

<ngf-single-select *ngIf="!multiselect"
                   [clearDropdownEvent]="clearDropdownEvent"></ngf-single-select>
<ngf-multi-select *ngIf="multiselect"></ngf-multi-select>

<ngf-options-overlay [triggerRef]="elementRef" [disabled]="disabled" [readonly]="isReadonly">
  <ng-content select="ngf-options-container"></ng-content>
</ngf-options-overlay>