export default `
<main class="registration">
  <aside class="col-md-4 col-lg-4 hidden-sm hidden-xs" id="side_banner" bg-img="{{leftPanelBg}}" swap="assets/images/home/utest_welcome.jpg">
    <div class="brand">
      <a ui-sref="home" href="/"><img src="assets/images/uTestLogoNav.svg" alt="uTest"></a>
    </div>
    <div class="left-content">
      <div class="h1">
        Welcome to the world's largest community of testers.
      </div>
      <div class="h4">
        Work with some of the most influential brands on the planet and earn money while doing it.
      </div>
      <hr />
    </div>
  </aside>
  <alert-service></alert-service>
  <section class="col-md-8 col-lg-8 col-sm-12 col-xs-12" id="regs_container">
    <div class="container-fluid">
      <div class="clearfix sign-in-box">
        <div class="text-left hidden-md hidden-lg col-xs-6" id="uTest_icon">
        </div>
        <div class="text-right col-xs-6 col-sm-6 col-md-12 col-lg-12 sign-in"> <span>Already have an account?</span>
          &nbsp;<a href="/login?return_to={{$location.absUrl()}}" target="_self">Sign in</a>
        </div>
      </div>
      <div ui-view ></div>
    </div>
  </section>
</main>
`;
