const UrlExtensionService = function UrlExtensionService() {
  let isExtension;
  function checkExtension(url, extension) {
    // get text after last full stop of url
    const afterLastStop = url.split('.').pop();
    const numOfExtLetters = extension.length;
    const numOfUrlLetters = afterLastStop.length;
    // quick and dirty way to check if its a match
    if (afterLastStop === url || (numOfExtLetters === numOfUrlLetters && afterLastStop !== extension)) {
      isExtension = false;
      return isExtension;
    }
    // handles the care where the end of url has extra text ex: foo.com/cow.jpg+?=123
    const reg = new RegExp(`^(${extension}){0,${numOfExtLetters}}`, 'i');
    const result = afterLastStop.match(reg);
    isExtension = result[0] === extension;
    return isExtension;
  }
  function isGif(url) {
    this.checkExtension(url, 'gif');
    return isExtension;
  }
  return {
    checkExtension, isGif,
  };
};

export default UrlExtensionService;
