import {
  Component, Input, Output, EventEmitter,
} from '@angular/core';

@Component({
  selector: 'ut-accordion-group',
  templateUrl: './accordion-group.component.html',
  styleUrls: ['./accordion-group.scss'],
})
class AccordionGroupComponent {
  @Input() opened:boolean = false;
  @Input() header: string;

  /**
   * Emitted when user clicks on group titlebar
   * @type {EventEmitter<any>}
   */
  @Output() toggleGroup: EventEmitter<any> = new EventEmitter<any>();
}

export { AccordionGroupComponent };
