export default `
<div id="mainContent">
  <div class="grid w890">
    <h2>Followers</h2>
    <div class="user-list">
      <user-list-item user="user" ng-repeat="user in followers.feedItems" ></user-list-item>
    </div>
    <load-more load-callback="followers.loadMore()" is-loading="followers.isLoading" is-last-page="followers.isLastPage" fade-target="footer"></load-more>
  </div>
</div>
`;
