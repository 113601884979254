import template from './nav-header-chat.component.html';
import controller from './nav-header-chat.controller';

const navHeaderChat = {
  bindings: {
    currentUser: '<',
  },
  template,
  controller,
};

export default navHeaderChat;
