export default `
<div id="mainContent" class="new-article">
  <community-nav></community-nav>
  <uib-alert type="{{instruction.type}}" close="closeAlert($index)" ng-repeat="instruction in instructions" >
    <div class="container-fluid instruction-message" ng-bind-html="instruction.message" ></div>
  </uib-alert>
  <div class="container-fluid block" >
    <div ng-if="item.state != 'published'">
      <p class="autosave-status" ng-switch on="autosaveStatus">
        <span role="button" tabindex="0" tooltip-append-to-body="true" tooltip="{{autosaveToggleTooltip}}" aria-label="{{autosaveToggleTooltip}}" tooltip-placement="left" class="clickable" ng-click="toggleAutosave()" ng-keydown="$ctrl.toggleAutosaveByKeyDown($event)">
          <small ng-switch-when="success" class="text-success"><span class="icon-check"></span> Auto save completed</small>
          <small ng-switch-when="error" class="text-warning"><span class="icon-cancel"></span> {{autosaveError}}</small>
          <small ng-switch-when="disabled">Auto saving disabled</small>
          <small ng-switch-default>Auto saving enabled</small>
        </span>
      </p>
    </div>
    <h1>New Article</h1>
    <div class="input-row-invisible" ng-class="titleError">
      <label>Article Title</label><span> *Required</span>
      <input ng-model="item.title" placeholder="Article Title" type="text" ng-change="deactivateSave()" aria-required="true" aria-label="Article Title">
    </div>
    <div class="form-group input-row-invisible" ng-class="contentError">
      <label for="article-uteditor">Article Text</label><span> *Required</span>
      <ut-editor [id]="'article-uteditor'" [(value)]="item.content" [config]="'full'" text-area-label="Write your article here" text-required="true"></ut-editor>
    </div>
    <slug-input
      url-pre="https://www.utest.com/articles/"
      to-slug="item.title"
      the-slug="item.slug"
      has-error="slugError"
      on-slug-change="onSlugChange(theSlug)">
    </slug-input>
    <div class="editor-footer">
      <div class="editor-footer-content">
        <div class="editor-footer-content-item">
          <ui-select multiple limit="2" ng-model="tags.select" ng-change="updateSelectedTagsAriaLabel()">
            <ui-select-match placeholder="Add up to 2 tags">{{$item.title}}</ui-select-match>
            <ui-select-choices repeat="item in preselectedTags | filter: $select.search">
              <div ng-bind-html="item.title | highlight: $select.search"></div>
            </ui-select-choices>
          </ui-select>
        </div>

        <div ng-if="item.state != 'published'" class="editor-footer-buttons">
          <a role="button" tabindex="0" class="btn btn-save editor-footer-action-btn" ng-class="{'saved': saveBtnSaved}" ng-click="action('save')" ng-keydown="actionByKeydown($event, 'save')"></a>

          <div class="submit-delete-container">
            <a role="button" tabindex="0" class="btn btn-blue editor-footer-action-btn" ng-if="['admin', 'moderator'].indexOf(currentUser.user_type) === -1" ng-click="action('review')" ng-keydown="actionByKeydown($event, 'review')">Submit for Review</a>
            <a role="button" tabindex="0" class="btn btn-blue editor-footer-action-btn" ng-if="['admin', 'moderator'].indexOf(currentUser.user_type) !== -1" ng-click="action('publish')" ng-keydown="actionByKeydown($event, 'publish')">Publish</a>
            <delete-btn ng-if="item.id && item.current_user_permissions.destroy" on-delete="onDelete()"></delete-btn>
          </div>
        </div>

        <div ng-if="item.state == 'published'" class="editor-footer-buttons">
          <a role="button" tabindex="0" class="btn btn-blue editor-footer-action-btn" ng-click="action('save')" ng-keydown="actionByKeydown($event, 'save')">Save & Publish</a>
          <delete-btn ng-if="item.id && item.current_user_permissions.destroy" on-delete="onDelete()"></delete-btn>
        </div>
      </div>
    </div>
  </div>
</div>
`;
