import template from './navigate-prev-next.component.html';
import controller from './navigate-prev-next.controller';

const navigatePrevNext = {
  bindings: {
    item: '=',
  },
  transclude: true,
  template,
  controller,
};

export default navigatePrevNext;
