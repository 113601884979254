import angular from 'angular';
import {
  AfterViewInit, Component, Input, OnInit, Inject,
} from '@angular/core';
import {
  ABOUT_US_CONTENT,
  APPLICATION_SIZE_MODES, ICONS,
  UNAUTH_ABOUT_US_BG,
} from '../../../constants';

@Component({
  selector: 'ut-about-us',
  templateUrl: './about-us.component.html',
})
class AboutUsComponent implements OnInit, AfterViewInit { // @ts-ignore
  constructor(@Inject('CommunityManagersService') private communityManagersService: any, @Inject('featureFlags') private featureFlags: any) {}

  @Input('sectionToScroll') sectionToScroll: string;
  bgUrl: string;
  customOffsets = {
    mission: 200,
    stats: 90,
    default: 50,
  };

  images = ABOUT_US_CONTENT.images;
  learnEarnConnectContent = ABOUT_US_CONTENT.learnEarnConnectContent;
  stats = ABOUT_US_CONTENT.stats;
  faq = ABOUT_US_CONTENT.faq;
  icons = ICONS;
  history = ABOUT_US_CONTENT.history;
  employees = ABOUT_US_CONTENT.employees;

  ngOnInit() {
    this.communityManagersService.getCommunityManagers().then((res) => {
      this.employees = res.data;
    });

    this.calculateBgStyle();
    angular.element(window).on('resize', () => {
      this.calculateBgStyle();
    });
  }

  ngAfterViewInit() {
    let scrolled = false;
    if (this.sectionToScroll !== undefined) {
      const scrollSection = $(`#${this.sectionToScroll}`)[0];
      if (scrollSection !== undefined) {
        const offset = this.customOffsets[this.sectionToScroll] !== undefined ? this.customOffsets[this.sectionToScroll] : this.customOffsets.default;
        window.scrollTo(0, scrollSection.offsetTop - offset);
        scrolled = true;
      }
    }
    if (!scrolled) {
      window.scroll(0, 0);
    }
  }

  calculateBgStyle() {
    this.bgUrl = UNAUTH_ABOUT_US_BG.small;
    if (window.innerWidth > APPLICATION_SIZE_MODES.MEDIUM) {
      this.bgUrl = UNAUTH_ABOUT_US_BG.wide;
    }
  }
}

export { AboutUsComponent };
