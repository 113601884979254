import template from './dashboard-activity-invitations-tab.component.html';
import controller from './dashboard-activity-invitations-tab.controller';

const dashboardActivityInvitationsTab = {
  bindings: {
    invitations: '<',
    invitationsCount: '<',
  },
  template,
  controller,
};

export default dashboardActivityInvitationsTab;
