/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { DOCUMENT } from '@angular/common';
import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, Output, QueryList } from '@angular/core';
import { contentChildren$ } from '@ng-falcon/+utils/content-children$';
import { PrefixDirective } from '@ng-falcon/controls/input-container/prefix.directive';
import { SuffixDirective } from '@ng-falcon/controls/input-container/suffix.directive';
import { map, Observable } from 'rxjs';
import { FormFieldControl } from '../form-field/form-field-control';

@Component({
  selector: 'ngf-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: FormFieldControl, useExisting: InputContainerComponent }
  ]
})
export class InputContainerComponent implements FormFieldControl, AfterContentInit {

  @Input()
  infixOverflowHidden = true;

  @HostBinding('class.ngf-input-container--active')
  @Input()
  active = false;

  @HostBinding('class.ngf-input-container--error')
  @Input()
  hasError = false;

  @HostBinding('class.ngf-input-container--elevation')
  @Input()
  hasShadow = false;

  @HostBinding('attr.tabindex')
  tabindex: number | null = null;

  @Output()
  touched = new EventEmitter<void>();

  @ContentChildren(PrefixDirective)
  private prefix!: QueryList<PrefixDirective>;

  @ContentChildren(SuffixDirective)
  private suffix!: QueryList<SuffixDirective>;

  protected hasPrefix$: Observable<boolean>;
  protected hasSuffix$: Observable<boolean>;

  constructor(private cdr: ChangeDetectorRef,
              private elementRef: ElementRef<HTMLElement>,
              @Inject(DOCUMENT) private document: Document) {}

  ngAfterContentInit(): void {
    this.hasPrefix$ = contentChildren$(this.prefix).pipe(map(items => items.length > 0));
    this.hasSuffix$ = contentChildren$(this.suffix).pipe(map(items => items.length > 0));

    // if at least one child <input> is not disabled
    // then we want to make input-container focusable, but not reachable via "Tab" key,
    // therefor tabindex is set to negative value
    //
    // see: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/tabindex
    const hasAnyActiveInput = this.elementRef.nativeElement.querySelector('input:not(:disabled)') !== null;
    this.tabindex = hasAnyActiveInput ? -1 : null;
  }

  @HostListener('focusin', ['$event'])
  handleFocusIn(event: FocusEvent): void {
    const prevFocusedElement = event.relatedTarget;
    const hasFocusWithin = this.isChildElement(prevFocusedElement);

    if (hasFocusWithin) {
      // nothing to do
      return;
    }

    // if ngf-input-container has initial focused (instead of any child element)
    // then we want to autofocus fist active input
    if (this.document.activeElement === this.elementRef.nativeElement) {
      this.focusFirstUsableInput();
    }
  }

  @HostListener('focusout', ['$event'])
  handleFocusOut(event: FocusEvent): void {
    const focusedElement = event.relatedTarget;
    const focusLost = !this.isChildElement(focusedElement);

    if (focusLost) {
      this.touched.emit();
    }
  }

  setHasError(error: boolean): void {
    this.hasError = error;
    this.cdr.markForCheck();
  }

  private focusFirstUsableInput(): void {
    // if exists, prefer focusing non-readonly & non-disabled input
    const activeInput = this.elementRef.nativeElement.querySelector<HTMLInputElement>('input:not(:read-only)');
    if (activeInput !== null) {
      activeInput.focus();
      return;
    }

    // otherwise, try to find readonly input
    const readonlyInput = this.elementRef.nativeElement.querySelector<HTMLInputElement>('input[readonly]');
    if (readonlyInput !== null && InputContainerComponent.isSelectionRangeSupported(readonlyInput)) {
      // by default, focus will put cursor to the end of the input value
      // but for readonly input we want to keep cursor at beginning of the value
      // it's important because for values longer then width we
      readonlyInput.setSelectionRange(0, 0);
      readonlyInput.focus();
    }
  }

  private isChildElement(element: EventTarget | null): boolean {
    if (element === null) {
      return false;
    }

    return this.elementRef.nativeElement === element || this.elementRef.nativeElement.contains(element as Node);
  }

  private static isSelectionRangeSupported(element: HTMLInputElement): boolean {
    return element.selectionStart !== null;
  }
}
