import _ from 'lodash';
import angular from 'angular';
import { STATUS_POST_MAX_CHARS } from '../../../constants';

class CommunityStatusUpdateCtrl {
  constructor($element, $scope, AlertService, EmbedlyService, ServerMessages, StatusServivce, UploadService, $window, AccessibleFocusTrapService, AccessibleInertService) {
    const ctrl: ng.IController = this;
    ctrl.AlertService = AlertService;
    ctrl.EmbedlyService = EmbedlyService;
    ctrl.ServerMessages = ServerMessages;
    ctrl.StatusService = StatusServivce;
    ctrl.UploadService = UploadService;
    ctrl.AccessibleFocusTrapService = AccessibleFocusTrapService;
    ctrl.AccessibleInertService = AccessibleInertService;
    ctrl.STATUS_POST_MAX_CHARS = STATUS_POST_MAX_CHARS;

    ctrl.$doCheck = () => {
      const newRemainingChars = STATUS_POST_MAX_CHARS - _.get(ctrl.status, 'content.length', 0);
      if (newRemainingChars !== ctrl.remainingChars) {
        ctrl.remainingChars = newRemainingChars;
      }
    };

    ctrl.$onInit = () => {
      ctrl.status = {
        content: '',
      };

      ctrl.activePopover = null;
      ctrl.attachmentUrl = null;
      ctrl.remainingChars = STATUS_POST_MAX_CHARS;
      ctrl.posting = false;
      ctrl.fullScreenOpen = false;
      ctrl.isImageExpanded = false;
      ctrl.isVideoExpanded = false;

      ctrl.$element = $element;

      ctrl.dropzone = {
        onSuccess(file, res, dz) {
          ctrl.setAttachment({
            label: res.file_name,
            value: res.url,
            icon: 'insert_photo',
            type: 'upload',
            id: res.id,
          });
          ctrl.closePopover();
          dz.removeAllFiles(true);
          $scope.$apply();
        },
        onError(err, dz) {
          dz.element.focus();
          if (_.isString(err)) {
            AlertService.add(err, 'danger');
          } else {
            switch (err.xhr.status) {
              case 400:
                setTimeout(() => {
                  AlertService.add('File type not allowed', 'danger');
                }, 500);
                break;
              case 0:
              case 413:
                AlertService.add('File too large', 'danger');
                break;
              case 429:
                AlertService.add('You reached the maximum allowed uploads in an hour. Please try again later.', 'danger');
                break;
              default:
                AlertService.add('File couldn\'t be uploaded', 'danger');
                break;
            }
          }
          dz.removeAllFiles(true);
          $scope.$apply();
        },
      };

      ctrl.onResize = function () {
        if ($window.innerWidth >= 769) {
          ctrl.fullScreenOpen = false;
          $scope.$apply(); // lifecycle removes ctrl *very slowly* if not called explicitly
        }
      };

      ctrl.removeEventListeners = function () {
        angular.element($window).off('resize', ctrl.onResize);
      };

      angular.element($window).on('resize', ctrl.onResize);
      $scope.$on('closeModal', () => {
        ctrl.closePopover();
      });
    };
    ctrl.$postLink = () => {
      ctrl.AccessibleInertService.initializeInertService();
    };

    ctrl.setFocusToStatusMobile = () => {
      setTimeout(() => {
        const fullscreenStatusUpdate = $('.community-status-update-fullscreen');
        ctrl.AccessibleInertService.setAriaHidden(true);
        ctrl.AccessibleFocusTrapService.trapAndSetFocus(fullscreenStatusUpdate, $scope);
      });
    };

    ctrl.handleFullscreenClose = () => {
      ctrl.fullScreenOpen = false;
      ctrl.AccessibleInertService.setAriaHidden(false);
    };

    ctrl.$onDestroy = function () {
      ctrl.removeEventListeners();
    };

    ctrl.togglePopover = (event, attachmentType) => {
      event.preventDefault();

      if (attachmentType !== ctrl.activePopover) {
        const $popover = ctrl.fullScreenOpen ? ctrl.$element.find('.community-status-update-fullscreen-popover') : ctrl.$element.find('.community-status-update-popover');
        const $target = $(event.target);
        $popover.css('left', $target.position().left + ($target.outerWidth(true) / 2));
        ctrl.activePopover = attachmentType;
        const closeBtn = document.getElementsByClassName('community-status-update-popover__close')[0];
        setTimeout(() => {
          (<HTMLElement>closeBtn).focus();
        }, 10);
        ctrl.AccessibleInertService.setAriaHidden(true);
        ctrl.AccessibleFocusTrapService.trapAndSetFocus($popover, $scope);
        ctrl.isImageExpanded = (attachmentType === 'image');
        ctrl.isVideoExpanded = (attachmentType === 'video');
      } else {
        ctrl.closePopover();
      }
    };

    ctrl.closePopover = () => {
      const contentBtn = ctrl.activePopover === 'image' ? document.getElementsByClassName('community-status-update__image-button')[0] : document.getElementsByClassName('community-status-update__video-button')[0];
      ctrl.activePopover = null;
      ctrl.isImageExpanded = false;
      ctrl.isVideoExpanded = false;
      if (!ctrl.fullScreenOpen) {
        ctrl.AccessibleInertService.setAriaHidden(false);
      }
      $scope.$broadcast('removeEventListener');
      setTimeout(() => {
        (<HTMLElement>contentBtn).focus();
      }, 10);
    };

    ctrl.submitAttachment = () => {
      const attachmentType = ctrl.activePopover;
      ctrl.EmbedlyService.getEmbed(ctrl.attachmentUrl).then(({ data }) => {
        const supportedTypes = ['image', 'video', 'photo'];
        if (!supportedTypes.includes(data.type)) {
          ctrl.AlertService.add(`Please provide a valid ${attachmentType} url`, 'danger');
          return;
        }
        if (attachmentType === 'image') {
          ctrl.setAttachment({
            label: ctrl.attachmentUrl.substring(ctrl.attachmentUrl.lastIndexOf('/') + 1),
            value: ctrl.attachmentUrl,
            icon: 'insert_photo',
          });
        } else {
          ctrl.setAttachment({
            label: data.title,
            value: ctrl.attachmentUrl,
            icon: 'play_circle_filled',
          });
        }

        ctrl.attachmentUrl = null;
      }).catch((err) => {
        if (err.data && err.data.errors) {
          ctrl.AlertService.add(err.data.errors[0].code, 'danger');
        } else {
          ctrl.AlertService.add('An unexpected error occured. Please try a different file.', 'danger');
        }
      });

      ctrl.closePopover();
    };

    ctrl.setAttachment = (attachment) => {
      // If user had uploaded an attachment earlier, delete it
      if (_.get(ctrl.status, 'attachment.id', false)) {
        ctrl.UploadService.delete(ctrl.status.attachment.id);
      }

      ctrl.status.attachment = attachment;
    };

    ctrl.reset = () => {
      ctrl.status = { content: '' };
      ctrl.closePopover();
      ctrl.fullScreenOpen = false;
      ctrl.AccessibleInertService.setAriaHidden(false);
    };

    ctrl.submit = () => {
      ctrl.status.attached_url = _.get(ctrl.status, 'attachment.value', null);

      if (_.isEmpty(_.trim(ctrl.status.content)) && !ctrl.status.attached_url) {
        ctrl.AlertService.addDanger('Can\'t post empty status!');
        return;
      }

      ctrl.posting = true;
      ctrl.StatusService.create(ctrl.status.content, ctrl.status.attached_url).then((response) => {
        ctrl.onSubmit({ status: response.data });
        ctrl.reset();
        ctrl.AlertService.add('Status successfully posted');
      }, (response) => {
        ctrl.AlertService.addDanger(ctrl.ServerMessages.prettyMessage(response));
      }).finally(() => {
        ctrl.posting = false;
      });
    };
  }
}

CommunityStatusUpdateCtrl.$inject = ['$element', '$scope', 'AlertService', 'EmbedlyService', 'ServerMessages', 'StatusService', 'UploadService', '$window', 'AccessibleFocusTrapService', 'AccessibleInertService'];

export default CommunityStatusUpdateCtrl;
