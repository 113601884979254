import SignUpEmbedCtrl from '../embed/signup/signup.embed.controller';
import signUpEmbedTemplate from '../embed/signup/signup.embed.html';

const EmbedRouter = {
  route: (module) => {
    module
      .controller('SignUpEmbedCtrl', SignUpEmbedCtrl)
      .config(['$stateProvider', ($stateProvider) => {
        $stateProvider
          .state('embed', {
            url: '/embed',
            template: '<div ui-view ></div>',
          })
          .state('embed.signup', {
            url: '/signup',
            controller: 'SignUpEmbedCtrl',
            template: signUpEmbedTemplate,
            resolve: {
              title: [() => 'uTest - User SignUp'],
            },
          });
      }]);
  },
};

export default EmbedRouter;
