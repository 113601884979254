import template from './simple-registration-success-page.component.html';

const simpleRegistrationSuccess = {
  bindings: {
    customRegistration: '<',
    user: '<',
  },
  template,
};

export default simpleRegistrationSuccess;
