import _ from 'lodash';

class NavigatePrevNextCtrl {
  constructor($rootScope, stateStore, ProjectService) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      if ($rootScope.navigatePrevNext && $rootScope.navigatePrevNext[ctrl.item.type]) {
        const { feed } = $rootScope.navigatePrevNext[ctrl.item.type];
        const index = _.findIndex(feed.feedItems, o => (o as any).id === ctrl.item.id);

        if (index >= 0) {
          const prev = index - 1;
          const next = index + 1;

          if (prev > -1) {
            ctrl.prevItem = feed.feedItems[prev];
          }

          if (!_.isUndefined(feed.feedItems[next])) {
            ctrl.nextItem = feed.feedItems[next];
          } else if (!feed.isLastPage) {
            feed.loadMore()
              .then(() => {
                if (!_.isUndefined(feed.feedItems[next])) {
                  ctrl.nextItem = feed.feedItems[next];
                }
              });
          }
        }
      } else {
        if (ctrl.item.next) {
          ProjectService.find(ctrl.item.next.id)
            .then((res) => {
              ctrl.nextItem = res.data;
            });
        }
        if (ctrl.item.previous) {
          ProjectService.find(ctrl.item.previous.id)
            .then((res) => {
              ctrl.prevItem = res.data;
            });
        }
      }
    };

    ctrl.getHref = function (item) {
      if (item) {
        return stateStore(item);
      }
      return '#';
    };
  }
}

NavigatePrevNextCtrl.$inject = ['$rootScope', 'stateStore', 'ProjectService'];

export default NavigatePrevNextCtrl;
