class DashboardNewJoinerChecklistCtrl {
  constructor(ConfigService, UserService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ConfigService.config().then((res) => {
        ctrl.testerAppUrl = `${res.data.profile_url}`;
        ctrl.testerAppProfileUrl = `${ctrl.testerAppUrl}/account/my-profile/`;
        ctrl.paymentMethodUrl = `${ctrl.testerAppUrl}/account/payments`;
      });
      UserService.me().then((res) => {
        if (res !== null) {
          ctrl.hasConfirmedEmailAddress = res.data.has_confirmed_email;
          ctrl.hasPaymentMethod = res.data.has_payment_method;
          ctrl.hasViewedCommunityPage = res.data.has_viewed_community_page;
          ctrl.hasCompletedProfile = res.data.has_completed_profile;
          ctrl.hasAppliedToProject = res.data.has_applied_to_project;
          ctrl.hasPassedAcademyCourse = res.data.has_passed_academy;
        }
      });
    };
  }
}

DashboardNewJoinerChecklistCtrl.$inject = ['ConfigService', 'UserService'];

export default DashboardNewJoinerChecklistCtrl;
