class UnauthenticatedAssetsService {
  $http: ng.IHttpService;

  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  getBrands() {
    return this.$http.get('/api/v1/unauthenticated_assets/brand');
  }
}

UnauthenticatedAssetsService.$inject = ['$http'];

export default UnauthenticatedAssetsService;
