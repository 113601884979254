import _ from 'lodash';

// ngAutoFocus set the focus to the element on element rendering
// if {onlyFocusWhenEmpty: true} assigned to ng-auto-focus then it'll focus only when it's empty
const ngAutoFocus = function ($timeout) {
  return {
    restrict: 'A',
    scope: {
      ngAutoFocus: '=',
    },
    link(scope, element) {
      $timeout(() => {
        if (scope.ngAutoFocus && scope.ngAutoFocus.onlyFocusWhenEmpty) {
          if (_.isEmpty(element.val())) {
            element[0].focus();
          }
        } else {
          element[0].focus();
        }
      });
    },
  };
};

ngAutoFocus.$inject = ['$timeout'];

export default ngAutoFocus;
