export default `
<div class="activity-feed-wrapper">
  <div profile="user" activity='activity' ng-repeat="activity in likesStore.feedItems" class="profile-activity-feed-item activity-{{activity.type}}" on-finish-render="ngRepeatFinished" show-extra-content="true" >
    <div class="activity-feed-content">
      <div class="profile-thumb" style="background: url({{activity.user.avatar_url}});background-size:cover;"></div>
      <div class="activity-header">
        <span>You liked </span><a ng-href="{{stateStore(activity.item.commentable || activity.item)}}">{{cs.getCommentableLabel(activity.item.commentable || activity.item)}}</a>
      </div>
    </div>
    <div class="time-stamp">{{activity.date | relativeDate}}</div>
    <div class="content-extra">
      <div ng-bind-html='activity.item.content | uMarkdown | trustAsHtml'></div>
      <status-attachment ng-if="activity.item.type == 'status' && activity.item.attached_url" status="activity.item"></status-attachment>
    </div>
  </div>
  <load-more load-callback="likesStore.loadMore()" is-loading="likesStore.isLoading" is-last-page="likesStore.isLastPage" fade-target="footer"></load-more>
</div>
`;
