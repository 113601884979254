import template from './progress-step.component.html';
import controller from './progress-step.controller';

const progressStep = {
  bindings: {
    stepNumber: '=',
    numberOfSteps: '@',
  },
  template,
  controller,
};

export default progressStep;
