export default `
<div>
  <form class="form-group-box user-form" name="userForm" ng-class="{'invalid': (!userForm.$valid && userForm.$$submitted)}">
    <div class="step-intro">
      Almost done! Please create a password for your account. Then review and accept our terms of use, uTest Guidelines, and privacy & security policy to complete the setup process.
    </div>
    <div class="form-control-static"></div>
    <div class="row">
      <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div>
          <label for="password">Create your uTest password</label>
        </div>
        <input required type="password" name="password" id="password" placeholder="10-64 characters" ng-minlength="10" ng-maxlength="64" ng-model="registrationData.password"
               password-strength-meter ng-auto-focus="{onlyFocusWhenEmpty: true}" aria-describedby="passwordInstruction" aria-invalid="{{(userForm.$submitted || userForm.password.$touched) && !!userForm.password.$invalid}}">
        <i aria-hidden="true" class="validation-icon material-icons">{{userForm.password.$valid ? 'done': 'warning'}}</i>
        <div class="sr-only" id="passwordInstruction" aria-hidden="true">
          Password Instructions, Use 10 to 64 characters. Must include a lower-case letter, an upper-case letter, and a number. Must not include email address. For added security, we do not allow common passwords. Password is case sensitive. Avoid using the same password for multiple sites.
        </div>
      </div>
      <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div>
          <label for="confirmPassword">Confirm password</label>
        </div>
        <input required type="password" name="confirmPassword" id="confirmPassword" placeholder="10-64 characters" ng-minlength="10" ng-maxlength="64"
               validation-match="registrationData.password" ng-model="registrationData.confirmPassword" aria-invalid="{{(userForm.$submitted || userForm.confirmPassword.$touched) && !!userForm.confirmPassword.$invalid}}">
        <i aria-hidden="true" class="validation-icon material-icons">{{userForm.confirmPassword.$valid ? 'done': 'warning'}}</i>
        <span class="error-msg" ng-show="!userForm.confirmPassword.$valid" aria-live="polite">
          {{userForm.confirmPassword.$error.required ? 'Confirm Password is required' : 'Password mismatch'}}
        </span>
      </div>
    </div>
    <div class="row col-xs-12">
      <label class="input-check-box signup-consent signup-consent--highlight">
        <div class="signup-consent__text--highlight">
          STAY INFORMED! <em>(optional)</em>
        </div>
        <div>Please keep me up-to-date via email on the latest projects and updates from uTest. This is not required but highly recommended in order to receive info as it becomes available.</div>
        <input type="checkbox" name="newsletterOptIn" ng-model="registrationData.newsletterOptIn">
        <span class="checkmark signup-consent__checkbox signup-consent__checkbox--highlight"></span>
      </label>
    </div>
    <div class="row col-xs-12">
      <label class="input-check-box signup-consent">
        <input type="checkbox" name="termOfUse" id="termOfUse" ng-model="registrationData.termsOfUseConductCode" required>
        I have read and accept the <a href="/terms-and-conditions" ng-click="showTerms('terms-and-conditions'); $event.preventDefault();" target="_blank">uTest Terms of Use</a> and the <a href="/utest-guidelines" ng-click="showTerms('utest-guidelines'); $event.preventDefault();" target="_blank">uTest Guidelines</a>
        <span class="checkmark signup-consent__checkbox" ng-class="{error: userForm.termOfUse.$error.required}"></span>
        <span class="error-msg" ng-show="!userForm.termsOfUseConductCode.$valid" aria-live="polite">
          You must accept Terms of Use and uTest Guidelines to be able to continue
        </span>
      </label>
    </div>
    <div class="row col-xs-12">
      <label class="input-check-box signup-consent">
        <input type="checkbox" name="privacySetting" id="privacySetting" ng-model="registrationData.privacySetting" required>
        I have read and accept the <a href="/privacy-policy" ng-click="showTerms('privacy-policy'); $event.preventDefault();" target="_blank">Privacy & Security Policy</a>
        <span class="checkmark signup-consent__checkbox" ng-class="{error: userForm.privacySetting.$error.required}"></span>
        <span class="error-msg" ng-show="!userForm.privacySetting.$valid" aria-live="polite">
           You must accept Privacy &amp; Security Policy to be able to continue
        </span>
      </label>
    </div>
    <div vc-recaptcha
         ng-if="captcha_method === 'recaptcha'"
         key="recaptchaKey"
         required="false"
         size="invisible"
         on-create="setWidgetId(widgetId)"
         on-success="onCaptchaResponse(response)"
         on-expire="cbExpiration()">
    </div>
    <div class="form-control-static clear"></div>
    <div ng-if="captcha_method === 'utest'" class="row">
      <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <div>
          <label>Enter the letters from the image</label>
        </div>
        <input required name="utest_captcha" type="text" ng-model="utestCaptcha.response">
        <span class="error-msg" ng-show="!utestCaptcha.response.$valid" aria-live="polite">
          Captcha solution cannot be empty
        </span>
      </div>
      <div class="form-group col-xs-12 col-sm-12 col-md-6 col-lg-3 utest-captcha">
        <img src="{{utestCaptcha.image_url}}"><br>
        <span class="check-mark">
          <a href="#" ng-click="loadUtestCaptcha()">Reload</a>
        </span>
      </div>
    </div>
  </form>
  <div>
    <button class="btn btn-grey btn-rounded pull-left" ng-click="navigateToStep('devices');" ng-disabled="isSignupInProgress">
      <i class="material-icons" aria-hidden="true">chevron_left</i>
      <span>Back</span>
    </button>
    <div class="pull-right next-step">
      <button class="btn btn-blue" ng-click="submitForm(userForm);" id="laddaBtn">
        <span>Complete Setup</span>
        <i class="material-icons" ng-if="!isSignupInProgress" aria-hidden="true">chevron_right</i>
      </button>
    </div>
  </div>
</div>
`;
