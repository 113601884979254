import _ from 'lodash';
import ToolService from '../components/tool/tool.service';
import ToolCategoryService from '../components/tool/category/tool-category.service';
import ToolCategoryItem from '../components/tool/tool-category-item/tool-category-item.component';
import ToolFeedItem from '../components/tool/tool-feed-item/tool-feed-item.component';
import ToolCtrl from '../tool/home/tool.controller';
import toolTemplate from '../tool/home/tool.html';
import ToolCategoryCtrl from '../tool/category/tool-category.controller';
import toolCategoryTemplate from '../tool/category/tool-category.html';
import SingleToolCtrl from '../tool/single/single-tool.controller';
import singleToolTemplate from '../tool/single/single-tool.html';
import SubmitAToolCtrl from '../tool/submit/submit-a-tool.controller';
import submitAToolTemplate from '../tool/submit/submit-a-tool.html';
import UserResolver from '../authentication/user-resolver';

const ToolsRouter = {
  route: (module) => {
    module
      .service('ToolService', ToolService)
      .service('ToolCategoryService', ToolCategoryService)
      .component('toolCategoryItem', ToolCategoryItem)
      .component('toolFeedItem', ToolFeedItem)
      .controller('ToolCtrl', ToolCtrl)
      .controller('ToolCategoryCtrl', ToolCategoryCtrl)
      .controller('SingleToolCtrl', SingleToolCtrl)
      .controller('SubmitAToolCtrl', SubmitAToolCtrl);

    module.config(['$stateProvider', ($stateProvider) => {
      $stateProvider
        .state('tools', {
          url: '/tools',
          controller: ['$state', '$scope', ($state, $scope) => ($scope.$state = $state)],
          abstract: true,
          template: '<div ui-view="tools-index" ng-show="$state.includes(\'tool\')"></div><div ui-view="tools-show" ng-show="$state.includes(\'singletool\')" ></div><div ui-view="tools-category" ng-show="$state.includes(\'toolcategory\')" ></div>',
          parent: 'root',
        })
        .state('tool', {
          url: '',
          parent: 'tools',
          resolve: {
            tools: ['ToolService', 'FeedStore', function (ToolService, FeedStore) {
              // default tab is "Highest Rated"
              return new FeedStore(_.bind(ToolService.feed, ToolService), { sort: '-rating,-published_at' }).load();
            }],
            headerData: ['StaticValuesService', (StaticValuesService) => {
              const defaultData = {
                title: 'Software Testing Tool Reviews',
                description: 'Research hundreds of QA &amp; QE tools to see how they stack up. Add your own reviews or submit your favorite test tool for the community to discover and rate.',
                coverBg: {
                  small: 'assets/images/hero/tools-bg-xs.jpg',
                  large: 'assets/images/hero/tools-bg.jpg',
                },
              };
              return StaticValuesService.pageData('tools').then((response) => {
                response.data = _.defaultsDeep(response.data, defaultData);
                return response;
              }, _error => ({
                data: defaultData,
              }));
            }],
            coverBg: ['ImageDataService', 'headerData', (ImageDataService, headerData) => (headerData.data.coverBg.small === 'assets/images/hero/tools-bg-xs.jpg'
              ? ImageDataService.getData(headerData.data.coverBg.small) : headerData.data.coverBg.small)],
            title: [() => 'uTest - Software Testing Tools'],
          },
          views: {
            'tools-index': {
              template: toolTemplate,
              controller: 'ToolCtrl',
            },
          },
          sticky: true,
        })
        .state('toolcategory', {
          url: '/category/:categoryId',
          parent: 'tools',
          resolve: {
            tools: ['$transition$', 'ToolService', 'FeedStore', function ($transition$, ToolService, FeedStore) {
              // default tab is "Highest Rated"
              return new FeedStore(
                _.bind(ToolService.feed, ToolService),
                { sort: '-rating,-published_at', filter: { category_id: $transition$.params().categoryId } },
              ).load();
            }],
            toolCategory: ['$transition$', 'ToolCategoryService', function ($transition$, ToolCategoryService) {
              return ToolCategoryService.find($transition$.params().categoryId);
            }],
            title: ['toolCategory', toolCategory => `uTest Software Testing Tools - ${toolCategory.data.name}`],
          },
          views: {
            'tools-category': {
              template: toolCategoryTemplate,
              controller: 'ToolCategoryCtrl',
            },
          },
          sticky: true,
        })
        .state('singletool', {
          url: '/:toolId?comments?sort',
          parent: 'tools',
          resolve: {
            tool: ['ToolService', '$transition$', function (ToolService, $transition$) {
              return ToolService.find($transition$.params().toolId);
            }],
            title: ['tool', tool => `uTest Software Testing Tool - ${tool.data.name}`],
          },
          views: {
            'tools-show': {
              template: singleToolTemplate,
              controller: 'SingleToolCtrl',
            },
          },
          sticky: true,
        })
        .state('submitatool', {
          url: '/submit-a-tool',
          parent: 'root',
          template: submitAToolTemplate,
          controller: 'SubmitAToolCtrl',
          resolve: {
            user: UserResolver,
            title: [() => 'uTest - Submit a Tool'],
          },
        });
    }]);
  },
};

export default ToolsRouter;
