<footer class="why-utest__footer" (mouseup)="onMouseUp()"
        (mousedown)="onMouseDown($event)" (mousemove)="onMouseMove($event)">
  <div class="why-utest__footer-arrows">
    <img class="arrow__image" [src]="images.leftArrow" (click)="testerQuoteNavigateLeftClick()" alt=""/>
    <img class="arrow__image" [src]="images.rightArrow" (click)="testerQuoteNavigateRightClick()" alt=""/>
  </div>
  <div class="why-utest__footer-content">
    <img class="quotes__image" [src]="images.quotesIcon" alt=""/>
    <span class="why-utest__footer-content-desc" [innerHTML]="testerQuoteSelected.quote"></span>
    <img class="why-utest__footer-content-person-image" [src]="testerQuoteSelected.image" alt="{{ testerQuoteSelected.name }}"/>
    <span class="why-utest__footer-content-person-name" [innerHTML]="testerQuoteSelected.name"></span>
    <span class="why-utest__footer-content-person-location" [innerHTML]="testerQuoteSelected.address"></span>
    <div class="why-utest__footer-content-nav">
      <div [class]="testerQuoteSelected.id === 1 ? 'oval-bottom-selected' : 'oval-bottom'" (click)="testerQuoteNavigateClick(1)"></div>
      <div [class]="testerQuoteSelected.id === 2 ? 'oval-bottom-selected' : 'oval-bottom'" (click)="testerQuoteNavigateClick(2)"></div>
      <div [class]="testerQuoteSelected.id === 3 ? 'oval-bottom-selected' : 'oval-bottom'" (click)="testerQuoteNavigateClick(3)"></div>
      <div [class]="testerQuoteSelected.id === 4 ? 'oval-bottom-selected' : 'oval-bottom'" (click)="testerQuoteNavigateClick(4)"></div>
      <div [class]="testerQuoteSelected.id === 5 ? 'oval-bottom-selected' : 'oval-bottom'" (click)="testerQuoteNavigateClick(5)"></div>
    </div>
  </div>
</footer>
