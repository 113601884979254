import {
  Component,
  Inject,
  Input,
  OnInit,
} from '@angular/core';
import _ from 'lodash';
import communityFeedItemLikeListOverlay from '../../../../../components/community/feed/community-feed-item-like-list-overlay.component.html';

@Component({
  selector: 'ut-community-feed-item-like-count',
  templateUrl: './community-feed-item-like-count.component.html',
})

export class CommunityFeedItemLikeCountComponent implements OnInit {
  @Input() likeable: any;
  users: any;
  theModal: any;

  constructor(
    @Inject('$uibModal') private $uibModal: any,
    @Inject('LikeService') private LikeService: any,
    @Inject('FeedStore') private FeedStore: any,
  ) {
  }

  ngOnInit(): void {
    this.users = new this.FeedStore(_.bind(this.LikeService.likedUsers, this.LikeService, this.likeable));
  }

  showLikes() {
    this.users.load();
    this.theModal = this.$uibModal.open({
      animation: true,
      template: communityFeedItemLikeListOverlay,
      controller: 'ModalCtrl',
      windowClass: 'community-modal',
      resolve: {
        data: () => ({
          likedUsers: this.users,
          likeable: this.likeable,
          closeOverlay: () => {
            this.theModal.close();
          },
        }),
      },
    }).result.catch((resp) => {
      if (['cancel', 'backdrop click', 'escape key press'].indexOf(resp) === -1) throw resp;
    });
  }
}
