const UserAuthenticator = ['UserService', '$q', '$state', '$rootScope', 'ConfigService', (UserService, $q, $state, $rootScope, ConfigService) => {
  const deferred = $q.defer();
  ConfigService.config().then((config) => {
    $rootScope.csrfCookieName = config.data.csrf_cookie_name;
    $rootScope.cookieOptInCookieName = config.data.cookie_opt_in_cookie_name;
    $rootScope.cookieDomain = config.data.cookie_domain;
    UserService.me().then((result) => {
      if (result !== null) {
        deferred.resolve(result.data);
      } else {
        $state.go('fourohone', null, { location: false });
        deferred.reject(null);
      }
    });
  }, (error) => {
    $state.go('fourohone', null, { location: false });
    deferred.reject(error);
  });

  return deferred.promise;
}];

export default UserAuthenticator;
