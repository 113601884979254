import template from './community-feed-item.component.html';
import controller from './community-feed-item.controller';

const communityFeedItem = {
  bindings: {
    item: '<',
    user: '<',
  },
  template,
  controller,
};

export default communityFeedItem;
