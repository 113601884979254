/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */
import * as Ladda from 'ladda';

class SimpleRegistrationSetPasswordLinkExpiredCtrl {
  constructor($scope, UserService, AlertService, $location, $window) {
    const ctrl: ng.IController = this;

    ctrl.$onInit = function () {
      ctrl.spinnerBtn = Ladda.create(document.querySelector('#laddaBtn'));

      UserService.me().then((res) => {
        if (res !== null) {
          $window.location = '/login';
        }
      });

      const queryParams = $location.search();

      ctrl.userCode = queryParams.code;

      ctrl.submissionInProgress = false;
      ctrl.linkResent = false;
    };

    ctrl.sendEmailRequest = function () {
      if (!ctrl.submissionInProgress && !ctrl.linkResent) {
        ctrl.startSpinner();
        ctrl.resendSetPasswordEmail();
      }
    };

    ctrl.resendSetPasswordEmail = function () {
      return UserService.resendSetPasswordEmail(ctrl.userCode).then(() => {
        ctrl.stopSpinner();
        ctrl.linkResent = true;
        AlertService.addSuccess('Email sent. Check your email for a new link.');
      }, () => {
        ctrl.stopSpinner();
        AlertService.addDanger('Request failed. Please try again later.');
      });
    };

    ctrl.startSpinner = function () {
      ctrl.submissionInProgress = true;
      ctrl.spinnerBtn.start();
    };

    ctrl.stopSpinner = function () {
      ctrl.spinnerBtn.stop();
      ctrl.spinnerBtn.remove();
      ctrl.submissionInProgress = false;
    };
  }
}

SimpleRegistrationSetPasswordLinkExpiredCtrl.$inject = ['$scope', 'UserService', 'AlertService', '$location', '$window'];

export default SimpleRegistrationSetPasswordLinkExpiredCtrl;
