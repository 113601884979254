export default `
<div class="modal-header">
  <button type="button" class="close" ng-click="cancel()" aria-label="Close">
    <i class="icon-cancel"></i>
  </button>
  <h2 class="modal-title">Report Abuse</h2>
</div>
<div class="modal-body">
  <label for="flagReason" class="feed-item-flag-message">
    Why are you reporting this post?
  </label>
  <textarea id="flagReason" name="flag-reason" ng-model="flagReason" autofocus></textarea>
  <button class="community-cta__button unfilled" ng-click="cancel()">Cancel</button>
  <button class="community-cta__button" ng-click="submitFlag(flagReason)" ng-mouseup="cancel()">Submit</button>
</div>
`;
