export default `
<section ng-if="$ctrl.emailconfirmed" class="overlay overlay-scale open" >
  <div class="sign-up-form-container" >
    <h1>Email address confirmed.</h1>
    <p>
      Congratulations! Your email address has been confirmed.
    </p>
    <a href="/login" target="_self" class="btn btn-warning">Log in</a>
  </div>
</section>
`;
