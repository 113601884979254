/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { OptNode } from '../opt-node';

export abstract class Option<T = any> extends OptNode {
  get textContent(): string {
    return undefined;
  }

  readonly value: T;
}
