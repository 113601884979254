import _ from 'lodash';

class ActivityService {
  $http: ng.IHttpService;
  constructor($http: ng.IHttpService) {
    this.$http = $http;
  }

  feed(params) {
    params = _.defaults(params || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/activities', { params });
  }

  communityActivities(params) {
    params = _.defaults(params || {}, { page: 1, per_page: 10 });
    return this.$http.get('/api/v1/activities/community', { params });
  }

  projectActivities(params) {
    params = _.defaults(params || {}, { page: 1, per_page: 10 });
    return this.$http.get('/api/v1/activities/projects', { params });
  }

  unreadCount() {
    return this.$http.get('/api/v1/activities/unread_count');
  }

  hasUnread() {
    return this.$http.get('/api/v1/activities/has_unread');
  }

  clearAll() {
    return this.$http.put('/api/v1/activities/clear_all', '');
  }

  clearProjectActivities() {
    return this.$http.put('/api/v1/activities/clear_projects', '');
  }

  clearCommunityActivities() {
    return this.$http.put('/api/v1/activities/clear_community', '');
  }

  combine(items) {
    const toCombine = [];

    _.forEach(items, (item, key: number) => {
      if (item.type === 'comment_activity') {
        if (key > 0 && items[key - 1].type === 'at_mention_activity' && items[key - 1].item.id === item.item.id) {
          toCombine.push({ comment: key, mention: key - 1 });
        } else if (key + 1 < items.length && items[key + 1].type === 'at_mention_activity' && items[key + 1].item.id === item.item.id) {
          toCombine.push({ comment: key, mention: key + 1 });
        }
      }
    });

    _.forEach(toCombine, (combine) => {
      items[combine.mention].type = 'hidden';
      items[combine.comment].combine = 'mention';
    });

    return items;
  }
}

ActivityService.$inject = ['$http'];

export default ActivityService;
