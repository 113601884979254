import angular from 'angular';

class CommunityFeaturedCtrl {
  constructor($window: ng.IWindowService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      const elem = document.getElementById('communityFeaturedList');

      ctrl.onResize = function () {
        if (elem.offsetWidth === elem.scrollWidth) {
          disableLeftButton();
          disableRightButton();
        } else {
          if (elem.scrollLeft <= 0) {
            disableLeftButton();
          } else {
            enableLeftButton();
          }
          if (elem.scrollLeft + elem.offsetWidth >= elem.scrollWidth) {
            disableRightButton();
          } else {
            enableRightButton();
          }
        }
      };

      ctrl.removeEventListeners = function () {
        angular.element($window).off('resize', ctrl.onResize);
      };

      angular.element($window).on('resize', ctrl.onResize);
    };

    ctrl.$onChanges = (changes) => {
      const elem = document.getElementById('communityFeaturedList');

      if (changes.feed && elem.offsetWidth === elem.scrollWidth) {
        disableLeftButton();
        disableRightButton();
      } else if (changes.feed && elem.scrollLeft + elem.clientWidth < elem.scrollWidth) {
        disableLeftButton();
        enableRightButton();
      } else if (changes.feed && elem.scrollLeft - elem.clientWidth <= 0) {
        enableLeftButton();
        disableRightButton();
      }
    };

    ctrl.$onDestroy = function () {
      ctrl.removeEventListeners();
    };

    ctrl.scrollLeft = () => {
      const elem = document.getElementById('communityFeaturedList');
      elem.scrollLeft -= elem.clientWidth;

      enableRightButton();

      if (elem.scrollLeft - elem.clientWidth <= 0) {
        disableLeftButton();
      } else {
        enableLeftButton();
      }
    };

    ctrl.scrollRight = () => {
      const elem = document.getElementById('communityFeaturedList');
      elem.scrollLeft += elem.clientWidth;

      enableLeftButton();

      if (elem.scrollLeft + elem.clientWidth + elem.offsetWidth >= elem.scrollWidth) {
        disableRightButton();
      } else {
        enableRightButton();
      }
    };

    ctrl.skipFeatured = (e) => {
      if ([13, 32].includes(e.keyCode)) {
        if ($window.innerWidth >= 769) {
          document.getElementById('communityStatusUpdateInput').focus();
        } else {
          $('community-status-update').attr('tabIndex', -1);
          $('community-status-update').focus();
        }
      }
    };

    function enableLeftButton() {
      addLeftFade();
      const leftButton = document.getElementById('communityFeaturedLeftScrollButton');
      leftButton.classList.remove('community-featured-list-scroll__button--disabled');
    }

    function disableLeftButton() {
      removeLeftFade();
      const leftButton = document.getElementById('communityFeaturedLeftScrollButton');
      leftButton.classList.add('community-featured-list-scroll__button--disabled');
    }

    function enableRightButton() {
      addRightFade();
      const rightButton = document.getElementById('communityFeaturedRightScrollButton');
      rightButton.classList.remove('community-featured-list-scroll__button--disabled');
    }

    function disableRightButton() {
      removeRightFade();
      const rightButton = document.getElementById('communityFeaturedRightScrollButton');
      rightButton.classList.add('community-featured-list-scroll__button--disabled');
    }

    function addLeftFade() {
      document.getElementById('communityFeaturedFadeLeft').classList.add('community-featured-fade--left');
    }

    function removeLeftFade() {
      document.getElementById('communityFeaturedFadeLeft').classList.remove('community-featured-fade--left');
    }

    function addRightFade() {
      document.getElementById('communityFeaturedFadeRight').classList.add('community-featured-fade--right');
    }

    function removeRightFade() {
      document.getElementById('communityFeaturedFadeRight').classList.remove('community-featured-fade--right');
    }
  }
}

CommunityFeaturedCtrl.$inject = ['$window'];

export default CommunityFeaturedCtrl;
