export default `
<a href="{{$ctrl.testerAppUrl}}">
  <div class="dashboard-activity-project-list-footer">
    <div class="dashboard-activity-project-list-footer-more">
      <div class="dashboard-activity-project-list-footer-more__stat">
        <i class="material-icons">more_horiz</i>
      </div>
      <div class="dashboard-activity-project-list-footer-more__stat">
        (+{{($ctrl.itemCount - 5)}} more {{$ctrl.itemType}}{{($ctrl.itemCount - 5) === 1 ? '' : 's'}})
      </div>
    </div>
    <div class="dashboard-activity-project-list-footer-view-all">
      View All {{$ctrl.itemType}}s
    </div>
  </div>
</a>
`;
