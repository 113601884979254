class RecommendationsCtrl {
  constructor($analytics) {
    const ctrl: ng.IController = this;
    ctrl.track = (item) => {
      $analytics.eventTrack('click', { category: 'Article Recommendation', label: item.slug });
    };
  }
}

RecommendationsCtrl.$inject = ['$analytics'];

export default RecommendationsCtrl;
