import template from './flag.html';

const flag = function (FlagService, $analytics, AlertService) {
  return {
    restrict: 'E',
    replace: true,
    template,
    scope: {
      flagable: '=',
      flagId: '=?',
    },
    link(scope) {
      scope.flagreason = {};
      scope.flagContainerId = `flag-${scope.flagable.type}-${scope.flagable.id}`;

      if (scope.flagId) {
        scope.flagContainerId += `-${scope.flagId}`;
      }

      const trackFlagging = (options) => {
        $analytics.eventTrack('Flagged', { category: 'Flag', label: options.type });
      };

      scope.flagIt = function () {
        if (!scope.flagreason.content) {
          AlertService.addDanger('Please fill out the reason');
        } else {
          FlagService.flag(scope.flagable.type, scope.flagable.id, scope.flagreason.content)
            .then(() => {
              scope.flagable.flagged_by_current_user = true;
              scope.$broadcast('toggleSlideUp');
              trackFlagging({ type: scope.flagable.type });
              AlertService.add('Report sent successfully!');
            });
        }
      };
    },
  };
};

flag.$inject = ['FlagService', '$analytics', 'AlertService'];
export default flag;
