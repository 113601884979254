import _ from 'lodash';

class LandingPageController {
  constructor($scope, page) {
    $scope.page = page.data;
    $scope.externalSignupLink = $scope.page.signup_link && !_.isEmpty(_.trim($scope.page.signup_link));
  }
}

LandingPageController.$inject = ['$scope', 'page'];
export default LandingPageController;
