/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { OptNode } from './opt-node';
import { OptionsRootHolder } from './options-root-holder';

@Injectable()
export class OptionHoverService {
  private readonly hovered = new BehaviorSubject<OptNode | null>(null);

  constructor(private root: OptionsRootHolder) {}

  readonly hovered$: Observable<OptNode | null> = this.hovered.pipe(distinctUntilChanged());

  getValue(): OptNode | null {
    return this.hovered.value;
  }

  isHovered$(node: OptNode): Observable<boolean> {
    return this.hovered.pipe(map(hovered => hovered === node));
  }

  isAnyHovered$(nodes: OptNode[]): Observable<boolean> {
    return this.hovered.pipe(map(hovered => nodes.some(node => hovered === node)));
  }

  hover(node: OptNode | null) {
    if (node !== this.hovered.value) {
      this.hovered.next(node);

      if (node) {
        this.scrollToNodeIfRequired(node);
      }
    }
  }

  next() {
    const hovered = this.hovered.value;

    if (hovered) {
      const children = hovered.parent
        ? hovered.parent.children.toArray()
        : this.root.get().children;

      const idx = children.indexOf(hovered);
      if (idx + 1 < children.length) {
        const next = children[idx + 1];
        this.hover(next);
      }
    } else {
      this.hover(this.root.get().children[0]);
    }
  }

  prev() {
    const hovered = this.hovered.value;

    if (hovered) {
      const children = hovered.parent
        ? hovered.parent.children.toArray()
        : this.root.get().children;

      const idx = children.indexOf(hovered);
      if (idx > 0) {
        const prev = children[idx - 1];
        this.hover(prev);
      }
    }
  }

  right() {
    const hovered = this.hovered.value;

    if (hovered && hovered.children) {
      const firstChild = hovered.children.find((_, index) => index === 0);
      this.hover(firstChild);
    }
  }

  left() {
    const hovered = this.hovered.value;

    if (hovered && hovered.parent) {
      this.hover(hovered.parent);
    }
  }

  private scrollToNodeIfRequired(node: OptNode) {
    const nodeEl = node.elementRef.nativeElement;
    const scrollParentEl = this.getScrollParent(nodeEl);

    if (!scrollParentEl) {
      // if node is not inside scrollable parent, then do nothing
      return;
    }

    const nodeRect = nodeEl.getBoundingClientRect();
    const scrollParentRect = scrollParentEl.getBoundingClientRect();

    if (nodeRect.top < scrollParentRect.top) {
      scrollParentEl.scrollTop += nodeRect.top - scrollParentRect.top;
    } else if (nodeRect.bottom > scrollParentRect.bottom) {
      scrollParentEl.scrollTop += nodeRect.bottom - scrollParentRect.bottom;
    }
  }

  private getScrollParent(element: HTMLElement): HTMLElement | null {
    let parent = element.parentElement;
    while (parent && parent.scrollHeight === parent.offsetHeight) {
      parent = parent.parentElement;
    }

    return parent;
  }
}
