import template from './stat-date.component.html';

const statDate = {
  bindings: {
    date: '=',
    label: '@',
    version: '@',
  },
  template,
};

export default statDate;
