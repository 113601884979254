import _ from 'lodash';

const PaginatorService = () => {
  const makePaginationData = (headers) => {
    const currentPage = parseInt(headers('X-Page'));
    const totalPages = parseInt(headers('X-Total-Pages'));
    const lastPage = currentPage === totalPages ? null : totalPages;
    const firstPage = currentPage === 1 ? null : 1;

    const visiblePagesCount = 10;

    const visiblePagesStart = currentPage - Math.floor(visiblePagesCount / 2);
    const visiblePagesEnd = currentPage + Math.ceil(visiblePagesCount / 2);

    const rightOffset = visiblePagesStart < 0 ? visiblePagesStart * -1 : 0;
    const leftOffset = visiblePagesEnd > totalPages ? totalPages - visiblePagesEnd : 0;

    const visiblePages = [visiblePagesStart + leftOffset, visiblePagesEnd + rightOffset];

    return {
      nextPage: parseInt(headers('X-Next-Page')),
      previousPage: parseInt(headers('X-Prev-Page')),
      totalItems: parseInt(headers('X-Total')),
      perPage: parseInt(headers('X-Per-Page')),
      totalPages,
      currentPage,
      lastPage,
      firstPage,
      paginationNumbers: _.range(_.max([1, visiblePages[0]]), _.min([totalPages + 1, visiblePages[1]])),
    };
  };

  return {
    makePaginationData,
  };
};

export default PaginatorService;
