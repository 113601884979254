export default `
<div class="col-md-4 col-sm-6" ng-if="($ctrl.devices.length > 0) && !$ctrl.hideCard">
  <div class="dashboard-card">
    <h2 class="dashboard-card__heading">
      Top requested devices in your area
    </h2>
    <div class="dashboard-card-list-fade dashboard-card-list-fade--top"></div>
    <ol class="dashboard-card-list dashboard-card-requested-devices__items">
      <li ng-repeat="device in $ctrl.devices" class="dashboard-card-requested-devices__item">
        <div class="dashboard-card-list-item-icon"><i class="material-icons dashboard-card-list-item-icon-image mobile-device"></i></div>
        <div class="dashboard-card-list-item-description dashboard-card-list-item-description--primary">{{ device.device_name }}</div>
      </li>
    </ol>
    <div class="dashboard-card-list-fade dashboard-card-list-fade--bottom"></div>
    <div class="dashboard-card-requested-devices__feedback" ng-if="!$ctrl.currentUserHasSentFeedback">
      <button class="dashboard-card-requested-devices__feedback-button" ng-click="$ctrl.helpful()">Helpful?</button>
      <button class="dashboard-card-requested-devices__feedback-button" ng-click="$ctrl.notHelpful()">Not helpful?</button>
    </div>
  </div>
</div>
`;
