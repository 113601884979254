import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ArticleFeedItemComponent } from './article-feed-item/article-feed-item.component';
import { CommunityModule } from '../common/community/community.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    CommunityModule,
    SharedModule,
    UIRouterModule,
  ],
  declarations: [
    ArticleFeedItemComponent,
  ],
  exports: [
    ArticleFeedItemComponent,
  ],
})
class ArticleModule {}

export { ArticleModule };
