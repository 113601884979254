export default `
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 request-set-password-page">
    <div>
        <h3 class="form-header-text set-password-expired-header">Your Password Link Has Expired</h3>
    </div>

    <p>Please request a new link to set your password and check your email.</p>
    <alert-service></alert-service>
    <div ng-disabled="$ctrl.submissionInProgress" class="pull-left submit-button simple-registration-btn-container set-password-container">
      <a ng-disabled="$ctrl.submissionInProgress || $ctrl.linkResent" href="#"
      aria-label="Request a New Password Link" class="simple-registration-btn request-set-password-btn" ng-click="$ctrl.sendEmailRequest();" id="laddaBtn" role="button">
        <span class="request-set-password-btn-text">Request a New Password Link</span>
      </a>
    </div>
</div>
`;
