export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const SET_CURRENT_USER_UNREAD_ACTIVITIES_COUNT = 'SET_CURRENT_USER_UNREAD_ACTIVITIES_COUNT';

export function setCurrentUser(user) {
  return { type: SET_CURRENT_USER, user };
}

export function setCurrentUserUnreadActivitesCount(activitiesCount) {
  return { type: SET_CURRENT_USER_UNREAD_ACTIVITIES_COUNT, activitiesCount };
}
