const UserResolver = ['UserService', '$q', '$rootScope', 'ConfigService', (UserService, $q, $rootScope, ConfigService) => {
  const deferred = $q.defer();
  ConfigService.config().then((config) => {
    $rootScope.csrfCookieName = config.data.csrf_cookie_name;
    $rootScope.cookieOptInCookieName = config.data.cookie_opt_in_cookie_name;
    $rootScope.cookieDomain = config.data.cookie_domain;
    UserService.me().then((result) => {
      if (result !== null) {
        deferred.resolve(result.data);
      } else {
        deferred.resolve(null);
      }
    });
  }, () => {
    deferred.resolve(null);
  });

  return deferred.promise;
}];

export default UserResolver;
