import template from './hide-btn.html';

const hideBtn = function (ArticleService, CommentService, StatusService, DiscussionService, AlertService, $window) {
  return {
    restrict: 'E',
    replace: true,
    template,
    scope: {
      item: '=',
      onHide: '&',
    },
    link(scope) {
      const successCallback = function (res) {
        if (res.data.state === 'hidden') {
          scope.onHide(res.data);
          AlertService.add('Successfully unpublished');
        } else {
          AlertService.add('Successfully republished');
        }
        scope.item = res.data;
      };

      const errorCallback = function () {
        AlertService.add('There was an error', 'danger');
      };

      scope.go = function () {
        const confirmMsg = scope.item.state === 'hidden' ? 'Republish this item?' : 'Confirm hiding this item!';
        if ($window.confirm(confirmMsg)) {
          switch (scope.item.type) {
            case 'article':
              ArticleService.toggleHide(scope.item).then(successCallback, errorCallback);
              break;
            case 'status':
              StatusService.toggleHide(scope.item).then(successCallback, errorCallback);
              break;
            case 'comment':
              CommentService.toggleHide(scope.item).then(successCallback, errorCallback);
              break;
            case 'topic':
              DiscussionService.toggleHide(scope.item).then(successCallback, errorCallback);
              break;
            default:
          }
        }
        return false;
      };
    },
  };
};

hideBtn.$inject = ['ArticleService', 'CommentService', 'StatusService', 'DiscussionService', 'AlertService', '$window'];

export default hideBtn;
