import moment from 'moment';

const timeLeft = function () {
  return function (input, dateLater, hidePrior) {
    let dateEarlier = input;
    if (typeof dateEarlier === 'number') {
      dateEarlier = new Date(dateEarlier);
    }
    if (!dateLater) {
      dateLater = new Date();
    }

    // This allows for the case when the cycle end date has past but the cron has not yet run. Returning 'in seconds'
    // as the old tester app also did.
    // Requires hidePrior param to be set to true, otherwise will return prior time ex. 'XX days ago'
    moment.relativeTimeThreshold('m', 59);
    moment.relativeTimeThreshold('h', 48);
    if (hidePrior && moment(dateEarlier).isBefore(dateLater)) {
      return 'in seconds';
    }
    return moment(dateEarlier).from(dateLater);
  };
};

timeLeft.$inject = ['$filter'];

export default timeLeft;
