<!--
  ~ Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
  -->

<div class="wrapper">
  <div *ngIf="hasPrefix$ | async" class="prefix">
    <ng-content select="[ngfPrefix]"></ng-content>
  </div>

  <div class="infix" [class.infix-overflow-hidden]="infixOverflowHidden">
    <ng-content></ng-content>
  </div>

  <div *ngIf="hasSuffix$ | async" class="suffix">
    <ng-content select="[ngfSuffix]"></ng-content>
  </div>
</div>
