import template from './comment-section.component.html';
import controller from './comment-section.controller';

const commentSection = {
  bindings: {
    service: '<',
    commentable: '<',
    commentBtnLabel: '@',
  },
  template,
  controller,
};

export default commentSection;
