import _ from 'lodash';
import { StateService } from '@uirouter/core';

class StatusService {
  $http: ng.IHttpService;
  $state: StateService;

  constructor($state, $http) {
    this.$state = $state;
    this.$http = $http;
  }

  find(id) {
    return this.$http.get(`/api/v1/statuses/${id}`);
  }

  create(content, attachedUrl) {
    return this.$http.post('/api/v1/statuses', { content, attached_url: attachedUrl });
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get('/api/v1/statuses', { params: parameters });
  }

  update(status) {
    return this.$http.put(`/api/v1/statuses/${status.id}`, status);
  }

  like(id) {
    return this.$http.post(`/api/v1/statuses/${id}/like`, '');
  }

  unlike(id) {
    return this.$http.delete(`/api/v1/statuses/${id}/like`);
  }

  flag(id, reason) {
    return this.$http.post(`/api/v1/statuses/${id}/flag`, { reason });
  }

  delete(id) {
    return this.$http.delete(`/api/v1/statuses/${id}`);
  }

  hide(id) {
    return this.$http.post(`/api/v1/statuses/${id}/hide`, '');
  }

  publish(id) {
    return this.$http.post(`/api/v1/statuses/${id}/publish`, '');
  }

  toggleHide(status) {
    if (status.state === 'hidden') {
      return this.publish(status.id);
    }
    return this.hide(status.id);
  }

  commentsPage(statusId, page, sort, perPage) {
    const params: any = {
      page: !page ? 1 : page,
      sort: sort || 'threaded',
    };

    if (perPage) {
      params.per_page = perPage;
    }

    return this.$http.get(`/api/v1/statuses/${statusId}/comments/flat`, { params });
  }

  postComment(id, comment) {
    return this.$http.post(`/api/v1/statuses/${id}/comments`, comment);
  }

  postCommentAndReturnCommentable(id, comment) {
    return this.$http.post(`/api/v1/statuses/${id}/comments`, Object.assign(comment, { return_commentable: true }));
  }

  likedUsers(id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });
    return this.$http.get(`/api/v1/statuses/${id}/liked_users`, { params: parameters });
  }

  subscribe(id) {
    return this.$http.post(`/api/v1/statuses/${id}/subscribe`, '');
  }

  unsubscribe(id) {
    return this.$http.delete(`/api/v1/statuses/${id}/subscribe`);
  }
}

StatusService.$inject = ['$state', '$http'];

export default StatusService;
