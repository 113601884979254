export default `
<div class="modal-header">
  <button type="button" class="close" ng-click="cancel()" autofocus aria-label="Close">
    <i class="icon-cancel" aria-hidden="true"></i>
  </button>
  <h2 ng-bind="likeable.likes.count|pluralize:'Like'" class="modal-title" aria-label="Community like list, {{likeable.likes.count|pluralize:'Like'}}"></h2>
</div>
  <div class="modal-body">
    <div class="user-list" >
      <community-user-list-item user="user" ng-repeat="user in likedUsers.feedItems" close="cancel"></community-user-list-item>
    </div>
    <view-more ng-if="likedUsers.feedItems.length != 0" load-callback="likedUsers.loadMore()" is-loading="likedUsers.isLoading" is-last-page="likedUsers.isLastPage"></view-more>
  </div>
</div>
`;
