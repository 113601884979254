<div class="block container unauth-section-margin">
  <div class="row">
    <div class="col-sm-4 col-xs-12 scrollable-header">
    <h2>{{ header }}</h2>
      <p>{{ text }}</p>
      <input type="image" src="{{disabledLeft ? icons.arrowLeftDisabled : icons.arrowLeft}}" class="hidden-xs" (click)="scrollLeft()"/>
      <input type="image" src="{{disabledRight ? icons.arrowRightDisabled : icons.arrowRight}}" class="hidden-xs margin-5" (click)="scrollRight()"/>
  </div>
    <div #scrollableContent class="col-sm-8 col-sx-12 scrollable-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
