class CookieAlertCtrl {
  constructor(CookieAgreementService, featureFlags, $location, $rootScope, $transitions) {
    const ctrl: ng.IController = this;
    const updateStatus = () => {
      ctrl.cookieAlertOpen = CookieAgreementService.isPending();
      ctrl.resetCookieBanner = ($location.path() === '/privacy-policy') && !ctrl.cookieAlertOpen;
      ctrl.statusText = CookieAgreementService.hasAgreed() ? 'ACCEPTED' : 'DECLINED';
      $rootScope.$broadcast('adjust-nav-padding');
    };

    ctrl.$onInit = function () {
      updateStatus();
    };

    ctrl.agree = function () {
      CookieAgreementService.agree();
      updateStatus();
    };

    ctrl.decline = function () {
      CookieAgreementService.disagree();
      updateStatus();
    };

    ctrl.reset = function () {
      CookieAgreementService.reset();
      updateStatus();
    };

    $transitions.onSuccess({}, () => {
      updateStatus();
    });
  }
}

CookieAlertCtrl.$inject = ['CookieAgreementService', 'featureFlags', '$location', '$rootScope', '$transitions'];

export default CookieAlertCtrl;
