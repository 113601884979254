import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';
import { TagBtnComponent } from './tag-btn/tag-btn.component';

@NgModule({
  imports: [
    CommonModule,
    UIRouterUpgradeModule,
  ],
  declarations: [
    TagBtnComponent,
  ],
  exports: [
    TagBtnComponent,
  ],
})
export class CommonComponentsModule {}
