export default `
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
  <div ng-if="!$ctrl.customRegistration">
    <div>
        <h3 class="form-header-text">Thank You</h3>
    </div>
    <p>You've created a uTest account and configured a payment method.</p>

    <p>Please follow the instructions in your email to login.
    From there you can manage payments and explore additional project opportunities.</p>

    <div class="simple-registration-image-container">
        <img class="utest-registration-finished-logo" src="/assets/images/simple-registration-thank-you.png" alt="">
    </div>
  </div>

  <div ng-if="$ctrl.customRegistration">
    <div class="sign-up-form-container">
      <div class="step-header"><h1 class="step-title"><span class="sub-title">Almost there!</span></h1></div>
    </div>

    <div class="user-form registration-intro">
      <p>Congratulations! Your account has been created with <b>tester ID {{$ctrl.user.testerId}}</b> and you are almost done. Next steps:</p>
      <ul>
        <li>Check out the instructions below</li>
        <li>Complete the form on this page asking about some additional details to see if you qualify.</li>
        <li>And finally confirm your email address to start getting test cycle invitations.</li>
      </ul>
    </div>
    <div ng-if="$ctrl.customRegistration.final_page">
      <article ng-bind-html="$ctrl.customRegistration.final_page | uMarkdown | trustAsHtml"></article>
    </div>
    <div ng-if="$ctrl.customRegistration.embedded_form">
      <div ng-bind-html="$ctrl.customRegistration.embedded_form | trustAsHtml"></div>
    </div>
  </div>
</div>
`;
