import { StateService } from '@uirouter/core';

class NavSidebarProjectsCtrl {
  constructor($state: StateService, ConfigService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = function () {
      ctrl.isExpanded = false;
      ctrl.state = $state;

      ConfigService.config().then((res) => {
        ctrl.testerAppUrl = res.data.profile_url;
        ctrl.testCyclesUrl = `${ctrl.testerAppUrl}/testcycles`;
        ctrl.issuesUrl = `${ctrl.testerAppUrl}/issues`;
        ctrl.testCasesUrl = `${ctrl.testerAppUrl}/testcases`;
      });

      ctrl.projectBoardSelected = () => ['project', 'singleproject', 'projectlist'].includes($state.current.name);
    };
  }
}

NavSidebarProjectsCtrl.$inject = ['$state', 'ConfigService'];

export default NavSidebarProjectsCtrl;
