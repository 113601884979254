export default `
<div class="community-status-update">
  <div class="community-status-update-fullscreen" ng-if="$ctrl.fullScreenOpen" role="dialog">
    <button class="community-status-update-fullscreen__cancel" ng-click="$ctrl.handleFullscreenClose()" aria-hidden="{{$ctrl.activePopover !== null}}">Cancel</button>
    <button class="community-status-update-fullscreen__submit" ng-click="$ctrl.submit()" aria-hidden="{{$ctrl.activePopover !== null}}">Share status</button>

    <div class="community-status-update-fullscreen__form">
      <textarea suggestion-typeahead typeahead-template-url="app/components/status/suggestion-typeahead.html" typeahead-on-select="replaceSuggestion($item)" typeahead-loading="typeaheadLoading" typeahead="user for user in loadSuggestions($viewValue)" class="community-status-update-fullscreen__input" maxlength="{{$ctrl.STATUS_POST_MAX_CHARS}}" placeholder="Write your status update here" ng-model="$ctrl.status.content" ng-trim="false" aria-label="Write your status update here" id="fullscreenStatusInput" aria-hidden="{{$ctrl.activePopover !== null}}"></textarea>
      <div class="community-status-update-fullscreen__attachment-chooser">
        <span class="community-status-update-attachment" ng-if="$ctrl.status.attachment">
          <i class="material-icons">{{$ctrl.status.attachment.icon}}</i>
          <span class="community-status-update-attachment__label">{{$ctrl.status.attachment.label}}</span>
          <button class="community-status-update-attachment__remove material-icons" ng-click="$ctrl.setAttachment(null)" aria-label="Cancel attachment {{$ctrl.status.attachment.label}}">close</button>
        </span>

        <div style="position: relative; display: flex;">
          <button class="community-status-update__image-button material-icons" ng-click="$ctrl.togglePopover($event, 'image')" aria-label="Insert an Image" aria-expanded="{{$ctrl.isImageExpanded}}" aria-hidden="{{$ctrl.activePopover !== null}}">insert_photo</button>
          <button class="community-status-update__video-button material-icons" ng-click="$ctrl.togglePopover($event, 'video')" aria-label="Insert a Video" aria-expanded="{{$ctrl.isVideoExpanded}}" aria-hidden="{{$ctrl.activePopover !== null}}">play_circle_filled</button>

          <div class="community-status-update-fullscreen-popover" ng-show="$ctrl.activePopover" role="dialog">
            <div class="community-status-update-popover__body">
              <div class="community-status-update-popover__title">
                <span ng-if="$ctrl.activePopover === 'image'">Insert an Image</span>
                <span ng-if="$ctrl.activePopover === 'video'">Insert a Video</span>
                <button class="community-status-update-popover__close material-icons"
                  ng-click="$ctrl.closePopover()">close</button>
              </div>
              <div class="community-status-update-popover__content">
                <button dropzone="$ctrl.dropzone" class="dropzone dropzone-btn" ng-show="$ctrl.activePopover === 'image'"></button>
                <p ng-if="$ctrl.activePopover === 'image'">OR</p>
                <p><input type="text" class="ut-form-control" ng-model="$ctrl.attachmentUrl" placeholder="Enter URL here" aria-label="Enter URL here"></p>
                <button type="button" ng-click="$ctrl.submitAttachment()" class="btn community-cta__button">Insert</button>
              </div>
            </div>
            <div class="community-status-update-popover__arrow"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="community-status-update-fullscreen__character-limit" aria-hidden="{{$ctrl.activePopover !== null || $ctrl.fullScreenOpen}}">
      {{$ctrl.remainingChars}} characters remaining
    </div>
  </div>

  <div class="community-status-update-body" aria-hidden="{{$ctrl.fullScreenOpen}}">
    <div ntux-avatar-badges="'me'" class="community-status-update__avatar"></div>
    <div class="community-status-update__arrow"></div>

    <div class="community-status-update__form hidden-sm hidden-md hidden-lg" aria-hidden="{{$ctrl.fullScreenOpen}}">
      <button class="community-status-update__fullscreen-button" ng-click="$ctrl.fullScreenOpen = true;$ctrl.setFocusToStatusMobile()">{{$ctrl.status.content || 'Write your status update here'}}</button>
    </div>

    <div class="community-status-update__form hidden-xs" aria-hidden="{{$ctrl.fullScreenOpen}}">
      <input suggestion-typeahead typeahead-template-url="app/components/status/suggestion-typeahead.html" typeahead-on-select="replaceSuggestion($item)" typeahead-loading="typeaheadLoading" typeahead="user for user in loadSuggestions($viewValue)" class="community-status-update__input" type="text" name="status" maxlength="{{$ctrl.STATUS_POST_MAX_CHARS}}" placeholder="Write your status update here" ng-model="$ctrl.status.content" ng-trim="false" aria-label="Write your status update here" aria-describedby="statusCharacterCount" id="communityStatusUpdateInput">
      <span class="community-status-update-attachment" ng-if="$ctrl.status.attachment">
        <i class="material-icons">{{$ctrl.status.attachment.icon}}</i>
        <span class="community-status-update-attachment__label">{{$ctrl.status.attachment.label}}</span>
        <button class="community-status-update-attachment__remove material-icons" ng-click="$ctrl.setAttachment(null)" aria-label="Cancel attachment {{$ctrl.status.attachment.label}}">close</button>
      </span>
      <div class="community-status-update__attachment-chooser">
        <button class="community-status-update__image-button material-icons" ng-click="$ctrl.togglePopover($event, 'image')" aria-label="Insert an Image" aria-expanded="{{$ctrl.isImageExpanded}}">insert_photo</button>
        <button class="community-status-update__video-button material-icons" ng-click="$ctrl.togglePopover($event, 'video')" aria-label="Insert a Video" aria-expanded="{{$ctrl.isVideoExpanded}}">play_circle_filled</button>

        <div class="community-status-update-popover" ng-show="$ctrl.activePopover" role="dialog">
          <div class="community-status-update-popover__body">
            <div class="community-status-update-popover__title">
              <span ng-if="$ctrl.activePopover === 'image'">Insert an Image</span>
              <span ng-if="$ctrl.activePopover === 'video'">Insert a Video</span>
              <button class="community-status-update-popover__close material-icons" ng-click="$ctrl.closePopover()">close</button>
            </div>
            <div class="community-status-update-popover__content">
              <button dropzone="$ctrl.dropzone" class="dropzone dropzone-btn" ng-show="$ctrl.activePopover === 'image'"></button>
              <p ng-if="$ctrl.activePopover === 'image'">OR</p>
              <p><input type="text" class="ut-form-control" ng-model="$ctrl.attachmentUrl" placeholder="Enter URL here" aria-label="Enter URL here"></p>
              <button type="button" ng-click="$ctrl.submitAttachment()" class="btn community-cta__button">Insert</button>
            </div>
          </div>
          <div class="community-status-update-popover__arrow"></div>
        </div>
      </div>
      <button class="community-status-update__submit" ng-click="$ctrl.submit()" ng-disabled="$ctrl.posting">Share status</button>
    </div>
  </div>

  <div id="statusCharacterCount" class="community-status-update__character-limit hidden-xs" aria-live="polite">
    {{$ctrl.remainingChars}} characters remaining
  </div>
  <div class="community-status-update__footer" aria-hidden="{{$ctrl.activePopover !== null || $ctrl.fullScreenOpen}}">
    Have more to say? <a href="/forums">Browse the Forum</a> to join community discussions.
  </div>
</div>
`;
