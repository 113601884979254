export default `
<div id="mainContent" class="dashboard">
  <div class="block container-fluid" id="mainContentActivity">
    <div class="row">
      <div class="col-md-10 col-sm-12">
        <div class="dashboard-card">
          <div class="activity-feed-header">
            <h1 class="dashboard-card__heading">Your Notifications</h1>
            <div class="activity-feed-filter">
              <ntux-select select-options="$ctrl.notificationFilterOptions" select-callback="$ctrl.updateFeed(value)"></ntux-select>
            </div>
          </div>

          <activity-list-item ng-show="$ctrl.feedType == 'all'" current-user="$ctrl.currentUser" activity="activity" ng-repeat="activity in $ctrl.allActivities.feedItems" ng-if="activity.type !== 'hidden'"></activity-list-item>
          <ntux-load-more ng-show="$ctrl.feedType == 'all'" load-callback="$ctrl.allActivities.loadMore()" is-loading="$ctrl.allActivities.isLoading" is-last-page="$ctrl.allActivities.isLastPage">
            <button aria-label="Load More Notifications" class="ntux-load-more-button">
              <span>Load More</span>
            </button>
          </ntux-load-more>

          <activity-list-item ng-show="$ctrl.feedType == 'community'" current-user="$ctrl.currentUser" activity="activity" ng-repeat="activity in $ctrl.communityActivities.feedItems" ng-if="activity.type !== 'hidden'"></activity-list-item>
          <ntux-load-more ng-show="$ctrl.feedType == 'community'" load-callback="$ctrl.communityActivities.loadMore()" is-loading="$ctrl.communityActivities.isLoading" is-last-page="$ctrl.communityActivities.isLastPage">
            <button aria-label="Load More Notifications" class="ntux-load-more-button">
              <span>Load More</span>
            </button>
          </ntux-load-more>

          <activity-list-item ng-show="$ctrl.feedType == 'projects'" current-user="$ctrl.currentUser" activity="activity" ng-repeat="activity in $ctrl.projectActivities.feedItems" ng-if="activity.type !== 'hidden'"></activity-list-item>
          <ntux-load-more ng-show="$ctrl.feedType == 'projects'" load-callback="$ctrl.projectActivities.loadMore()" is-loading="$ctrl.projectActivities.isLoading" is-last-page="$ctrl.projectActivities.isLastPage">
            <button aria-label="Load More Notifications" class="ntux-load-more-button">
              <span>Load More</span>
            </button>
          </ntux-load-more>

          <!-- need to come up with a scheme for this to work  -->
          <div class="dashboard-activity-no-list-items" ng-if="$ctrl.allActivities.feedItems.length === 0" ng-hide="$ctrl.allActivities.isLoading">
            <div class="dashboard-activity-no-list-items__primary">
              You don't have any recent activity.
            </div>

            <div class="dashboard-activity-no-list-items__secondary">
              Check out the community page to share content and meet other uTesters.
            </div>

            <a ui-sref="community" class="dashboard-link-button">
              Community Page
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
`;
