export default `
<div class="dashboard-card" style="min-height: 400px;">
  <h2 class="dashboard-card__heading">
    My Activity
  </h2>
  <div class="dashboard-card-tabset">
    <uib-tabset>
      <uib-tab class="dashboard-card-tabset-tab dashboard-card-tabset-tab--all" heading="All" type="All" select="$ctrl.setSelectedTab('All')">
        <dashboard-all-tab activities="$ctrl.activities" max-activities-count="$ctrl.maxActivitiesCount"></dashboard-all-tab>
      </uib-tab>
      <uib-tab class="dashboard-card-tabset-tab dashboard-card-tabset-tab--projects" heading="Projects" type="Projects" select="$ctrl.setSelectedTab('Projects')">
        <uib-tab-heading>Projects <span class="dashboard-card-tab-indicator">{{$ctrl.projectsCount || 0}}</span></uib-tab-heading>
        <dashboard-activity-projects-tab projects="$ctrl.projects" projects-count="$ctrl.projectsCount"></dashboard-activity-projects-tab>
      </uib-tab>
      <uib-tab class="dashboard-card-tabset-tab dashboard-card-tabset-tab--invitations" heading="Invitations" select="$ctrl.setSelectedTab('Invitations')" type="Invitations">
        <uib-tab-heading>Invitations <span class="dashboard-card-tab-indicator">{{$ctrl.invitationsCount || 0}}</span></uib-tab-heading>
        <dashboard-activity-invitations-tab invitations="$ctrl.invitations" invitations-count="$ctrl.invitationsCount"></dashboard-activity-invitations-tab>
      </uib-tab>
      <uib-tab class="dashboard-card-tabset-tab dashboard-card-tabset-tab--community" heading="Community" type="Community" select="$ctrl.setSelectedTab('Community')" >
        <dashboard-activity-community-tab community-activities="$ctrl.communityActivities"></dashboard-activity-community-tab>
      </uib-tab>
    </uib-tabset>
  </div>
</div>
`;
