export default `
<div class="user-tooltip">
  <div class="user-tooltip-inner">
    <div class="user-tooltip-inner-1">
      <span class="user-tooltip-caret"></span>
      <a ui-sref="profile.about({profileId: user.slug})" aria-hidden="true" tabindex="-1"><div avatar-badges="user" class="profile-thumb larger"></div></a>
      <p class="user-tooltip-info" ng-if="user.follow_stats.follows_current_user" >
        <i class="icon-check"></i> FOLLOWS YOU
      </p>
      <p>
        <span ng-bind="user.points | number:0"></span>
        <small>uPOINTS</small>
      </p>
    </div>
    <div class="user-tooltip-inner-2" style="padding-top: 5px;">
      <button id="closeUserTooltipBtn" autofocus type="button" class="close"
              ng-click="hideTooltip(); $event.preventDefault(); $event.stopImmediatePropagation()"
              ng-keypress="hideTooltipOnKeyPress($event)"
              aria-label="Close" style="color: white; opacity: 0.8;"><i class="icon-cancel" ></i></button>
      <div class="user-tooltip-inner-2-content" style="width: 100%">
        <p class="tooltip-user-name" ng-bind="user.name" aria-label="{{user.name}}"></p>
        <p ng-if="user.user_title">{{user.user_title}}</p>
        <p class="user-slug"><a ui-sref="profile.about({profileId: user.slug})" ng-bind="'@' + user.slug" aria-label="{{user.name}}, @{{user.slug}}, Community Profile"></a></p>
        <p ng-if="!userLocation" ng-bind="userRole"></p>
        <p ng-if="userLocation"><i class="icon-location"></i> <span ng-bind="userLocation"></span></p>
        <community-follow-btn user="user" ng-if="user.current_user_permissions['follow']"></community-follow-btn>
      </div>
    </div>
  </div>
</div>
`;
