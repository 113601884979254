import template from './community-status-update.component.html';
import controller from './community-status-update.controller';

const community = {
  bindings: {
    onSubmit: '&',
  },
  template,
  controller,
};

export default community;
