import {
  Directive, ElementRef, Input, OnChanges, OnInit, Renderer2, SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[bgImg]',
})
export class BgImgDirective implements OnInit, OnChanges {
  @Input() bgImg: string;
  @Input() swap: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.el.nativeElement.classList.add('blurable');

    if (this.swap) {
      const img = new Image();
      img.addEventListener('load', () => {
        this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.swap})`);
        this.el.nativeElement.classList.remove('blur');
      });
      img.src = this.swap;
      if (!img.complete) {
        this.el.nativeElement.classList.add('blur');
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.bgImg) {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.bgImg})`);
      this.renderer.setStyle(this.el.nativeElement, 'background-size', 'cover');
      this.renderer.setStyle(this.el.nativeElement, 'background-repeat', 'no-repeat');
      this.renderer.setStyle(this.el.nativeElement, 'background-position', 'center center');

      if (this.swap) {
        this.el.nativeElement.classList.add('blur');
      }
    }
  }
}
