import _ from 'lodash';

class LikedContentCtrl {
  constructor($scope, HomeFeedService, FeedStore) {
    $scope.feedStore = new FeedStore(_.bind(HomeFeedService.feed, HomeFeedService), { filter: { liked: true } });
    $scope.feedStore.load();

    $scope.removeStatus = function (status) {
      _.remove($scope.feedStore.feedItems, status);
    };
  }
}

LikedContentCtrl.$inject = ['$scope', 'HomeFeedService', 'FeedStore'];

export default LikedContentCtrl;
