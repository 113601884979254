import template from './course-track-nav-item.component.html';
import controller from './course-track-nav-item.controller';

const courseTrackNavItem = {
  bindings: {
    navCourse: '<',
    selectedCourse: '<',
    courseTrack: '<',
    failedCourses: '<',
  },
  template,
  controller,
};

export default courseTrackNavItem;
