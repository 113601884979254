import template from './community-feed-item-subscribe.component.html';
import controller from './community-feed-item-subscribe.controller';

const communityFeedItemSubscribe = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default communityFeedItemSubscribe;
