/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';

const suggestionBoxSetup = ({
  easymde, $element, $timeout, $scope, atConfig,
}) => {
  const suggestionBox = $('<div class="codeMirror-suggestion-box"></div>');
  $element.append(suggestionBox);

  const hideSuggestions = () => suggestionBox.hide();

  let focusBack;

  easymde.codemirror.on('blur', (editor) => {
    $timeout(() => {
      hideSuggestions();
      if (focusBack) {
        editor.focus();
        editor.setCursor(focusBack);
        focusBack = null;
      }
    }, 300);
  });

  const showSuggestions = (cm, items, choiceAction) => {
    if (_.isEmpty(items)) {
      hideSuggestions();
      return;
    }

    // This should be a directive
    const ul = $('<ul></ul>');

    _.forEach(items, ([itemHtml, itemVal]) => {
      ul.append(`<li data-val="${itemVal}" >${itemHtml}</li>`);
    });

    const editorOffset = $element.offset();

    suggestionBox.html('<span class="arrow"></span>').append(ul);

    $timeout(() => {
      const $cursorWrapper = $(cm.display.cursorDiv);
      const $cursor = $cursorWrapper.find('.CodeMirror-cursor');
      const $arrow = suggestionBox.find('.arrow');
      const offset = cm.cursorCoords('local');
      if (offset) {
        const outOfScreenLeft = offset.left - 95;
        if (outOfScreenLeft < 0) {
          offset.left = 95;
          $arrow.css('transform', `translateX(${outOfScreenLeft}px)`);
        } else {
          let outOfScreenRight = $cursorWrapper.width() - (offset.left + 75);
          if (outOfScreenRight < 0) {
            offset.left = $cursorWrapper.width() - 75;
            outOfScreenRight *= -1;
            $arrow.css('transform', `translateX(${outOfScreenRight}px)`);
          }
        }
        offset.top += $cursor.height() || 20;
        suggestionBox.css({
          top: offset.top - editorOffset.top + 10,
          left: offset.left - editorOffset.left,
        }).show();
      }
    });

    ul.find('li').on('click', ({ currentTarget }) => choiceAction($(currentTarget).attr('data-val')));
  };

  function checkScrollDown(li) {
    const x = suggestionBox.height() - li.height() + suggestionBox.offset().top - li.offset().top;
    if (x < 0) {
      const st = suggestionBox.find('ul').scrollTop();
      suggestionBox.find('ul').scrollTop(st - x + li.height());
    }
  }

  function checkScrollUp(li) {
    const x = li.offset().top - suggestionBox.offset().top;
    if (x < 0) {
      const st = suggestionBox.find('ul').scrollTop();
      suggestionBox.find('ul').scrollTop(st + x - 5);
    }
  }

  const ignoreIfSuggestionsOpen = () => (suggestionBox.is(':visible') ? null : easymde.codemirror.constructor.Pass);

  easymde.value($scope.ngModel);

  easymde.codemirror.addKeyMap({
    Enter: ignoreIfSuggestionsOpen,
    Up: ignoreIfSuggestionsOpen,
    Down: ignoreIfSuggestionsOpen,
  });

  const handleScrolling = (editor, name) => {
    if (!suggestionBox.is(':visible')) {
      return;
    }

    const keyHandlers = {
      Down: () => {
        if (suggestionBox.find('li.active + li').length) {
          const li = suggestionBox.find('li.active').removeClass('active').next('li').addClass('active');
          checkScrollDown(li);
        }
        if (!suggestionBox.find('li.active').length) {
          suggestionBox.find('li:first').addClass('active');
        }
      },

      Up: () => {
        if (suggestionBox.find('li.active').length && suggestionBox.find('li.active').prev('li').length) {
          const li = suggestionBox.find('li.active').removeClass('active').prev('li').addClass('active');
          checkScrollUp(li);
        }
      },

      Esc: hideSuggestions,

      Enter: () => {
        if (suggestionBox.find('li.active').length) {
          suggestionBox.find('li.active').click();
        }
      },
    };

    const handler = keyHandlers[name];
    if (_.isFunction(handler)) { handler(); }
  };

  const searchAndDisplay = (codeMirror) => {
    const { line, ch } = codeMirror.doc.getCursor();
    // This gets the whole line up to the cursor.
    const stringToTest = codeMirror.doc.getLine(line).substr(0, ch);

    const matchingConfig = _.find(atConfig, conf => stringToTest.match(conf.match));

    if (matchingConfig) {
      const match = stringToTest.match(matchingConfig.match);
      const matchResult = matchingConfig.feed(match);
      const rm = matchingConfig.replaceMatch || matchingConfig.match;

      const replaceFunction = function (val) {
        const blurredAtChar = codeMirror.doc.getCursor();
        const matchLength = stringToTest.match(rm)[0].length;
        codeMirror.doc.setSelection({ line, ch: ch - matchLength }, { line, ch });
        codeMirror.doc.replaceSelection(val);
        focusBack = { line, ch: blurredAtChar.ch + val.length - matchLength };
      };

      matchResult.then = matchResult.then || (λ => λ(matchResult));
      matchResult.then((matchResult) => {
        showSuggestions(codeMirror, matchResult, replaceFunction);
      });
    } else {
      hideSuggestions();
    }
  };

  easymde.codemirror.on('keyHandled', handleScrolling);
  easymde.codemirror.on('cursorActivity', _.debounce(searchAndDisplay, 400));
  easymde.codemirror.on('change', (doc) => {
    $timeout(() => ($scope.ngModel = doc.getValue()));
  });
};

export default suggestionBoxSetup;
