export default `
<div class="stat-date" ng-class="'stat-date--' + $ctrl.version">
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <polygon vector-effect="non-scaling-stroke" class="stat-date_bg" points="0,50 50,0 100,50 50,100" />
  </svg>
  <div class="stat-date_label" ng-bind="$ctrl.label" ></div>
  <div class="stat-date_month" ng-bind="$ctrl.date | date:'MMM'"></div>
  <div class="stat-date_year" ng-bind="$ctrl.date | date:'yyyy'"></div>
  <div class="stat-date_day"  ng-bind="$ctrl.date | date:'dd'"></div>
  <span ng-if="$ctrl.label === 'JOIN DATE'" id="statDateDetails1">Joined uTest on {{$ctrl.date | date:'M'}}/{{$ctrl.date | date:'dd'}}/{{$ctrl.date | date:'yyyy'}}</span>
  <span ng-if="$ctrl.label === 'LAST LOGIN'" id="statDateDetails2">Last login on {{$ctrl.date | date:'M'}}/{{$ctrl.date | date:'dd'}}/{{$ctrl.date | date:'yyyy'}}</span>
  <span ng-if="$ctrl.label === 'LAST COMMENT'" id="statDateDetails3">Last commented on {{$ctrl.date | date:'M'}}/{{$ctrl.date | date:'dd'}}/{{$ctrl.date | date:'yyyy'}}</span>
</div>
`;
