<!--
  ~ Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
  -->

<ngf-input-container [hasError]="hasError" [hasShadow]="hasShadow" [class.disabled]="disabled">
  <mat-icon *ngIf="!displayValue" ngfPrefix inline class="search-icon">search</mat-icon>

  <input *ngIf="!displayValue"
         #inputRef type="search" [formControl]="searchControl" [placeholder]="placeholder" (blur)="handleBlur()">

  <input *ngIf="displayValue"
         #inputRef
         [value]="displayValue" readonly
         (keydown.backspace)="handleClear()"
         (keydown.delete)="handleClear()"
         (blur)="handleBlur()"
         [disabled]="disabled">

  <button *ngIf="!!searchControl.value || displayValue"
          ngfSuffix type="button" class="clear-btn"
          (click)="handleClear()"
          [disabled]="disableClear || disabled">
    <mat-icon *ngIf='!disabled' inline>clear</mat-icon>
  </button>

  <button *ngIf="!displayValue"
          ngfSuffix type="button" class="toggle-btn" tabindex="-1"
          (click)="handleToggle()"
          [disabled]="disabled">
    <mat-icon inline>{{isOpen ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>
</ngf-input-container>
