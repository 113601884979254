import template from './dashboard-activity-project-empty-list-notification.component.html';

const dashboardActivityProjectEmptyListNotification = {
  bindings: {
    haveNo: '@',
  },
  template,
};

export default dashboardActivityProjectEmptyListNotification;
