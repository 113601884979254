import template from './nav-header-notifications.component.html';
import controller from './nav-header-notifications.controller';

const navHeaderNotifications = {
  bindings: {
    currentUser: '<',
  },
  template,
  controller,
};

export default navHeaderNotifications;
