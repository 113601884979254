export default `
<div id="mainContent">
  <uib-alert type="{{instruction.type}}" close="closeAlert($index)" ng-repeat="instruction in instructions">
    <div class="container" ng-bind-html="instruction.message" ></div>
  </uib-alert>
  <div class="container-fluid" >
      <form ng-submit="transfer()" class="form-clean" >
        <div class="input-row-invisible">
          <label>From Category</label>
          <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                     ng-model="transfer.fromCategory" >
            <ui-select-match placeholder="Select a From category" >{{$select.selected.fullName}}</ui-select-match>
              <ui-select-choices repeat="item in categories | filter: $select.search">
                <div ng-bind-html="item.fullName | highlight: $select.search"></div>
              </ui-select-choices>
          </ui-select>
        </div>

        <div class="input-row-invisible">
          <label>To Category</label>
          <ui-select role="combobox" aria-expanded="{{$select.open}}" aria-required="true"
                     ng-model="transfer.toCategory" >
            <ui-select-match placeholder="Select a To category" >{{$select.selected.fullName}}</ui-select-match>
              <ui-select-choices repeat="item in categories | filter: $select.search">
                <div ng-bind-html="item.fullName | highlight: $select.search"></div>
              </ui-select-choices>
          </ui-select>
        </div>
      </form>
  </div>
</div>
<div class="editor-footer">
  <div class="container-fluid editor-footer-content" >
    <button type="button" ng-disabled="saveInProgress" class="btn btn-yellow editor-footer-action-btn" ng-click="transfer()">Transfer</button>
  </div>
</div>
`;
