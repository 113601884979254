/*
 * Copyright (C) 2023 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Component } from '@angular/core';

@Component({
  selector: 'ngf-entire-window-pending',
  templateUrl: './entire-window-pending.component.html',
  styleUrls: ['./entire-window-pending.component.scss']
})
export class EntireWindowPendingComponent {
}
