import template from './recommendations.component.html';
import controller from './recommendations.controller';

const recommendations = {
  bindings: {
    items: '<',
  },
  template,
  controller,
};

export default recommendations;
