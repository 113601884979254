export default `
<button class="nav-sidebar-toggle" ng-click="$ctrl.toggleNavMenu($ctrl.action)" aria-label="{{($ctrl.action == 'open') ? 'open' : 'close'}} sidebar navigation" aria-expanded="{{$ctrl.action == 'close'}}">
  <div ng-if="$ctrl.action == 'open'" >
    <i class="material-icons">menu</i>
  </div>
  <div ng-if="$ctrl.action == 'close'" >
    <i class="material-icons">close</i>
  </div>
</button>
`;
