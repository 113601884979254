import _ from 'lodash';

class CourseService {
  $http: ng.IHttpService;
  cachedCoursePromise: any;
  cachedCourseId: string;
  cachedCourseCommentsPromise: Promise<any>;
  UrlService: any;
  constructor(UrlService, $http: ng.IHttpService) {
    this.UrlService = UrlService;
    this.$http = $http;
    this.cachedCoursePromise = null;
    this.cachedCourseCommentsPromise = null;
    this.cachedCourseId = null;
  }

  find(id) {
    if (!this.cachedCoursePromise || this.cachedCourseId !== id) {
      this.cachedCoursePromise = this.$http.get(`/api/v1/courses/${id}`).then(this.UrlService.addResponseUrl);
      this.cachedCourseId = id;
    }
    return this.cachedCoursePromise;
  }

  random() {
    return this.$http.get('/api/v1/courses/random').then(this.UrlService.addResponseUrls);
  }

  feed(parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10, filter: { state: 'published' } });

    return this.$http.get('/api/v1/courses', { params: parameters }).then(this.UrlService.addResponseUrls);
  }

  like(id) {
    return this.$http.post(`/api/v1/courses/${id}/like`, '');
  }

  unlike(id) {
    return this.$http.delete(`/api/v1/courses/${id}/like`);
  }

  comments(courseId) {
    return this.$http.get(`/api/v1/courses/${courseId}/comments`);
  }

  commentsPage(id, page, sort) {
    const params = {
      page: !page ? 1 : page,
      sort: sort || 'threaded',
    };

    return this.$http.get(`/api/v1/courses/${id}/comments/flat`, { params });
  }

  postComment(id, comment) {
    return this.$http.post(`/api/v1/courses/${id}/comments`, comment);
  }

  postCommentAndReturnCommentable(id, comment) {
    return this.$http.post(`/api/v1/courses/${id}/comments`, Object.assign(comment, { return_commentable: true }));
  }

  addTodo(id) {
    return this.$http.post(`/api/v1/courses/${id}/todo`, '');
  }

  removeTodo(id) {
    return this.$http.delete(`/api/v1/courses/${id}/todo`);
  }

  likedUsers(id, parameters) {
    parameters = _.defaults(parameters || {}, { page: 1, per_page: 10 });

    return this.$http.get(`/api/v1/courses/${id}/liked_users`, { params: parameters });
  }

  subscribe(id) {
    return this.$http.post(`/api/v1/courses/${id}/subscribe`, '');
  }

  unsubscribe(id) {
    return this.$http.delete(`/api/v1/courses/${id}/subscribe`);
  }

  addPageView(id) {
    return this.$http.post(`/api/v1/courses/${id}/page_view`, '');
  }
}

CourseService.$inject = ['UrlService', '$http'];

export default CourseService;
