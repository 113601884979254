export default `
<div class="sign-up-embed">
  <div ng-if="!currentUser && !isUserLoading">
    <div class="sign-up-form-container">
      <div class="sign-up-form-inner">
        <div class="sign-up-form">
          <a href="https://www.utest.com/signup" href="_self" class="btn btn-filled btn-yellow">Sign Up</a>
        </div>
      </div>
    </div>
  </div>
  <div ng-if="currentUser" class="sign-up-form-success">
    <h1>Hello {{currentUser.first_name}}</h1>
    <p>
      Looks like you're already signed up!
    </p>
    <a class="btn btn-info"  href="/logout" target="_self">Log Out</a>
  </div>
</div>
`;
