import _ from 'lodash';
import template from './write-status-attach.html';

const writeStatusAttach = function ($timeout, AlertService, EmbedlyService, UploadService) {
  return {
    restrict: 'E',
    replace: true,
    template,
    scope: {
      popoverPlacement: '@',
      setAttachment: '=?',
      status: '=',
    },
    link(scope, el) {
      scope.setAttachment = scope.setAttachment || function (att) {
        if (scope.status) {
          if (_.get(scope.status, 'attachment.id', false)) {
            UploadService.delete(scope.status.attachment.id);
          }
          scope.status.attachment = att;
        }
      };

      scope.setAttachment({});

      if (scope.status) {
        if (scope.status.attached_url) {
          if (scope.status.attached_url.indexOf('/') === 0) {
            scope.setAttachment({
              label: scope.status.attached_url.substring(scope.status.attached_url.lastIndexOf('/') + 1),
              value: scope.status.attached_url,
            });
          } else {
            EmbedlyService.getEmbed(scope.status.attached_url).then(({ data }) => {
              scope.setAttachment({
                label: data.title || data.url.substring(data.url.lastIndexOf('/') + 1),
                value: scope.status.attached_url,
              });
            }, () => {
              scope.setAttachment({
                label: scope.status.attached_url,
                value: scope.status.attached_url,
              });
            });
          }
        }
      }

      const $popover = $(el.find('.popover'));
      const $popoverParent = $(el);

      scope.closePopover = () => {
        const contentBtn: any = scope.attachmentPopoverShow === 'image' ? document.getElementsByClassName('status-update__image-button active')[0] : document.getElementsByClassName('status-update__video-button active')[0];
        contentBtn.focus();
        scope.attachmentPopoverShow = null;
        $popover.hide();
      };

      scope.popover = function (event, which) {
        event.preventDefault();
        if (which !== scope.attachmentPopoverShow) {
          const $target = $(event.target);
          $popover.css('left', $target.offset().left - $popoverParent.offset().left + ($target.width() / 2));
          event.target.classList.toggle('active');
          scope.attachmentPopoverShow = which;
          $popover.show();
          setTimeout(() => {
            const closeBtn: any = document.getElementsByClassName('icon-cancel clickable active')[0];
            closeBtn.focus();
          }, 100);
        } else {
          scope.closePopover();
        }
      };

      scope.imageFromUrl = {
        url: '',
        action() {
          scope.setAttachment({
            label: scope.imageFromUrl.url.substring(scope.imageFromUrl.url.lastIndexOf('/') + 1),
            value: scope.imageFromUrl.url,
            icon: 'icon-picture',
          });
          scope.closePopover();
          scope.embed.url = '';
        },
      };

      scope.embed = {
        url: '',
        action() {
          EmbedlyService.getEmbed(scope.embed.url).then(({ data }) => {
            scope.setAttachment({
              label: data.title,
              value: scope.embed.url,
              icon: 'icon-youtube-play',
            });
            scope.embed.url = '';
            scope.closePopover();
          });
        },
      };

      scope.dropzone = {
        onSuccess(file, res, dz) {
          scope.setAttachment({
            label: res.file_name,
            value: res.url,
            icon: 'icon-picture',
            type: 'upload',
            id: res.id,
          });
          dz.removeAllFiles(true);
          scope.closePopover();
          scope.$apply();
        },
        onError(err, dz) {
          dz.element.focus();
          if (_.isString(err)) {
            AlertService.add(err, 'danger');
          } else {
            switch (err.xhr.status) {
              case 400:
                setTimeout(() => {
                  AlertService.add('File type not allowed', 'danger');
                }, 500);
                break;
              case 0:
              case 413:
                AlertService.add('File too large', 'danger');
                break;
              case 429:
                AlertService.add('You reached the maximum allowed uploads in an hour. Please try again later.', 'danger');
                break;
              default:
                AlertService.add('File couldn\'t be uploaded', 'danger');
                break;
            }
          }
          dz.removeAllFiles(true);
          scope.$apply();
        },
      };
    },
  };
};

writeStatusAttach.$inject = ['$timeout', 'AlertService', 'EmbedlyService', 'UploadService'];

export default writeStatusAttach;
