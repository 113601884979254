import template from './community-feed-item-hide.component.html';
import controller from './community-feed-item-hide.controller';

const communityFeedItemHide = {
  bindings: {
    item: '<',
  },
  template,
  controller,
};

export default communityFeedItemHide;
