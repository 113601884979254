// Sets aria roles for <ul> <li> and <a> and aria selected for the active tab
export default (tabNavs: HTMLCollection, timeout: number = 10) => {
  setTimeout(() => {
    tabNavs[0].setAttribute('role', 'tablist');
    const tabElements = tabNavs[0].children;
    for (let i = 0; i < tabElements.length; i++) {
      tabElements[i].setAttribute('role', 'presentation');
      tabElements[i].firstElementChild.setAttribute('role', 'tab');
      tabElements[i].firstElementChild.setAttribute('aria-selected', String(tabElements[i].className === 'active'));
    }
  }, timeout);
};
