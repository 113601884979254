class DashboardActivityInvitationsTabCtrl {
  constructor(ConfigService) {
    const ctrl: ng.IController = this;
    ctrl.$onInit = () => {
      ConfigService.config().then((res) => {
        ctrl.profile_url = res.data.profile_url;
      });
    };
  }
}

DashboardActivityInvitationsTabCtrl.$inject = ['ConfigService'];

export default DashboardActivityInvitationsTabCtrl;
