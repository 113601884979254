import _ from 'lodash';

class ProgressStepCtrl {
  constructor() {
    const ctrl: ng.IController = this;

    ctrl.$onInit = () => {
      const totalSteps = ((ctrl.numberOfSteps * 1) || 4) + 1;
      ctrl.steps = _.range(1, totalSteps);
    };

    ctrl.stepClass = function (step) {
      const currentOrBlank = step === ctrl.stepNumber ? 'current' : 'disabled';
      return (step < ctrl.stepNumber ? 'done' : currentOrBlank);
    };
  }
}

export default ProgressStepCtrl;
