export default `
<div class="community-feed-filter" ng-if="$ctrl.user">
  <span id="ntuxSelectLabel">See content from: </span><ntux-select select-options="$ctrl.filterOptions" select-callback="$ctrl.updateFeed(value)"></ntux-select>
</div>
<div class="community-feed" infinite-scroll="$ctrl.loadMore();" infinite-scrolling-element="#main-content-container">
  <community-feed-item item="item" user="$ctrl.user" ng-repeat="item in $ctrl.communityFeedItems.feedItems" ng-show="$ctrl.feedType == 'entireCommunity'"></community-feed-item>
  <community-feed-item ng-if="$ctrl.user" item="item" user="$ctrl.user" ng-repeat="item in $ctrl.personalFeedItems.feedItems" ng-show="$ctrl.feedType == 'followedOnly'"></community-feed-item>
  <loading-dots ng-if="$ctrl.loadingMore"></loading-dots>
</div>
`;
