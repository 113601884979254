import controller from './project-feed-item.controller';
import template from './project-feed-item.component.html';

const projectFeedItem = {
  bindings: {
    project: '<',
    featured: '<',
    feed: '<',
  },
  template,
  controller,
};

export default projectFeedItem;
