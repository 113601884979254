/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, ElementRef, EventEmitter, forwardRef, InjectFlags, Injector, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CompareWithFn } from '../../+utils/compare-with-fn';
import { DisplayWithFn } from '../../+utils/display-with-fn';
import { FormFieldControl } from '../form-field/form-field-control';
import { OptNode } from '../option/opt-node';
import { OptionProviders } from '../option/option-providers';
import { OptionsOverlayComponent } from '../option/options-overlay/options-overlay.component';
import { OptionsRoot } from '../option/options-root';

@Component({
  selector: 'ngf-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    OptionProviders(AutocompleteComponent),
    {
      provide: FormFieldControl,
      useExisting: AutocompleteComponent
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true
    }
  ]
})
export class AutocompleteComponent implements ControlValueAccessor, FormFieldControl, OptionsRoot, OnChanges {
  @Input()
  set selected(value: any) {
    this.writtenValue.next(value);
  }

  @Input()
  placeholder = '';
  @Input()
  hasError = false;
  @Input()
  hasShadow = false;
  /**
   * @deprecated
   * use ngf-select with ngf-options-search-container instead
   */
  @Input()
  multiselect = false;
  @Input()
  clearDropdownEvent: Subject<void>

  /**
   * customize display of selected value,
   * by default option's HTMLElement.textContent will be displayed
   */
  @Input()
  displayWith: DisplayWithFn<any> | null = null;

  /**
   * customize value comparision,
   * by default values will be compared by reference
   */
  @Input()
  compareWith: CompareWithFn<any> | null = null;

  /**
   * allow null as option value,
   * by default null is treated as no value
   */
  @Input()
  nullAsValue = false;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('readonly')
  isReadonly = false;

  @Input()
  disabled = false;

  @Input()
  disableClear: boolean;

  @Output()
  search = new EventEmitter<string>();

  @Output()
  selectedChange = new EventEmitter();

  @ViewChild(OptionsOverlayComponent, {static: true})
  optionsOverlay: OptionsOverlayComponent;

  @ContentChildren(OptNode, {descendants: true})
  allChildren: QueryList<OptNode>;

  constructor(private cdr: ChangeDetectorRef,
              public elementRef: ElementRef<HTMLElement>,
              private injector: Injector) {

    this.writtenValue = new BehaviorSubject<any>(this.multiselect ? [] : null);
    this.writtenValue$ = this.writtenValue.asObservable();
  }

  private readonly writtenValue: BehaviorSubject<any>;

  readonly writtenValue$: Observable<any>;

  onChange: (value: any) => void = () => {};
  onTouched: () => void = () => {};

  get children(): OptNode[] {
    // OptNode's creates a tree, but @ContentChildren(..., {descendants: true}) return flattened array of all nodes
    // because of that we need only direct children, aka without `parent`
    return this.allChildren.filter(node => !node.parent);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('disabled') && this.hasFormControl()) {
      throw new Error('FormControl and disabled @Input used simultaneously. Please choose only one way of controlling disabled state.');
    }
  }

  handleSearch(search: string) {
    this.search.emit(search);

    if (search) {
      this.optionsOverlay.open();
    } else {
      this.optionsOverlay.close();
    }
  }

  handleSelection(value: any) {
    this.selectedChange.emit(value);
    this.onChange(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  writeValue(value: any): void {
    this.writtenValue.next(value);
    this.cdr.markForCheck();
  }

  setHasError(hasError: boolean): void {
    this.hasError = hasError;
    this.cdr.markForCheck();
  }

  private hasFormControl() {
    return !!this.injector.get(NgControl, null, InjectFlags.Optional | InjectFlags.Self);
  }
}
