/*
 * Copyright (C) 2021 to Present Applause App Quality, Inc. All rights reserved.
 */

import _ from 'lodash';

const UTEST_ACADEMY_TEST_CYCLE_ADDED = 'You have successfully registered for an upcoming uTest Academy practice test cycle!';
const UTEST_ACADEMY_TEST_CYCLE_ADD_ERROR = 'Sorry, there was a problem adding you to the test cycle. Please try again later.';
const UTEST_ACADEMY_TEST_CYCLE_PRECEDING_NOT_PASSED = 'Please pass the preceding practice test cycle to sign in to this one!';
const NOT_ACCEPTABLE_HTML_CODE = 406;

class CourseTrackNavCtrl {
  constructor($state, CourseTrackService, $window, localStorageService, $scope, UserService, AlertService) {
    const ctrl: ng.IController = this;
    let userId;

    ctrl.$onInit = () => {
      ctrl.course = ctrl.courseTrack.current_course;
      ctrl.courses = ctrl.courseTrack.courses;

      if (ctrl.courseTrack.has_quiz) {
        const quizStorageKey = `courseTrackQuiz${ctrl.courseTrack.id}`;
        ctrl.quizResults = localStorageService.get(quizStorageKey);
        if (ctrl.quizResults !== null) {
          ctrl.failedCourses = _.map(_.reject(ctrl.quizResults.userChoice, choice => choice.correct_answer), 'course_slug');
          if ($state.current.name === 'quiz') {
            ctrl.userIsTakingQuiz = true;
          }
        }
      }
      $scope.$on('testCycleSignUp', () => {
        ctrl.testCycleSignUp();
      });
      UserService.me().then((user) => {
        userId = user.data.platform_id;
      });
    };

    ctrl.$onChanges = (changes) => {
      ctrl.courseTrack = changes.courseTrack.currentValue;
    };

    ctrl.testCycleSignUp = () => {
      if (ctrl.courseTrack.academy_level) {
        CourseTrackService.getPendingAcademyTestCycleByLevel(ctrl.courseTrack.academy_level).then((testCycleResponse) => {
          const payload = {
            test_cycle_id: testCycleResponse.data.test_cycle_id,
            platform_user_id: userId,
            course_track_id: ctrl.courseTrack.id,
          };
          CourseTrackService.addTesterToTestCycle(payload).then((res) => {
            ctrl.courseTrack.user_has_completed_course_track = res?.data?.user_has_completed_course_track;
            AlertService.add(UTEST_ACADEMY_TEST_CYCLE_ADDED);
          }, (error) => {
            if (error.status === NOT_ACCEPTABLE_HTML_CODE) {
              AlertService.addDanger(UTEST_ACADEMY_TEST_CYCLE_PRECEDING_NOT_PASSED);
            } else {
              AlertService.addDanger(UTEST_ACADEMY_TEST_CYCLE_ADD_ERROR);
            }
          });
        }, () => AlertService.addDanger(UTEST_ACADEMY_TEST_CYCLE_ADD_ERROR));
      } else {
        $window.open(ctrl.courseTrack.test_cycle_link, '_blank');
        CourseTrackService.updateCourseTrackProgress(ctrl.courseTrack.id, {
          applied_to_test_cycle: true,
        }).then((res) => {
          ctrl.courseTrack.user_has_completed_course_track = res.data.user_has_completed_course_track;
        });
      }
    };
  }
}

CourseTrackNavCtrl.$inject = ['$state', 'CourseTrackService', '$window', 'localStorageService', '$scope', 'UserService', 'AlertService', 'featureFlags'];

export default CourseTrackNavCtrl;
