import _ from 'lodash';

function CompileService($compile, $timeout, $rootScope) {
  function replace(id, template, params) {
    $timeout(() => {
      $(`[data-compile-replace="${id}"]`).each((i, wrapper) => {
        const scope = _.merge($rootScope.$new(), params);
        const html = $compile(template)(scope);
        $(wrapper).html(html);
      });
    });
  }

  return {
    replace,
  };
}

CompileService.$inject = ['$compile', '$timeout', '$rootScope'];
export default CompileService;
