import angular from 'angular';

const scrolly = function ($transitions) {
  return {
    restrict: 'A',
    link(scope, element, attrs) {
      const scrollTarget = angular.element(attrs.scrollTarget);

      $transitions.onStart({}, () => {
        scrollTarget.removeClass();
      });

      $transitions.onSuccess({}, () => {
        element.scroll();
      });

      let lastPos = 0;
      const scrollDownOffset = 100;

      element.scroll(() => {
        const currentPos = element[0].scrollTop;
        const pageHeight = window.innerHeight;
        const docHeight = element[0].scrollHeight;

        if (currentPos > 0) {
          scrollTarget.addClass('default');
          if (currentPos > scrollDownOffset) {
            scrollTarget.addClass('detached');
            element.addClass('topOfWindow');
            if (lastPos > currentPos || currentPos + pageHeight === docHeight + 5) {
              scrollTarget.addClass('expanded');
              element.removeClass('topOfWindow');
            } else {
              scrollTarget.removeClass('expanded');
            }
          } else {
            scrollTarget.removeClass();
            element.removeClass('topOfWindow');
          }
        }
        lastPos = currentPos;
        $(element).closest('body, html > [ui-view]').animate({ scrollTop: 0 }, 'medium');
      });
    },
  };
};

scrolly.$inject = ['$transitions'];

export default scrolly;
