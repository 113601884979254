/*
 * Copyright (C) 2020 to Present Applause App Quality, Inc. All rights reserved.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { DisplayWithFn } from '../../+utils/display-with-fn';
import { OptionSelectionService } from './selection/option-selection.service';

@Pipe({
  name: 'displayValue'
})
export class DisplayValuePipe implements PipeTransform {

  constructor(private service: OptionSelectionService) {}

  transform(value: any, displayWith: DisplayWithFn<any> | null, nullAsValue = false): string | null {
    if (value || value === false || value === 0 || nullAsValue) {
      if (displayWith) {
        return displayWith(value);
      } else {
        const options = this.service.findOptionByValue(value);
        return options?.textContent ?? String(value);
      }
    } else {
      return null;
    }
  }

}
