const accessibleFormService = function accessibleFormService() {
  let topElement;
  let topElementY;
  let topElementX;
  let i;
  let j;

  function initializeFormService() {
    topElement = '';
    topElementX = null;
    topElementY = null;
    i = 0;
    j = 0;
  }

  function focusFirstError(form, $scope) {
    initializeFormService();
    const formErrors = Object.keys(form.$error);
    for (i = 0; i < formErrors.length; i++) {
      const error = formErrors[i];
      // need to ignore reCAPTCHA as there is no data associated with it
      if (error !== 'recaptcha') {
        findTopLeftMostElement(form.$error[error]);
      }
    }
    document.getElementById(topElement).focus({ preventScroll: true });
    // if using ui-select elements in form, add focus-on="{name} + 'focus'" it will be focused on this broadcast
    $scope.$broadcast(`${topElement}Focus`);
  }

  function findTopLeftMostElement(errorList) {
    for (j = 0; j < errorList.length; j++) {
      const errorName = errorList[j].$name;
      const errorY = document.getElementById(errorName).getBoundingClientRect().top;
      const errorX = document.getElementById(errorName).getBoundingClientRect().x;

      if (topElementY == null || errorY < topElementY) {
        topElement = errorName;
        topElementY = errorY;
      } else if (errorY === topElementY) {
        topElementX = document.getElementById(topElement).getBoundingClientRect().x;
        if (errorX < topElementX) {
          topElement = errorName;
          topElementY = errorY;
        }
      }
    }
  }

  return {
    focusFirstError,
  };
};

export default accessibleFormService;

